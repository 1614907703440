<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Details</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          :sm="cp ? '4' : '4'"
          v-for="item in data"
          :key="item.icon"
          :class="item.customClass"
        >
          <b-media
            no-body
            :class="
              item.subtitle == 'Customers' || item.subtitle == 'Projects'
                ? 'cursor-pointer'
                : ''
            "
            @click="showCustomer(item.subtitle)"
          >
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <svg
                  v-if="item.icon == 'rupee'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-currency-rupee"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z"
                  />
                </svg>
                <feather-icon size="24" :icon="item.icon" v-else />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ kFormatter(item.title) }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
    <b-modal
      id="modal-form"
      v-model="visiblePopUp"
      size="md"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      title="Customers"
      hide-footer
    >
      <div style="width: 100%; overflow: auto">
        <table class="table table-bordered">
          <thead class="text">
            <tr>
              <th>Sr. No.</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in customerOption" :key="index" @click="$router.push(`/crm/customer/editcustomer/${item.id}`)">
              <td>{{ index + 1 }}</td>
              <td>{{ item.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import axios from '@/components/axios';
import { kFormatter } from "@core/utils/filter";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    cp: {},
  },
  data() {
    return {
      visiblePopUp: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      customerOption: [],
      userId: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.userId = userData.id;
    this.getCustomer();
  },
  methods: {
    kFormatter,
    showCustomer(title) {
      if (title == "Customers") {
        this.visiblePopUp = true;
      } else if (title == "Projects") {
        this.$router.push("/project");
      }
      else if (title == "Bulk Lands") {
        this.$router.push("/bulkland");
      }
    },
    async getCustomer() {
      const data = {
        assignto: this.userId,
      };
      const requestoption = {
        method: "POST",
        url: `${this.baseApi}/getcustomerassignby`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
      };
      await axios(requestoption).then((response) => {
        this.customerOption = response.data.data;
      });
    },
  },
};
</script>
