<template>
  <b-card v-if="data" class="earnings-card">
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1"> Earnings </b-card-title>
        <div class="font-small-2">This Month</div>
        <h5 class="mb-1">₹{{ kFormatter(earning) }}</h5>
        <b-card-text class="text-muted font-small-2">
          <span class="font-weight-bolder">{{ per ? per : 0 }}%</span
          ><span> {{ msg ? msg : "more earnings than last month" }}.</span>
        </b-card-text>
      </b-col>
      <b-col cols="6" v-if="success">
        <!-- chart -->
        <vue-apex-charts
          height="120"
          :options="earningsChart.chartOptions"
          :series="series"
          type="donut"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { kFormatter } from '@core/utils/filter'
import { $themeColors } from '@themeConfig'
import axios from '@/components/axios'

const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      success: false,
      thisMonthPr: '',
      lastPr: '',
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: { show: false },
          comparedResult: [2, -3],
          labels: ['Last Month', 'This Month'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor3, $earningsStrokeColor2],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20
            }
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15
                  },
                  value: {
                    offsetY: -15,
                    formatter (val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    }
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Total',
                    formatter () {
                      return '100%'
                    }
                  }
                }
              }
            }
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100
                }
              }
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120
                }
              }
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100
                }
              }
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120
                }
              }
            }
          ]
        }
      },
      accessToken: localStorage.getItem('accessToken'),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      earning: 0,
      lastEarning: 0,
      month: new Date().getMonth(),
      per: 0,
      msg: '',
      series: []
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    kFormatter,
    async fetchData () {
      await axios({
        method: 'GET',
        url: `${this.baseApi}/graph`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        }
      })
        .then((json) => {
          json.data.data.map((item) => {
            if (item.month == this.month) {
              this.lastEarning = item.amount
            }
            if (item.month == this.month + 1) {
              this.earning = item.amount
              if (parseFloat(this.earning) < parseFloat(this.lastEarning)) {
                this.per =
                  100 - ((this.earning * 100) / this.lastEarning).toFixed(1)
                this.msg = 'Less earnings than last month'
              } else {
                const mid = this.earning - this.lastEarning
                this.per = (this.mid * 100) / this.lastEarning
                this.msg = 'More earnings than last month'
              }
            }
          })
          const total = this.earning + this.lastEarning
          this.lastPr = this.lastEarning
            ? ((this.lastEarning * 100) / total).toFixed(1)
            : 1
          this.thisMonthPr = 100 - this.lastPr
          this.series = [parseFloat(this.lastPr), parseFloat(this.thisMonthPr)]
          this.success = true
        })
        .catch((error) => console.log(error, 'error'))
    }
  }
}
</script>
