import axios from 'axios';
import { computed } from 'vue';
export default {
  data() {
    return {
    };
  },
  // created: function() {
  //   this.IVRLogin();
  // },
  computed:{
    handleLegalStatus(){
      const statusTitle =[
        {
          title: 'Entry',
          id:0,
        },
        {
          title: 'BanaChhiththi Draft',
          id:1,

        }, {
          title: 'Title Form For Signature of Farmer',
          id:2,

        }, {
          title: 'Security Report',
          id:3,

        }, {
          title: 'Publication in News Paper',
          id:4,

        }, {
          title: 'Revenue Records Documents',
          id:5,

        }, {
          title: 'Agreement to Sale',
          id:6,

        }, {
          title: 'Submission of NA Application',
          id:7,

        }, {
          title: 'Title Report-Final',
          id:8,

        }, {
          title: 'Doc -> Query of Title Report',
          id:9,

        },
        {
          title: 'Id of Buyer for Appointment Schedule',
          id:10,

        }, {
          title: 'Sale Deed for Execution',
          id:11,

        },
        {
          title: 'POA (Power of Attorney)',
          id:12,

        }, {
          title: 'Uploading of Executed Docs',
          id:13,

        }, {
          title: 'Citation of Entries',
          id:14,

        }, {
          title: 'Confirmation of Buyer (for received documents)',
          id:15,

        },
      ]
      return (status) => statusTitle[status].title;

    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Active: "light-success",
        Inactive: "light-danger",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Pending: "secondary",
        Approved: "light-success",
        Cancelled: "danger",
        Applied: "light-info",
        Sold: "yellow",
        Booked: "info",
        Vacant: "success",
        Hold: "warning",
        "Pre Launching": "success",
        Launching: "info",
        Registered: 'danger',
        Running: "primary",
        "Sold Out": "danger",
        MQL: "light-success",
        "In Progress": "light-primary",
        "Not Interested": "light-danger",
        "Site Visit": "light-warning",
        "Schedule Zoom Demo": "light-info",
        Presentation: "light-success",
        Lost: "light-warning",
        Close: "light-danger",
        Accepted: "light-success",
        Complete: 'success',
        Open: 'info',
        pending: 'danger'
        /* eslint-enable key-spacing */
      };
      return (status) => statusColor[status];
    },
  },
  data(){
    return{
      imagePath: process.env.VUE_APP_IMAGE_PATH,
    }
  },
  methods: {
    getprofileImage(image){
      if(image !== undefined && image !== null && image !== ''){
        let test =  image.includes(this.imagePath) ? image :  this.imagePath + image
        return test
      }
      return image
    },
    IVRLogin() {
      const options = {
        method: 'POST',
        url: 'https://api-smartflo.tatateleservices.com/v1/auth/login',
        headers: {accept: 'application/json', 'content-type': 'application/json'},
        data: {email: 'TACN3303', password: 'Admin@123456'}
      };
      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          localStorage.setItem('IVRData',JSON.stringify(response.data))
          alert('Please try Again')
        })
        .catch(function (error) {
          console.error(error);
        });
    },

    roundOfDecimal(value, decimalpoint=2) {
      let round = Math.round(value * Math.pow(10,decimalpoint)) / Math.pow(10,decimalpoint)
      return isNaN(round) ? 0 : round;
    },
    twoDecimal(value,decimalpoint) {
        return parseFloat(value ? value : 0).toFixed(decimalpoint ? decimalpoint:2)
    },


  }
};
