<template>
  <div>
    <h1
      class="p-2"
      v-if="
        $store.getters['app/widgetAccess']('Lead Details') ||
        $store.state.app.user_data.role == 'admin'
      "
    >
      Lead Details
    </h1>
    <b-row
      class="d-flex justify-content-center"
      v-if="
        $store.getters['app/widgetAccess']('Lead Details') ||
        $store.state.app.user_data.role == 'admin'
      "
    >
      <b-col
        :lg="userData.role == 'admin' ? 3 : 2"
        sm="4"
        v-for="(item, index) in leaddetails"
        :key="index + 1"
        class="cursor-pointer"
        @click="handleRoute(item.route)"
      >
        <smallcard
          :icon="item.icon"
          :statistic="item.title"
          :statistic-title="item.subtitle"
          :color="item.color"
        />
      </b-col>
      <b-col
        :lg="userData.role == 'admin' ? 3 : 2"
        sm="4"
        v-for="(item, index) in leadcategory"
        class="cursor-pointer"
        :key="(index + 1) * 100"
        @click="handleRoute(item.route)"
      >
        <smallcard
          :icon="item.icon"
          :statistic="item.title"
          :statistic-title="item.subtitle"
          :color="item.color"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        v-if="
          $store.getters['app/widgetAccess']('Lead Stage Details') ||
          $store.state.app.user_data.role == 'admin'
        "
      >
        <b-card>
          <b-card-header>
            <b-card-title>Lead Stage Details</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body" style="height: 470px; overflow: auto">
            <b-row>
              <b-col
                :lg="userData.role == 'admin' ? 4 : 3"
                cols="6"
                v-for="(item, index) in leadstage"
                :key="index"
                class="cursor-pointer"
                @click="handleRoute(item.route)"
              >
                <leadstagesmall
                  :icon="item.icon"
                  :statistic="item.title"
                  :statistic-title="item.subtitle"
                  :color="item.color"
                />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="6"
        v-if="
          $store.getters['app/widgetAccess']('Lead Source Details') ||
          $store.state.app.user_data.type == 'admin'
        "
      >
        <b-card>
          <b-card-header>
            <b-card-title>Lead Source Details</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body" style="height: 470px; overflow: auto">
            <b-row>
              <b-col
                :lg="userData.role == 'admin' ? 4 : 3"
                cols="6"
                v-for="(item, index) in leadsource"
                :key="index"
                class="cursor-pointer"
                @click="handleRoute(item.route)"
              >
                <leadstagesmall
                  :icon="item.icon"
                  :statistic="item.title"
                  :statistic-title="item.subtitle"
                  :color="item.color"
                />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "@/components/axios";

import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import StatisticCardWithLineChart from "@core/components/statistics-cards/StatisticCardWithLineChart.vue";
import { kFormatter } from "@core/utils/filter";

import CardStatisticOrderChart from "../../card/card-statistic/CardStatisticOrderChart.vue";
import CardStatisticProfitChart from "../../card/card-statistic/CardStatisticProfitChart.vue";
import CardStatisticsGroup from "../../card/card-statistic/CardStatisticsGroup.vue";
import leadstagesmall from "@core/components/statistics-cards/leadstagesmall.vue";
import smallcard from "@core/components/statistics-cards/smallcard.vue";

export default {
  components: {
    BRow,
    BCardBody,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    StatisticCardVertical,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    CardStatisticOrderChart,
    CardStatisticProfitChart,
    CardStatisticsGroup,
    leadstagesmall,
    smallcard,
  },
  props: ["data"],
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      // Area charts
      subscribersGained: {},
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {},

      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},
      leaddetails: [
        {
          icon: "UsersIcon",
          color: "primary",
          title: "0",
          subtitle: "Total Lead",
          customClass: "mb-2 mb-xl-0",
          route: "/crm/lead",
        },
        {
          icon: "UsersIcon",
          color: "success",
          title: "0",
          subtitle: "Transfer Lead",
          customClass: "",
          route: "/crm/lead?filter=transferlead",
        },
      ],
      leadcategory: [],
      leadstage: [],
      leadsource: [],
      second: [],
      third: [],
      visitCount: 0,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
    };
  },
  mounted() {
    this.getLeadDetails();
  },
  methods: {
    handleRoute(route) {
      this.$router.push(route);
    },
    async getLeadDetails() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/lead-count`,
      };

      await axios(requestOptionsUsers).then((response) => {
        let data = response.data;
        this.leaddetails[0].title = data.total;
        this.leaddetails[1].title = data.transferlead;
        if (
          this.$store.getters["app/widgetAccess"]("Lead assign to employee") ||
          this.$store.state.app.user_data.role == "admin"
        ) {
          this.leaddetails.push({
            icon: "UsersIcon",
            color: "danger",
            title: data.assignleadtoemployee,
            subtitle: "Lead Assign to Employee",
            customClass: "",
            route: "/crm/lead?filter=employee",
          });
        }
        if (
          this.$store.getters["app/widgetAccess"]("Lead assign to cp") ||
          this.$store.state.app.user_data.role == "admin"
        ) {
          this.leaddetails.push({
            icon: "UsersIcon",
            color: "warning",
            title: data.assignleadtocp,
            subtitle: "Lead Assign to CP",
            customClass: "",
            route: "/crm/lead?filter=cp",
          });
        }

        this.leadcategory = [];
        this.leadstage = [];
        this.leadsource = [];

        let arr = ["primary", "success", "warning", "danger", "secondary"];
        data.leadcategory.map((item) => {
          this.leadcategory.push({
            icon: "UsersIcon",
            color: arr[Math.floor(Math.random() * arr.length)],
            title: item.count,
            subtitle: item.name,
            customClass: "mb-2 mb-xl-0",
            route: `crm/lead?leadcategory_id=${item.id}`,
          });
        });
        console.log(this.leadcategory);
        data.leadsource.map((item) => {
          if (item.count != 0) {
            this.leadsource.push({
              icon: "UsersIcon",
              color: arr[Math.floor(Math.random() * arr.length)],
              title: item.count,
              subtitle: item.name,
              customClass: "mb-2 mb-xl-0",
              route: `crm/lead?leadsource=${item.id}`,
            });
          }
        });
        Object.keys(data.leadstatge).map((item) => {
          if (data.leadstatge[item] != 0) {
            this.leadstage.push({
              icon: "UsersIcon",
              color: arr[Math.floor(Math.random() * arr.length)],
              title: data.leadstatge[item],
              subtitle: item,
              customClass: "mb-2 mb-xl-0",
              route: `crm/lead?status=${item}`,
            });
          }
        });
      });
    },
  },
};
</script>
