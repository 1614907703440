import { render, staticRenderFns } from "./ApprovalStatic.vue?vue&type=template&id=4f084611&"
import script from "./ApprovalStatic.vue?vue&type=script&lang=js&"
export * from "./ApprovalStatic.vue?vue&type=script&lang=js&"
import style0 from "./ApprovalStatic.vue?vue&type=style&index=0&id=4f084611&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports