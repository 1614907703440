import { render, staticRenderFns } from "./Email.vue?vue&type=template&id=697d1a68&scoped=true&"
import script from "./Email.vue?vue&type=script&lang=js&"
export * from "./Email.vue?vue&type=script&lang=js&"
import style0 from "./Email.vue?vue&type=style&index=0&id=697d1a68&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "697d1a68",
  null
  
)

export default component.exports