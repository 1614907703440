<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label> Select Softwares</label>
              <validation-provider
                #default="{ errors }"
                name="Softwares"
                rules="required"
              >
                <v-select
                  label="name"
                  v-model="software"
                  placeholder="None"
                  :options="softwareOption"
                  @input="handelClickSoftware($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label> Select Modules</label>
              <validation-provider
                #default="{ errors }"
                name="Modules"
                rules="required"
              >
                <v-select
                  label="name"
                  v-model="module"
                  placeholder="None"
                  :options="modulesOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Enter Name</label>
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            ><label class="mb-1"> Enable/Disable</label>
            <b-form-checkbox
              checked="true"
              v-model="enabledisable"
              @input="handelClickEnableDisable"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <div class="pt-2 flex" style="justify-content: center">
          <b-button
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
            :disabled="flag"
          >
            submit
          </b-button>
          <b-button class="ml-2" variant="primary" @click="handleBack">
            back
          </b-button>
        </div>
        <!-- <div class="mt-2" style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="width:36cm">
            <thead class="text">
              <tr>
                <th>Sr.No.</th>
                <th>type</th>
                <th>Approval Name</th>
                <th>Level1 Type</th>
                <th>Level1</th>
                <th>Level2 Type</th>
                <th>Level2</th>
                <th>Level3 Type</th>
                <th>Level3</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in reminders" :key="index">
                <td>
                  <label>{{ index + 1 }} </label>
                </td>
                <td>
                  <label>{{ item.modules }}</label>
                </td>
                <td>{{ item.name }}</td>
                <td>
                  <label>{{ userReminder }}</label>

        <v-select :v-model="userReminder[item.id] &&
                  userReminder[item.id]['level1']
                  ? userReminder[item.id]['level1']
                  : 0" @input="handelclick(item.id, $event, 'level1')" placeholder="None" label="name"
                    :options="level" />
                </td>

                <td>
                  <v-select v-if="showlevel1[item.id]" :v-model="userReminder[item.id] &&
                  userReminder[item.id]['level1type']
                  ? userReminder[item.id]['level1type']
                  : 0" @input="handelclick(item.id, $event, 'level1type')" label="name" :options="userOptions"
                    multiple />
                </td>
                <td>
                  <v-select :v-model="userReminder[item.id] &&
                  userReminder[item.id]['level2']
                  ? userReminder[item.id]['level2']
                  : 0" @input="handelclick(item.id, $event, 'level2')" placeholder="None" label="name"
                    :options="level" />
                </td>
                <td>
                  <v-select v-if="showlevel2[item.id]" :v-model="userReminder[item.id] &&
                  userReminder[item.id]['level2type']
                  ? userReminder[item.id]['level2type']
                  : 0" @input="handelclick(item.id, $event, 'level2type')" label="name" :options="userOptions"
                    multiple />
                </td>
                <td>
                  <v-select :v-model="userReminder[item.id] &&
                  userReminder[item.id]['level3']
                  ? userReminder[item.id]['level3']
                  : 0" @input="handelclick(item.id, $event, 'level3')" placeholder="None" label="name"
                    :options="level" />
                </td>
                <td>
                  <v-select v-if="showlevel3[item.id]" :v-model="userReminder[item.id] &&
                  userReminder[item.id]['level3type']
                  ? userReminder[item.id]['level3type']
                  : 0" @input="handelclick(item.id, $event, 'level3type')" label="name" :options="userOptions"
                    multiple />
        </td>
        <td>
                  <v-select label="name" :options="level" />
                </td>
                <td>
                  <v-select label="name" :options="userOptions" multiple />
                </td>
                <td>
                  <v-select label="name" :options="level" />
                </td>
                <td>
                  <v-select label="name" :options="userOptions" multiple />
                </td>
                <td>
                  <v-select label="name" :options="level" />
                </td>
                <td>
                  <v-select label="name" :options="userOptions" multiple />
                </td>-->
        <!-- </tr>
            </tbody>
          </table>
        </div> -->
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from '@/components/axios';
import {
  BCard,
  BFormInput,
  BFormGroup,
  BButton,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    "v-select": vSelect,
    BCard,
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BButton,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    required,
    BFormCheckbox,
  },
  props: ["closepopup", "getGridData", "selectedID"],
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      enabledisable: "",
      software: "",
      module: "",
      name: "",
      softwareOption: [],
      modulesOption: [],
      reminders: "",
      level: [{ name: "None" }, { name: "User" }, { name: "Parent" }],
      userReminder: [],
      userOptions: [],
      showlevel1: {},
      showlevel2: {},
      showlevel3: {},
      enabledisablePayload: 0,
      ifEdit: false,
      test: "",
      id: "",
      flag: false,
    };
  },
  mounted() {
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      this.$router.push("/");
    }
    this.getSoftWare();
    this.ifEdit = !!this.selectedID;
    this.ifEdit && this.getEditValue();
    // this.getReminder()
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getreminderById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        (this.software = this.getSoftWare(item.software)),
          (this.test = item.module);
        (this.module = this.handelClickSoftware(item.software)),
          (this.name = item.name),
          (this.enabledisable = item.active == 1);
      });
    },
    // async getReminder(){
    //   this.getUsersdata()
    //     const user = {
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${this.accessToken}`
    //       },
    //       url: `${this.baseApi}/getreminder`
    //     }
    //     await axios(user)
    //       .then((response) => {
    //         console.log(response)
    //         this.reminders = response.data.data
    //         console.log(this.reminders, 'approvals==')

    //         this.reminders.map((item) => {
    //           if (!this.userReminder[item.id]) {
    //             this.userReminder[item.id] = {}
    //           }
    //           item.id ? this.userReminder[item.id]['id'] = item.id : this.userReminder[item.id]['id'] = null
    //           item.SoftwareName ? this.userReminder[item.id]['SoftwareName'] = item.SoftwareName : this.userReminder[item.id]['SoftwareName'] = null
    //           item.modulesName ? this.userReminder[item.id]['modulesName'] = item.modulesName : this.userReminder[item.id]['modulesName'] = null
    //           item.name ? this.userReminder[item.id]['name'] = item.name : this.userReminder[item.id]['name'] = null
    //           item.level1 ? this.userReminder[item.id]['level1'] = item.level1 : this.userReminder[item.id]['level1'] = null
    //           item.level1type ? this.userReminder[item.id]['level1type'] = item.level1type : this.userReminder[item.id]['level1type'] = []
    //           item.level2 ? this.userReminder[item.id]['level2'] = item.level2 : this.userReminder[item.id]['level2'] = null
    //           item.level2type ? this.userReminder[item.id]['level2type'] = item.level2type : this.userReminder[item.id]['level2type'] = []
    //           item.level3 ? this.userReminder[item.id]['level3'] = item.level3 : this.userReminder[item.id]['level3'] = null
    //           item.level3type ? this.userReminder[item.id]['level3type'] = item.level3type : this.userReminder[item.id]['level3type'] = []
    //         })
    //       })
    //       .catch((error) => console.log(error, 'error'))
    // },
    async getSoftWare(id) {
      const softwares = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getsoftwares`,
      };

      await axios(softwares)
        .then((response) => {
          this.softwareOption = response.data.data;
          if (id != undefined) {
            this.softwareOption.map((item) => {
              if (item.id == id) {
                this.software = item;
              }
            });
          }
          this.$forceUpdate();
        })
        .catch((error) => console.log(error, "error"));
    },
    handelClickSoftware(e) {
      this.module = "";
      if (!this.ifEdit) {
        this.id = e.id;
      }
      const module = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getmodulesBySid`,
        data: {
          sid: this.ifEdit ? e : this.id,
        },
      };

      axios(module)
        .then((response) => {
          this.modulesOption = [];
          this.modulesOption = response.data.data;
          if (this.ifEdit) {
            this.modulesOption.map((item) => {
              if (this.test == item.id) {
                this.module = item;
              }
            });
          }
          this.$forceUpdate();
        })
        .catch((error) => console.log(error, "error"));
    },
    handelClickEnableDisable() {
      // console.log(e, 'enabledisable')
      if (this.enabledisable == true) {
        this.enabledisablePayload = 1;
      } else {
        this.enabledisablePayload = 0;
      }
    },
    //  handelclick(id, e, name) {
    //   console.log(e.name, 'userRemindere.name')
    //   console.log(name, 'userRemindername')
    //   console.log(id, 'userReminderid')
    //   if (name == 'level1type' || name == 'level2type' || name == 'level3type') {
    //     this.userReminder[id][`${name}`] = e
    //   }
    //   if (name == 'level1' || name == 'level2' || name == 'level3') {
    //     this.userReminder[id][`${name}`] = e.name
    //     if (this.userReminder[id][`${name}`] == 'User') {
    //       if (name == 'level1') {
    //         this.showlevel1[id] = true
    //       } else if (name == 'level2') {
    //         this.showlevel2[id] = true
    //       } else if (name == 'level3') {
    //         this.showlevel3[id] = true
    //       }
    //     } else if (name == 'level1') {
    //       this.userReminder[id][`${name}`] = null
    //       this.showlevel1[id] = false
    //     } else if (name == 'level2') {
    //       this.userReminder[id][`${name}`] = null
    //       this.showlevel2[id] = false
    //     } else if (name == 'level3') {
    //       this.userReminder[id][`${name}`] = null
    //       this.showlevel3[id] = false
    //     }
    //   }
    //   console.log(this.userReminder, 'userReminder')
    //   this.$forceUpdate()
    // },
    handelClickEnableDisable() {
      // console.log(e, 'enabledisable')
      if (this.enabledisable == true) {
        this.enabledisablePayload = 1;
      } else {
        this.enabledisablePayload = 0;
      }
    },
    // getUsersdata() {
    //   const accessToken = localStorage.getItem('accessToken')
    //   const baseApi = process.env.VUE_APP_APIENDPOINT
    //   const user = {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': `Bearer ${accessToken}`
    //     },
    //     url: `${baseApi}/getUsers`
    //   }
    //   axios(user)
    //     .then((response) => {
    //       this.userOptions = []
    //       console.log(response)
    //       this.userOptions = response.data.data
    //       response.data.data.map((item) => {
    //         this.userOptions.push({ name: item.name, value: item.id })
    //       })

    //     })
    //     .catch((error) => console.log(error, 'error'))
    // },
    async validationForm() {
      const data = {
        software: this.software.id,
        module: this.module.id,
        name: this.name,
        active: this.enabledisablePayload,
      };
      this.$refs.simpleRules.validate().then(async (success) => {
        if (!success) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;

          const requestOptions = {
            method: this.ifEdit ? "PUT" : "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data,
            url: this.ifEdit
              ? `${this.baseApi}/reminder/${this.selectedID}`
              : `${this.baseApi}/reminder`,
          };
          await axios(requestOptions)
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // this.getReminder()
              this.closepopup();
              this.getGridData();
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleBack() {
      this.closepopup();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
