<template>
  <div>
    <b-row>


        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Village:</label>
          <p class="viewValue">{{ data.village ? data.village.village_name :'' }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Survey No. New :</label>
          <p class="viewValue">{{ data.newsurveyno }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Survey No. Old :</label>
          <p class="viewValue">{{ data.oldsurveyno }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> OP FP. No :</label>
          <p class="viewValue">{{ data.opfpno ? data.opfpno.opno:'' }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> FP. No :</label>
          <p class="viewValue">{{ data.opfpno ? data.opfpno.fpno:'' }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Account No / Khata No :</label>
          <p class="viewValue">{{ data.accountno }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Name :</label>
          <p class="viewValue">{{ data.projectname }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Phase Name :</label>
          <p class="viewValue">{{ data.phasename }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Phase Image (1100px * 700px) :</label>
          <img :src="data.phaseattachment" style="height: 150px; width:150px"/>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> No. Of Plots :</label>
          <p class="viewValue">{{ data.noofplot }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> No. Of Gardens :</label>
          <p class="viewValue">{{ data.noofgarden }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel">Bank :</label>
          <p class="viewValue"  v-for="(item, index) in data.bank_data" :key="index">
          {{ item.name }}
          </p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Price (per sq. yard) :</label>
          <p class="viewValue">{{ data.pricepersqyd }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Value (in %) :</label>
          <p class="viewValue">{{ data.projectvalue }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Development Plan Value(in %):</label>
          <p class="viewValue">{{ data.developmentplanvalue }}</p>
        </b-col>
        <b-col md="4" class="mt-2" >
          <label class="viewLabel"> Corner phase :</label>
          <p class="viewValue">{{ data.cornerphase }}</p>
        </b-col>
        <b-col md="4" class="mt-2" v-if="data.cornerphase == 'Yes'">
          <label class="viewLabel"> Corner phase Value(%) :</label>
          <p class="viewValue">{{ data.cornerphasevalue }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Garden Phase :</label>
          <p class="viewValue">{{ data.gardenphase }}</p>
        </b-col>
        <b-col md="4" class="mt-2" v-if="data.gardenphase == 'Yes'">
          <label class="viewLabel"> Garden Phase Value(%) :</label>
          <p class="viewValue">{{ data.gardenphasevalue }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Area(In Sq. MTR):</label>
          <p class="viewValue">{{ data.totalprojectareamtr }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Super Build Up Area(In Sq. MTR):</label>
          <p class="viewValue">{{ data.projectsuperbuildupareasqmtr }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Total Project Area(In Sq. MTR) :</label>
          <p class="viewValue">{{ data.totalprojectareamtr }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Area(In Sq. Yard) :</label>
          <p class="viewValue">{{ data.plottedareainsqyd }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Super Build Up Area(In Sq. Yard):</label>
          <p class="viewValue">{{ data.projectsuperbuildareasqyd }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Total Project Area(In Sq. MTR):</label>
          <p class="viewValue">{{ data.totalprojectareayd }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Min. Booking Amount :</label>
          <p class="viewValue">{{ data.bookingamount }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Company Name :</label>
          <p class="viewValue">{{ data.companyname }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> NA Order No :</label>
          <p class="viewValue">{{ data.naorderno }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> NA Order Date :</label>
          <p class="viewValue">{{ data.naorderdate }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Type :</label>
          <p class="viewValue">{{ data.projecttype }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> RERA No:</label>
          <p class="viewValue">{{ data.rerano }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Taluka :</label>
          <p class="viewValue">{{ data.taluka }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Pin Code :</label>
          <p class="viewValue">{{ data.pincode }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> City:</label>
          <p class="viewValue">{{ data.city}}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Area :</label>
          <p class="viewValue">{{ data.area }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> State :</label>
          <p class="viewValue">{{ data.state }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Assign To :</label>
          <p class="viewValue">{{ assignto}}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> CP/Franchise :</label>
          <p class="viewValue">{{ data.is_cp }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Status :</label>
          <p class="viewValue">{{ data.projectstatus }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Banner :</label>
          <img :src="data.projectbanner" style="height: 150px; width:150px"/>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Brochure :</label>
          <attachment :data="data.projectboucher" style="height:110px; width:110px"/>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Logo :</label>
          <attachment :data="data.projectlogo" style="height:110px; width:110px"/>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> QR Code :</label>
          <attachment :data="data.qrcode" style="height:110px; width:110px"/>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Land Location :</label>
          <iframe
          :src="iframe"
          class="mt-2 w-100"
          style="border: 5"
          height="200"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        >
        </iframe>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Project Description :</label>
          <p class="viewValue">{{ data.description }}</p>
        </b-col>
        <b-col md="12" class="mt-2">
          <label class="viewLabel"> Booking Type :</label>
          <br>

            <p class="viewvalue" v-if="bookingtype.pp" style="font-weight" > Part Payment:  {{bookingtype.pp}} </p>
            <p class="viewvalue" v-if="bookingtype.fp" >Full Payment: {{bookingtype.fp}} </p>
            <p class="viewvalue" v-if="bookingtype.emi" >EMI:  {{bookingtype.emi}} </p>

        </b-col>
        <b-col md="12" class="mt-2" v-if="data.bookingtype && data.bookingtype.emi !== false">
          <div style="width: 100%; overflow: auto">
            <table class="table table-bordered" style="min-width: 25cm">
              <thead>
                <tr>
                  <th style="font-size:11px !important">Sr No.</th>
                  <th style="font-size:11px !important">MONTH</th>
                  <th style="font-size:11px !important">RATE IN %</th>
                  <th style="font-size:11px !important">ENABLE/DISABLE</th>
                  <th style="font-size:11px !important">REMARKS </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (item,index) in data.emi ? JSON.parse(data.emi):[]" :key="index">
                  <td>{{index+1}}</td>
                  <td>{{item.fromMonth}} To {{ item.toMonth }} </td>
                  <td>{{item.rate }}</td>
                  <td>{{item.enable}}</td>
                  <td>{{item.remakrs}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <b-col md="12" class="mt-3">
          <h3>Gallery</h3>
        </b-col>
        <b-col md="12" class="mt-1">
          <div style="width: 100%; overflow: auto">
            <table class="table table-bordered" style="min-width: 20.5cm; ">
              <thead>
                <tr>
                  <th style="font-size:11px !important" class="col-md-2"></th>
                  <th style="font-size:11px !important">Images</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (item,index) in data.gallery ? JSON.parse(data.gallery):[]" :key="index">
                  <td>{{index + 1}}</td>
                  <td><attachment :data="item.attachment" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>

        <b-col md="12" class="mt-3">
          <h3>VideoUrl</h3>
        </b-col>
        <b-col md="12" class="mt-1">
          <div style="width: 100%; overflow: auto">
            <table class="table table-bordered" style="min-width: 20.5cm; ">
              <thead>
                <tr>
                  <th style="font-size:11px !important" class="col-md-2"></th>
                  <th style="font-size:11px !important">Video URL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (item,index) in data.videourl ? JSON.parse(data.videourl):[]" :key="index">
                  <td>{{index + 1}}</td>
                  <td> <attachment :data="item.attachment" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>

        <b-col md="12" class="mt-3">
          <h3>Conditions</h3>
        </b-col>
        <b-col md="12" class="mt-1">
          <div style="width: 100%; overflow: auto">
            <table class="table table-bordered" style="min-width: 20.5cm; ">
              <thead>
                <tr>
                  <th style="font-size:11px !important" class="col-md-2"></th>
                  <th style="font-size:11px !important">CONDITION NAME</th>
                  <th style="font-size:11px !important">CONDITION</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (item,index) in data.conditions ? JSON.parse(data.conditions):[]" :key="index">
                  <td>{{index + 1}}</td>
                  <td>{{item.name}}</td>
                  <td> <attachment :data="item.attachment" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>


        <b-col md="12" class="mt-3">
          <h3>Documantation</h3>
        </b-col>
        <b-col md="12" class="mt-1">
          <div style="width: 100%; overflow: auto">
            <table class="table table-bordered" style="min-width: 20.5cm; ">
              <thead>
                <tr>
                  <th style="font-size:11px !important" class="col-md-2"></th>
                  <th style="font-size:11px !important">DOCUMENT NAME</th>
                  <th style="font-size:11px !important">DOCUMENTATION</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (item,index) in data.data ? JSON.parse(data.videourl):[]" :key="index">
                  <td>{{index + 1}}</td>
                  <td>{{item.name}}</td>
                  <td> <attachment :data="item.attachment" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>

        <b-col md="12" class="mt-3">
          <h3>Highlights</h3>
        </b-col>
        <b-col md="12" class="mt-1">
          <div style="width: 100%; overflow: auto">
            <table class="table table-bordered" style="min-width: 20.5cm; ">
              <thead>
                <tr>
                  <th style="font-size:11px !important" class="col-md-2">SR. NO</th>
                  <th style="font-size:11px !important">Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (item,index) in data.highlights ? JSON.parse(data.highlights):[]" :key="index">
                  <td>{{index + 1}}</td>
                  <td>{{ item.name }} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>


      <b-col md="12" class="my-2">
        <h3>Amenities</h3>
      </b-col>
      <b-col md="12" class="mt-2">
        <div style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 25cm">
            <thead>
              <tr>
                <th style="font-size:11px !important">Sr No.</th>
                <th style="font-size:11px !important">AMENITIES</th>
                <th style="font-size:11px !important">ATTACHMENT</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" (item,index) in data.amenities ? JSON.parse(data.amenities):[]" :key="index">
                <td>{{index+1}}</td>
                <td>{{item.details}}</td>
                <td> <attachment :data="item.attachment" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import axios from "@/components/axios";
import Attachment from "@/components/Attechment.vue";
export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: {
    closepopup: {
      default: () => { },
    },
    selectedID: {
      default: 0,
    },
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: {},
      loginData: JSON.parse(localStorage.getItem("userData")),
      tokenchitthi:'No',
      tokenchitthireceived:'No',
      farmerapplication:'No',
      papernote:'No',
      titleclear:'No',
      iframe:'',
      videourl:'',
      assignto:'',
      bookingtype: {

      },

    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getProjectById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data)
            ? json.data.data[0]
            : json.data.data;

            this.bookingtype = this.data.bookingtype ? JSON.parse(this.data.bookingtype):[];
            console.log(this.bookingtype);
            this.assignto = this.data.assignto?this.data.assignto :'Franchise';

            if (this.data.iframe && this.data.iframe.includes("src=")) {
            this.iframe = this.data.iframe.split('src="')[1].split('"')[0];
        }
        if (this.data.videourl && this.data.videourl.includes("src=")) {
            this.videourl = this.data.videourl.split('src="')[1].split('"')[0];
        }
        })
        .catch((error) => console.log(error, "error"));

    },
  }
}

</script>

<style>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}
.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
