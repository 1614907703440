import axios from '@/components/axios'
export default {
  namespaced: true,
  state: {
    accessToken: localStorage.getItem("accessToken"),
    baseApi: process.env.VUE_APP_APIENDPOINT,
    leadCategory: [],
    leadStages: [],
    projects: [],
    channelPartners: [],
    employee: [],
    franchise: [],
    userLead: [],
    customers: [],
    customersSales: [],
    customersSiteVisit: [],
    vehicles: [],
    lands: [],
    customersLandSales: []
  },

  getters: {
    getUsers: (state) => (val) => {
      if (val == 'channelpartner')
        return state.channelPartners
      else if (val == 'franchise')
        return state.franchise
      else if (val == 'employee')
        return state.employee
    },

  },
  mutations: {
    SET_LEAD_CATEGORY(state, val) {
      state.leadCategory = val
    },
    SET_LEAD_STAGE(state, val) {
      state.leadStages = val
    },
    SET_PROJECTS(state, val) {

      state.projects = val
    },
    SET_CP(state, val) {
      state.channelPartners = val
    },
    SET_EMP(state, val) {
      state.employee = val
    },
    SET_FR(state, val) {
      state.franchise = val
    },
    SET_USER_LEAD(state, val) {
      state.userLead = val
    },
    SET_CUSTOMER(state, val) {
      state.customers = val
    },
    SET_CUSTOMER_LAND_SALES(state, val) {
      state.customersLandSales = val
    },
    SET_CUSTOMER_SALES(state, val) {
      state.customersSales = val
    },
    SET_CUSTOMER_VISIT(state, val) {
      state.customersSiteVisit = val
    },
    SET_VEHICLE(state, val) {
      state.vehicles = val
    },
    SET_LANDS(state, val) {
      state.lands = val
    },
  },
  actions: {
    async getUserLead(context) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getUserLead`,
      };
      await axios(reportauthority)
        .then((response) => {
          context.commit('SET_USER_LEAD', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },

    async getLands(context) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/allLand`,
      };
      await axios(reportauthority)
        .then((response) => {
          context.commit('SET_LANDS', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },
    async getLeadCategory(context) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/all-leadcategory`,
      };
      await axios(reportauthority)
        .then((response) => {
          context.commit('SET_LEAD_CATEGORY', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },
    async getLeadStage(context) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getLeadstage`,
      };
      await axios(reportauthority)
        .then((response) => {
          context.commit('SET_LEAD_STAGE', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },
    async getProject(context) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          context.commit('SET_PROJECTS', response.data.data)

        })
        .catch((error) => console.log(error, "error"));
    },
    async getChannelpartner(context) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getChannelpartner`,
      };
      await axios(requestOptions)
        .then((response) => {
          context.commit('SET_CP', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },
    async getFranchise(context) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getFranchise`,
      };
      await axios(requestOptions)
        .then((response) => {
          context.commit('SET_FR', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },
    async getVehicle(context) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getvehicle`,
      };
      await axios(requestOptions)
        .then((response) => {
          context.commit('SET_VEHICLE', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },
    async getEmployee(context) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          context.commit('SET_EMP', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },
    async getCustomer(context) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getcustomerassignby`,
      };
      await axios(requestOptions)
        .then((response) => {
          context.commit('SET_CUSTOMER', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },
    async getCustomerBySales(context, id) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getCustomerBySales`,
        data: {
          user_id: id
        }
      };
      await axios(requestOptions)
        .then((response) => {
          context.commit('SET_CUSTOMER_SALES', response.data.data)
        })
        .catch((error) => console.log(error, "error"));

    },
    async getCustomerByLandSales(context, id) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getCustomerByLandSales`,
        data: {
          user_id: id
        }
      };
      await axios(requestOptions)
        .then((response) => {
          context.commit('SET_CUSTOMER_LAND_SALES', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },
    async getCustomerBySiteVisit(context, id) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getCustomerBySiteVisit`,
        data: {
          user_id: id
        }
      };
      await axios(requestOptions)
        .then((response) => {
          context.commit('SET_CUSTOMER_VISIT', response.data.data)
        })
        .catch((error) => console.log(error, "error"));

    },
  },
}
