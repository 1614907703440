<template>
  <b-card no-body id="leadDetails">
    <b-card-header>
      <div class="d-flex align-items-center justify-content-between w-100">
        <b-card-title class="ml-25">
        <feather-icon size="24" class="mr-1" icon="ListIcon" />
        Lead Details  </b-card-title>
        <b-card-title class="ml-25" v-if="LeadDataItem.id && !$route.query.id">
          <b-button variant="primary" size="sm" @click="$router.push(`/followup/followupinfo?id=${LeadDataItem.id}`)">
            <feather-icon icon="Edit2Icon" class="mr-1" />
            Edit
          </b-button>
         </b-card-title>
      </div>
      <div v-if="LeadDataItem"
        class="w-100 d-flex justify-content-between w-100 flex-wrap align-items-center"
      >
        <div class="p-1" v-if="LeadDataItem.assignto && LeadDataItem.assignto.length>0">
          <div class="mt-1 mb-0">
            {{LeadDataItem.assignto && LeadDataItem.assignto.length>0
              ? LeadDataItem.assignto[0].role =='employee'?'Employee':'Channelpartner':''}} :
            <span class="font-weight-bolder" style="color: green"
              >{{ LeadDataItem.assignto && LeadDataItem.assignto.length>0 ? LeadDataItem.assignto[0].name : "" }}
            </span>
          </div>
          <div>
            Customer :
            <span class="font-weight-bolder" style="color: green">
              {{ LeadDataItem.name }} {{ LeadDataItem.surname }}</span
            >
          </div>
          <div v-if="LeadDataItem.transferdatetime">
            Transfer Datetime :
            <span class="font-weight-bolder" style="color: green">
              {{ LeadDataItem.transferdatetime.split(' ')[0].split('-').reverse().join('/') }}</span
            >
            <span class="font-weight-bolder" style="color: green">
              {{ LeadDataItem.transferdatetime.split(' ')[1] }}</span
            >
          </div>
          <div v-else>
            Assign Datetime :
            <span v-if="LeadDataItem.enterdatetime" class="font-weight-bolder" style="color: green">
              {{ LeadDataItem.enterdatetime.split('T')[0].split('-').reverse().join('/') }}</span
            >
            <span v-if="LeadDataItem.enterdatetime" class="font-weight-bolder" style="color: green">
              {{ LeadDataItem.enterdatetime.split('T')[1].split('.')[0] }}</span
            >
          </div>

          <div v-if="LeadDataItem.transferremarks">
            Transfer Remarks :
            <span class="font-weight-bolder" style="color: green">
              {{ LeadDataItem.transferremarks }}</span
            >
          </div>
        </div>
        <div class="mt-1">
          <p>MO:- {{ LeadDataItem.mobile }}</p>
          <b-media-body class="my-auto d-flex">
            <b-badge
              @click="
                clickWhatsapp(
                  LeadDataItem.mobile && LeadDataItem.mobile.includes('+91')
                    ? LeadDataItem.mobile
                    : '+91'.concat(LeadDataItem.mobile)
                )
              "
              variant="success"
              class="badge-glow mr-2 cursor-pointer"
              style="padding: 6px"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="width: 18px; height: 18px"
                fill="white"
                class="bi bi-whatsapp"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                />
              </svg>
            </b-badge>
            <!-- <div
            @click="callIVR(LeadDataItem.mobile)"
            v-if="userRole == 'admin'">

            </div> -->
            <!-- <div
              @click="callIVR(LeadDataItem.mobile.includes('+91') ? LeadDataItem.mobile : '+91'.concat(LeadDataItem.mobile))"
              v-if="userRole == 'admin' || userRole == 'employee'">

              <b-badge
                variant="primary"
                class="badge-glow mr-2 cursor-pointer"
                style="padding: 6px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 18px; height: 18px"
                  fill="white"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </b-badge>
              </div>
            <a v-else
              :href="`tel:${
                LeadDataItem.mobile && LeadDataItem.mobile.includes('+91')
                  ? LeadDataItem.mobile
                  : '+91'.concat(LeadDataItem.mobile)
              }`"
            >
              <b-badge
                variant="primary"
                class="badge-glow mr-2 cursor-pointer"
                style="padding: 6px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 18px; height: 18px"
                  fill="white"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </b-badge>
            </a> -->
            <a
              :href="`sms:${
                LeadDataItem.mobile && LeadDataItem.mobile.includes('+91')
                  ? LeadDataItem.mobile
                  : '+91'.concat(LeadDataItem.mobile)
              }`"
            >
              <b-badge
                variant="info"
                class="badge-glow cursor-pointer"
                style="padding: 6px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 18px; height: 18px"
                  fill="white"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                  />
                  <path
                    d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"
                  />
                </svg>
              </b-badge>
            </a>
          </b-media-body>
        </div>
      </div>
      <!-- <feather-icon
        icon="MoreVerticalIcon"
        size="18"
      /> -->
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <app-timeline
        class="d-flex justify-content-center align-self-center"
        v-if="
          JSON.stringify(LeadDataItem ? LeadDataItem.followupData : []) ==
            '[]' || !LeadDataItem.followupData
        "
      >
        <h4 class="font-weigth-bold">Does not Take Folloup For this Lead</h4>
      </app-timeline>
      <app-timeline v-else>
        <app-timeline-item
          v-for="(item, index) in LeadDataItem ? LeadDataItem.followupData : []"
          :key="index"
          :variant="
            item.priority == 'COLD'
              ? 'info'
              : item.priority == 'HOT'
              ? 'danger'
              : item.priority == 'WARM'
              ? 'success'
              : 'primary'
          "
        >
          <div
            class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
          >
            <h5>
              <span style="font-weight: 600 !important">{{
                item.enterby_data[0].name
              }}</span>
              done this Changes. And take Followup by
              <span style="font-weight: 900 !important">{{
                item.followuptype
              }}</span>
            </h5>
            <!-- <small class="timeline-item-time text-nowrap text-muted ml-1">{{item.datetime}}</small> -->
            <p>{{ item.datetime.split("-").reverse().join("/") }}</p>
          </div>
          <p>Next Folloup After {{ item.nextfollowupafterdays }} days</p>
          <p>{{ item.remarks }}</p>
          <p>
            Project Name :-<span
              :style="
                item.project_id ? 'color:green; font-weight: 600' : 'color: red'
              "
            >
              <span v-if="item.project_id == 0 ">Other </span>
              <span v-else>{{ item.project ? item.project.projectname : "NONE SELECTED" }}</span>


              </span
            >
          </p>
          <p>
            Status :-
            <b-badge
              v-if="item.status"
              variant="primary"
              class="badge-glow m-0"
            >
              {{ item.status.name }}
            </b-badge>
            <span v-else style="color: red"> NONE SELECTED</span>
          </p>
          <p v-if="item.status == 'Presentation'">
            Presented By :-
            <b-badge
              v-if="item.status"
              variant="primary"
              class="badge-glow m-0"
            >
              {{ presentedby_data.length>0?presentedby_data[0].name:'' }}
            </b-badge>

            &nbsp;&nbsp;&nbsp;At :-
            <span style="color: red">{{ item.presentationlocation }}</span>
          </p>
          <p v-if="item.status == 'MQL'">
            Is he decision maker ? :- {{ item.answer1 }}
          </p>
          <p v-if="item.status == 'MQL'">
            Are you buying plot for the first time ? :- {{ item.answer2 }}
          </p>
          <p v-if="item.status == 'MQL'">
            How are you planning finance for the purchase ? :-
            {{ item.answer3 }}
          </p>
          <p v-if="item.status == 'MQL'">
            Is the purchase for stay or investment ? :- {{ item.answer4 }}
          </p>
          <p v-if="item.status == 'MQL'">
            How long have you been looking for a plot ?:- {{ item.answer5 }}
          </p>
          <div v-if="item.status == 'Schedule Zoom Demo'">
            <p>Email : {{ item.emp_email }}</p>
            <p>Mobile No. : {{ item.emp_mobile }}</p>
          </div>

          <h5
            class="font-weight-bolder"
            v-if="JSON.parse(item.remarks1) && JSON.parse(item.remarks1).length > 0"
          >
            Activities
          </h5>
          <div
            style="padding-left: 20px"
            v-if="JSON.parse(item.remarks1) && JSON.parse(item.remarks1).length > 0"
          >
            <ul class="mt-1">
              <li v-for="(innerItem, index) in JSON.parse(item.remarks1)" :key="index" class="mt-1">
                <span class="font-weight-bolder">{{ innerItem.datetime }}</span>
                <br />
                <span>{{ innerItem.remarks }}</span>
              </li>
            </ul>
          </div>

          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              variant="primary"
              @click="addRemarks(item.id,index)"
              class="mb-1 mt-1"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Remarks</span>
            </b-button>
          </div>


          <!-- <b-media no-body>
            <b-media-aside class="mr-1">
              <b-img
                :src="require('@/assets/images/icons/json.png')"
                height="23"
                alt="data.json"
              />
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="media-body mb-0">
                data.json
              </h6>
            </b-media-body>
          </b-media> -->
        </app-timeline-item>
      </app-timeline>
      <!--/ timeline -->
    </b-card-body>
    <b-modal
            id="modal-form"
            v-model="popUp"
            size="md"
            cancel-variant="outline-secondary"
            ok-title="Submit"
            cancel-title="Close"
            title="Add Remarks"
            hide-footer
          >
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label>Date and Time</label>
                  <flat-pickr
                    v-model="datetime"
                    style="background: transparent"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'd/m/Y h:i K',
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Remarks</label>
                  <b-form-input v-model="remarks" placeholder="Enter Remarks" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="primary"
                  class="mr-4"
                  type="submit"
                  @click.prevent="submitForm()"
                >
                  Submit
                </b-button>
                <b-button variant="primary" @click="onClickBack"
                  >Back
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BBadge,
  BCardHeader,
  BCardTitle,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  BButton,
  VBTooltip,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import Mixins from '@/Mixins';

import axios from '@/components/axios';
/* eslint-disable global-require */
export default {
  components: {
    flatPickr,
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BBadge,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: ["LeadDataItem"],
  data() {
    return {
      remarks: "",
      accessToken: localStorage.getItem("accessToken"),
      userData: JSON.parse(localStorage.getItem("userData")),
      userRole: JSON.parse(localStorage.getItem("userData")).role,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      presentedby: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      arrEmail: [],
      arrMobile: [],
      popUp: false,
      datetime: moment(new Date()).format("llll"),
      activity: [],
      role:'',
      index:0,
      followupid:0
    };
  },
  mounted() {
    let arr=this.userRole.split('')
    arr[0]= arr[0].toUpperCase()
    this.role =arr.join('')
  },
  methods: {
    onClickBack(){

    },
    callIVR(mobile){
        const options1 = {
          method: 'POST',
          url: 'https://api-smartflo.tatateleservices.com/v1/click_to_call',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${localStorage.IVRData  ? JSON.parse(localStorage.IVRData).access_token : ''}`,
            'content-type': 'application/json'
          },
          data: {
            agent_number: this.userData.mobile,
            destination_number: mobile
          }
        };

        axios
          .request(options1)
          .then(function (response) {
            console.log(response.data);
          })
          .catch(function (error) {
            if(error.toString().includes('401') || error.toString.includes('400')){
              Mixins.methods.IVRLogin()
            }
          });
    },
    async submitForm(id) {
      // console.log(this.LeadDataItem.followupData[index]);
      this.activity = JSON.parse(this.LeadDataItem.followupData[this.index].remarks1)
        ? JSON.parse(this.LeadDataItem.followupData[this.index].remarks1)
        : [];
      this.activity.push({ datetime: this.datetime, remarks: this.remarks });
      this.LeadDataItem.followupData[this.index].remarks1=JSON.stringify(this.activity)


      await axios({
        method: "PUT",
        url: `${this.baseApi}/followupstatusupdate/${this.followupid}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: {
          remarks1: this.activity,
        },
      }).then((res) => {
        this.popUp=false;
        // window.location.reload()
      });
    },
    addRemarks(id,index) {
      this.index=index
      this.followupid=id
      this.popUp = true;
      this.datetime = "";
      this.remarks = "";
    },
    clickWhatsapp(num) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        let url = "https://wa.me/";
        const all = url + num;
        window.open(all, "_blank");
      } else {
        let url = "https://web.whatsapp.com/send";
        const all = url + "?phone=" + num;
        window.open(all, "_blank");
      }
    },
    async getUsers(id) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }).then((res) => {
        this.presentedby = res.data.data[0].name;
      });
    },
  },
};
</script>
