<template>
  <b-card class="text-center cursor-pointer boxes" style="border-radius: 34px;box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;" >
    <!-- <b-avatar class="mb-1" :variant="color" size="35">
      <feather-icon size="17" :icon="icon" />
    </b-avatar> -->
    <div class="truncate d-flex align-items-center justify-content-around flex-column" style="height:90px">
      <h4>{{ statisticTitle }}</h4>
      <h1 :class="'mb-25 font-weight-bolder text-'+color" >
        {{ statistic }}
      </h1>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      // required: true,
      default: "",
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    route: {
      type: String,
      default: "",
    },
  },
};
</script>
<style>
.boxes {
  min-height: 130px;
  min-width: 160px;
}
</style>
