<template>
  <b-card class="card-transaction" no-body style="cursor: default">
    <b-card-header style="position: sticky !important; top: 0px; background: white">
      <b-card-title>Lead</b-card-title>
      <!-- <feather-icon
        icon="MoreVerticalIcon"
        size="18"
        class="cursor-pointer"
      /> -->
      <b-form-radio-group
        id="radio-group-3"
        v-model="type"
        name="radio-sub-component"
        class="d-flex flex-wrap justify-content-between mt-2"
        @change="handleLeadType($event)"
        v-if="userData.type !== 'leadmanager'"
      >
        <div class="d-flex flex-wrap justify-content-between w-50">
          <b-form-radio name="some-radios" value="assignto" class="w-100"
            >Assign To Me</b-form-radio
          >
          <b-form-radio name="some-radios1" value="subsource" class="w-100"
            >Assign By Me</b-form-radio
          >
        </div>
        <div class="d-flex flex-wrap justify-content-between w-50">
          <b-form-radio name="some-radios1" value="myleads" class="w-100"
            >My Leads</b-form-radio
          >
          <b-form-radio name="some-radios1" selected value="enterby" class="w-100"
            >Enter By Me</b-form-radio
          >
        </div>
      </b-form-radio-group>

      <b-card no-body class="mb-1 w-100">
        <b-card-header header-tag="header" class="p-1 w-100" role="tab">
          <b-button block ref="toggle" v-b-toggle.accordion-3 variant="info"
            >Filters</b-button
          >
        </b-card-header>
        <b-collapse
          id="accordion-3"
          style="z-index: 31"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label>Lead Date</label>
                  <flat-pickr
                    v-model="leaddate"
                    class="form-control"
                    :config="{
                      enableTime: false,
                      dateFormat: 'd/m/Y',
                    }"
                    style="background-color: transparent"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label>Client Name</label>
                  <b-form-input v-model="name" placeholder="Search Client Name" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label>Assign To</label>
                  <v-select
                    v-model="assignto"
                    label="name"
                    placeholder="None"
                    :options="assigntoOption"
                  />
                  <!-- <b-form-input v-model="assignto" placeholder="Search Assignto"   /> -->
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label>Status</label>
                  <b-form-input v-model="status" placeholder="Search status" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button variant="primary" @click="handleSearch()"> Submit </b-button>
            <b-button variant="primary" class="ml-1" @click="handleClear">
              Clear Filter
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-card-header>
    <b-card-body
      v-if="JSON.stringify(search || categorize ? finalData : LeadData) !== '[]'"
      style="overflow: auto"
    >
      <div
        v-for="item in search || categorize ? finalData : LeadData"
        :key="item.id"
        @click="handelClickItem(item)"
        class="transaction-item p-1"
        :class="
          clickedItem
            ? clickedItem == item.id
              ? 'bg-primary bg-lighten-1 text-white rounded'
              : ''
            : ''
        "
      >
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <!-- <b-img
                v-if="JSON.stringify(item.enterby).charAt(0) == '['"
                rounded
                class="img"
                :src="
                  item.enterby[0].profile_image &&
                  item.enterby[0].profile_image !== null
                    ? item.enterby[0].profile_image
                    : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                "
                height="30"
              /> -->
              <!-- {{ item }} -->
              <b-img
                rounded
                class="rounded-circle"
                height="40px"
                width="40px"
                :src="
                  item.assignto[0] &&
                  item.assignto[0].profile_image &&
                  item.assignto[0].profile_image !== null
                    ? item.assignto[0].profile_image
                    : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                "
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <!-- <h4
              v-if="JSON.stringify(item.enterby).charAt(0) == '['"
              :class="
                clickedItem ? (clickedItem == item.id ? 'text-white' : '') : ''
              "
              class="transaction-title"
            >
              {{ item.name }}<br /><span style="font-size: 14px"
                >({{
                  item.enterby[0].id == userData.id
                    ? "You have entered"
                    : item.leadsource[0].name == "Channel Partner" ||
                      item.leadsource[0].name == "Employee" ||
                      item.leadsource[0].name == "Franchise" ||
                      item.leadsource[0].name == "Self"
                    ? item.subsource == userData.id
                      ? "As a Source"
                      : "Error"
                    : "Error1"
                }})</span
              >
            </h4> -->
            <h6
              :class="clickedItem ? (clickedItem == item.id ? 'text-white' : '') : ''"
              class="transaction-title"
            >
              <p style="font-size: 13px">
                Client :&nbsp;<span
                  :class="!clickedItem ? 'text-success' : 'font-weight-bold'"
                >
                  {{ item.name }}</span
                >
              </p>
              <p style="font-size: 13px">
                Assign To :&nbsp;<span
                  :class="!clickedItem ? 'text-primary' : 'font-weight-bold'"
                >
                  {{ item.assignto[0] ? item.assignto[0].name : "" }}</span
                >
                <!-- <p style="font-size: 14px;color:red">{{ item.assignto[0].name }}</p> -->
              </p>
              <p
                style="font-size: 13px"
                v-if="item.transfer_from && item.transfer_from.length > 0"
              >
                Transfer From :&nbsp;<span class="text-primary">
                  {{ setTransferFrom(item.transfer_from) }}</span
                >
                <!-- <p style="font-size: 14px;color:red">{{ item.assignto[0].name }}</p> -->
              </p>
            </h6>
            <!-- <a
              :href="`tel:${
                item.mobile.includes('+91')
                  ? item.mobile
                  : '+91'.concat(item.mobile)
              }`"
            >
              <b-badge
                variant="primary"
                class="badge-glow mr-2 cursor-pointer"
                style="padding: 6px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 18px; height: 18px"
                  fill="white"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </b-badge>
            </a> -->
            <!-- <small> {{ item.leaddate.split("-").reverse().join("/") }}</small> -->
          </b-media-body>
        </b-media>
        <div style="height: 80px" class="d-flex flex-column">
          <div class="d-flex flex-column h-100 justify-content-around align-items-end">
            <b-badge
              v-if="item.followupData[item.followupData.length - 1]"
              :variant="
                item.followupData[item.followupData.length - 1].priority == 'HOT'
                  ? 'danger'
                  : item.followupData[item.followupData.length - 1].priority == 'COLD'
                  ? 'info'
                  : 'warning'
              "
              class="badge-glow m-0"
            >
              {{ item.followupData[item.followupData.length - 1].priority }}
            </b-badge>
            <small> {{ item.leaddate.split("-").reverse().join("/") }}</small>
            <b-badge
              :variant="
                clickedItem ? (clickedItem == item.id ? 'success' : 'primary') : 'primary'
              "
              class="badge-glow m-0"
            >
              {{ item.status }}
            </b-badge>
          </div>
        </div>
      </div>
    </b-card-body>
    <b-card-body
      style="display: flex; justify-content: center; align-items: center"
      v-else
    >
      <h4>NO DATA FOUND</h4>
    </b-card-body>
    <div class="mb-2">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <b-form-select
            v-model="pageLength"
            :options="['5', '10', '20', '50', '100', '500']"
            class="mx-1"
            @input="handleType(type)"
          />
          <span class="text-nowrap"
            >{{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal }}</span
          >
        </div>
        <div>
          <b-pagination
            @input="handleType(type)"
            v-model="paginationValue"
            :total-rows="paginationTotal"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
          >
            <!-- @input="(value) => props.pageChanged({ currentPage: value })" -->
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BButton,
  BCardBody,
  BCollapse,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
  BBadge,
  BLink,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BFormCheckbox,
  BCol,
  BRow,
  BPagination,
  BFormSelect,
  VBToggle,
} from "bootstrap-vue";
import moment from "moment";
import axios from "@/components/axios";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BFormRadio,
    flatPickr,
    BCardText,
    BCollapse,
    BFormRadioGroup,
    BFormGroup,
    BButton,
    BLink,
    BCard,
    BImg,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BFormInput,
    BFormCheckbox,
    BCol,
    BRow,
    BPagination,
    BFormSelect,
  },
  props: ["LeadData", "paginationTotalProp", "paginationStartProp", "paginationEndProp"],
  directives: {
    "b-toggle": VBToggle,
  },
  computed: {
    setTransferFrom() {
      return (item) => {
        let arr = item.map((inner) => {
          return inner.transfer_from_user ? inner.transfer_from_user.fullname : "";
        });
        arr = arr.filter((inner) => (inner ? true : false));
        return arr.join(", ");
      };
    },
  },
  data() {
    return {
      clickedItem: 0,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      userData: JSON.parse(localStorage.getItem("userData")),
      search: "",
      finalData: this.LeadData,
      type: "assignto",
      categorize: false,
      alldata: [],
      priority: {
        HOT: false,
        WARM: false,
        COLD: false,
      },
      nonPriorityData: [],
      paginationValue: 1,
      paginationTotal: 0,
      pageLength: 10,
      paginationStart: 0,
      paginationEnd: 0,
      text: "435345",
      name: null,
      assignto: null,
      leaddate: null,
      status: null,
      assigntoOption: [],
      params: "",
    };
  },
  mounted() {
    if (this.userData.role !== "admin" && this.userData.type !== "leadmanager") {
      this.handleType("assignto");
      this.getAssignto();
    } else {
      this.getAllUsers();
    }
    this.finalData = this.LeadData;
    this.alldata = this.LeadData;
    this.nonPriorityData = this.LeadData;
    this.type = "assignto";
    this.paginationTotal = this.paginationTotalProp;
    this.paginationStart = this.paginationStartProp;
    this.paginationEnd = this.paginationEndProp;
  },
  methods: {
    handleClear() {
      this.name = null;
      this.assignto = null;
      this.leaddate = null;
      this.status = null;
      this.assignto = null;
      this.handleSearch();
    },
    async getAssignto() {
      this.assigntoOption = [];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${this.userData.id}`,
      };
      await axios(requestOptions).then(async (response) => {
        this.assigntoOption.push(this.userData);

        response.data.data.map((item) => {
          this.assigntoOption.push(item);
          if (item.children.length > 0) {
            this.handleChild(item);
          }
        });
      });
    },
    handleChild(item) {
      item.children.map((inner) => {
        this.assigntoOption.push(inner);
        if (inner.children.length > 0) {
          this.handleChild(inner);
        }
      });
    },
    async getAllUsers() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUsers`,
      };
      await axios(requestOptions).then(async (response) => {
        this.assigntoOption = response.data.data;
      });
    },
    handleLeadType(e) {
      this.paginationValue = 1;
      this.paginationTotal = 0;
      this.pageLength = 10;
      this.paginationStart = 0;
      this.paginationEnd = 0;
      if (this.userData.role !== "admin" && this.userData.type !== "leadmanager") {
        this.handleType(e);
      } else {
        this.totalLeadData();
      }
    },
    handlePriority(name, e) {
      this.categorize = true;
      this.priority[name] = e;
      this.$forceUpdate();
    },
    async handleSearch(e) {
      this.params = "";
      if (this.name) {
        this.params += `&name=${this.name}`;
      }
      if (this.assignto && this.assignto.id) {
        this.params += `&assignto=${this.assignto.id}`;
      }
      if (this.leaddate) {
        this.params += `&leaddate=${this.leaddate}`;
      }
      if (this.status) {
        this.params += `&status=${this.status}`;
      }
      this.handleType(this.type);
      this.$refs.toggle.click();
    },
    async totalLeadData() {
      this.categorize = true;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url:
          `${this.baseApi}/leaddata?pagenum=${this.paginationValue}&pagesize=${this.pageLength}` +
          this.params,
      };
      await axios(requestOptions)
        .then((response) => {
          let arr = [];

          this.paginationTotal = response.data.data.total;
          this.paginationStart = response.data.data.from;
          this.paginationEnd = response.data.data.to;
          arr = response.data.data.data;
          this.finalData = arr;
          this.alldata = arr;
          this.nonPriorityData = arr;
          this.$emit("LeadDataItem", this.finalData.length > 0 ? this.finalData[0] : {});
          this.$forceUpdate();
        })
        .catch((error) => console.log(error, "error"));
    },
    handelClickItem(item) {
      document.getElementById("leadDetails").scrollIntoView();
      this.clickedItem = item.id;
      this.$emit("LeadDataItem", item);
    },
    async handleType(e) {
      if (this.userData.role == "admin" || this.userData.type == "leadmanager") {
        this.totalLeadData();
        return;
      }
      this.type = e;
      let arr = [];
      this.categorize = true;
      let data = {
        data: e,
        id: this.userData.id,
      };
      await axios({
        method: "POST",
        url:
          `${this.baseApi}/getleadbycategory?pagenum=${this.paginationValue}&pagesize=${this.pageLength}` +
          this.params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data,
      }).then((response) => {
        this.paginationTotal = response.data.data.total;
        this.paginationStart = response.data.data.from;
        this.paginationEnd = response.data.data.to;
        arr = response.data.data.data;

        this.finalData = arr;
        this.$emit("LeadDataItem", this.finalData.length > 0 ? this.finalData[0] : {});
        this.alldata = arr;
        this.nonPriorityData = arr;
        this.$forceUpdate();
      });
    },
  },
};
</script>
