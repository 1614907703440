<template>
  <div>
    <b-row
      v-if="
        $store.getters['app/widgetAccess']('Total Users Details') ||
        $store.state.app.user_data.type == 'admin'
      "
    >
      <b-col
        lg="3"
        sm="6"
        v-for="item in first"
        :key="item.subtitle"
        class="cursor-pointer"
        @click="handleClick(item)"
      >
        <statistic-card-horizontal
          :icon="item.icon"
          :statistic="item.title"
          :statistic-title="item.subtitle"
          :color="item.color"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="
        $store.getters['app/widgetAccess']('Business Details') ||
        $store.state.app.user_data.type == 'admin'
      "
    >
      <b-col lg="3" sm="6" v-for="item in second" :key="item.subtitle">
        <statistic-card-with-area-chart
          :icon="item.icon"
          :statistic="kFormatter(item.title)"
          :statistic-title="item.subtitle"
          :color="item.color"
          :chart-data="item.data"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="
        $store.getters['app/widgetAccess']('Plot-User-Sitevisit') ||
        $store.state.app.user_data.type == 'admin'
      "
    >
      <b-col
        lg="3"
        sm="6"
        v-for="item in third"
        :key="item.subtitle"
        class="cursor-pointer"
        @click="handleClick(item)"
      >
        <statistic-card-horizontal
          :icon="item.icon"
          :statistic="item.title"
          :statistic-title="item.subtitle"
          :color="item.color"
        />
      </b-col>
      <b-col lg="3" sm="6" class="cursor-pointer" @click="handleClickvisit()">
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="visitCount"
          statistic-title="Site Visit Request"
          color="warning"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="
        $store.getters['app/widgetAccess']('KYC') ||
        $store.state.app.user_data.type == 'admin'
      "
    >
      <b-col
        lg="3"
        sm="6"
        class="cursor-pointer"
        @click="handleClickKyc('channelpartner')"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="pending_kyc_cp"
          statistic-title="KYC Pending CP"
          color="primary"
        />
      </b-col>
      <b-col lg="3" sm="6" class="cursor-pointer" @click="handleClickKyc('franchise')">
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="pending_kyc_fr"
          statistic-title="KYC Pending Franchise"
          color="success"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "@/components/axios";

import { BRow, BCol } from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import StatisticCardWithLineChart from "@core/components/statistics-cards/StatisticCardWithLineChart.vue";
import { kFormatter } from "@core/utils/filter";

import CardStatisticOrderChart from "../../card/card-statistic/CardStatisticOrderChart.vue";
import CardStatisticProfitChart from "../../card/card-statistic/CardStatisticProfitChart.vue";
import CardStatisticsGroup from "../../card/card-statistic/CardStatisticsGroup.vue";

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    CardStatisticOrderChart,
    CardStatisticProfitChart,
    CardStatisticsGroup,
  },
  props: ["data"],
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      // Area charts
      subscribersGained: {},
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {},
      pending_kyc_cp: 0,
      pending_kyc_fr: 0,
      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},
      first: [],
      second: [],
      third: [],
      visitCount: 0,
    };
  },
  mounted() {
    this.subscribersGained = {
      analyticsData: {
        subscribers: 92600,
      },
      series: [{ data: [28, 40, 36, 52, 38, 60, 55], name: "sales" }],
    };
    this.data.map((item, index) => {
      if (index < 4) {
        this.first.push(item);
      } else if (index < 8) {
        this.second.push(item);
      } else {
        this.third.push(item);
      }
    });
    this.pendingRequest();
  },
  //   created() {
  //     // Subscribers gained
  //     this.$http.get("/card/card-statistics/subscribers").then((response) => {
  //       this.subscribersGained = response.data;
  //       this.subscribersGained.series[0].name = "sales";
  //       console.log(this.subscribersGained, "first");
  //     });
  //   },

  methods: {
    handleClickKyc(name) {
      this.$router.push({ path: "/setup/kyc", query: { selected: name } });
    },
    kFormatter,
    handleClick(item) {
      if (item.route) {
        this.$router.push(item.route);
      }
    },
    handleClickvisit() {
      this.$router.push({ path: "/crm/sitevisit", query: { pending: true } });
    },
    async pendingRequest() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/extrawidgetes`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.visitCount = response.data.pending_sitevisit;
          this.pending_kyc_cp = response.data.kyc_cp;
          this.pending_kyc_fr = response.data.kyc_frachise;
        })
        .catch((error) => console.log(error, "error"));
    },
    // async totalsales() {
    //   await axios({
    //     method: "GET",
    //     url: `${this.baseApi}/totalsale`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //   }).then((response) => {});
    // },
  },
};
</script>
