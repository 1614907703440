<template>
  <div>
    <b-modal
      id="modal-form"
      v-model="open"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      title="Customer Details"
      hide-footer
      size="lg"
      @hidden="$store.commit('app/SET_CUSTOMER_MODAL')"
    >
      <div class="main-div">
        <div
          class="modal-spinner"
          v-if="
            $store.state.app.customerDetails && $store.state.app.customerDetails.loading
          "
        >
          <b-spinner />
        </div>
        <div
          class="modal-error"
          v-if="
            $store.state.app.customerDetails && $store.state.app.customerDetails.error
          "
        >
          <b>{{ $store.state.app.customerDetails.error }}</b>
        </div>
        <div class="modal-details" v-if="userDetail">
          <b-row>
            <b-col sm="4">
              <div
                class="d-flex justify-content-center align-items-center mb-3"
                style="width: 100%; height: 100%; margin: 0px; max-width: 100%"
              >
                <b-link>
                  <b-img
                    ref="previewEl"
                    style="border-radius: 15px"
                    :src="
                      userDetail.profile_image
                        ? userDetail.profile_image
                        : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                    "
                    height="120"
                  />
                </b-link>
              </div>
            </b-col>
            <b-col sm="8" class="popUpInfo">
              <div class="Details d-flex flex-column">
                <p>
                  <b-row>
                    <b-col md="5">
                      <span class="font-weight-bold h5" style="color: black"> Name:</span>
                    </b-col>
                    <b-col md="7">
                      <span style="font-size: larger"> {{ userDetail.fullname }}</span>
                    </b-col>
                  </b-row>
                </p>
                <p>
                  <b-row>
                    <b-col md="5">
                      <span class="font-weight-bold h5" style="color: black">
                        Mobile No:</span
                      >
                    </b-col>
                    <b-col md="7">
                      <span style="font-size: larger"> {{ userDetail.mobile }}</span>
                    </b-col>
                  </b-row>
                </p>
                <p>
                  <b-row>
                    <b-col md="5">
                      <span class="font-weight-bold h5" style="color: black">
                        Email:</span
                      >
                    </b-col>
                    <b-col md="7">
                      <span style="font-size: larger"> {{ userDetail.email }}</span>
                    </b-col>
                  </b-row>
                </p>

                <p>
                  <b-row>
                    <b-col md="5">
                      <span class="font-weight-bold h5" style="color: black">
                        State:</span
                      >
                    </b-col>
                    <b-col md="7">
                      <span style="font-size: larger"> {{ userDetail.curstate }}</span>
                    </b-col>
                  </b-row>
                </p>
                <p>
                  <b-row>
                    <b-col md="5">
                      <span class="font-weight-bold h5" style="color: black"> City:</span>
                    </b-col>
                    <b-col md="7">
                      <span style="font-size: larger"> {{ userDetail.curcity }}</span>
                    </b-col>
                  </b-row>
                </p>
                <p>
                  <b-row>
                    <b-col md="5">
                      <span class="font-weight-bold h5" style="color: black"> Area:</span>
                    </b-col>
                    <b-col md="7">
                      <span style="font-size: larger"> {{ userDetail.curarea }}</span>
                    </b-col>
                  </b-row>
                </p>
                <p>
                  <b-row>
                    <b-col md="5">
                      <span class="font-weight-bold h5" style="color: black">
                        Assign to:</span
                      >
                    </b-col>
                    <b-col md="7">
                      <span style="font-size: larger">
                        {{
                          userDetail.assignto && userDetail.assignto.length > 0
                            ? userDetail.assignto[0].fullname
                            : ""
                        }}</span
                      >
                    </b-col>
                  </b-row>
                </p>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="sales && sales.length > 0">
            <h5 class="pl-2">Plot Sales Details</h5>
            <div class="sales-table">
              <b-table :items="sales" :fields="salesColumn">
                <template #cell(id)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(bookingdate)="{ item }">
                  {{
                    item.bookingdate ? moment(item.bookingdate).format("DD/MM/YYYY") : ""
                  }}
                </template>
                <template #cell(project)="{ item }">
                  {{ item.project ? item.project.projectname : "" }}
                </template>

                <template #cell(plotno)="{ item }">
                  {{ item.plotno ? item.plotno.plotno : "" }}
                </template>

                <template #row-details="data">
                  <b-table
                    v-if="data.item.receipt_items && data.item.receipt_items.length > 0"
                    :items="data.item.receipt_items"
                    :fields="receiptColumn"
                  >
                    <template #cell(id)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template #cell(date)="{ item }">
                      {{
                        item.receiptdate
                          ? moment(item.receiptdate).format("DD/MM/YYYY")
                          : ""
                      }}
                    </template>
                    <template #cell(transaction_date)="{ item }">
                      {{
                        item.transaction_date && item.transaction_date !== "0000-00-00"
                          ? moment(item.transaction_date).format("DD/MM/YYYY")
                          : ""
                      }}
                    </template>
                    <template #cell(reference)="{ item }">
                      {{ item.utrno ? item.utrno : item.chequeno }}
                    </template>
                  </b-table>
                </template>
                <template #cell(show_receipt)="data">
                  <feather-icon
                    icon="EyeIcon"
                    size="22"
                    class="m-1"
                    @click="data.toggleDetails"
                    style="cursor: pointer"
                  />
                </template>
              </b-table>
            </div>
          </b-row>
          <b-row v-if="landSales && landSales.length > 0">
            <h5 class="pl-2">Land Sales Details</h5>
            <div class="sales-table">
              <b-table :items="landSales" :fields="landColumn">
                <template #cell(id)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(bookingdate)="{ item }">
                  {{
                    item.bookingdate ? moment(item.bookingdate).format("DD/MM/YYYY") : ""
                  }}
                </template>
                <template #cell(village)="{ item }">
                  {{ item.village ? item.village.villagename : "" }}
                </template>
              </b-table>
            </div>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BModal, BSpinner, BRow, BCol, BLink, BImg, BTable } from "bootstrap-vue";
import moment from "moment";
export default {
  name: "CustomerModal",
  components: { BModal, BSpinner, BRow, BCol, BLink, BImg, BTable },
  watch: {
    "$store.state.app.customerDetails": {
      handler(newValue, oldValue) {
        this.customerData = newValue;
        this.userDetail =
          this.customerData && this.customerData.data
            ? this.customerData.data.data
            : false;
        this.sales =
          this.customerData && this.customerData.data ? this.customerData.data.sales : [];
        this.landSales =
          this.customerData && this.customerData.data
            ? this.customerData.data.landSales
            : [];
      },
      deep: true,
    },
  },
  data() {
    return {
      moment,
      open: true,
      customerData: {},
      sales: [],
      landSales: [],
      userDetail: false,
      receiptColumn: [
        { key: "id", label: "Sr no" },
        { key: "date", label: "Receipt Date" },
        { key: "transaction_date", label: "Transaction Date" },
        "amount",
        { key: "reference", label: "Reference" },
        "remarks",
      ],
      salesColumn: [
        { key: "id", label: "Sr. No" },
        { key: "bookingdate", label: "Booking Date" },
        { key: "project", label: "Project" },
        { key: "plotno", label: "Plot" },
        { key: "totalpayment", label: "Total Payment" },
        { key: "paid_amount", label: "Paid Amount" },
        { key: "remaining_payment", label: "Remaining Amount" },
        { key: "show_receipt", label: "Show Receipt" },
      ],
      landColumn: [
        { key: "id", label: "Sr. No" },
        { key: "bookingdate", label: "Booking Date" },
        { key: "village", label: "Village" },
        { key: "oldsurveyno", label: "Old Survay No" },
        { key: "newsurveyno", label: "New Survay No" },
        { key: "totalpayment", label: "Total Payment" },
        { key: "paid_amount", label: "Paid Amount" },
        { key: "remaining_payment", label: "Remaining Amount" },
      ],
    };
  },
  mounted() {},
};
</script>
<style>
.main-div {
  min-height: 200px;
}
.modal-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.modal-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.main-div p {
  margin-bottom: 5px !important;
}
.sales-table {
  min-width: 100%;
  overflow: auto;
  padding: 20px;
}
.main-div td,
.main-div th {
  padding: 3px 5px !important;
  border-left: 1px solid #ebe9f1;
}
.main-div th {
  font-size: 12px !important;
}
</style>
