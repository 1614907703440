<template>
  <div>

    <b-row class="row justify-content-between">
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Project :</label>
        <p class="viewValue">{{ data.projectname }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Phase :</label>
        <p class="viewValue">{{ data.phaseno }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot No :</label>
        <p class="viewValue">{{ data.plotno }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot Area(IN SQ. YARD) :</label>
        <p class="viewValue">{{ data.plotareayd }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> SB UP AREA(IN SQ. YARD) :</label>
        <p class="viewValue">{{ data.superarea_sqyds }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> CARPET AREA(IN SQ. YARD) :</label>
        <p class="viewValue">{{ data.carpetarea_sqyds }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot Area(IN SQ. MTR) :</label>
        <p class="viewValue">{{ data.plotareasqmtr }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> SB UP AREA(IN SQ. MTR) :</label>
        <p class="viewValue">{{ data.superarea_sqmt }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> CARPET AREA(IN SQ. MTR) :</label>
        <p class="viewValue">{{ data.carpetarea_sqmt }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot Area(IN SQ. FEET) :</label>
        <p class="viewValue">{{ data.plotareasqfeet }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> SB UP AREA(IN SQ. FEET) :</label>
        <p class="viewValue">{{ data.superarea_sqfeet }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> CARPET AREA(IN SQ. FEET) :</label>
        <p class="viewValue">{{ data.carpetarea_sqfeet }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Length :</label>
        <p class="viewValue">{{ data.length }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Width :</label>
        <p class="viewValue">{{ data.width }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot Unit In :</label>
        <p class="viewValue">{{ data.unitname }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Rate per yard for CP/Employee:</label>
        <p class="viewValue">{{ data.rateperyd }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Rate per yard for Franchise :</label>
        <p class="viewValue">{{ data.franchiserate }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> With Development :</label>
        <p class="viewValue">{{ data.development }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot Value :</label>
        <p class="viewValue">{{ data.plotValue }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Total Plot Amount :</label>
        <p class="viewValue">{{ data.plotamount }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot Value For Franchise:</label>
        <p class="viewValue">{{ data.plotValueFr }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Total Plot Amount For Franchise:</label>
        <p class="viewValue">{{ data.plotValueFr }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel">Status:</label>
        <p class="viewValue">{{ data.status }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Place facing:</label>
        <p class="viewValue">{{ data.placefacing }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> PLC Charge:</label>
        <p class="viewValue">{{ plc}}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="plc == 'yes'">
        <label class="viewLabel">Corner Phase:</label>
        <p class="viewValue">{{cornerphase }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="cornerphase === 'yes'">
        <label class="viewLabel"> Corner phase Value(%):</label>
        <p class="viewValue">{{data.cornerphasevalue }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="cornerphase === 'yes'">
        <label class="viewLabel"> Cornar Phase Amount:</label>
        <p class="viewValue">{{data.cornerAmount}}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="cornerphase === 'yes'">
        <label class="viewLabel"> Cornar Phase Amount (Franchise):</label>
        <p class="viewValue">{{data.cornerAmountFr }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="plc == 'yes'">
        <label class="viewLabel"> Garden Phase:</label>
        <p class="viewValue">{{gardenphase}}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="gardenphase == 'yes'">
        <label class="viewLabel"> Garden phase Value(%):</label>
        <p class="viewValue">{{ data.gardenphasevalue}}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="gardenphase == 'yes'">
        <label class="viewLabel"> Garden Phase Amount:</label>
        <p class="viewValue">{{gardenAmount }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="gardenphase == 'yes'">
        <label class="viewLabel"> Garden Phase Amount (Franchise):</label>
        <p class="viewValue">{{data.gardenAmountFr }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Development Charge extra:</label>
        <p class="viewValue">{{ data.development }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Development Charge (Per Sq. Yard):</label>
        <p class="viewValue">{{data.developmentcharge}}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Development Amount:</label>
        <p class="viewValue">{{ developmentamount }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Maintainance charge:</label>
        <p class="viewValue">{{ maintainance }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="maintainance == 'yes'">
        <label class="viewLabel"> Maintainance Amount(Per Month):</label>
        <p class="viewValue">{{data.maintainancecharge }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="maintainance == 'yes'">
        <label class="viewLabel"> Months:</label>
        <p class="viewValue">{{ data.months}}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="maintainance == 'yes'">
        <label class="viewLabel"> Total Maintainance Charges:</label>
        <p class="viewValue">{{data.total_maintenance_charge }}</p>
      </b-col>

      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Club House Charges:</label>
        <p class="viewValue">{{club }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="club== 'yes'">
        <label class="viewLabel"> Club House Amount:</label>
        <p class="viewValue">{{data.clubHouse }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Electricity Charges:</label>
        <p class="viewValue">{{electricity}}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="electricity == 'yes'">
        <label class="viewLabel"> Electricity Amount:</label>
        <p class="viewValue">{{ data.electricitycharges}}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Other Charge:</label>
        <p class="viewValue">{{ extracharge}}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="extracharge">
        <label class="viewLabel"> Other Charges(Amount):</label>
        <p class="viewValue">{{data.otherextracharges }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Extra Amount:</label>
        <p class="viewValue">{{data.extraamount }}</p>
      </b-col>
    </b-row>



    <b-row class="md-12" style="margin-top:10px">
      <b-col>
        <h3>Attachments</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-2">
        <div style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 25cm">
            <thead>
              <tr>
                <th style="font-size:11px !important">SR. NO.</th>
                <th style="font-size:11px !important">ATTACHMENT NAME </th>
                <th style="font-size:11px !important">ATTACHMENT</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data.plotattachment" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>
                  <attachment :data="item.attachment" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <b-row class="md-12" style="margin-top:25px">
      <b-col>
        <h3>Updated Amount</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-2">
        <div style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 25cm">
            <thead>
              <tr>
                <th style="font-size:11px !important">SR. NO.</th>
                <th style="font-size:11px !important">DATE</th>
                <th style="font-size:11px !important">UPDATED PLOT AMOUNT </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in data.updatedrate" :key="index">
                <td>{{index + 1}}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
  import axios from "@/components/axios";
  import Attachment from "@/components/Attechment.vue";
  export default {
    components: {
      BFormCheckbox,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormGroup,
      BFormFile,
      Attachment,
      BForm,
      BRow,
      BCol,
      BButton,
      BFormDatepicker,
      BFormTextarea,
      BImgLazy,
      BMedia,
      BMediaAside,
      BMediaBody,
      BLink,
      BImg,
    },
  props: {
    closepopup: {
      default: () => { },
    },
    selectedID: {
      default: 0,
    },
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: {},
      loginData: JSON.parse(localStorage.getItem("userData")),
      selectedSalesBy: "",

      plccharges: '',
      gardenphase:'Yes',
      unitname:"Yard",
      developmentamount:'',
      plc:'Yes',
      cornerphase:'Yes',
      cornerphasevalue:'',
      gardenAmount:'',
      cornerAmount:'',
      plotAmount:'',
      plotareasqmtr:'',
      rateperyard: "",
      franchiserate:'',
      unitname:'',
      plotareasqfeet:'',
      plotValueFr:'',
      plotareayd:"",
      plotAmountFr:"",
      gardenPhaseValue:"",
      withdevelopment:"",
      development:"",
      developmentcharge:"",
      maintainance:"Yes",
      club:"Yes",
      electricity:"Yes",
      extracharge:"Yes"
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getPlotById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data)
            ? json.data.data[0]
            : json.data.data;

            this.plotareayd = this.data.plotareayd ? this.data.plotareayd :'';

        })
        .catch((error) => console.log(error, "error"));

    },
    totalAmountcal() {

      let plotAmount = 0
      let plotAmountFr = 0

      if (this.cornerphase !== 'Yes' || (this.plccharges !== 'Yes')) {
        this.cornerphasevalue = 0
        this.cornerphase = "No"
      }
      if (this.gardenphase !== 'Yes' || this.plccharges !== 'Yes') {
        this.gardenphasevalue = 0
        this.gardenphase = "No"
      }
      if (this.unitname == 'Meter') {
        plotAmount = (this.plotareasqmtr ? this.plotareasqmtr : 0) * (this.rateperyard ? this.rateperyard : 0)
        this.plotValue = plotAmount

        plotAmountFr = (this.plotareasqmtr ? this.plotareasqmtr : 0) * (this.franchiserate ? this.franchiserate : 0)
        this.plotValueFr = plotAmountFr

      }
      else if (this.unitname == 'Feet') {
        plotAmount = (this.plotareasqfeet ? this.plotareasqfeet : 0) * (this.rateperyard ? this.rateperyard : 0)
        this.plotValue = plotAmount

        plotAmountFr = (this.plotareasqfeet ? this.plotareasqfeet : 0) * (this.franchiserate ? this.franchiserate : 0)
        this.plotValueFr = plotAmountFr


      }
      else {

        plotAmount = (this.plotareayd ? this.plotareayd : 0) * (this.rateperyard ? this.rateperyard : 0)
        this.plotValue = plotAmount

        plotAmountFr = (this.plotareayd ? this.plotareayd : 0) * (this.franchiserate ? this.franchiserate : 0)
        this.plotValueFr = plotAmountFr
      }

      const cornerPhaseValue = this.cornerphase == 'Yes' && this.plccharges == 'Yes' ? (((plotAmount * this.cornerphasevalue) ? parseFloat(plotAmount * this.cornerphasevalue) : 0) / 100) : 0
      const gardenPhaseValue = this.gardenphase == 'Yes' && this.plccharges == 'Yes' ? (((plotAmount * this.gardenphasevalue) ? parseFloat(plotAmount * this.gardenphasevalue) : 0) / 100) : 0

      const cornerPhaseValueFr = this.cornerphase == 'Yes' && this.plccharges == 'Yes' ? (((plotAmountFr * this.cornerphasevalue) ? parseFloat(plotAmountFr * this.cornerphasevalue) : 0) / 100) : 0
      const gardenPhaseValueFr = this.gardenphase == 'Yes' && this.plccharges == 'Yes' ? (((plotAmountFr * this.gardenphasevalue) ? parseFloat(plotAmountFr * this.gardenphasevalue) : 0) / 100) : 0


      this.plotamount = plotAmount + cornerPhaseValue + gardenPhaseValue
      this.plotamount = this.handleRoundOff(this.plotamount)

      this.plotamountFr = plotAmountFr + cornerPhaseValueFr + gardenPhaseValueFr
      this.plotamountFr = this.handleRoundOff(this.plotamountFr)
      this.handleAmount()
      this.plotValue = isNaN(this.plotValue) ? 0 : this.handleRoundOff(this.plotValue)
      this.plotValueFr = isNaN(this.plotValueFr) ? 0 : this.handleRoundOff(this.plotValueFr)



      this.gardenAmount = this.handleRoundOff(gardenPhaseValue)
      this.cornerAmount = this.handleRoundOff(cornerPhaseValue)

      this.gardenAmountFr = this.handleRoundOff(gardenPhaseValueFr)
      this.cornerAmountFr = this.handleRoundOff(cornerPhaseValueFr)

    },
    handleRoundOff(num){
      return num ? parseFloat(num.toFixed(2)):0
    },
    handelDevelopment(){
      if(this.development == 'Yes' && this.withdevelopment=='No'){
        this.developmentamount=this.plotareayd * this.developmentcharge;
        this.developmentamount = isNaN(this.developmentamount)?0:this.handleRoundOff(this.developmentamount)
        this.handelExtra()
        return;
      }
      if(this.development == 'No'){
        this.developmentamount = null
        this.developmentcharge = null
      }
      this.handelExtra()

      this.developmentcharge=0
      this.developmentamount=0
      this.development=''
    },

  },
};
</script>
<style>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
