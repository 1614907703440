<style>
@media only screen and (max-width: 480px) {
  .swiper-wrapper {
    display: flex;
    flex-direction: column;
  }

  .swiper-slide {
    width: 100% !important;
  }
}
</style>
<template>
  <div>
    <div>
      <b-col class="content-header-left mb-1" cols="12" md="12">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">Project</h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb style="font-size: 0.9rem">
                <b-breadcrumb-item to="/">
                  <feather-icon icon="HomeIcon" size="14" class="align-text-top" />
                </b-breadcrumb-item>
                <b-breadcrumb-item active> PROJECT </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </div>
    <b-card>
      <b-card-body>
        <b-row>
          <b-col md="8">
            <b-form-group>
              <label>Select Project</label>
              <div class="w-100">
                <b-row class="d-flex">
                  <b-col md="6" class="p-1">
                    <v-select v-model="selectedProject" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="projectname"
                    :options="projectOption" placeholder="None" @input="getPlot($event)" />
                  </b-col>
                  <b-col md="6" class="p-1">
                    <b-dropdown text="Download Plots" style="height:35px">
                      <b-dropdown-item @click="downloadPlots('All')">All</b-dropdown-item>
                      <b-dropdown-item @click="downloadPlots('Vacant')" >Vacant</b-dropdown-item>
                      <b-dropdown-item v-if="userData.type== 'admin' || userData.type == 'Account Head' || userData.type == 'Account Assistant'" @click="downloadPlots('Booked')">Booked</b-dropdown-item>
                      <b-dropdown-item v-if="userData.type== 'admin' || userData.type == 'Account Head' || userData.type == 'Account Assistant'" @click="downloadPlots('Sold')">Sold</b-dropdown-item>
                      <b-dropdown-item v-if="userData.type== 'admin' || userData.type == 'Account Head' || userData.type == 'Account Assistant'" @click="downloadPlots('Hold')">Hold</b-dropdown-item>
                      <b-dropdown-item v-if="userData.type== 'admin' || userData.type == 'Account Head' || userData.type == 'Account Assistant'" @click="downloadPlots('Registered')">Registered</b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="selectedProject">
            <div style="max-width:180px">
              <img :src="selectedProject.projectlogo" style="width:100%"/>
            </div>
          </b-col>

          <b-col md="4">
            <!-- <b-button v-if="selectedProject" variant="primary" type="submit" @click="downloadVacant" class="mt-2">
                <feather-icon icon="DownloadIcon" /> Download</b-button> -->
          </b-col>
          <b-col md="12" class="d-flex align-items-center flex-wrap" v-if="selectedProject">
            <!-- <vue-goodshare
            :bundle="isMobile() ? 'mobile' : 'world'"
            :page_url="selectedProject.projectboucher"
          ></vue-goodshare> -->
              <div class="customerButton">

            <vue-goodshare-email :mail_subject="'Project_broucher'" :title_social="'Email'"
              :imageUrl="selectedProject.projectboucher" has_icon
              :page_url="selectedProject.projectboucher"></vue-goodshare-email>
               <vue-goodshare-sms v-if="isMobile()" :mail_subject="'Project_broucher'" :title_social="'SMS'"
              :imageUrl="selectedProject.projectboucher" has_icon
              :page_url="selectedProject.projectboucher"></vue-goodshare-sms>
              </div>
            <div class="customerButton">
              <b-badge @click="clickWhatsapp()" variant="success"
              class="justify-content-around cursor-pointer d-flex align-items-center"
              style="height: 35px; width: 110px">
              <svg xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 18px" fill="white"
                class="bi bi-whatsapp" viewBox="0 0 16 16">
                <path
                  d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
              </svg>
              Whatsapp
            </b-badge>
          </div>
            <div class="customerButton" v-if="selectedProject">
              <a :href="selectedProject.projectboucher" download="brochure" target="_blank">
                <b-button  style="height:35px" variant="primary" type="submit" @click="download">
                  <feather-icon icon="DownloadIcon" /> Download Brochure
                </b-button>
              </a>

            </div>
            <div class="customerButton" v-if="selectedProject && selectedProject.kmzfile">
              <a :href="selectedProject.kmzfile" download="kmz" target="_blank">
                    <b-button  style="height:35px" variant="secondary" type="submit">
                      <feather-icon icon="DownloadIcon" /> KMZ File
                    </b-button>
                  </a>
            </div>
            <!-- <vue-goodshare-whats-app
              :mail_subject="'Project_broucher'"
              :title_social="'WhatsApp'"
              :imageUrl="selectedProject.projectboucher"
              has_icon
              :page_url="selectedProject.projectboucher"
            ></vue-goodshare-whats-app> -->

          </b-col>
          <b-col md="12" class="d-flex align-items-center mt-2" style="overflow: auto" v-if="visible">
            <ImageMapView :value="phaseImage" :plotOption="allPlotOption" :booking="true" :plots="plotObj"
              @BookPlot="BookPlot" @input="(zones) => change({ zones })" />
            <!-- <div
              id="modal-form"
              size="lg"
              cancel-variant="outline-secondary"
              ok-title="Submit"
              cancel-title="Close"
              title="Plot Image"
              hide-footer
              class="overflow-auto"
            >
              <div class="w-full">
                <PinchScrollZoom ref="zoomer" :width="900" :height="500">
                  <b-img-lazy
                    :src="selectedProject && selectedProject.phaseattachment"
                    fluid
                    style="width: 1100px; height: 500px"
                  />
                </PinchScrollZoom>
              </div>
            </div> -->
          </b-col>
        </b-row>

        <b-row v-if="selectedProject && selectedProject.description">
          <b-col cols="12">
            <b-card class="bg-danger bg-lighten-3" style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h4 class="text-white bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="danger" size="35">
                  <feather-icon size="17" icon="BookOpenIcon" />
                </b-avatar>
                Project Description
              </h4>
              <b-card-body>
                <h4 class="text-white">
                  {{ selectedProject.description }}
                </h4>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="selectedProject && selectedProject.locaiton_highlights">
            <b-col cols="12">
              <b-card style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
                <h4 class=" bookingTitle m-1 d-flex align-items-center">
                  <b-avatar class="mr-1" variant="danger" size="35">
                    <feather-icon size="17" icon="BookOpenIcon" />
                  </b-avatar>
                  Location Highlights
                </h4>
                <b-card-body>
                  <h4>
                    {{ selectedProject.locaiton_highlights }}
                  </h4>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        <b-row v-if="selectedProject">
          <b-card class="w-100" style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
            <h3 class="bookingTitle m-1 d-flex align-items-center justify-content-between">
              <div>
                <b-avatar class="mr-1" variant="danger" size="35">
                  <feather-icon size="17" icon="HomeIcon" />
                </b-avatar>
                Overview
              </div>
              <div>
                <b-button v-if="selectedPlot" size="sm" variant="primary" @click="handleBook()">
                  Book
                </b-button>
              </div>

            </h3>
            <b-card-body>
              <b-row style="width: 100%;" class="d-flex justify-content-between">
                <b-col md="4">
                  <b-form-group>
                    <label>Suggested Plot No</label>
                    <v-select v-model="selectedPlot" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="plotname"
                      :options="plotOption" placeholder="None" @input="getPlotById($event)" />
                  </b-form-group>
                </b-col>
                <b-col md="12"></b-col>
                <b-col cols="6" md="4" class="d-flex p-1">
                  <b-avatar class="mr-1" variant="light-warning" size="35">
                    <feather-icon size="17" icon="MapIcon" />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <label>Project</label>
                    <span>{{ selectedProject.projectname }}</span>
                  </div>
                </b-col>
                <b-col cols="6" md="4" class="d-flex p-1">
                  <b-avatar class="mr-1" variant="light-warning" size="35">
                    <feather-icon size="17" icon="MapPinIcon" />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <label>Plot No.</label>
                    <span v-if="selectedPlot">{{ selectedPlot.plotno }}</span>
                  </div>
                </b-col>
                <b-col cols="6" md="4" class="d-flex p-1">
                  <b-avatar class="mr-1" variant="light-warning" size="35">
                    <feather-icon size="17" icon="SlackIcon" />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <label>No. Of Plots</label>
                    <span>{{ selectedProject.noofplot }}</span>
                  </div>
                </b-col>
                <b-col cols="6" md="4" class="d-flex p-1">
                  <b-avatar class="mr-1" variant="light-warning" size="35">
                    <feather-icon size="17" icon="CrosshairIcon" />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <label>No. Of Garden</label>
                    <span>{{ selectedProject.noofgarden }}</span>
                  </div>
                </b-col>
                <b-col cols="6" md="4" class="d-flex p-1">
                  <b-avatar class="mr-1" variant="light-warning" size="35">
                    <feather-icon size="17" icon="GridIcon" />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <label>Project Type</label>
                    <span>{{ selectedProject.projecttype }}</span>
                  </div>
                </b-col>
                <b-col cols="6" md="4" class="d-flex p-1" v-if="selectedProject.village">
                  <b-avatar class="mr-1" variant="light-warning" size="35">
                    <feather-icon size="17" icon="GridIcon" />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <label>Village Name</label>
                    <span>{{ selectedProject.village.village_name }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-row>

        <b-row class="mt-1">
          <b-col cols="12" v-if="selectedPlot">
            <b-row>
              <b-card no-body class="mb-1 w-100">
                <b-card-header header-tag="header" class="p-1 w-10" style="width: fit-content" role="tab">
                  <b-button block ref="toggle" v-b-toggle.accordion-3 variant="success">Rate Details</b-button>
                </b-card-header>
                <b-collapse id="accordion-3" style="z-index: 31" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row>
                      <b-col md="3">
                        <div class="d-flex justify-content-center" v-if="customer_plotrate">
                          <span><b>Original Price : </b><span class="font-weight-bolder text-danger">{{ customer_plotrate + ' ₹' }}</span></span>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="3">
                        <bookingInfo :statistic="plotRate + ' ₹'" :statistic-title="'Plot Rate In ' + unitname"
                          color="success" />
                      </b-col>

                      <b-col v-if="selectedPlot &&
                        parseInt(selectedPlot.gardenphasevalue) > 0
                        " md="3">
                        <bookingInfo :statistic="gardenValue + ' ₹'" statistic-title="GardenPhase Value" color="success" />
                      </b-col>
                      <b-col v-if="selectedPlot &&
                        parseInt(selectedPlot.cornerphasevalue) > 0
                        " md="3">
                        <bookingInfo :statistic="cornerValue + ' ₹'" statistic-title="CornerPhase Value" color="success" />
                      </b-col>
                      <b-col md="3">
                        <bookingInfo :statistic="plotValue + ' ₹'" statistic-title="Plot Value" color="success" />
                      </b-col>
                      <b-col md="3">
                        <bookingInfo :statistic="totalPayment + ' ₹'" statistic-title="Total Payment" color="success" />
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-col md="12">
                <h3 class="bookingTitle m-1 d-flex align-items-center">
                  <b-avatar class="mr-1" variant="danger" size="35">
                    <feather-icon size="17" icon="ClipboardIcon" />
                  </b-avatar>
                  Other Details
                </h3>
              </b-col>
              <b-col md="3">
                <bookingInfo :statistic="plotAreaYard" statistic-title="Plot Area(Sq. Yard)" color="danger" />
              </b-col>
              <b-col md="3">
                <bookingInfo :statistic="plotAreaFeet" statistic-title="Plot Area(Sq. Feet)" color="danger" />
              </b-col>
              <b-col md="3">
                <bookingInfo :statistic="plotAreaMeter" statistic-title="Plot Area(Sq. MTR)" color="danger" />
              </b-col>
              <b-col md="3" v-if="selectedPlot.placefacing && selectedPlot.placefacing != 0">
                <bookingInfo :statistic="selectedPlot.placefacing" statistic-title="Place Facing" color="danger" />
              </b-col>
              <b-col md="3" v-if="selectedPlot.maintainancecharge &&
                selectedPlot.maintainancecharge != '0.00'
                ">

                <bookingInfo :statistic="selectedPlot.maintainancecharge.toLocaleString('en-In') + ' ₹'" statistic-title="Maintainance Amount(Per Month)"
                  color="danger" />
              </b-col>
              <b-col md="3" v-if="selectedPlot.months && selectedPlot.months != 0">

                <bookingInfo :statistic="selectedPlot.months" statistic-title="Months" color="danger" />
              </b-col>
              <b-col md="3" v-if="selectedPlot.total_maintenance_charge &&
                selectedPlot.total_maintenance_charge != '0.00'
                ">
                <bookingInfo :statistic="parseFloat(selectedPlot.total_maintenance_charge).toLocaleString('en-In') + ' ₹'"
                  statistic-title="Total Maintainance Charges" color="danger" />
              </b-col>
              <b-col md="3" v-if="selectedPlot.developmentcharge &&
                selectedPlot.developmentcharge != '0.00'
                ">
                <bookingInfo :statistic="parseFloat(selectedPlot.developmentcharge).toLocaleString('en-In') + ' ₹'" :statistic-title="'Development Charges (Per Sq.' + selectedPlot.unitname + ')'
                  " color="danger" />
              </b-col>
              <b-col md="3" v-if="selectedPlot.developmentcharges &&
                selectedPlot.developmentcharges != 0
                ">
                <bookingInfo :statistic="parseFloat(selectedPlot.totalDevelopmentCharges).toLocaleString('en-In') +' ₹'" statistic-title="Total Development Charges"
                  color="danger" />
              </b-col>
              <b-col md="3" v-if="selectedPlot.clubHouse && selectedPlot.clubHouse != '0.00'
                ">
                <bookingInfo :statistic="parseFloat(selectedPlot.clubHouse).toLocaleString('en-In') + ' ₹'" statistic-title="Club House Charges" color="danger" />
              </b-col>
              <b-col md="3" v-if="selectedPlot.electricitycharges &&
                selectedPlot.electricitycharges != '0.00'
                ">
                <bookingInfo :statistic="parseFloat(selectedPlot.electricitycharges).toLocaleString('en-In') +' ₹'" statistic-title="Electricity Charges"
                  color="danger" />
              </b-col>
              <b-col md="3" v-if="selectedPlot.otherextracharges &&
                selectedPlot.otherextracharges != '0.00'
                ">
                <bookingInfo :statistic="parseFloat(selectedPlot.otherextracharges).toLocaleString('en-In') + ' ₹'" statistic-title="Other Charges" color="danger" />
              </b-col>
              <b-col md="3" v-if="selectedPlot.extraamount">
                <bookingInfo :statistic="parseFloat(selectedPlot.extraamount).toLocaleString('en-In') +' ₹'" statistic-title="Total Extra Amount" color="danger" />
              </b-col>
            </b-row>
          </b-col>

          <b-col v-if="highlights.length > 0" md="12" class="mt-1">
            <b-card no-body style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="success" size="35">
                  <feather-icon size="17" icon="SlackIcon" />
                </b-avatar>
                Heighlights
              </h3>
              <b-card-body>

                <b-row class="d-flex justify-content-start flex-wrap">
                  <b-col v-for="(item, index) in highlights" :key="index" md="4">
                    <heighlights :statistic="item.name" :statistic-title="index + 1" color="success" />
                  </b-col>
                </b-row>
                <b-row class="mt-2" v-if="selectedPlot">
                  <b-col md="12" class="text-right">
                    <b-button variant="primary" @click="handleBook()">Book Your Plot</b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col v-if="bookingtype.pp ||
            bookingtype.fp ||
            bookingtype.emi" md="12" class="mt-5">
            <b-card no-body style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="warning" size="35">
                  <feather-icon size="17" icon="BarChart2Icon" />
                </b-avatar>
                Payment Type
              </h3>
              <b-card-body>
                <b-row class="d-flex justify-content-between flex-wrap">
                  <b-col md="4" v-if="bookingtype.pp">
                    <heighlights statistic="Part Payment" statistic-title="⬤" color="warning" />
                  </b-col>
                  <b-col md="4" v-if="bookingtype.fp">
                    <heighlights statistic="Full Payment" statistic-title="⬤" color="warning" />
                  </b-col>
                  <b-col md="4" v-if="bookingtype.emi">
                    <heighlights statistic="EMI" statistic-title="⬤" color="warning" />
                  </b-col>
                </b-row>

                <div v-if="emi.length > 0">
                  <div style="width: 100%; overflow: auto">
                    <table class="table table-bordered mt-1" style="min-width: 10cm">
                      <thead>
                        <tr>
                          <th>
                            <p class="head">No. Of EMI</p>
                          </th>
                          <th>
                            <p class="head">Interest</p>
                          </th>
                          <th>
                            <p class="head">Remarks</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in emi" :key="index">
                          <td>
                            <h5 align="center">
                              {{ item.fromMonth }} <b> To </b>
                              {{ item.toMonth }}
                            </h5>
                          </td>
                          <td>
                            <h5 align="center">{{ item.rate }} %</h5>
                          </td>
                          <td>{{ item.remarks }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col v-if="conditions.length > 0" md="12">
            <b-card no-body style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="danger" size="35">
                  <feather-icon size="17" icon="BookOpenIcon" />
                </b-avatar>
                Documents
              </h3>
              <b-card-body>
                 <Carousel :perPageCustom="[[0, 1], [576, 2], [768, 3], [992, 4], [1200, 5]]" paginationColor="#d2d0d0">
                      <!-- Your carousel content goes here -->
                      <Slide v-for="(item, index) in conditions" :key="index">
                      <!-- Item content -->
                      <img :src="item.attachment" alt="Item Image" style="max-width:400px">
                      <h2> <b>{{ item.name }}</b></h2>
                    </Slide>
                  </Carousel>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col v-if="gallery.length > 0" md="12" class="mt-2">
            <b-card no-body style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="warning" size="35">
                  <feather-icon size="17" icon="GridIcon" />
                </b-avatar>
                Gallery
              </h3>
              <b-row>
                <b-col cols="12" v-if="selectedImg" class="d-flex justify-content-center">
                  <div style="max-width:750px;padding:20px">
                    <b-img :src="selectedImg" style="border-radius: 10px" class="w-100 h-100" />
                  </div>
                </b-col>
                <b-col>
                  <b-card-body style="height: 160px ; overflow-x:auto;flex-wrap:nowrap">
                    <b-row class="d-flex justify-content-start align-items-start parentImgDiv"
                      style='width: fit-content;flex-wrap:nowrap'>
                      <b-col cols="6" md="2" class="cursor-pointer w-100 galleryImg" v-for="(img, index) in gallery"
                        :key="index" style="padding: 3px !important; height: 120px" @click="handleClickImage(img)">
                        <b-img :src="img.attachment" class="w-100 h-100" style="border-radius: 15px" />
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-col>

              </b-row>
            </b-card>
          </b-col>

          <b-col v-if="amenities.length > 0" md="12" class="mt-3 p-0">
            <b-card no-body style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <!-- <h3 class="bookingTitle m-1 ">Amenities</h3> -->
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="info" size="35">
                  <feather-icon size="17" icon="BarChart2Icon" />
                </b-avatar>
                Amenities
              </h3>
              <b-card-body class="d-flex align-items-center pl-2 p-0">
                <div style="width:100%">
                  <Carousel :perPageCustom="[[0, 1], [576, 2], [768, 3], [992, 4], [1200, 5]]" paginationColor="#d2d0d0">
                    <!-- Your carousel content goes here -->
                    <Slide v-for="(item, index) in amenities" class="d-flex flex-column justify-content-center ml-2" :key="index" >
                    <!-- Item content -->
                    <img :src="item.attachment" alt="Item Image" style="max-width:400px" @click="handleModal(item)">
                    <h2> <b>{{ item.details }}</b></h2>
                  </Slide>
                </Carousel>
              </div>
                <!-- <swiper class="swiper-multiple" :slides-per-view="1"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                  <swiper-slide class="pb-2" v-for="(data, index) in amenities" :key="index">
                    <div>
                      <div class="d-flex">
                        <b-img :src="data.attachment" style="width: 100%; height: 50%" />
                      </div>
                      <div class="p-1" style="background-color: white">
                        <h2 class="m-0">
                          <b>{{ data.details }}</b>
                        </h2>
                      </div>
                    </div>
                  </swiper-slide>
                  <div slot="pagination" class="swiper-pagination" />
                </swiper> -->
              </b-card-body>
            </b-card>
          </b-col>
          <b-col v-if="selectedProject" cols="12" class="pt-3 d-flex flex-column">
            <b-card no-body style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="success" size="35">
                  <feather-icon size="17" icon="MapPinIcon" />
                </b-avatar>
                Project Location
              </h3>
              <b-card-body class="w-100">
                <iframe :src="iframe" class="mt-2 w-100" style="border: 5" height="500" allowfullscreen="" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade">
                </iframe>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col class="mt-3" v-if="videourl.length > 0 && selectedProject" md="12">
            <b-card no-body style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important">
              <h3 class="bookingTitle m-1 d-flex align-items-center">
                <b-avatar class="mr-1" variant="danger" size="35">
                  <feather-icon size="17" icon="MapIcon" />
                </b-avatar>
                Project Video
              </h3>
              <b-card-body class="d-flex align-items-center pl-2 p-0">
                <div v-for="(item, index) in videourl" :key="index" v-if="selectedProject"
                  class="w-50 p-1 d-flex flex-column">
                  <b-embed type="iframe" aspect="16by9" :src="item.url.replace('youtu.be', 'www.youtube.com/embed')"
                    allowfullscreen />
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-modal id="modal-form" v-model="amenitiesModal" size="md" cancel-variant="outline-secondary" ok-title="Submit"
            cancel-title="Close" :title="selectedAmenities.details" hide-footer>
            <div class="w-100 h-100 d-flex align-items-center flex-column">
              <img :src="selectedAmenities.attachment" style="max-width:100%;min-width:50%"/>
            </div>
          </b-modal>

        <div id="container" hidden></div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Carousel, Slide } from 'vue-carousel';

// import "swiper/css/swiper.css";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BAvatar,
  BCard,
  BBadge,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BButton,
  BBreadcrumb,
  BEmbed,
  BBreadcrumbItem,
  BDropdown,
  BDropdownItem,
  BImgLazy,
  BImg,
  VBToggle,
  BCollapse,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import bookingInfo from "@core/components/statistics-cards/bookingInfo.vue";

import { BTable } from "bootstrap-vue";
import axios from "@/components/axios";
import vSelect from "vue-select";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import image from "../../../assets/images/pages/no-image.png";
import BCardCode from "@core/components/b-card-code";
import Attachment from "../../../components/Attechment.vue";

import ImageMapView from "../../../components/ImageMapView.vue";
import VueGoodshare from "./share/VueGoodshare.vue";
import VueGoodshareEmail from "./share/providers/Email.vue";
import VueGoodshareWhatsApp from "./share/providers/WhatsApp.vue";
import VueGoodshareSms from "./share/providers/SMS.vue";
import heighlights from "@/@core/components/statistics-cards/heighlights.vue";

export default {
  components: {
    bookingInfo,
    Carousel, Slide,
    heighlights,
    ImageMapView,
    VueGoodshare,
    VueGoodshareEmail,
    VueGoodshareWhatsApp,
    VueGoodshareSms,
    // Swiper,
    // SwiperSlide,
    BAvatar,
    BEmbed,
    Attachment,
    BCardCode,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BDropdownItem,
    BBadge,
    PinchScrollZoom,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BFormInput,
    BFormGroup,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BTable,
    vSelect,
    BImgLazy,
    ValidationProvider,
    BImg,
    VBToggle,
    BCollapse,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  async mounted() {
    this.getOverview();
    this.init();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // const accessToken = localStorage.getItem("accessToken"); // JSON.parse()
    // const baseApi = process.env.VUE_APP_APIENDPOINT;
    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    //   data: JSON.stringify({ data: "Today" }),
    //   url: `${baseApi}/getLeadsByBranches`,
    // };

    // await axios(requestOptions)
    //   .then((response) => {
    //     this.leadSummaryData = response.data;
    //   })
    //   .catch((error) => console.log(error, "error"));
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      plotObj: {},
      gallery: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      unitname: "",
      visible: false,
      checkpointReward: {},
      allPlotOption: [],
      subscribersGained: {},
      ordersRecevied: {},
      salesBarSession: {},
      supportTracker: {},
      productsOrder: {},
      salesRadar: {},
      InquiryFollowUp: false,
      InnerInquiryFollowUp: false,
      MemberRenewalFollowUp: false,
      InnerMemberRenewalFollowUp: false,
      followUp: null,
      followUpCount: {
        Overdue: 0,
        Today: 0,
        Upcoming: 0,
      },
      leadSummaryData: null,
      overviewkey: null,
      branchID: null,
      selectedProject: "",
      projectOption: [],
      selectedPhase: {},
      phaseOption: [],
      selectedPlot: "",
      plotOption: [],
      phaseImage: {
        uuid: 3721,
        source: image,
      },
      plotAreaYard: "",
      plotAreaFeet: "",
      plotAreaMeter: "",

      plotValue: "",
      totalPayment: "",
      plotRate: "",
      discount: "",
      selected: [],
      iframe: "",
      videoUrl: "",
      branchData: [],
      userData: {},
      leadID: null,
      InnerUsersLead: false,
      leadKey: null,

      analyticsData: null,
      dispatchedOrders: [],
      leadSummaryData: null,
      selected: [],
      developmentcharge: "",
      amenities: [],
      conditions: [],
      videourl: [],
      cornerValue: 0,
      gardenValue: 0,
      totalMaintainance: 0,
      totalDevelopmentCharges: 0,
      highlights: [],
      plotStatus: null,
      emi: [],
      bookingtype: {},
      selectedImg: "",
      customer_plotrate:'',
      /* eslint-disable global-require */
      swiperData: [
        { img: require("@/assets/images/banner/banner-31.jpg") },
        { img: require("@/assets/images/banner/banner-32.jpg") },
        { img: require("@/assets/images/banner/banner-33.jpg") },
        { img: require("@/assets/images/banner/banner-34.jpg") },
        { img: require("@/assets/images/banner/banner-35.jpg") },
      ],
      message1: "Copy Me Without Directive",
      /* eslint-disable global-require */
      text: `Lorem ipsum dolor sit amet, et nam pertinax gloriatur. Sea te minim soleat senserit, ex quo luptatum
              tacimates voluptatum, salutandi delicatissimi eam ea. In sed nullam laboramus appellantur, mei ei omnis
              dolorem mnesarchum.`,
              swiperOptionsTest: {
        slidesPerView: 1,
        spaceBetween: 10,
        // Add responsive options
        breakpoints: {
          // When window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // When window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // When window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
      },
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 3000,
        },
        scrollbar: { draggable: true },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      breakpointsAm: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      },
      swiperGalleryOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        autoplay: {
          delay: 3000,
        },
        scrollbar: { draggable: true },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      amenitiesModal: false,
      selectedAmenities:{}
    };
  },
  methods: {
    handleClickImage(img) {
      this.selectedImg = img.attachment;
      this.$forceUpdate()
    },
    handleModal(item) {
      this.selectedAmenities = item
      this.amenitiesModal = true
    },
    downloadPlots(e) {
      if (!this.selectedProject) {
        this.$swal({
          title: "Warning",
          text: "Please Select Project",
          icon: "warning",
          timer: 5000,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      var aoa = [
        ['Project Name:', this.selectedProject.projectname],
        [],
        ["Sr.No.", "Plot Number", "Plot size (Sq. Yard) carpet", "Plot Size (Sq. Yars) SBA", "Corner Phase Value (%)", "Garden Phase Value (%)", "Facing"],
        [],
      ];
      if (e !== 'All') {
        aoa[2].push("Status")
      }
      if (this.selectedProject) {
        this.allPlotOption.map((item, index) => {
          if (e == 'All') {
            aoa.push([
              aoa.length -3,
              item.plotno,
              item.carpetarea_sqyds,
              item.plotareayd,
              item.cornerphasevalue,
              item.gardenphasevalue,
              item.placefacing != 0 ? item.placefacing : '',
            ]);
          }
          else if (item.status.toLowerCase() == e.toLowerCase()) {
            aoa.push([
              aoa.length -3,
              item.plotno,
              item.carpetarea_sqyds,
              item.plotareayd,
              item.cornerphasevalue,
              item.gardenphasevalue,
              item.placefacing != 0 ? item.placefacing : '',
              item.status,
            ]);
          }
        });
      }

      var ws = XLSX.utils.aoa_to_sheet(aoa);
      var html_string = XLSX.utils.sheet_to_html(ws, {
        id: "data-table",
        editable: true,
      });
      document.getElementById("container").innerHTML = html_string;
      function doit(type, fn) {
        var elt = document.getElementById("data-table");
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        XLSX.writeFile(wb, fn || "SheetJSTableExport." + (type || "xlsx"));
      }
      doit("xlsx", this.selectedProject.projectname + ".xlsx");
    },
    clickWhatsapp() {
      let url = "https://wa.me/";
      const all = url + this.userData.mobile;
      window.open(all, "_blank");
    },
    isMobile() {
      // credit to Timothy Huang for this regex test:
      // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    openShareDialog() {
      if (navigator.share) {
        let url = this.selectedProject.projectboucher.includes("https")
          ? this.selectedProject.projectboucher
          : this.selectedProject.projectboucher.replace("http", "https");
        navigator
          .share({
            title: "Nestoria Group",
            text: "Share Project Broucher",
            url: url,
          })
          .then(() => {
            console.log("Shared successfully.");
          })
          .catch((error) => {
            console.error("Error sharing:", error);
          });
      } else {
        console.log("Web Share API not supported.");
      }
    },
    download() {
      // const url = this.selectedProject.projectboucher.replace('http','https')
      if (!this.selectedProject.projectboucher) {
        this.$swal({
          title: "Warning",
          text: "There Is No Brochure",
          icon: "warning",
          timer: 5000,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        let url = this.selectedProject.projectboucher.includes("https")
          ? this.selectedProject.projectboucher
          : this.selectedProject.projectboucher.replace("http", "https");
        //     fetch(url, {
        //         mode : 'no-cors',
        //       })
        //   .then(response => response.blob())
        //   .then(blob => {
        //   let blobUrl = window.URL.createObjectURL(blob);
        //   let a = document.createElement('a');
        //   a.download = url.replace(/^.*[\\\/]/, '');
        //   a.href = blobUrl;
        //   document.body.appendChild(a);
        //   a.click();
        //   a.remove();
        // })
        // const ex = url.split(".")[5];

        axios({
          url,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const fileURL = url;
          const fURL = document.createElement("a");
          let ext1 = url.split(".").pop();
          fURL.href = fileURL;
          fURL.setAttribute("download", `Brochure.${[ext1]}`);
          document.body.appendChild(fURL);
          fURL.click();
        });
      }

      // window.open(this.selectedProject.projectboucher)
    },
    async init() {
      this.getProject();
    },
    async getOverview() {
      const accessToken = localStorage.getItem("accessToken"); // JSON.parse()
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      // const user = JSON.parse(localStorage.getItem("userData"));

      fetch(`${baseApi}/getoverview`, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          this.followUpCount = json;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getProject() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getPlot(e) {
      if (e) {
        this.amenities = this.selectedProject.amenities
          ? JSON.parse(this.selectedProject.amenities)
          : [];
        this.conditions = this.selectedProject.conditions
          ? JSON.parse(this.selectedProject.conditions)
          : [];
        this.videourl = this.selectedProject.videourl
          ? JSON.parse(this.selectedProject.videourl)
          : [];
        this.highlights = this.selectedProject.highlights
          ? JSON.parse(this.selectedProject.highlights)
          : [];
        this.gallery = this.selectedProject.gallery
          ? JSON.parse(this.selectedProject.gallery)
          : [];

        this.emi = this.selectedProject.emi
          ? JSON.parse(this.selectedProject.emi)
          : [];
        this.bookingtype = this.selectedProject.bookingtype
          ? JSON.parse(this.selectedProject.bookingtype)
          : {};

        this.plotOption = [];
        if (e.iframe && e.iframe.includes("src=")) {
          this.iframe = e.iframe.split('src="')[1].split('"')[0];
        }
        if (e.videourl && e.videourl.includes("src=")) {
          this.videoUrl = e.videourl.split('src="')[1].split('"')[0];
        }
        if (this.gallery.length > 0) {
          this.selectedImg = this.gallery[0].attachment;
        }
        this.gallery = this.gallery.filter((item) =>
          item.attachment ? true : false
        );
        this.amenities = this.amenities.filter((item) =>
          item.attachment ? true : false
        );
        this.highlights = this.highlights.filter((item) =>
          item.name ? true : false
        );
        this.videourl = this.videourl.filter((item) =>
          item.url ? true : false
        );
        this.conditions = this.conditions.filter((item) =>
          item.attachment ? true : false
        );

        this.getAttachment(e.id);
        this.getAllPlot(e.id);
        this.visible = true;
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/plotno`,
          data: JSON.stringify({
            project_id: e.id,
          }),
        };
        await axios(requestOptions)
          .then((response) => {
            this.plotOption = response.data.data;
            this.$forceUpdate();
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async getAllPlot(id) {
      const requestOptions = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getallplotbyproject/${id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.allPlotOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getAttachment(id) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getattachment`,
        data: JSON.stringify({
          project_id: id,
        }),
      };
      await axios(requestOptions)
        .then((response) => {
          this.phaseImage.source = response.data.data
            ? response.data.data
            : image;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getPlotById(plot) {
      if (!plot) {
        return;
      }
      this.plotObj = {};
      this.plotObj[plot.id] = plot.id;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getplotno`,
        data: JSON.stringify({
          plot_id: plot.id,
        }),
      };
      await axios(requestOptions)
        .then((response) => {
          let data = response.data.data.data[0];
          // console.log(response, "res=======");
          this.unitname = data.unitname;
          this.plotAreaYard = parseFloat(data.plotareayd).toLocaleString('en-IN');
          this.plotAreaFeet = parseFloat(data.plotareasqfeet).toLocaleString('en-IN');
          this.plotAreaMeter = parseFloat(data.plotareasqmtr).toLocaleString('en-IN');

          this.plotRate = parseFloat(data.rateperyd).toLocaleString('en-IN');
          this.plotValue = parseFloat(data.plotValue).toLocaleString('en-IN');
          this.totalPayment = parseFloat(data.plotamount).toLocaleString('en-IN');
          this.customer_plotrate = parseFloat(data.customer_plotrate).toLocaleString('en-IN');
          this.cornerValue = (

            data.cornerphase == "Yes"
              ? (data.plotValue * data.cornerphasevalue) / 100
              : 0
            ).toLocaleString('en-IN');

          this.gardenValue =(
            data.gardenphase == "Yes"
              ? (data.plotValue * data.gardenphasevalue) / 100
            : 0
            ).toLocaleString('en-IN');

          this.totalMaintainance = (data.maintainancecharge
            ? data.maintainancecharge * data.month
            : 0).toLocaleString('en-IN')
          let area = 0;

          if (data.unitname.toLowerCase().includes("feet")) {
            area = this.plotAreaFeet;
          } else if (data.unitname.toLowerCase().includes("yard")) {
            area = this.plotAreaYard;
          } else {
            area = this.plotAreaMeter;
          }
          this.totalDevelopmentCharges = (data.developmentcharge
            ? data.developmentcharge * area
            : 0).toLocaleString('en-IN');
        })
        .catch((error) => console.log(error, "error"));
    },
    // async handleClickInquiryFollowUp(key) {
    //   this.InquiryFollowUp = true;
    //   this.InnerInquiryFollowUp = false;
    //   this.InnerUsersLead = false;
    //   this.overviewkey = key;
    //   const accessToken = localStorage.getItem("accessToken"); // JSON.parse()
    //   const baseApi = process.env.VUE_APP_APIENDPOINT;
    //   const requestOptions = {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //     data: JSON.stringify({ data: `${key}` }),
    //     url: `${baseApi}/getLeadsByBranches`,
    //   };

    //   await axios(requestOptions)
    //     .then((response) => {
    //       this.leadSummaryData = response.data;
    //     })
    //     .catch((error) => console.log(error, "error"));
    // },
    BookPlot(zone) {
      localStorage.removeItem("plotData");
      const plotData = {
        project: this.selectedProject,
        projectid: this.selectedProject.id,
        plot: zone.plotData.plotName,
        plotid: zone.plotData.plotId,
        dashboard: true,
      };
      localStorage.setItem("plotData", JSON.stringify(plotData));
      this.$router.push("/crm/sales/addsales");
    },
    handleBook() {
      localStorage.removeItem("plotData");
      const plotData = {
        project: this.selectedProject,
        projectid: this.selectedProject.id,
        plot: this.selectedPlot,
        plotid: this.selectedPlot.id,
        dashboard: true,
      };
      localStorage.setItem("plotData", JSON.stringify(plotData));
      this.$router.push("/crm/sales/addsales");
    },
  },
};
</script>
<style scoped>

.parentImgDiv:hover>.galleryImg:not(:hover) {
  opacity: 0.5;
}
.customerButton{
  padding:3px
}
.carousel-item {
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
}
</style>

