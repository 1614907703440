<template>
  <div>
    <b-row>

      <b-col md="12" class="mt-1">
        <b-col md="12">
          <h3>Broker details</h3>
        </b-col>
        <div style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 20.5cm; ">
            <thead>
              <tr>
                <th style="font-size:11px !important">Broker Name</th>
                <th style="font-size:11px !important">Broker Mobile No</th>
                <th style="font-size:11px !important">Brokerage Type </th>
                <th style="font-size:11px !important">Type Calculation </th>
                <th style="font-size:11px !important">Brokerage Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" (item, index) in (data.broker)" :key="index">
                <td>{{ item.brokername }}</td>
                <td>{{ item.brokermobile }}</td>
                <td>{{ item.brokeragetype }}</td>
                <td>{{ item.typecalculation }}</td>
                <td>{{ item.brokerageamount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
        <b-col md="12" class="my-2">
          <h3>Land Details</h3>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Village Name:</label>
          <p class="viewValue">{{ data.villagename }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Survey No. New :</label>
          <p class="viewValue">{{ data.surveynonew }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Survey No. Old :</label>
          <p class="viewValue">{{ data.surveynoold }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> FP. No :</label>
          <p class="viewValue">{{ data.fpno }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Original Plot No. (OP) :</label>
          <p class="viewValue">{{ data.orignalplotno }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Khata No :</label>
          <p class="viewValue">{{ data.khatano }}</p>
        </b-col>
        <b-col md="12">
          <h3>Farmer Details</h3>
        </b-col>
        <b-col md="12" class="mt-2">
          <div style="width: 100%; overflow: auto">
            <table class="table table-bordered" style="min-width: 25cm">
              <thead>
                <tr>
                  <th style="font-size:11px !important">Farmer Name</th>
                  <th style="font-size:11px !important">Farmer Father / Husband Name</th>
                  <th style="font-size:11px !important">Aadhar Card No</th>
                  <th style="font-size:11px !important">Pan No </th>
                  <th style="font-size:11px !important">Mobile</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (item,index) in data.farmer" :key="index">
                  <td>{{item.farmername}}</td>
                  <td>{{item.farmerhusbandname}}</td>
                  <td>{{item.farmeraadharno }}</td>
                  <td>{{item.farmerpanno}}</td>
                  <td>{{item.farmermobile }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <b-col md="12" class="my-2">
          <h3>Buyer Details</h3>
        </b-col>
        <b-col md="12" class="mt-1">
          <div style="width: 100%; overflow: auto">
            <table class="table table-bordered" style="min-width: 20.5cm; ">
              <thead>
                <tr>
                  <th style="font-size:11px !important">Buyer Name</th>
                  <th style="font-size:11px !important">Buyer Father / Husband Name</th>
                  <th style="font-size:11px !important">Aadhar Card No</th>
                  <th style="font-size:11px !important">Pan No </th>
                  <th style="font-size:11px !important">Mobile</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (item,index) in data.buyer" :key="index">
                  <td>{{item.buyername}}</td>
                  <td>{{item.buyerhusbandname}}</td>
                  <td>{{item.buyeraadharno }}</td>
                  <td>{{item.buyerpanno}}</td>
                  <td>{{item.buyermobile }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>

        <b-col md="12" class="my-2">
          <h3>Purchasing Details</h3>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Property Type:</label>
          <p class="viewValue">{{ data.propertyType }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Property in :</label>
          <p class="viewValue">{{ data.propertyin }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Per Unit Type:</label>
          <p class="viewValue">{{ data.plot }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Quantity:</label>
          <p class="viewValue">{{ data.quantity }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Total Area :</label>
          <p class="viewValue">{{ data.area }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> FP Area :</label>
          <p class="viewValue">{{ data.fparea }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Rate :</label>
          <p class="viewValue">{{ data.rate }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Total Value :</label>
          <p class="viewValue">{{ data.totalvalue }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Per Sq.Yd :</label>
          <p class="viewValue">{{ data.perbigha }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Total Amount :</label>
          <p class="viewValue">{{ data.totalamount }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> legal Expenses :</label>
          <p class="viewValue">{{ data.legalexpenses }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Registry Expenses :</label>
          <p class="viewValue">{{ data.registryexpenses }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> NA Expenses :</label>
          <p class="viewValue">{{ data.NAexpenses }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Other Payment :</label>
          <p class="viewValue">{{ data.otherpayment }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Remarks :</label>
          <p class="viewValue">{{ data.remarks }}</p>
        </b-col>
        <b-col md="12" class="my-2">
          <h2>Documentation</h2>
        </b-col>
        <b-col md="12">
          <h3>Token Chiththi</h3>
        </b-col>
        <b-col md="3" class="mt-2">
          <label class="viewLabel"> Signed Token Chiththi:</label>
          <p class="viewValue">{{ tokenchitthi }}</p>
        </b-col>
        <b-col md="3" class="mt-2" v-if="tokenchitthi == 'Yes' ">
          <label class="viewLabel"> Upload File :</label>
          <div style="width:100px;">
            <img :src="data.tokenchitthifile" />
            </div>
        </b-col>
        <b-col md="3" class="mt-2">
          <label class="viewLabel"> Received:</label>
          <p class="viewValue">{{tokenchitthireceived }}</p>
        </b-col>
        <b-col md="3" class="mt-2" v-if="tokenchitthireceived == 'Yes'" >
          <label class="viewLabel"> Upload File :</label>
          <div style="width:100px;">
            <img :src="data.tokenchitthireceivedfile" />
            </div>
        </b-col>
        <b-col md="12" class="my-2">
          <h3>Title Clear</h3>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Farmer Application for Papernote/Jaher suchna :</label>
          <p class="viewValue">{{farmerapplication}}</p>
        </b-col>
        <b-col md="3" class="mt-2" v-if="farmerapplication == 'Yes'">
          <label class="viewLabel"> Select Date :</label>
          <p class="viewValue">{{ data.farmerapplicationdate }}</p>
        </b-col>
        <b-col md="3" class="mt-2" v-if="farmerapplication == 'Yes'">
          <label class="viewLabel"> Upload File :</label>
          <div style="width:100px;">
            <img :src="data.farmerapplicationupload" />
            </div>
        </b-col>
        <b-col md="3" class="mt-2" v-if="farmerapplication == 'Yes'">
          <label class="viewLabel"> Remark :</label>
          <p class="viewValue">{{ data.farmerapplicationremarks }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel">Paper Note/Jaher Suchna:</label>
          <p class="viewValue">{{ papernote }}</p>
        </b-col>
        <b-col md="3" class="mt-2" v-if="papernote == 'Yes'">
          <label class="viewLabel"> Select Date :</label>
          <p class="viewValue">{{ data.papernotedate }}</p>
        </b-col>
        <b-col md="3" class="mt-2" v-if="papernote == 'Yes'">
          <label class="viewLabel"> Upload File :</label>
          <div style="width:100px;">
            <img :src="data.papernoteupload" alt="">
            </div>
        </b-col>
        <b-col md="3" class="mt-2" v-if="papernote == 'Yes'">
          <label class="viewLabel"> Remark :</label>
          <p class="viewValue">{{ data.papernoteremarks }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Title Clear/NOC:</label>
          <p class="viewValue">{{ titleclear }}</p>
        </b-col>
        <b-col md="3" class="mt-2" v-if="titleclear == 'Yes'">
          <label class="viewLabel"> Select Date :</label>
          <p class="viewValue">{{ data.titlecleardate }}</p>
        </b-col>
        <b-col md="3" class="mt-2" v-if="titleclear == 'Yes'">
          <label class="viewLabel"> Upload File :</label>
          <div style="width:100px;">
            <img :src="data.titleclearupload" alt="">
            </div>
        </b-col>
        <b-col md="3" class="mt-2" v-if="titleclear == 'Yes'">
          <label class="viewLabel"> Remark :</label>
          <p class="viewValue">{{ data.titleclearremarks }}</p>
        </b-col>
      <b-col md="12" class="my-2">
        <h3>NA Process</h3>
      </b-col>
      <b-col md="12" class="mt-2">
        <div style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 25cm">
            <thead>
              <tr>
                <th style="font-size:11px !important">Activity</th>
                <th style="font-size:11px !important">Upload File</th>
                <th style="font-size:11px !important">Pending</th>
                <th style="font-size:11px !important">Remark</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" (item,index) in data.naprocess" :key="index">
                <td>{{item.naactivity}}</td>
                <td><img :src="item.attachment" /></td>
                <td>{{item.napending }}</td>
                <td>{{item.naremarks}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <b-col md="12" class="my-2">
        <h3>Sale Deed</h3>
      </b-col>
      <b-col md="12" class="mt-2">
        <div style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 25cm">
            <thead>
              <tr>
                <th style="font-size:11px !important">Sr No.</th>
                <th style="font-size:11px !important">DOCUMENTATION</th>
                <th style="font-size:11px !important">REMARKS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" (item,index) in data.saledeed" :key="index">
                <td>{{index=1}}</td>
                <td><p>{{item.documentation}}</p> <br> <p>{{item.processtracking}}</p> <br> <p>{{item.finalbuyername}}</p></td>
                <td><p>{{item.documentationremarks}}</p> <br> <p>{{item.processtrackingremarks}}</p> <br> <p>{{item.finalbuyerremarks}}</p></td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import axios from "@/components/axios";
import Attachment from "@/components/Attechment.vue";
export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: {
    closepopup: {
      default: () => { },
    },
    selectedID: {
      default: 0,
    },
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: {},
      loginData: JSON.parse(localStorage.getItem("userData")),
      tokenchitthi:'No',
      tokenchitthireceived:'No',
      farmerapplication:'No',
      papernote:'No',
      titleclear:'No'

    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getlandpurchaseById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data)
            ? json.data.data[0]
            : json.data.data;

        })
        .catch((error) => console.log(error, "error"));

    },
  }
}

</script>

<style>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}
.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
