<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <h4 class="mb-3" v-if="(pending || rejected) && specialRemarks"><span class="text-danger">NOTE : </span> {{specialRemarks}}</h4>
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Pan No</label>
              <validation-provider #default="{ errors }" rules="required" name="Pan No">
                <b-form-input v-model="panno" maxlength="10" @input="validatePanClick($event)"
                  placeholder="Enter PAN No" />
                <small v-if="pannoValidate == false" class="text-danger">Enter Valid PAN No</small>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Aadhar No</label>
              <validation-provider #default="{ errors }" rules="required" name="Aadhar no">
                <b-form-input v-model="aadharcardno" maxlength="14" @input="validateAadharClick($event)"
                  placeholder="Enter Aadhar No" />
                <small v-if="adharnoValidate == false" class="text-danger">Enter Valid Aadhar No</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <label>Bank</label>
              <b-form-input v-model="bankname" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Bank" rules="required" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Branch</label>
              <b-form-input v-model="bankbranch" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Branch" rules="required" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Account No</label>
              <b-form-input v-model="bankaccno" type="number" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Account No" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>IFSC Code</label>
              <b-form-input v-model="ifsccode" maxlength="11" @input="validateIFSCCodeClick($event)"
                placeholder="Enter IFSC Code" />
              <small v-if="ifsccodeValidate == false" class="text-danger">Enter Valid IFSC Code</small>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Pan Card</label>
              <validation-provider #default="{ errors }" rules="required" name="Pancard">
                <b-form-file v-model="pancard" placeholder="Select Pan Card" drop-placeholder="Drop file here..."
                  accept="image/*" @input="handleFile($event, 'pancard')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="pancard" />
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Aadhar Front side">
                <label>Aadhar Card Front Side</label>
                <b-form-file v-model="aadharfrontside" placeholder="Select Aadhar Card Front Side"
                  drop-placeholder="Drop file here..." accept="image/*" @input="handleFile($event, 'aadharfrontside')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="aadharfrontside" />
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Aadhar Back side">
                <label>Aadhar Card Back Side</label>
                <b-form-file v-model="aadharbackside" placeholder="Select Aadhar Card Back Side"
                  drop-placeholder="Drop file here..." accept="image/*" @input="handleFile($event, 'aadharbackside')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <attachment :data="aadharbackside" />
          </b-col>
          <b-col cols="12">
            <b-button variant="primary" class="mt-2 mr-1" type="submit" :disabled="!flag" @click.prevent="submitForm">
              Save changes
            </b-button>
            <b-button v-if="!back" variant="outline-secondary" type="reset" class="mt-2" @click="handleBack()">
              Back
            </b-button>
            <b-button v-if="back" variant="danger" class="mt-2" @click="handleLogout()">
              Logout
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import useAppConfig from "@core/app-config/useAppConfig";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormFile,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import BCardCode from "@core/components/b-card-code";
import Attachment from "../../../components/Attechment.vue";
import axios from '@/components/axios';
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BImgLazy,
    BFormFile,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => { },
    },
    back: {
      default: true,
    },
    pending: {
      default: false,
    },
    rejected: {
      default: false,
    },
  },
  data() {
    return {
      aadharcardno: "",
      panno: "",
      aadharfrontside: "",
      aadharbackside: "",
      pancard: "",
      pannoValidate: true,
      adharnoValidate: true,
      flag: true,
      userData: JSON.parse(localStorage.getItem("userData")),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      bankaccno: '',
      bankname: '',
      bankbranch: '',
      ifsccode: '',
      ifsccodeValidate:true,
      specialRemarks:''
    };
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    async getUserData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.userData.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }).then((response) => {
        this.getEditValue(response.data.data[0]);
      });
    },
    getEditValue(item) {
      this.aadharcardno = item.aadharcardno;
      this.panno = item.panno;
      this.aadharfrontside = item.aadharfrontside;
      this.aadharbackside = item.aadharbackside;
      this.pancard = item.pancard;
      this.bankaccno= item.bankaccno;
      this.bankbranch= item.bankbranch;
      this.bankname= item.bankname;
      this.ifsccode= item.ifsccode;
      this.specialRemarks = item.specialremarks

    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    validateAadharClick(e) {
      const regex = new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/);
      this.aadharcardno.length == 4
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      this.aadharcardno.length == 9
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      if (regex.test(this.aadharcardno) == true) {
        this.adharnoValidate = true;
      } else {
        this.adharnoValidate = false;
      }
    },
    async handleFile(e, type) {
      this.flag = false;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestoption)
        .then((response) => {
          if (type == "aadharfrontside") {
            this.aadharfrontside = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else if (type == "aadharbackside") {
            this.aadharbackside = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else {
            this.pancard = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.flag = true;
        })
    },
    submitForm(e) {
      this.flag = false
      const data = {
        aadharcardno: this.aadharcardno,
        panno: this.panno,
        aadharbackside: this.aadharbackside,
        aadharfrontside: this.aadharfrontside,
        pancard: this.pancard,

        bankaccno: this.bankaccno,
        bankbranch: this.bankbranch,
        bankname: this.bankname,
        ifsccode: this.ifsccode,

      };

      // var ifEdit = this.$route.query.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (!success || !this.adharnoValidate || !this.pannoValidate || !this.ifsccodeValidate) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.flag = true

        }
        if (success && this.adharnoValidate && this.pannoValidate && this.ifsccodeValidate ) {
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: "PUT",
            url: `${baseApi}/auth/userupdate/${this.userData.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.$store.dispatch('app/getUserData')
              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$forceUpdate();
            })
            .catch((error) => {
              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .finally(() => {
              this.flag = true

            })
        }
      });
    },
    handleBack() {
      this.$router.push("/");
    },
    async handleLogout() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/logout`,
      };

      await axios(requestOptionsUsers).then((response) => {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

        // Remove userData from localStorage

        const keys = Object.keys(localStorage);
        let i = keys.length;

        while (i--) {
          localStorage.removeItem(`${keys[i]}`);
        }
        // Reset ability
        this.$ability.update(initialAbility);

        // Redirect to login page
        this.$router.push({ name: "auth-login" });
      });
    },
  },
};
</script>
