var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Deed Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Deed Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background":"transparent"},attrs:{"config":{
                  enableTime: false,
                  dateFormat: 'd/m/Y',
                }},model:{value:(_vm.deeddate),callback:function ($$v) {_vm.deeddate=$$v},expression:"deeddate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Deed No")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Deed No"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Enter Deed No"},model:{value:(_vm.deedno),callback:function ($$v) {_vm.deedno=$$v},expression:"deedno"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Sale Deed Attachment")]),_c('b-form-file',{attrs:{"placeholder":"Select Attachment","drop-placeholder":"Drop file here...","accept":"image/*"},on:{"input":function($event){return _vm.handleFileChangeDeed($event, 'deedattachment')}},model:{value:(_vm.deedattachment),callback:function ($$v) {_vm.deedattachment=$$v},expression:"deedattachment"}})],1),_c('attachment',{attrs:{"data":_vm.deedattachment}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"primary","type":"submit","disabled":_vm.flag},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }