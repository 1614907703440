  <template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label>Deed Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Deed Date"
              >
                <flat-pickr
                  v-model="deeddate"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                  }"
                  style="background: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Deed No</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Deed No"
              >
                <b-form-input
                  v-model="deedno"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Deed No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Sale Deed Attachment</label>
              <b-form-file
                v-model="deedattachment"
                placeholder="Select Attachment"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChangeDeed($event, 'deedattachment')"
              />
            </b-form-group>
            <attachment :data="deedattachment" />
          </b-col>
          <b-col md="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              :disabled="flag"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Attachment from "./Attechment.vue";
import {
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  BRow,
  BCol,
  BInputGroup,
  BButton,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';
import moment from "moment";

export default {
  props: ["salesDeedClose", "selectedId", "getGridData",'selectedPlot'],

  components: {
    flatPickr,
    Attachment,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      deeddate: moment(new Date()).format("DD/MM/yyyy"),
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      deedattachment: "",
      deedno: "",
      flag: false,
    };
  },
  mounted() {
    if(this.selectedPlot.deedno){
      this.deedno = this.selectedPlot.deedno
      this.deedattachment = this.selectedPlot.deedattachment
      this.deeddate = this.selectedPlot.deeddate
    }
  },
  methods: {
    submitForm(e) {
      const data = {
        branch: 1,
        active: 1,
        deeddate: this.deeddate,
        deedattachment: this.deedattachment,
        deedno: this.deedno,
        status: "Sold",
        action: 2,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: "put",
            url: `${baseApi}/saledeed/${this.selectedId}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.salesDeedClose();
              this.getGridData();
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    async handleFileChangeDeed(e, type) {
      this.flag = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.deedattachment =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.salesDeedClose();
    },
  },
};
</script>
