<template>
  <div>
    <b-row class="row justify-content-between">
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Sales By :</label>
        <p class="viewValue">{{ selectedSalesBy }}</p>
      </b-col>

      <b-col
        md="4"
        class="mt-2"
        :style="selectedSalesBy !== 'Direct' ? '' : 'display: none'"
      >
        <label class="viewLabel">{{ selectedSalesByLabel }}</label>
        <p class="viewValue">{{ data.selectedteam ? data.selectedteam.name : "" }}</p>
      </b-col>
      <!-- <b-col md="4" class="mt-2" :style="selectedSalesBy == 'Employee' ? '' : 'display: none'">
          <label class="viewLabel">{{ selectedSalesByLabel }}</label>
          <p class="viewValue">{{data.selectedteam && data.selectedteam ? data.selectedteam[0].name : ''}}</p>
        </b-col>
        <b-col md="4" class="mt-2" :style="selectedSalesBy == 'Franchise' && selectedSalesBy != 'Direct' ? '' : 'display: none'">
          <label class="viewLabel">{{ selectedSalesByLabel }}</label>
          <p class="viewValue">{{data.selectedteam && data.selectedteam.length>0 ? data.selectedteam[0].name : ''}}</p>
        </b-col> -->

      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Booking Date :</label>
        <p class="viewValue">{{ bookingdate }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Booking Type :</label>
        <p class="viewValue">{{ data.bookingtype ? data.bookingtype : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Email :</label>
        <p class="viewValue">{{ data.selectedteam ? data.selectedteam.email : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Phone No :</label>
        <p class="viewValue">{{ data.selectedteam ? data.selectedteam.mobile : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Username :</label>
        <p class="viewValue">
          {{ data.customer && data.customer.length > 0 ? data.customer[0].username : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Photo :</label>
        <div style="width: 100px">
          <attachment v-if="data.selectedteam" :data="data.selectedteam.profile_image" />
        </div>
      </b-col>
      <b-col md="12" class="mt-2">
        <h3>Application Form</h3>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> In SIR?:</label>
        <p class="viewValue">{{ sir }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Village Name (New Survay No.) :</label>
        <p class="viewValue">
          {{ data.village ? data.village.villagename : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Zone:</label>
        <p class="viewValue">{{ data.zone ? data.zone.name : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Selelct T.P. :</label>
        <p class="viewValue">{{ data.tpno ? data.tpno : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Selelct Sub T.P. :</label>
        <p class="viewValue">{{ data.subtpno ? data.subtpno : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Survey No. New:</label>
        <p class="viewValue">
          {{ data.village && data.village.length > 0 ? data.village[0].surveynonew : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Old Survay No. :</label>
        <p class="viewValue">
          {{ data.village && data.village.length > 0 ? data.village[0].surveynoold : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> F.P. No. :</label>
        <p class="viewValue">{{ data.fpno ? data.fpno : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> F.P. Area /Land size (In Sq. Yard) :</label>
        <p class="viewValue">{{ data.fparea ? data.fparea : "" }}</p>
      </b-col>

      <b-col md="12" class="mt-2">
        <h3>Rate Details</h3>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> CP Rate :</label>
        <p class="viewValue">{{ data.cprate ? data.cprate : "" }}</p>
      </b-col>
      <b-col
        md="4"
        class="mt-2"
        :style="selectedPaymentThrough == 'By UPI' ? '' : 'Display:none'"
      >
        <label class="viewLabel"> Bulk Land Rate:</label>
        <p class="viewValue">{{ data.landrate ? data.landrate : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> With NA? :</label>
        <p class="viewValue">{{ data.withna ? data.withna : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Remarks:</label>
        <p class="viewValue">{{ data.remarks ? data.remarks : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Land Jantri Rate/ Govt. Guideline Rs. :</label>
        <p class="viewValue">{{ data.jantrirate ? data.jantrirate : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Payment Type :</label>
        <p class="viewValue">{{ data.paymenttype ? data.paymenttype : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="paymenttype == 'Token'">
        <label class="viewLabel"> Payment Token :</label>
        <p class="viewValue">{{ data.paymenttoken ? data.paymenttoken : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Payment Terms Days:</label>
        <p class="viewValue">{{ data.paymenttermday ? data.paymenttermday : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Payment Through :</label>
        <p class="viewValue">{{ data.paymentthrough ? data.paymentthrough : "" }}</p>
      </b-col>
      <b-col md="12">
        <h3>Payment Details</h3>
      </b-col>
      <b-col md="4" class="mt-2" v-if="noChequeField">
        <label class="viewLabel"> Bank Name :</label>
        <p class="viewValue">{{ data.bankname ? data.bankname : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="noChequeField">
        <label class="viewLabel"> Reference Number:</label>
        <p class="viewValue">{{ data.refno ? data.refno : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="noChequeField">
        <label class="viewLabel"> Upload Receipt:</label>
        <img src="data.bankreceipt" alt="" />
      </b-col>
      <b-col md="4" class="mt-2" v-if="noChequeField">
        <label class="viewLabel"> Date:</label>
        <p class="viewValue">{{ data.bankdate ? data.bankdate : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="noChequeField">
        <label class="viewLabel"> Remarks:</label>
        <p class="viewValue">{{ data.bankremarks ? data.bankremarks : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="chequeField">
        <label class="viewLabel"> Cheque No :</label>
        <p class="viewValue">{{ data.chequeno ? data.chequeno : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="chequeField">
        <label class="viewLabel"> Bank Name :</label>
        <p class="viewValue">{{ data.bankname ? data.bankname : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="chequeField">
        <label class="viewLabel"> Cheque Date:</label>
        <p class="viewValue">{{ data.chequedate ? data.chequedate : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="chequeField">
        <label class="viewLabel"> Status :</label>
        <p class="viewValue">{{ data.status ? data.status : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel">Total Payment :</label>
        <p class="viewValue">{{ data.totalpayout ? data.totalpayout : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> First Payment:</label>
        <p class="viewValue">{{ data.firstpayment ? data.firstpayment : "" }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Remaining Payment :</label>
        <p class="viewValue">{{ data.remainingpayment ? data.remainingpayment : "" }}</p>
      </b-col>
      <b-col md="12">
        <h3>Customer Details</h3>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Title :</label>
        <p class="viewValue">
          {{ data.customer && data.customer.length > 0 ? data.customer[0].title : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Customer Name :</label>
        <p class="viewValue">
          {{ data.customer && data.customer.length > 0 ? data.customer[0].name : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Address :</label>
        <p class="viewValue">
          {{
            data.customer && data.customer.length > 0
              ? data.customer[0].curaddressline1
              : ""
          }}
          {{
            data.customer && data.customer.length > 0
              ? data.customer[0].curaddressline2
              : ""
          }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Pin Code :</label>
        <p class="viewValue">
          {{
            data.customer && data.customer.length > 0 ? data.customer[0].perpincode : ""
          }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> City :</label>
        <p class="viewValue">
          {{ data.customer && data.customer.length > 0 ? data.customer[0].curcity : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Area :</label>
        <p class="viewValue">
          {{ data.customer && data.customer.length > 0 ? data.customer[0].perarea : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> state :</label>
        <p class="viewValue">
          {{ data.customer && data.customer.length > 0 ? data.customer[0].curstate : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Country :</label>
        <p class="viewValue">
          {{ data.customer && data.customer.length > 0 ? data.customer[0].country : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> DOB :</label>
        <p class="viewValue">
          {{
            data.customer && data.customer.length > 0 ? data.customer[0].birthdate : ""
          }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Mobile No :</label>
        <p class="viewValue">
          {{ data.customer && data.customer.length > 0 ? data.customer[0].mobile : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Email ID :</label>
        <p class="viewValue">
          {{ data.customer && data.customer.length > 0 ? data.customer[0].email : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Aadhar No :</label>
        <p class="viewValue">
          {{
            data.customer && data.customer.length > 0 ? data.customer[0].aadharcardno : ""
          }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Pan No :</label>
        <p class="viewValue">
          {{ data.customer && data.customer.length > 0 ? data.customer[0].panno : "" }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Nominee :</label>
        <p class="viewValue">
          {{
            data.customer && data.customer.length > 0 ? data.customer[0].nomineename : ""
          }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Nominee Relationship :</label>
        <p class="viewValue">
          {{
            data.customer && data.customer.length > 0
              ? data.customer[0].nomineerelation
              : ""
          }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Nominee Phone :</label>
        <p class="viewValue">
          {{
            data.customer && data.customer.length > 0 ? data.customer[0].nomineephone : ""
          }}
        </p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Nominee DOB :</label>
        <p class="viewValue">
          {{
            data.customer && data.customer.length > 0 ? data.customer[0].nomineedob : ""
          }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import axios from "@/components/axios";
import Attachment from "@/components/Attechment.vue";
export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: {
    closepopup: {
      default: () => {},
    },
    selectedID: {
      default: 0,
    },
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: {},
      loginData: JSON.parse(localStorage.getItem("userData")),
      selectedPaymentThrough: "",
      selectedTeam: "",
      label: "",
      selectedSalesBy: "",
      selectedSalesByLabel: "",
      userType: "",
      email: "",
      bookingdate: "",
      sir: "",
      noChequeField: false,
      chequeField: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getlandsalesById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data) ? json.data.data[0] : json.data.data;
          this.selectedPaymentThrough = this.data.paymentthrough
            ? this.data.paymentthrough
            : "";
          this.selectedSalesBy = this.data.selecttype ? this.data.selecttype : "";
          this.selectedSalesByLabel = this.getLabelForSelection(this.selectedSalesBy);
          this.bookingdate = this.data.bookingdate ? this.data.bookingdate : "";
          this.sir = this.data.sir ? this.data.sir : "No";
          this.handleSelectPayment(data.paymentthrough);
        })
        .catch((error) => console.log(error, "error"));
    },
    handleSelectPayment(e) {
      const name = e;
      if (name == "By Cheque") {
        this.chequeField = true;
      } else {
        this.chequeField = false;
      }
      if (name !== "By Cash" && name !== "By Cheque" && name !== "By UPI") {
        this.noChequeField = true;
      } else {
        this.noChequeField = false;
      }
    },
    getLabelForSelection(selection) {
      if (selection === "Franchise") {
        return "Franchise";
      } else if (selection === "ChannelPartner") {
        return "ChannelPartner";
      } else if (selection === "Employee") {
        return "Employee";
      } else {
        return "Sales By Selection";
      }
    },
  },
};
</script>
<style>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
