<template>
  <div>

  <b-row class="row justify-content-between">
    <b-col md="4" class="mt-2">
      <label class="viewLabel"> Invoice Number :</label>
      <p class="viewValue">{{data.invoiceno}}</p>
    </b-col>
    <b-col md="4" class="mt-2">
      <label class="viewLabel"> Invoice Date :</label>
      <p class="viewValue">{{ data.invoicedate }}</p>
    </b-col>
    <b-col md="4" class="mt-2">
      <label class="viewLabel"> Product Type :</label>
      <p class="viewValue">{{ data.producttype }}</p>
    </b-col>
    <b-col md="4" class="mt-2">
      <label class="viewLabel"> Party Name :</label>
      <p class="viewValue">{{ data.partyname?data.partyname:'' }}</p>
    </b-col>
    <b-col md="4" class="mt-2">
      <label class="viewLabel"> Currency :</label>
      <p class="viewValue">{{ data.currency?data.currency:'' }}</p>
    </b-col>
    <b-col md="4" class="mt-2">
      <label class="viewLabel"> Remarks :</label>
      <p class="viewValue">{{ data.remarks?data.remarks:'' }}</p>
    </b-col>
    <b-col md="12" class="mt-2">
      <h3>Product</h3>
    </b-col>
    <b-col md="12" class="mt-2">
      <div style="width: 100%; overflow: auto">
        <table class="table table-bordered" style="min-width: 25cm">
          <thead>
            <tr>
              <th style="font-size:11px !important">Sr. No.</th>
              <th style="font-size:11px !important">WORK/OTHER</th>
              <th style="font-size:11px !important">HSNCODE</th>
              <th style="font-size:11px !important">QTY</th>
              <th style="font-size:11px !important">UOM</th>
              <th style="font-size:11px !important">DISCOUNT</th>
              <th style="font-size:11px !important">RATE</th>
              <th style="font-size:11px !important">AMOUNT</th>
              <th style="font-size:11px !important">CGST %</th>
              <th style="font-size:11px !important">CGST AMOUNT</th>
              <th style="font-size:11px !important">SGST %</th>
              <th style="font-size:11px !important">SGST AMOUNT	</th>
              <th style="font-size:11px !important">IGST %</th>
              <th style="font-size:11px !important">IGST AMOUNT</th>
              <th style="font-size:11px !important">CESS %</th>
              <th style="font-size:11px !important">CESS</th>
              <th style="font-size:11px !important">NET AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data.OtherPurchasedetails" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                {{ item.LEDGERNAME}}
              </td>
              <td>
                {{ item.hsncode }}
              </td>
              <td>
                {{ item.qty }}
              </td>
              <td>
                {{ item.uom }}
              </td>
              <td>
                {{ item.disc }}
              </td>
              <td>
                {{ item.rate }}
              </td>
              <td>
                {{ item.amount }}
              </td>
              <td>
                {{ item.cgst }}
              </td>
              <td>
                {{ item.cgstamount }}
              </td>
              <td>
                {{ item.sgst }}
              </td>
              <td>
                {{ item.sgstamount }}
              </td>
              <td>
                {{ item.igst }}
              </td>
              <td>
                {{ item.igstamount }}
              </td>
              <td>
                {{ item.cessper }}
              </td>
              <td>
                {{ item.cess }}
              </td>
              <td>
                {{ item.netamount }}
              </td>
              </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>PF</td>
              <td>{{data.pf.desc}}</td>
              <td>{{data.pf.rate}}</td>
              <td>{{data.pf.amount}}</td>
              <td>{{data.pf.cgst}}</td>
              <td>{{data.pf.cgstamount}}</td>
              <td>{{data.pf.sgst}}</td>
              <td>{{data.pf.sgstamount}}</td>
              <td>{{data.pf.igst}}</td>
              <td>{{data.pf.igstamount}}</td>
              <td>{{data.pf.cessper}}</td>
              <td>{{data.pf.cess}}</td>
              <td>{{data.pf.netamount}}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Freight</td>
              <td>{{data.freight.amount}}</td>
              <td>{{data.freight.cgst}}</td>
              <td>{{data.freight.cgstamount}}</td>
              <td>{{data.freight.sgst}}</td>
              <td>{{data.freight.sgstamount}}</td>
              <td>{{data.freight.igst}}</td>
              <td>{{data.freight.igstamount}}</td>
              <td>{{data.freight.cessper}}</td>
              <td>{{data.freight.cess}}</td>
              <td>{{data.freight.netamount}}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>TDS Ledger</td>
              <td>{{data.tdsledger}}</td>
              <td>{{data.tdsnetamount}}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="fs-5"><Strong>Sub Total</Strong></td>
              <td></td>
              <td class="fs-5"><strong>Total CGST Amount</strong></td>
              <td></td>
              <td class="fs-5"><strong>Total SGST Amount</strong></td>
              <td></td>
              <td class="fs-5"><strong>Total IGST Amount</strong></td>
              <td></td>
              <td class="fs-5"><strong>Roundoff</strong></td>
              <td>{{data.roundoff}}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="fs-5"><strong>Total</strong></td>
              <td>{{data.subtotal}}</td>
              <td></td>
              <td>{{data.cgst}}</td>
              <td></td>
              <td>{{data.sgst}}</td>
              <td></td>
              <td>{{data.igst}}</td>
              <td></td>
              <td class="fs-5"><strong>Grand Total</strong></td>
              <td>{{data.grandtotal}}</td>
            </tr>

          </tbody>
        </table>
      </div>
    </b-col>
  </b-row>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import axios from "@/components/axios";
import Attachment from "@/components/Attechment.vue";
export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: {
    closepopup: {
      default: () => {},
    },
    selectedID: {
      default: 0,
    },
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: {},
      name:'',
      loginData: JSON.parse(localStorage.getItem("userData")),
      invoiceno:"",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getotherpurchaseById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data)
            ? json.data.data[0]
            : json.data.data;
            // this.invoiceno = this.data[0] && this.data.length>0 ? this.data[0].invoiceno :'';
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>
<style>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}
.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
