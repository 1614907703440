<template>
  <div>
    <b-card v-bind="cardAttrs" no-body v-on="$listeners" :class="customClass">
      <div class="card-header">
        <!-- Title & SubTitle -->
        <div>
          <b-card-title>{{ $attrs.title }}</b-card-title>
          <b-card-sub-title v-if="$attrs['sub-title']">
            {{ $attrs["sub-title"] }}
          </b-card-sub-title>
        </div>

        <!-- <i
        class="code-toggler feather icon-code cursor-pointer"
        :aria-expanded="!code_visible ? 'true' : 'false'"
        :aria-controls="parentID"
        @click="code_visible = !code_visible"
      /> -->
        <!-- <div class="rigth-button">
          
        </div> -->
      </div>

      <template v-if="$attrs['no-body'] !== undefined">
        <slot />

        <!-- Code Content -->
        <b-collapse
          :id="parentID"
          v-model="code_visible"
          class="card-code"
          :visible="code_visible"
        >
          <b-card-body>
            <prism :language="codeLanguage">
              <slot name="code" />
            </prism>
          </b-card-body>
        </b-collapse>
      </template>

      <b-card-body v-else>
        <!-- Card Content -->
        <slot />

        <!-- Code Content -->
        <b-collapse
          :id="parentID"
          v-model="code_visible"
          class="card-code"
          :visible="code_visible"
        >
          <div class="p-1" />
          <prism :language="codeLanguage">
            <slot name="code" />
          </prism>
        </b-collapse>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCollapse,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import Form from "@/components/form/Form.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCollapse,
    Prism,
    Form,
  },
  inheritAttrs: false,
  props: {
    codeLanguage: {
      default: "markup",
      type: String,
    },
    customClass: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      parentID: "",
      code_visible: false,
      visible: false,
      AssignToModal: false,
      renderComponent: false,
    };
  },
  computed: {
    cardAttrs() {
      const cardAttrs = JSON.parse(JSON.stringify(this.$attrs));
      delete cardAttrs.title;
      delete cardAttrs["sub-title"];
      return cardAttrs;
    },
  },
  created() {
    this.parentID = String(Math.floor(Math.random() * 10) + 1);
  },
  methods: {
    handleClickAssignTo() {
      this.AssignToModal = true;
    },
    handleClickAddbutton() {
      // this.$eventBus.$emit("onMounted", this.formOptions);
      let countFields = 0;
      this.$attrs.formOptions.inputFields &&
        this.$attrs.formOptions.inputFields.map((item) => {
          if (item.type != "hidden") {
            countFields = countFields + 1;
          }
        });
      countFields < 10
        ? (this.visible = true)
        : this.$router.push(`${this.$attrs.routerpath}`);
      this.forceRerender();
      this.$attrs.selectedID = 0;
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();
      // Add the component back in
      this.renderComponent = true;
    },
    handleCloseModel() {
      this.$attrs.visible = false;
      this.visible = false;
    },
  },
  mounted() {
    this.forceRerender();
    this.visible = this.$attrs.visible;
  },
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "~@core/scss/base/bootstrap-extended/variables";
@import "bootstrap/scss/variables";
@import "~@core/scss/base/components/variables-dark";

.card-code {
  pre[class*="language-"] {
    margin: 0;
    max-height: 350px;
    border-radius: 0.5rem;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: #2d2d2d;
    border-radius: 100%;

    .dark-layout & {
      background-color: $theme-dark-body-bg !important;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: rgba(241, 241, 241, 0.4);
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  // }

  ::-webkit-scrollbar-corner {
    display: none;
  }
}

.code-toggler {
  border-bottom: 1px solid transparent;

  &[aria-expanded="false"] {
    border-bottom-color: $primary;
  }
}

// HTML
.card {
  .card-header .heading-elements {
    position: static;
    background: red;
  }
}
</style>
