<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Income Graph</b-card-title>
      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range' }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body v-if="success">
      <chartjs-component-bar-chart
        :height="400"
        :data="data"
        :options="options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from 'bootstrap-vue'
import axios from '@/components/axios'

import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import chartjsData from './chartjsData'
import { $themeColors } from '@themeConfig'
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)'
}
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart
  },
  mounted () {
    this.fetchData()
  },
  data () {
    return {
      max: 400,
      year: new Date().getFullYear(),
      success: false,
      accessToken: localStorage.getItem('accessToken'),
      baseApi: process.env.VUE_APP_APIENDPOINT,

      chartjsData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom'
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color
              },
              scaleLabel: {
                display: false
              },
              ticks: {
                fontColor: chartColors.labelColor
              }
            }
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color
              },
              ticks: {
                stepSize: 100,
                min: 0,
                max: 1000,
                fontColor: chartColors.labelColor
              }
            }
          ]
        }
      },
      data: {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        datasets: [
          {
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: chartColors.primaryColorShade,
            borderColor: 'transparent'
          }
        ]
      }
    }
  },
  methods: {
    async fetchData () {
      await axios({
        method: 'GET',
        url: `${this.baseApi}/graph`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        }
      })
        .then((json) => {
          this.data.datasets.data = []
          this.data.labels.map((item, index) => {
            this.data.labels[index] = `${item}/${this.year}`
          })
          json.data.data.map((item) => {
            if (item.year == this.year) {
              this.data.datasets[0].data[item.month - 1] = parseInt(
                item.amount
              )
            }
          })
          if (this.data.datasets[0].data.length == 0) {
            this.data.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          const maxvalue = Math.max(...this.data.datasets[0].data)
          if (Math.ceil(maxvalue / 100) * 100 <= 500) {
            this.options.scales.yAxes[0].ticks.max = 500
          } else if (maxvalue > 500 && maxvalue <= 2000) {
            this.options.scales.yAxes[0].ticks.max =
                Math.ceil(maxvalue / 100) * 100
          } else if (maxvalue > 2000 && maxvalue <= 5000) {
            this.options.scales.yAxes[0].ticks.stepSize = 500
            this.options.scales.yAxes[0].ticks.max = 5000
          } else if (maxvalue > 5000 && maxvalue <= 10000) {
            this.options.scales.yAxes[0].ticks.stepSize = 1000
            this.options.scales.yAxes[0].ticks.max = 10000
          } else if (maxvalue > 10000 && maxvalue <= 25000) {
            this.options.scales.yAxes[0].ticks.stepSize = 2000
            this.options.scales.yAxes[0].ticks.max = 26000
          } else if (maxvalue > 25000 && maxvalue <= 100000) {
            this.options.scales.yAxes[0].ticks.stepSize = 10000
            this.options.scales.yAxes[0].ticks.max = 100000
          } else if (maxvalue > 100000 && maxvalue <= 1000000) {
            this.options.scales.yAxes[0].ticks.stepSize = 100000
            this.options.scales.yAxes[0].ticks.max = 1000000
          } else {
            this.options.scales.yAxes[0].ticks.stepSize = 1000000
            this.options.scales.yAxes[0].ticks.max = 10000000
          }
          this.success = true
          // this.options.scales.yAxes[0].ticks.max=Math.max(...this.data.datasets[0].data)
          // console.log(this.options.scales.yAxes[0].ticks.max)
        })
        .catch((error) => console.log(error, 'error'))
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
