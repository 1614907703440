<template>
  <div class=" mt-1 text-center cursor-pointer box" style="border-radius: 34px;box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;" >
    <!-- <b-avatar class="mb-1" :variant="color" size="35">
      <feather-icon size="17" :icon="icon" />
    </b-avatar> -->
      <div class="d-flex align-items-center justify-content-center flex-column h-100 w-100" >
        <h5 class="m-0">{{ statisticTitle }}</h5>
        <h4 :class="'m-0 font-weight-bolder text-'+color" >
          {{ statistic }}
        </h4>
      </div>
  </div>
</template>

<script>
import { BCard, BAvatar, BCardBody } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
    BCardBody
  },
  props: {
    icon: {
      type: String,
      // required: true,
      default: "",
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    route: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.box {
  min-width: 160px;
  height: 80px !important;
}
</style>
