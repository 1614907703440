<template>
  <div class="demo-inline-spacing">
    <b-media no-body>
      <b-media-aside class="mr-50">
        <b-avatar v-if="ifPerson" class="bg-primary" size="38">
          <span style="font-size: 25px">{{ personNo }}</span>
        </b-avatar>
        <b-avatar
          v-else
          :src="
            img
              ? img
              : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
          "
          size="38"
        />
      </b-media-aside>
      <b-media-body class="my-auto">
        <h6 class="mb-0">{{ name }} ({{ type }})</h6>
        <p class="mb-0">MO:- {{ number }}</p>
        <div class="d-flex">
        <b-badge v-if="number"
          @click="clickWhatsapp(number.includes('+91') ? number : '+91'.concat(number))"
          variant="success"
          class="badge-glow mr-2 cursor-pointer"
          style="padding: 6px"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style="width: 18px; height: 18px"
            fill="white"
            class="bi bi-whatsapp"
            viewBox="0 0 16 16"
          >
            <path
              d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
            />
          </svg>
        </b-badge>
            <!-- <div
              @click="callIVR(number.includes('+91') ? number : '+91'.concat(number))"
              v-if="number && (userRole == 'admin' || userRole == 'employee')">

              <b-badge
                variant="primary"
                class="badge-glow mr-2 cursor-pointer"
                style="padding: 6px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 18px; height: 18px"
                  fill="white"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </b-badge>
              </div>
            <a
            v-else-if="number"
              :href="`tel:${
                number.includes('+91') ? number : '+91'.concat(number)
              }`"
        >
          <b-badge
            variant="primary"
            class="badge-glow mr-2 cursor-pointer"
            style="padding: 6px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="width: 18px; height: 18px"
              fill="white"
              class="bi bi-whatsapp"
              viewBox="0 0 16 16"
            >
              <path
                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </b-badge>
        </a> -->
        <a
        v-if="number"
          :href="`sms:${
            number.includes('+91') ? number : '+91'.concat(number)
          }`"
        >
          <b-badge
            variant="info"
            class="badge-glow cursor-pointer"
            style="padding: 6px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="width: 18px; height: 18px"
              fill="white"
              class="bi bi-whatsapp"
              viewBox="0 0 16 16"
            >
              <path
                d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
              />
              <path
                d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"
              />
            </svg>
          </b-badge>
        </a>
      </div>
      </b-media-body>
    </b-media>
  </div>
</template>
<script>
import {
  BBadge,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import Mixins from '@/Mixins';
import axios from '@/components/axios';
// import PDFViewer from 'pdf-viewer-vue'

export default {
  props: ["number", "name", "type", "img", "ifPerson", "personNo"],
  components: {
    BBadge,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  data(){
 return{
  userRole : JSON.parse(localStorage.getItem('userData')).role,
  userData : JSON.parse(localStorage.getItem('userData'))
 }
  },
  mounted() {},
  methods: {

    callIVR(mobile){
      console.log()
        const options1 = {
          method: 'POST',
          url: 'https://api-smartflo.tatateleservices.com/v1/click_to_call',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${localStorage.IVRData  ? JSON.parse(localStorage.IVRData).access_token : ''}`,
            'content-type': 'application/json'
          },
          data: {
            agent_number: this.userData.mobile,
            destination_number: mobile
          }
        };

        axios
          .request(options1)
          .then(function (response) {
            console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            if(error.toString().includes('401') || error.toString.includes('400')){
              Mixins.methods.IVRLogin()
            }
          });
    },
    clickWhatsapp(num) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        let url = "https://wa.me/";
        const all = url + num;
        window.open(all, "_blank");
      } else {
        let url = "https://web.whatsapp.com/send";
        const all = url + "?phone=" + num;
        window.open(all, "_blank");
      }
    },
  },
};
</script>
