var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.$store.getters['app/widgetAccess']('Lead Details') ||
      _vm.$store.state.app.user_data.role == 'admin'
    )?_c('h1',{staticClass:"p-2"},[_vm._v(" Lead Details ")]):_vm._e(),(
      _vm.$store.getters['app/widgetAccess']('Lead Details') ||
      _vm.$store.state.app.user_data.role == 'admin'
    )?_c('b-row',{staticClass:"d-flex justify-content-center"},[_vm._l((_vm.leaddetails),function(item,index){return _c('b-col',{key:index + 1,staticClass:"cursor-pointer",attrs:{"lg":_vm.userData.role == 'admin' ? 3 : 2,"sm":"4"},on:{"click":function($event){return _vm.handleRoute(item.route)}}},[_c('smallcard',{attrs:{"icon":item.icon,"statistic":item.title,"statistic-title":item.subtitle,"color":item.color}})],1)}),_vm._l((_vm.leadcategory),function(item,index){return _c('b-col',{key:(index + 1) * 100,staticClass:"cursor-pointer",attrs:{"lg":_vm.userData.role == 'admin' ? 3 : 2,"sm":"4"},on:{"click":function($event){return _vm.handleRoute(item.route)}}},[_c('smallcard',{attrs:{"icon":item.icon,"statistic":item.title,"statistic-title":item.subtitle,"color":item.color}})],1)})],2):_vm._e(),_c('b-row',[(
        _vm.$store.getters['app/widgetAccess']('Lead Stage Details') ||
        _vm.$store.state.app.user_data.role == 'admin'
      )?_c('b-col',{attrs:{"md":"6"}},[_c('b-card',[_c('b-card-header',[_c('b-card-title',[_vm._v("Lead Stage Details")])],1),_c('b-card-body',{staticClass:"statistics-body",staticStyle:{"height":"470px","overflow":"auto"}},[_c('b-row',_vm._l((_vm.leadstage),function(item,index){return _c('b-col',{key:index,staticClass:"cursor-pointer",attrs:{"lg":_vm.userData.role == 'admin' ? 4 : 3,"cols":"6"},on:{"click":function($event){return _vm.handleRoute(item.route)}}},[_c('leadstagesmall',{attrs:{"icon":item.icon,"statistic":item.title,"statistic-title":item.subtitle,"color":item.color}})],1)}),1)],1)],1)],1):_vm._e(),(
        _vm.$store.getters['app/widgetAccess']('Lead Source Details') ||
        _vm.$store.state.app.user_data.type == 'admin'
      )?_c('b-col',{attrs:{"md":"6"}},[_c('b-card',[_c('b-card-header',[_c('b-card-title',[_vm._v("Lead Source Details")])],1),_c('b-card-body',{staticClass:"statistics-body",staticStyle:{"height":"470px","overflow":"auto"}},[_c('b-row',_vm._l((_vm.leadsource),function(item,index){return _c('b-col',{key:index,staticClass:"cursor-pointer",attrs:{"lg":_vm.userData.role == 'admin' ? 4 : 3,"cols":"6"},on:{"click":function($event){return _vm.handleRoute(item.route)}}},[_c('leadstagesmall',{attrs:{"icon":item.icon,"statistic":item.title,"statistic-title":item.subtitle,"color":item.color}})],1)}),1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }