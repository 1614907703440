<template>
  <b-media
    v-if="data && JSON.stringify(data) !== '[]'"
    class="d-flex flex-column"
    no-body
  >
    <b-media-aside>
      <b-link>
        <b-button
          variant="primary"
          v-if="JSON.stringify(data).includes('pdf')"
          href="#0"
          @click="pdf(data.includes(imagePath) ? data :  imagePath + data)"
          size="sm"
        >
          Show PDF
        </b-button>
        <b-img
          v-else-if='data'
          rounded
          @click="pdf(data.includes(imagePath) ? data :  imagePath + data)"
          :src="data.includes(imagePath) ? data :  imagePath + data"
          style="width: 200px; height: 150px"
        />
      </b-link>
    </b-media-aside>
  </b-media>
</template>
<script>
import { BMedia, BMediaAside, BLink, BImg, BButton } from "bootstrap-vue";
// import PDFViewer from 'pdf-viewer-vue'

export default {
  props: ["data"],
  data(){
    return{
      imagePath: process.env.VUE_APP_IMAGE_PATH
    }
  },
  components: {
    BButton,
    // PDFViewer,
    BMedia,
    BMediaAside,
    BLink,
    BImg,
  },
  methods: {
    pdf(data) {
      window.open(`${data}`);
    },
  },
};
</script>
