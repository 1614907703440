// const params = new URLSearchParams(window.location.search).has("id");
let userRole
if (localStorage.getItem('accessToken') !== null) {
  userRole = JSON.parse(localStorage.getItem('userData')).role
} else {
  userRole = false
}


export default [
  {
    path: '/legal/saleslegal',
    name: 'legal-saleslegal',
    component: () => import('@/views/crm/saleslegal/Legal.vue'),
    meta: {
      pageTitle: 'Legal',
      breadcrumb: [
        userRole == 'admin'?{
          text: 'Legal',
          active: true

        }:{
          text: 'Legal',
          to: '/legal'
        },
        {
          text: 'Sales',
          active: true
        }
      ]
    }
  },
  {
    path: '/legal/purchaselegal',
    name: 'legal-purchaselegal',
    component: () => import('@/views/crm/purchaselegal/Legal.vue'),
    meta: {
      pageTitle: 'Legal',
      breadcrumb: [
        userRole == 'admin'?{
          text: 'Legal',
          active: true

        }:{
          text: 'Legal',
          to: '/legal'
        },
      {
        text: 'Prchase',
        active: true
      }
    ]
    }
  },
  {
    path: '/commission/payout-generation',
    name: 'commission-payout-generation',
    component: () => import('@/views/commission/PayoutGeneration.vue'),
    meta: {
      pageTitle: 'Payout Generation',
      breadcrumb: [
        userRole == 'admin'?{
          text: 'Commission',
          active: true

        }:{
          text: 'Commission',
          to: '/'
        },
      {
        text: 'Payout Generation',
        active: true
      }
    ]
    }
  },
  {
    path: '/commission/blocked-payment',
    name: 'commission-blocked-payment',
    component: () => import('@/views/commission/BlockedPayment.vue'),
    meta: {
      pageTitle: 'Blocked Payment',
      breadcrumb: [
        userRole == 'admin'?{
          text: 'Commission',
          active: true

        }:{
          text: 'Commission',
          to: '/'
        },
      {
        text: 'Blocked Payment',
        active: true
      }
    ]
    }
  },
  {
    path: '/commission/release-payment',
    name: 'commission-release-payment',
    component: () => import('@/views/commission/ReleasedPayment.vue'),
    meta: {
      pageTitle: 'Released Payment',
      breadcrumb: [
        userRole == 'admin'?{
          text: 'Commission',
          active: true

        }:{
          text: 'Commission',
          to: '/'
        },
      {
        text: 'Released Payment',
        active: true
      }
    ]
    }
  },
  {
    path: '/commission/paid-commission-list',
    name: 'commission-paid-commission-list',
    component: () => import('@/views/commission/ConfirmPayment.vue'),
    meta: {
      pageTitle: 'Paid Commission',
      breadcrumb: [
        userRole == 'admin'?{
          text: 'Commission',
          active: true

        }:{
          text: 'Commission',
          to: '/'
        },
      {
        text: 'Paid Commission',
        active: true
      }
    ]
    }
  },
  // {
  //   path: '/crm/legal/kanban',
  //   name: 'crm-legal',
  //   component: () => import('@/views/crm/legal/Legal.vue'),
  //   meta: {
  //     pageTitle: 'Legal',
  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: 'CRM',
  //         to: '/crm'
  //       } : {
  //         text: 'CRM',
  //         active: true
  //       },
  //       {
  //         text: 'Legal',
  //         to:'/crm/legal'
  //       },
  //       {
  //         text: 'Kanban',
  //         active: true
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/crm/legal/:id',
  //   name: 'crm-legal',
  //   component: () => import('@/views/crm/legal/legalForm.vue'),
  //   meta: {
  //     pageTitle: 'Legal',
  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: 'CRM',
  //         to: '/crm'
  //       } : {
  //         text: 'CRM',
  //         active: true
  //       },
  //       {
  //         text: 'Legal',
  //         to: '/crm/legal'
  //       },
  //       {
  //         text: 'Edit Legal',
  //         active: true
  //       }
  //     ]
  //   }
  // },
  {
    path: '/crm/lead',
    name: 'crm-lead',
    component: () => import('@/views/crm/lead/Lead.vue'),
    meta: {
      pageTitle: 'Lead',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Lead',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/addlead',
    alias: '/crm/editlead',
    name: 'add-lead',
    component: () => import('@/views/crm/lead/AddLead.vue'),
    meta: {
      pageTitle: 'Add Lead',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Lead',
          to: '/crm/lead'
        },
        {
          text: 'Add Lead',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/editlead/:id',
    name: 'edit-lead',
    component: () => import('@/views/crm/lead/AddLead.vue'),
    meta: {
      pageTitle: 'Edit Lead',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Lead',
          to: '/crm/lead'
        },
        {
          text: 'Edit Lead',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/customer',
    name: 'crm-customer',
    component: () => import('@/views/crm/customer/Customer.vue'),
    meta: {
      pageTitle: 'Customer',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'customer',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/customer/addcustomer',
    name: 'add-customer',
    component: () => import('@/views/crm/customer/AddCustomer.vue'),
    meta: {
      pageTitle: 'Add Customer',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Customer',
          to: '/crm/customer'
        },
        {
          text: 'Add Customer',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/customer/editcustomer/:id',
    name: 'edit-customer',
    component: () => import('@/views/crm/customer/AddCustomer.vue'),
    meta: {
      pageTitle: 'Edit Customer',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Customer',
          to: '/crm/customer'
        },
        {
          text: 'Edit Customer',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/activities',
    name: 'crm-activities',
    component: () => import('@/views/crm/activities/Activities.vue'),
    meta: {
      pageTitle: 'Activities',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'activities',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/addactivities',
    alias: '/crm/editactivities',
    name: 'add-activities',
    component: () => import('@/views/crm/activities/AddActivities.vue'),
    meta: {
      pageTitle: 'Add Activities',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Activities',
          to: '/crm/activities'
        },
        {
          text: 'Add Activities',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/editactivities/:id',
    name: 'edit-activities',
    component: () => import('@/views/crm/activities/AddActivities.vue'),
    meta: {
      pageTitle: 'Edit Activities',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Activities',
          to: '/crm/activities'
        },
        {
          text: 'Edit Activities',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/rawcontact',
    name: 'crm-rawcontact',
    component: () => import('@/views/crm/rawcontact/RawContact.vue'),
    meta: {
      pageTitle: 'Raw Contact',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Raw Contact',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/rawcontact/addrawcontact',
    name: 'add-rawcontact',
    component: () => import('@/views/crm/rawcontact/AddRawContact.vue'),
    meta: {
      pageTitle: 'Add Raw Contact',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Raw Contact',
          to: '/crm/rawcontact'
        },
        {
          text: 'Add Raw Contact',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/rawcontact/editrawcontact/:id',
    name: 'edit-rawcontact',
    component: () => import('@/views/crm/rawcontact/AddRawContact.vue'),
    meta: {
      pageTitle: 'Edit Raw Contact',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Raw Contact',
          to: '/crm/rawcontact'
        },
        {
          text: 'Edit Raw Contact',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/complaint',
    name: 'crm-complaint',
    component: () => import('@/views/crm/complaint/Complaint.vue'),
    meta: {
      pageTitle: 'Comlapint',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Complaint',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/complaint/addcomplaint',
    alias: '/crm/complaint/editcomplaint',
    name: 'add-complaint',
    component: () => import('@/views/crm/complaint/AddComplaint.vue'),
    meta: {
      pageTitle: 'Add Complaint',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Complaint',
          to: '/crm/complaint'
        },
        {
          text: 'Add Complaint',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/complaint/editcomplaint/:id',
    name: 'edit-complaint',
    component: () => import('@/views/crm/complaint/AddComplaint.vue'),
    meta: {
      pageTitle: 'Edit Complaint',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Complaint',
          to: '/crm/complaint'
        },
        {
          text: 'Edit Complaint',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/target',
    name: 'crm-target',
    component: () => import('@/views/crm/target/Target.vue'),
    meta: {
      pageTitle: 'Target',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Traget',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/addtarget',
    alias: '/crm/edittarget',
    name: 'add-Traget',
    component: () => import('@/views/crm/target/AddTarget.vue'),
    meta: {
      pageTitle: 'Add Target',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Traget',
          to: '/crm/target'
        },
        {
          text: 'Add Target',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/edittarget/:id',
    name: 'edit-Traget',
    component: () => import('@/views/crm/target/AddTarget.vue'),
    meta: {
      pageTitle: 'Edit Target',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Traget',
          to: '/crm/target'
        },
        {
          text: 'Edit Target',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/addmultipletarget',
    alias: '/crm/editmultipletarget',
    name: 'add-multi-Traget',
    component: () => import('@/views/crm/target/AddMultipleTarget.vue'),
    meta: {
      pageTitle: 'Add Multiple Target',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Traget',
          to: '/crm/target'
        },
        {
          text: 'Add  Multiple Target',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/editmultipletarget/:id',
    name: 'edit-multi-Traget',
    component: () => import('@/views/crm/target/AddMultipleTarget.vue'),
    meta: {
      pageTitle: 'Edit Multiple Target',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Traget',
          to: '/crm/target'
        },
        {
          text: 'Edit Multiple Target',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/campaign',
    name: 'crm-campaign',
    component: () => import('@/views/crm/campaign/Campaign.vue'),
    meta: {
      pageTitle: 'Campaign',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Campaign',
          active: true
        }
      ]
    }
  },
  // {
  //   path: '/crm/campaign/addcampaign',
  //   alias: '/crm/campaign/editcampaign',
  //   name: 'add-campaign',
  //   component: () => import('@/views/crm/campaign/AddCampaign.vue'),
  //   meta: {
  //     pageTitle: 'Add Campaign',


  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: 'CRM',
  //         to: '/crm'
  //       } : {
  //         text: 'CRM',
  //         active: true
  //       },
  //       {
  //         text: 'Campaign',
  //         to: '/crm/campaign'
  //       },
  //       {
  //         text: 'Add Campaign',
  //         active: true
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/crm/campaign/editcampaign/:id',
  //   name: 'edit-campaign',
  //   component: () => import('@/views/crm/campaign/AddCampaign.vue'),
  //   meta: {
  //     pageTitle: 'Edit Campaign',


  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: 'CRM',
  //         to: '/crm'
  //       } : {
  //         text: 'CRM',
  //         active: true
  //       },
  //       {
  //         text: 'Campaign',
  //         to: '/crm/campaign'
  //       },
  //       {
  //         text: 'Edit Campaign',
  //         active: true
  //       }
  //     ]
  //   }
  // },
  {
    path: '/crm/task',
    name: 'crm-task',
    component: () => import('@/views/crm/task/Task.vue'),
    meta: {
      pageTitle: 'Task',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Task',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/task/addtask',
    alias: '/crm/task/edittask',
    name: 'add-task',
    component: () => import('@/views/crm/task/AddTask.vue'),
    meta: {
      pageTitle: 'Add Task',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Task',
          to: '/crm/task'
        },
        {
          text: 'Add Task',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/task/edittask/:id',
    name: 'edit-task',
    component: () => import('@/views/crm/task/AddTask.vue'),
    meta: {
      pageTitle: 'Edit Task',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Task',
          to: '/crm/task'
        },
        {
          text: 'Edit Task',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/expense',
    name: 'crm-expense',
    component: () => import('@/views/crm/expense/Expense.vue'),
    meta: {
      pageTitle: 'Expense',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Expense',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/expense/addexpense',
    alias: '/crm/expense/editexpense',
    name: 'add-expense',
    component: () => import('@/views/crm/expense/AddExpense.vue'),
    meta: {
      pageTitle: 'Add Expense',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Expense',
          to: '/crm/expense'
        },
        {
          text: 'Add Expense',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/expense/editexpense/:id',
    name: 'edit-expense',
    component: () => import('@/views/crm/expense/AddExpense.vue'),
    meta: {
      pageTitle: 'Edit Expense',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Expense',
          to: '/crm/expense'
        },
        {
          text: 'Edit Expense',
          to: `${userRole == 'channelpartner' ? '/crm' : true}`
        }
      ]
    }
  },
  // // {
  // //   path: '/crm/addcampaign',
  // //   alias: '/crm/editcampaign',
  // //   name: 'add-campaign',
  // //   component: () => import('@/views/crm/campaign/AddCampaign.vue'),
  // //   meta: {
  // //     breadcrumb: [

  // //       {
  // //         text: 'CRM',
  // //         active: true
  // //       },
  // //       {
  // //         text: 'Campaign',
  // //         to: '/crm/campaign'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Campaign' : 'Add Campaign'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/crm/sitevisit',
    name: 'crm-sitevisit',
    component: () => import('@/views/crm/sitevisit/SiteVisit.vue'),
    meta: {
      pageTitle: 'Site Visit',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Site Visit',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/addsitevisit',
    name: 'add-sitevisit',
    component: () => import('@/views/crm/sitevisit/AddSiteVisit.vue'),
    meta: {
      pageTitle: 'Add Site Visit',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Site Visit',
          to: '/crm/sitevisit'
        },
        {
          text: 'Add Site Visit',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/editsitevisit/:id',
    name: 'edit-sitevisit',
    component: () => import('@/views/crm/sitevisit/AddSiteVisit.vue'),
    meta: {
      pageTitle: 'Edit Site Visit',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Site Visit',
          to: '/crm/sitevisit'
        },
        {
          text: 'Edit Site Visit',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/sitevisit",
  //   name: "add-sitevisit",
  //   component: () => import("@/views/crm/sitevisit/AddSiteVisit.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "CRM",
  //         active: true,
  //       },
  //       {
  //         text: "Site Visit",
  //         to: "/crm/sitevisit",
  //       },
  //       {
  //         text: `${params ? "Edit Site Visit" : "Add Site Visit"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // // {
  // //   path: "/crm/updates",
  // //   name: "crm-updates",
  // //   component: () => import("@/views/crm/updates/Updates.vue"),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: "CRM",
  // //         active: true,
  // //       },
  // //       {
  // //         text: "Updates",
  // //         active: true,
  // //       },
  // //     ],
  // //   },
  // // },
  // // {
  // //   path: "/updates",
  // //   name: "add-updates",
  // //   component: () => import("@/views/crm/updates/AddUpdates.vue"),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: "CRM",
  // //         active: true,
  // //       },
  // //       {
  // //         text: "Updates",
  // //         to: "/crm/updates",
  // //       },
  // //       {
  // //         text: `${params ? "Edit Updates" : "Add Updates"}`,
  // //         active: true,
  // //       },
  // //     ],
  // //   },
  // // },
  // // {
  // //   path: "/crm/applicationform",
  // //   name: "crm-applicationform",
  // //   component: () => import("@/views/crm/applicationform/ApplicationForm.vue"),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: "CRM",
  // //         active: true,
  // //       },
  // //       {
  // //         text: "Application Form",
  // //         active: true,
  // //       },
  // //     ],
  // //   },
  // // },
  // // {
  // //   path: "/applicationform",
  // //   name: "add-applicationform",
  // //   component: () =>
  // //     import("@/views/crm/applicationform/AddApplicationForm.vue"),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: "CRM",
  // //         active: true,
  // //       },
  // //       {
  // //         text: "Application Form",
  // //         to: "/crm/applicationform",
  // //       },
  // //       {
  // //         text: `${params ? "Edit Application Form" : "Add Application Form"}`,
  // //         active: true,
  // //       },
  // //     ],
  // //   },
  // // },
  // // {
  // //   path: "/crm/newbookplot",
  // //   name: "crm-newbookplot",
  // //   component: () => import("@/views/crm/newbookplot/NewbookPlot.vue"),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: "CRM",
  // //         active: true,
  // //       },
  // //       {
  // //         text: "New book Plot",
  // //         active: true,
  // //       },
  // //     ],
  // //   },
  // // },
  // // {
  // //   path: "/newbookplot",
  // //   name: "add-newbookplot",
  // //   component: () => import("@/views/crm/newbookplot/AddNewbookPlot.vue"),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: "CRM",
  // //         active: true,
  // //       },
  // //       {
  // //         text: "New book Plot",
  // //         to: "/crm/newbookplot",
  // //       },
  // //       {
  // //         text: `${params ? "Edit New book Plot" : "Add New book Plot"}`,
  // //         active: true,
  // //       },
  // //     ],
  // //   },
  // // },
  // // {
  // //   path: "/crm/agriculture",
  // //   name: "crm-agriculture",
  // //   component: () => import("@/views/crm/agriculture/Agriculture.vue"),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: "CRM",
  // //         active: true,
  // //       },
  // //       {
  // //         text: "Agriculture",
  // //         active: true,
  // //       },
  // //     ],
  // //   },
  // // },
  // // {
  // //   path: "/agriculture",
  // //   name: "add-agriculture",
  // //   component: () => import("@/views/crm/agriculture/AddAgriculture.vue"),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: "CRM",
  // //         active: true,
  // //       },
  // //       {
  // //         text: "Agriculture",
  // //         to: "/crm/agriculture",
  // //       },
  // //       {
  // //         text: `${params ? "Edit Agriculture" : "Add Agriculture"}`,
  // //         active: true,
  // //       },
  // //     ],
  // //   },
  // // },
  {
    path: '/crm/sales',
    name: 'crm-sales',
    component: () => import('@/views/crm/sales/Sales.vue'),
    meta: {
      pageTitle: 'Sales',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Sales',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/sales/addsales',
    name: 'add-sales',
    component: () => import('@/views/crm/sales/AddSales.vue'),
    meta: {
      pageTitle: 'Add Sales',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Sales',
          to: '/crm/sales'
        },
        {
          text: 'Add Sales',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/sales/editsales/:id',
    name: 'edit-sales',
    component: () => import('@/views/crm/sales/AddSales.vue'),
    meta: {
      pageTitle: 'Edit Sales',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Sales',
          to: '/crm/sales'
        },
        {
          text: 'Edit Sales',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/multisales',
    name: 'crm-multisales',
    component: () => import('@/views/crm/multisales/Sales.vue'),
    meta: {
      pageTitle: 'Multi Sales',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Multi Sales',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/multisales/addsales',
    name: 'add-multisales',
    component: () => import('@/views/crm/multisales/AddSales.vue'),
    meta: {
      pageTitle: 'Add Multi Sales',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Sales',
          to: '/crm/sales'
        },
        {
          text: 'Add Multi Sales',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/bulklandsales',
    name: 'crm-bulklandsales',
    component: () => import('@/views/crm/landsales/LandSales.vue'),
    meta: {
      pageTitle: 'Bulk Land Sales',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Bulk Land Sales',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/bulklandsales/addbulklandsales',
    name: 'add-bulklandsales',
    component: () => import('@/views/crm/landsales/AddLandSales.vue'),
    meta: {
      pageTitle: 'Add Bulk Land Sales',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Bulk Land Sales',
          to: '/crm/bulklandsales'
        },
        {
          text: 'Add Bulk Land Sales',
          active: true
        }
      ]
    }
  },
  {
    path: '/crm/bulklandsales/editbulklandsales/:id',
    name: 'edit-bulklandsales',
    component: () => import('@/views/crm/landsales/AddLandSales.vue'),
    meta: {
      pageTitle: 'Edit Bulk Land Sales',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'CRM',
          to: '/crm'
        } : {
          text: 'CRM',
          active: true
        },
        {
          text: 'Bulk Land Sales',
          to: '/crm/bulklandsales'
        },
        {
          text: 'Edit Bulk land Sales',
          active: true
        }
      ]
    }
  }
]
