<template>
  <b-card-code name="main">
    <validation-observer ref="simpleRules">
      <div v-if="renderComponent">
        <!-- <b-modal
          id="modal-prevent-closing"
          ref="my-modal"
          title="Submit Your Name"
          ok-title="Submit"
          cancel-variant="outline-secondary" && ifEdit == true && item.type == 'password' ? 'd-none' : item.class
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
        > -->
        <b-form>
          <b-row>
            <!--  This field is required-->
            <div
              v-for="item in inputFields"
              :class="
                ifEdit == false && item.type == 'username'
                  ? 'd-none'
                  : item.class &&
                    showPassword == false &&
                    item.type == 'password'
                  ? 'd-none'
                  : item.class
              "
              :key="item.name"
            >
              <div v-if="item.type == 'title'">
                <b-card-code :title="item.title" no-body name="first">
                  <b-col></b-col>
                </b-card-code>
              </div>
              <div v-if="item.type == 'hidden'">
                <input v-model="form[item.name]" :type="item.type" />
              </div>
              <div v-if="item.type == 'label'">
                <b-col>
                  <label>{{ item.label }} : {{ item.value }}</label>
                </b-col>
              </div>
              <div v-if="item.type == 'labeldate'">
                <label>{{ item.label }} </label>
                <b-form-input v-model="current_date.date" :disabled="true">
                  {{ current_date.date }}
                </b-form-input>
              </div>
              <div v-if="item.type == 'text'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      :rules="item.rules?item.rules:`required`"
                      :name="item.label"
                    >
                      <b-form-input
                        v-model="form[item.name]"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="item.placeholder"
                        :disabled="item.disable"
                        :maxlength="`${item.maxlength?item.maxlength:''}`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-input
                      v-model="form[item.name]"
                      :placeholder="item.placeholder"
                      :disabled="item.disable"
                    />
                  </div>
                </b-form-group>
                <!-- </b-col> -->
              </div>
              <div v-if="item.type == 'username' && ifEdit">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="item.label"
                    >
                      <b-form-input
                        v-model="form[item.name]"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="item.placeholder"
                        :disabled="item.disable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-input
                      v-model="form[item.name]"
                      :placeholder="item.placeholder"
                      :disabled="item.disable"
                    />
                  </div>
                </b-form-group>
                <!-- </b-col> -->
              </div>
              <div v-if="item.type == 'Uname'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="item.label"
                    >
                      <b-form-input
                        v-model="form[item.name]"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="item.placeholder"
                        @input="UnameValidation"
                        :disabled="item.disable"
                      />
                      <small class="text-danger" v-if="UnameValid == false"
                        >Plesase Enter Only Text Value</small
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-input
                      v-model="form[item.name]"
                      :placeholder="item.placeholder"
                      :disabled="item.disable"
                    />
                  </div>
                </b-form-group>
                <!-- </b-col> -->
              </div>
              <div v-if="item.type == 'plaintext'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="item.label"
                    >
                      <b-form-input
                        v-model="form[item.name]"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="item.placeholder"
                        :disabled="item.disable"
                        plaintext
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-input
                      v-model="form[item.name]"
                      :placeholder="item.placeholder"
                      :disabled="item.disable"
                      plaintext
                    />
                  </div>
                </b-form-group>
                <!-- </b-col> -->
              </div>

              <div v-if="item.type == 'textarea'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="item.label"
                    >
                      <b-form-textarea
                        :id="item.name"
                        v-model="form[item.name]"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="item.placeholder"
                        :rows="item.row"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-textarea
                      :id="item.name"
                      v-model="form[item.name]"
                      :placeholder="item.placeholder"
                      :rows="item.row"
                    />
                  </div>
                </b-form-group>
                <!-- </b-col> -->
              </div>

              <!-- <div v-if="item.type == 'password'">
                <b-col> </b-col>
              </div> -->

              <div v-if="item.type == 'number'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      :rules="item.rules?item.rules:''"
                      :name="item.label"
                    >
                      <b-form-input
                        v-model="form[item.name]"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="item.placeholder"
                        @input="handleChange($event, item.name, item.onKeyUp)"
                        :disabled="item.disable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-input
                      v-model="form[item.name]"
                      type="number"
                      :placeholder="item.placeholder"
                      @input="handleChange($event, item.name, item.onKeyUp)"
                      :disabled="item.disable"
                    />
                  </div>
                </b-form-group>
                <!-- </b-col> -->
              </div>

              <div v-if="item.type == 'mobilenumber'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      rules="required|integer"
                      :name="item.label"
                    >
                      <b-form-input
                        v-model="form[item.name]"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="item.placeholder"
                        @input="handleChange($event, item.name, item.onKeyUp)"
                        :disabled="item.disable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-input
                      v-model="form[item.name]"
                      :placeholder="item.placeholder"
                      @input="handleChange($event, item.name, item.onKeyUp)"
                      :disabled="item.disable"
                    />
                  </div>
                </b-form-group>
                <!-- </b-col> -->
              </div>

              <div v-if="item.type == 'email'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="form[item.name]"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Your Email Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- </b-col> -->
              </div>

              <div v-if="item.type == 'password' && showPassword">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|password"
                    :name="item.label"
                    vid="password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        v-model="form[item.name]"
                        placeholder="Enter Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- </b-col> -->
              </div>

              <div v-if="item.type == 'dropdown'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="item.label"
                    >
                      <v-select
                        label="name"
                        v-model="form[item.name]"
                        :class="{
                          'is-invalid':
                            submitted &&
                            $v.form[item.name] &&
                            $v.form[item.name].$error,
                        }"
                        :options="item.options"
                        placeholder="None"
                        @input="
                          handleChangeSelect(
                            $event,
                            item.name,
                            item.parentComponent,
                            item.followupreporttype,
                            item.onKeyUp
                          )
                        "
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <v-select
                      label="name"
                      v-model="form[item.name]"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.form[item.name] &&
                          $v.form[item.name].$error,
                      }"
                      :options="item.options"
                      placeholder="None"
                      @input="
                        handleChangeSelect(
                          $event,
                          item.name,
                          item.parentComponent,
                          item.followupreporttype,
                          item.onKeyUp
                        )
                      "
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                  </div>
                </b-form-group>
                <!-- </b-col> -->
              </div>

              <div v-if="item.type == 'multiselect'">
                <!-- <b-col> -->
                <b-form-group :state="state" :label-for="item.name">
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="item.label"
                    >
                      <b-form-tags
                        v-model="form[item.name]"
                        :input-id="item.name"
                        :input-attrs="{
                          'aria-describedby': 'tags-validation-help',
                        }"
                        :state="state"
                        :tag-validator="
                          item.inputTagType == 'email'
                            ? EmailValidator
                            : NumberValidator
                        "
                        :placeholder="item.placeholder"
                        remove-on-delete
                        separator=" "
                        :invalid-tag-text="item.invalidText"
                      >
                        <!-- The following slots are for b-form-group -->
                        <template #invalid-feedback>
                          You must provide valid Email
                        </template>
                        <template #description>
                          <div id="tags-validation-help">
                            Please Enter Valid Email
                          </div>
                        </template>
                      </b-form-tags>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <v-select
                      label="name"
                      v-model="form[item.name]"
                      multiple
                      taggable
                      push-tags
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.form[item.name] &&
                          $v.form[item.name].$error,
                      }"
                      :options="item.options"
                      placeholder="None"
                      @input="handleChangeSelect"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                  </div>
                </b-form-group>
                <!-- </b-col> -->
              </div>

              <div v-if="item.type == 'radio'">
                <!-- <b-col> -->
                <b-form-group>
                  <b-form-radio-group
                    v-model="form[item.name]"
                    :options="item.options"
                    class="mb-1"
                    value-field="value"
                    text-field="text"
                    :disabled-field="item.disabled"
                  />
                </b-form-group>
                <!-- </b-col> -->
              </div>

              <div v-if="item.type == 'switch'">
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <b-form-checkbox
                    v-model="form[item.name]"
                    @click="clickSwitch"
                    :checked="item.checked"
                    class="custom-control-primary"
                    :name="item.label"
                    switch
                  />
                </b-form-group>
              </div>
              <div v-if="item.type == 'Number'">
                <b-form-group :label="item.label" :label-for="item.name">
                  <b-form-input
                    @input="handleChange($event, item.name, item.onKeyUp)"
                    v-model="nextfollowupafterdays"
                    :name="item.name + '[' + id + ']'"
                    type="number"
                    :placeholder="item.placeholder"
                    :disabled="item.disable"
                  />
                </b-form-group>
              </div>
              <div v-if="item.type == 'date'">
                <!-- <b-col> -->
                <b-form-group v-if="item.rules == 'required'">
                  <!-- <b-form-group> -->
                  <label>{{ item.label }}</label>
                  <validation-provider
                    #default="{ errors }"
                    :rules="item.rules"
                    :name="item.label"
                  >
                    <!-- <b-form-datepicker :id="item.name"  menu-class="w-100"
                      calendar-width="100%" :date-format-options="{
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      }"  /> -->

                    <flat-pickr
                      class="form-control"
                      value=""
                      v-model="form[item.name]"
                      placeholder="Select Date"
                      :config="{
                        dateFormat: 'd/m/Y',
                      }"
                      style="background-color: transparent"
                      @input="handlenextfollowupdatechange($event, item.name)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-else>
                  <!-- <b-form-group> -->
                  <label>{{ item.label }}</label>
                  <validation-provider #default="{ errors }" :name="item.label">
                    <!-- <b-form-datepicker :id="item.name" v-model="form[item.name]" menu-class="w-100"
                      calendar-width="100%" :date-format-options="{
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      }" @input="handlenextfollowupdatechange($event, item.name)" /> -->

                    <flat-pickr
                      class="form-control"
                      value=""
                      v-model="form[item.name]"
                      placeholder="Select Date"
                      :config="{
                        dateFormat: 'd/m/Y',
                      }"
                      style="background-color: transparent"
                      @input="handlenextfollowupdatechange($event, item.name)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- </b-col> -->
              </div>
              <div v-if="item.type == 'inputmaskdate'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="item.label"
                  >
                    <cleave
                      :id="item.name"
                      :v-model="form[item.name]"
                      class="form-control"
                      :raw="false"
                      :options="{
                        date: true,
                        delimiter: '/',
                        datePattern: ['d', 'm', 'Y'],
                      }"
                      placeholder="DD/MM/YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- </b-col> -->
              </div>
              <div v-if="item.type == 'datetime'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="item.label"
                    >
                      <flat-pickr
                        v-model="form[item.name]"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          dateFormat: 'd/m/Y',
                          defaultDate: new Date(),
                        }"
                        @input="handlenextfollowupdatechange($event, item.name)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <flat-pickr
                      v-model="form[item.name]"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'd/m/Y h:i:s K',
                        maxDate: new Date(),
                      }"
                      @input="handlenextfollowupdatechange($event, item.name)"
                    />
                  </div>
                </b-form-group>
                <!-- </b-col> -->
              </div>
              <div v-if="item.type == 'time'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="item.label"
                  >
                    <b-form-timepicker
                      :id="item.name"
                      now-button
                      reset-button
                      locale="en"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- </b-col> -->
              </div>
              <div v-if="item.type == 'fileinput'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="item.label"
                    >
                      <b-form-file
                        v-model="form[item.name]"
                        :placeholder="item.placeholder"
                        drop-placeholder="Drop file here..."
                        accept="image/*"
                        @input="
                          handleFileChange($event, item.name, item.imageType)
                        "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-file
                      v-model="form[item.name]"
                      :placeholder="item.placeholder"
                      drop-placeholder="Drop file here..."
                      accept="image/*"
                      @input="
                        handleFileChange($event, item.name, item.imageType)
                      "
                    />
                  </div>
                </b-form-group>
                <attachment :data="form[item.name]" />
                <!-- </b-col> -->
              </div>
              <!-- <div v-if="item.type == 'fileinput2'">
                <b-form-group>
                  <b-media class="mb-2">
                    <template #aside>
                      <b-avatar
                        ref="previewEl"
                        src="http://ngerp.s3.ap-south-1.amazonaws.com/phasemaster/VElxdkUCzXGRPy7xWXCl6VJLZe3Iaq7wEU9zOvoX.jpg"
                        text="text"
                        variant="primary"
                        size="90px"
                        rounded
                      />
                    </template>
                    <div class="d-flex flex-wrap">
                      <b-button
                        variant="primary"
                        @click="$refs.refInputEl.click()"
                      >
                        <input
                          ref="refInputEl"
                          type="file"
                          class="d-none"
                          @input="inputImageRenderer"
                        />
                        <span class="d-none d-sm-inline">Upload</span>
                        <feather-icon
                          icon="EditIcon"
                          class="d-inline d-sm-none"
                        />
                      </b-button>
                      <b-button variant="outline-secondary" class="ml-1">
                        <span class="d-none d-sm-inline">Remove</span>
                        <feather-icon
                          icon="TrashIcon"
                          class="d-inline d-sm-none"
                        />
                      </b-button>
                    </div>
                  </b-media>
                </b-form-group>
              </div> -->
              <div v-if="item.type == 'multiplefileinput'">
                <!-- <b-col> -->
                <b-form-group>
                  <label>{{ item.label }}</label>
                  <div v-if="item.required">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="item.label"
                    >
                      <b-form-file
                        v-model="form[item.name]"
                        :placeholder="item.placeholder"
                        drop-placeholder="Drop file here..."
                        accept="image/*"
                        multiple
                        @input="
                          handleFileChange($event, item.name, item.imageType)
                        "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-file
                      v-model="form[item.name]"
                      :placeholder="item.placeholder"
                      drop-placeholder="Drop file here..."
                      accept="image/*"
                    />
                  </div>
                </b-form-group>
                <attachment :data="form[item.name]" />
                <!-- </b-col> -->
              </div>
              <div v-if="item.type == 'quilleditor'">
                <!-- <b-col> -->
                <b-form-group>
                  <b-card-code :title="item.title" name="Second">
                    <quill-editor
                      v-model="form[item.name]"
                      :options="item.snowOption"
                    />
                  </b-card-code>
                </b-form-group>
                <!-- </b-col> -->
              </div>
              <div v-if="item.type == 'rating'">
                <b-col>
                  <b-form-group>
                    <label class="mr-2">{{ item.label }}</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="item.label"
                    >
                      <b-form-rating
                        id="rating-lg"
                        v-model="form[item.name]"
                        stars="10"
                        inline
                        variant="primary"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </div>
              <div v-if="item.type == 'formrepeater'">
                <b-card-code :title="item.title" name="third">
                  <b-form
                    ref="form"
                    :style="{ height: trHeight }"
                    class="repeater-form"
                    @submit.prevent="repeateAgain($event, item.inputFields)"
                  >
                    <!-- Row Loop -->
                    <b-row
                      v-for="(inputfield, id) in item.inputFields"
                      :id="id"
                      :key="id"
                      ref="row"
                    >
                      <!-- Item Name -->
                      <!-- <div
                        v-for="(inputfield, id) in item.inputFields"
                        class="d-flex"
                      > -->
                      <div
                        v-for="key in Object.keys(inputfield)"
                        :class="inputfield[key].class"
                        :key="key"
                      >
                        <!-- <label>{{ Object.keys(inputfield) }}</label> -->
                        <div v-if="inputfield[key].type == 'text'">
                          <b-form-group
                            :label="inputfield[key].label"
                            :label-for="inputfield[key].name"
                          >
                            <b-form-input
                              v-model="
                                form[inputfield[key].name + '[' + id + ']']
                              "
                              :name="inputfield[key].name + '[' + id + ']'"
                              type="text"
                              :placeholder="inputfield[key].placeholder"
                            />
                          </b-form-group>
                        </div>
                        <div v-if="inputfield[key].type == 'plaintext'">
                          <!-- <b-col> -->
                          <b-form-group>
                            <label>{{ inputfield[key].label }}</label>
                            <b-form-input :value="id" plaintext />
                          </b-form-group>
                          <!-- </b-col> -->
                        </div>
                        <div v-if="inputfield[key].type == 'number'">
                          <b-form-group
                            :label="inputfield[key].label"
                            :label-for="inputfield[key].name"
                          >
                            <b-form-input
                              v-model="
                                form[inputfield[key].name + '[' + id + ']']
                              "
                              :name="inputfield[key].name + '[' + id + ']'"
                              type="number"
                              :placeholder="inputfield[key].placeholder"
                              :disabled="inputfield[key].disable"
                            />
                          </b-form-group>
                        </div>
                        <div v-if="inputfield[key].type == 'fileinput'">
                          <!-- <b-col> -->
                          <b-form-group>
                            <label>{{ inputfield[key].label }}</label>
                            <div v-if="inputfield[key].required">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                :name="inputfield[key].label"
                              >
                                <b-form-file
                                  v-model="form[inputfield[key].name]"
                                  :placeholder="inputfield[key].placeholder"
                                  drop-placeholder="Drop file here..."
                                  accept="image/*"
                                  @input="
                                    handleFileChange(
                                      $event,
                                      inputfield[key].name,
                                      inputfield[key].imageType
                                    )
                                  "
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </div>
                            <div v-else>
                              <b-form-file
                                v-model="form[inputfield[key].name]"
                                :placeholder="inputfield[key].placeholder"
                                drop-placeholder="Drop file here..."
                                accept="image/*"
                                @input="
                                  handleFileChange(
                                    $event,
                                    inputfield[key].name,
                                    inputfield[key].imageType
                                  )
                                "
                              />
                            </div>
                          </b-form-group>
                          <attachment :data="form[item.name]" />
                          <!-- </b-col> -->
                        </div>
                        <div v-if="inputfield[key].type == 'dropdown'">
                          <b-form-group
                            :label="inputfield[key].label"
                            :label-for="inputfield[key].name"
                          >
                            <v-select
                              label="name"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.form[inputfield[key].name] &&
                                  $v.form[inputfield[key].name].$error,
                              }"
                              :options="inputfield[key].options"
                              placeholder="None"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                            />
                          </b-form-group>
                        </div>
                        <div v-if="inputfield[key].type == 'date'">
                          <!-- <b-col> -->
                          <b-form-group
                            :label="inputfield[key].label"
                            :label-for="inputfield[key].name"
                          >
                            <!-- <label>{{ inputfield[key].label }}</label> -->
                            <!-- <validation-provider
                              #default="{ errors }"
                              rules="required"
                              :name="inputfield[key].label"
                            > -->
                            <b-form-datepicker
                              v-model="
                                form[inputfield[key].name + '[' + id + ']']
                              "
                              :name="inputfield[key].name + '[' + id + ']'"
                              menu-class="w-100"
                              calendar-width="100%"
                              :date-format-options="{
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                              }"
                              @input="
                                handlenextfollowupdatechange(
                                  $event,
                                  inputfield[key].name
                                )
                              "
                            />
                            <!-- <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> -->
                          </b-form-group>
                          <!-- </b-col> -->
                        </div>
                      </div>

                      <!-- <b-col> -->
                      <!-- <b-form-group
                          :label="inputfield.label"
                          :label-for="itemsid.id"
                        > -->

                      <!-- </b-form-group> -->
                      <!-- </b-col> -->
                      <!-- </div> -->

                      <!-- Remove Button -->

                      <b-col class="mb-50">
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeItem(id)"
                        >
                          <feather-icon icon="TrashIcon" class="" />
                          <!-- <span>Delete</span> -->
                        </b-button>
                      </b-col>
                      <!-- <b-col cols="12">
                        <hr />
                      </b-col> -->
                    </b-row>
                  </b-form>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgain($event, item.inputFields)"
                    class="mb-1"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add More</span>
                  </b-button>
                </b-card-code>
              </div>
            </div>

            <!-- submit button -->
            <b-col cols="12" v-if="options.gridForm">
              <b-button
                variant="primary"
                @click="submitForm"
                class="mr-4"
                :disabled="disabled"
              >
                {{
                  options.submitButtonName ? options.submitButtonName : "Submit"
                }}
              </b-button>
              <b-button variant="primary" @click="onClickBack">Back </b-button>
            </b-col>
            <b-row class="demo-inline-spacing" v-if="options.tableForm">
              <div>
                <b-button variant="primary" @click="onClickSearch">
                  Search
                </b-button>
              </div>
              <div>
                <b-button variant="primary" @click="onClickPDF"> PDF </b-button>
              </div>
              <div>
                <b-button variant="primary" @click="onClickEXCEL">
                  EXCEL
                </b-button>
              </div>
            </b-row>
          </b-row>
        </b-form>
        <!-- </b-modal> -->
      </div>
    </validation-observer>
  </b-card-code>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import BCardCode from "@core/components/b-card-code";
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  min_value,
  max_value,
  digits,
  alphaDash,
  length,
} from "@validations";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BFormTextarea,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BFormTimepicker,
  BFormFile,
  BFormRating,
  BFormRadioGroup,
  BFormCheckbox,
  VBModal,
  BModal,
  BFormTags,
  BAvatar,
  BMedia,
} from "bootstrap-vue";
// import DatePicker from 'vue2-datepicker'
import Datepicker from "vuejs-datepicker";
import Switches from "vue-switches";
import vue2Dropzone from "vue2-dropzone";
import axios from '@/components/axios';
import ItemTable from "./Item-table.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import Attachment from "../Attechment.vue";
import { dateFilter } from "vue-date-fns";
import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  diff,
} from "date-fns";

/**
 * Form Advanced component
 */
export default {
  filters: {
    date: dateFilter,
  },
  props: [
    "existingEmail",
    "options",
    "handleCloseModel",
    "selectedID",
    "getGridData",
    "getTableData",
    "displayTable",
    "searchData",
    "popUp",
  ],

  components: {
    BCardCode,
    Datepicker,
    BFormFile,
    BAvatar,
    Attachment,
    BMedia,
    BFormTags,
    BFormTimepicker,
    Multiselect,
    quillEditor,
    Cleave,
    Switches,
    BFormCheckbox,
    BFormRadioGroup,
    BInputGroupAppend,
    BInputGroup,
    BFormTextarea,
    BModal,
    vueDropzone: vue2Dropzone,
    ItemTable,
    // "v-select": vSelect,
    vSelect,
    flatPickr,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormRating,
  },
  computed: {
    state() {
      // Overall component validation state
      return this.dirty ? this.tags : null;
    },
  },
  watch: {
    tags() {
      // Set the dirty flag on first change to the tags array
      this.dirty = true;
    },
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  mixins: [heightTransition, togglePasswordVisibility],
  data() {
    return {
      test: "",
      disabled: false,
      current_date: { date: moment(new Date()).format("DD/MM/yyyy") },
      nextfollowupafterdays: "",
      nextfollowupdate: new Date(),
      snowOption: {
        theme: "snow",
      },
      dirty: false,
      countFields: 0,
      items: [
        {
          id: 1,
          // selected: "male",
          // selected1: "designer",
          prevHeight: 0,
          value: "",
        },
      ],
      Installments: [
        { id: 1, prevHeight: 0 },
        // { id: 2, prevHeight: 0 },
      ],
      itemsIds: [
        {
          id: 1,
          // selected: "male",
          // selected1: "designer",
          prevHeight: 0,
        },
      ],
      nextTodoId: 2,
      newElementId: 2,
      name: "",
      passwordValue: "",
      passwordCon: "",
      emailValue: "",
      number: "",
      numberRange: "",
      numberRegx: "",
      URL: "",
      Alphabetic: "",
      digitValue: "",
      digitValue2: "",
      character: "",
      required,
      // showHide,
      confirmed,
      password,
      email,
      min,
      min_value,
      max_value,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
      userData: null,
      branchData: null,
      designationData: null,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      inputFields: [],
      submitted: false,
      showPassword: true,
      selectedValue: null,
      datePicker: "",
      multiValue: [],
      form: {},
      subItemArray: [],
      subItemArrayBlank: [],
      userToken: localStorage.getItem("accessToken"),
      renderComponent: false,
      ifEdit: false,
      UnameValid: true,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
        previewTemplate: this.template(),
      },
      editorOption: {
        // Some Quill options...
        theme: "snow",
        modules: {
          toolbar: [
            [
              {
                font: [],
              },
              {
                size: [],
              },
            ],
            ["bold", "italic", "underline", "strike"],
            [
              {
                color: [],
              },
              {
                background: [],
              },
            ],
            [
              {
                script: "super",
              },
              {
                script: "sub",
              },
            ],
            [
              {
                header: [false, 1, 2, 3, 4, 5, 6],
              },
              "blockquote",
              "code-block",
            ],
            [
              {
                list: "ordered",
              },
              {
                list: "bullet",
              },
              {
                indent: "-1",
              },
              {
                indent: "+1",
              },
            ],
            [
              "direction",
              {
                align: [],
              },
            ],
            ["link", "image", "video"],
            ["clean"],
          ],
        },
      },
    };
  },
  // validations () {
  //   return {
  //     form: this.options.validations
  //   }
  // },
  emits() {
    // console.log('in emit method')
  },
  beforeUpdate() {
    // console.log('before update call')
  },
  beforeMount() {
    // this.inputFields = this.options.inputFields;

    this.forceRerender();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created() {
    // console.log('in created');
    if (this.$route.params.id) {
      this.showPassword = false;
    }
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  mounted() {
    this.clickSwitch();
    this.initTrHeight();
    // this.$eventBus.$on('onMounted', (formOptions) => {
    this.inputFields = this.options.inputFields;
    this.options.inputFields.map((item) => {
      item.maxlength=item.maxlength?item.maxlength:''
      item.rules=item.rules?item.rules:''

      if (item.type != "hidden") {
        this.countFields = this.countFields + 1;
      }
    });
    this.options.inputFields.map((item) => {
      this.form = { ...this.form, [item.name]: item.value };
      if (item.type == "formrepeater") {
        item.inputFields.map((item) => {
          this.form = { ...this.form, [item.name]: item.value };
        });
      }
    });
    // this.options.functions.map((function) => {
    //   console.log(function(),"function===");
    // })
    this.forceRerender();
    // })

    // this.$eventBus.$on('onMounted', (formOptions) => {
    // !window.location.pathname.includes("followupinfo") &&
    //   this.options.formTitle.includes("Edit") &&

    this.selectedID != 0 &&
      !window.location.pathname.includes("add") &&
      this.getEditValue(this.options);
    // this.getDropdownItem();
    window.location.pathname.includes("followupinfo") &&
      this.getFollowupInfobyId();
    // })
  },

  methods: {
    UnameValidation() {
      this.options.inputFields.map((item) => {
        if (item.type == "Uname") {
          const regex = new RegExp(/^[a-zA-Z]*$/);
          if (regex.test(this.form[item.name])) {
            this.UnameValid = true;
          } else {
            this.UnameValid = false;
          }
        }
      });
    },
    clickSwitch() {
      this.options.inputFields.map((item) => {
        if (item.type == "switch") {
          if (this.form[item.name] == true) {
            this.form[item.name] = 1;
          }
          if (this.form[item.name] == false) {
            this.form[item.name] = 0;
          }
        }
      });
    },
    async handleFileChange(e, name, type) {
      this.disabled = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then(async (response) => {
          await this.inputFields.map((item) => {
            if (item.name == name) {
              this.form[item.name] =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
            }
          });
          this.disabled = false;
        })
        .catch((error) => {
          this.disabled = false;

          console.log(error, "error");
        });
    },
    onClickSearch() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          // eslint-disable-next-line
          this.options.cancelEvent && this.options.cancelEvent();
          this.form["nextfollowupdate"] &&
            (this.form["nextfollowupdate"] = moment(
              new Date(this.form["nextfollowupdate"])
            ).format("DD/MM/yyyy"));
          // this.form["startdate"] &&
          //   (this.form["startdate"] = moment(
          //     new Date(this.form["startdate"])
          //   ).format("DD/MM/yyyy"));
          // this.form["enddate"] &&
          //   (this.form["enddate"] = moment(
          //     new Date(this.form["enddate"])
          //   ).format("DD/MM/yyyy"));

          this.setDropdownsPayload();
          await axios({
            method: `${
              window.location.pathname.includes("followupinfo")
                ? this.options.method
                : this.$route.params.id || this.selectedID
                ? "put"
                : this.options.method
            }`,
            url: `${
              window.location.pathname.includes("followupinfo")
                ? this.options.url
                : this.$route.params.id
                ? `${this.options.url}/${this.$route.params.id}`
                : this.selectedID
                ? `${this.options.url}/${this.selectedID}`
                : this.options.url
            }`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.userToken}`,
            },
            data: JSON.stringify(this.form),
          })
            .then((json) => {
              this.searchData(json.data.data);
              // this.leadData(json.data.data)
              // this.options.inputFields.map((item) => {
              //   item.name = "";
              // });
              // window.location.pathname.includes("followupinfo")
              //   ? this.getFollowup()
              //   :
            })
            .catch((error) => console.log(error, "error"));
        }
      });
    },
    onClickPDF() {},
    onClickEXCEL() {},
    ValidateNumber(number) {
      if (/^(([0|\+[0-9]{1,5})?([7-9][0-9]{9}))/.test(number)) {
        return true;
      }
      return false;
    },
    ValidateEmail(mail) {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          mail
        )
      ) {
        return true;
      }
      return false;
    },
    EmailValidator(tag) {
      // Individual tag validator function
      return this.ValidateEmail(tag);
    },
    NumberValidator(tag) {
      // Individual tag validator function
      return this.ValidateNumber(tag);
    },
    handleChangeMultiSelect(e) {
      const { name } = e;
    },
    handleChangeSelect(e, selectedname, parentComponent, type, onKeyUp) {
      this.dropdownData();
      if (selectedname == "type") {
        this.displayTable(e.name);
      }
      const { name, id } = e;
      // console.log(selectedname, "selectedname===", type, "type===");
      const minValue = 0;
      // this.options.inputFields.map((item) => {
      //   // if (item.url == "phase") {
      //   //   console.log(item.options, "options===");
      //   //   item.options.map((options) => {
      //   //     if (options.project_id == id) {
      //   //       item.options = [];
      //   //       item.options.push(options);
      //   //       console.log(item.options, "item.options====");
      //   //     }
      //   //   });
      //   // }
      // });
      this.options.inputFields.map(async (item) => {
        if (item.rules) {
          if (item.onKeyUp == onKeyUp) {
            if (item.rules.includes("value")) {
              item.rules = `required|integer|min_value:${this.options.functions[
                onKeyUp
              ](name, type)}`;
            }
          }
          if (item.parentDropdown == selectedname) {
            const returnValue = await this.options.functions[onKeyUp](id);
            this.form[item.name] = "";
            item.options = returnValue;
          } else if (
            (item.name == selectedname &&
              item.parentDropdown != selectedname) ||
            item.getDynamicValue
          ) {
            const dynamicValue = await this.options.functions[onKeyUp](id);
            if (item.getDynamicValue) {
              this.form[item.name] = dynamicValue;
              this.form["plotareasqfeet"] = dynamicValue * 9;
            }
          }
        }
        if (item.parentComponent == name) {
          if (item.name == "reason") {
            item.options.map((options) => {
              if (options.type == name) {
                item.options = [];
                item.options.push(options);
              }
            });
          }
          item.type = item.changeFieldType;
          item.class = item.changeclass;
        } else if (
          item.parentComponent &&
          item.parentComponent != parentComponent
        ) {
          item.type = "hidden";
          item.class = "";
        }
      });
    },
    resetModal() {},
    handleOk() {},
    repeateAgain(e, inputFields) {
      this.Installments.push({
        id: (this.nextTodoId += 1),
      });
      this.inputFields.map((item) => {
        if (item.type == "formrepeater") {
          // Object.keys(inputFields[inputFields.length - 1]).map((key) => {
          //   // key += 1;
          //   // inputFields[inputFields.length - 1][key].id += 1;
          //   // inputFields[inputFields.length - 1][key].name += 1;
          // });
          item.inputFields.push(inputFields[inputFields.length - 1]);
          // console.log(this.form, "form===");
          // item.inputFields.map((field) => {
          //   // console.log(Object.keys(field), "field===");
          //   Object.keys(field).map((key) => {
          //     field[key].id += 1;
          //     field[key].name += 1;
          //   });
          //   rowFields.push(field);
          // });
          // item.inputFields = rowFields;
          // inputFields.map((field) => {
          //   console.log(inputFields, "inputFields===");
          // });
        }
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      // this.Installments.splice(index, 1);

      this.inputFields.map((item) => {
        if (item.type == "formrepeater") {
          // console.log(item.inputFields, "inputFieldsinputFields===");
          let filteredvalue;
          item.inputFields.map((field, indexNo) => {
            if (indexNo == index) {
              // item.inputFields.splice(index, 1);
              filteredvalue = item.inputFields.filter(function (
                value,
                idx,
                arr
              ) {
                return idx != index;
              });
            }
          });
          item.inputFields = filteredvalue;
        }
      });
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.form.scrollHeight);
      // });
    },
    async getFollowupInfobyId() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: JSON.stringify({
          leadid: this.$route.params.id,
        }),
        url: `${baseApi}/getfollowupbylead`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.options.followupInfo = response.data;
          this.options.inputFields.map((item) => {
            if (item.type != "datetime") {
              // if (this.options.followupInfo[item.name] != undefined) {
              item.value = this.options.followupInfo[item.name];
              // }
            }
            this.form = { ...this.form, [item.name]: item.value };
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    // async getDropdownItem() {
    //   const accessToken = localStorage.getItem("accessToken");
    //   const requestOptionsUsers = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //     url: `${this.baseApi}/getUsers`,
    //   };

    //   this.options.usersDropdown &&
    //     (await axios(requestOptionsUsers)
    //       .then((response) => {
    //         this.userData = response.data.data;
    //         this.options.inputFields.map((item) => {
    //           if (item.name == item.dropdownname) {
    //             this.userData.map((user) => {
    //               if (user.id == this.form[item.dropdownname]) {
    //                 this.form[item.dropdownname] = user.name;
    //               }
    //             });
    //           }
    //         });
    //       })
    //       .catch((error) => console.log(error, "error")));

    //   const requestOptionsBranch = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //     url: `${this.baseApi}/branch`,
    //   };

    //   this.options.branchDropdown &&
    //     (await axios(requestOptionsBranch)
    //       .then((response) => {
    //         this.branchData = response.data.data.data;
    //         this.options.inputFields.map((item) => {
    //           // this.form[item.name] = data[item.name];
    //           if (item.name == item.dropdownname) {
    //             this.branchData.map((user) => {
    //               if (user.id == this.form[item.dropdownname]) {
    //                 this.form[item.dropdownname] = user.name;
    //               }
    //             });
    //           }
    //         });
    //       })
    //       .catch((error) => console.log(error, "error")));

    //   const requestOptionsDesignation = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //     url: `${this.baseApi}/getPosition`,
    //   };

    //   this.options.designationDropdown &&
    //     (await axios(requestOptionsDesignation)
    //       .then((response) => {
    //         this.designationData = response.data.data;
    //         this.options.inputFields.map((item) => {
    //           // this.form[item.name] = data[item.name];
    //           if (item.name == item.dropdownname) {
    //             this.designationData.map((user) => {
    //               if (user.id == this.form[item.dropdownname]) {
    //                 this.form[item.dropdownname] = user.name;
    //               }
    //             });
    //           }
    //         });
    //       })
    //       .catch((error) => console.log(error, "error")));
    // },
    async handleChange(number, name, onKeyUp) {
      // this.form["name"] = number;
      const CurrentDate = new Date();

      CurrentDate.setDate(CurrentDate.getDate() + parseInt(number));
      name == "nextfollowupafterdays" &&
        this.form["nextfollowupdate"] &&
        (this.form["nextfollowupdate"] = CurrentDate);

      if (name == "noofinstallment") {
        // this.options.functions[onKeyUp](number, name);
        if (number && parseInt(number) !== 0) {
          this.Installments = [];
        } else {
          this.Installments = [{ id: 1, prevHeight: 0 }];
        }
        for (let index = 0; index < parseInt(number); index++) {
          this.Installments.push({
            id: index + 1,
            srno: index + 1,
            prevHeight: 0,
          });
        }
      }

      this.inputFields.map((item) => {
        if (item.onKeyUp && item.childComponent && item.calculateValue) {
          const obj = {};
          item.calculateValue.map((calValue) => {
            obj[calValue] =
              this.form[calValue] !== "" ? parseInt(this.form[calValue]) : 0;
          });
          item.childComponent.map((childComp) => {
            this.form[childComp] = this.options.functions[item.onKeyUp](
              name,
              obj
            );
          });
        }
      });
    },
    handlenextfollowupafterdayschange(e, name) {
      //   console.log(e, "e--");
      //   console.log(name, "e--");
      //   const CurrentDate = new Date();
      //   const newDate = new Date();
      //   const newDate1 = new Date();
      //   const newDate2 = new Date();
      //   const newDate3 = new Date();
      //   const newDate4 = new Date();
      //   let selectedMonth = CurrentDate.getMonth() + 1;
      //   console.log(selectedMonth, "selectedMonth===");
      //   let month = selectedMonth;
      //   const demo =
      //     newDate.getDate() +
      //     CurrentDate.getDate() +
      //     newDate1.getDate() +
      //     newDate2.getDate() +
      //     newDate3.getDate() +
      //     newDate4.getDate();
      //   console.log(demo, "demo====");
    },
    parseDate(str) {
      const mdy = str.split("/");
      return new Date(mdy[2], mdy[0] - 1, mdy[1]);
    },
    datediff(first, second) {
      // Take the difference between the dates and divide by milliseconds per day.
      // Round to nearest whole number to deal with DST.
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },
    handlenextfollowupdatechange(date, name) {
      const CurrentDate = new Date();
      const newDate = moment(date, "DD/MM/YYYY").toDate();
      const nextfollowupafterdays = this.datediff(CurrentDate, newDate);
      this.nextfollowupafterdays = nextfollowupafterdays;
      this.nextfollowupdate = moment(date, "DD/MM/YYYY");
      this.form["nextfollowupafterdays"] = this.nextfollowupafterdays;
    },
    getEditValue(formOptions) {
      this.ifEdit = true;
      this.inputFields = this.options.inputFields;

      this.options.inputFields.map((item) => {
        return (this.form = { ...this.form, [item.name]: item.value });
      });

      axios({
        method: `${
          this.$route.params.id || this.selectedID ? "get" : formOptions.method
        }`,
        url: this.$route.params.id
          ? `${formOptions.getEditValue}/${this.$route.params.id}`
          : `${formOptions.getEditValue}/${this.selectedID}`,
        // url: this.$route.query.id
        //   ? `${formOptions.url}/${this.$route.query.id}`
        //   : `${formOptions.url}/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.userToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          let data;
          json.data.data ? (data = json.data.data[0]) : (data = json.data[0]);

          this.options.inputFields.map((item) => {
            this.form[item.name] = data[item.name];
            if (this.form["leadid"] == this.form[item.name])
              this.form["leadid"] = data.id;

            if (item.name == item.dropdownname) {
              item.options.map((user) => {
                if (user.id == this.form[item.dropdownname]) {
                  // console.log("value----");
                  this.form[item.dropdownname] = user.name;
                }
              });
            }
            if (item.type == "switch") {
              if (data.installed == 1) {
                this.form[item.name] = true;
              }
              if (data.installed == 0) {
                this.form[item.name] = false;
              }
            }
            // if (item.type == "dropdown") {
            //   item.options.map((user) => {
            //     if (user.id == this.form[item.dropdownname]) {

            //       console.log('value----')
            //       this.form[item.dropdownname] = user.name
            //     }
            //   })
            // }
            if (item.type == "dropdown") {
              item.options.map((user) => {
                if (user.id == this.form[item.dropdownname]) {
                  this.form[item.dropdownname] = user.name;
                }
              });
            }
          });
          if (this.existingEmail == true) {
            this.options.inputFields.map((item) => {
              if (item.type == "email") {
                this.test = this.form.email;
              }
            });
          }
          // }
        })
        .catch((error) => console.log(error, "error"));
    },
    setDropdownsPayload() {
      // this.form["nextfollowupdate"] &&
      //   (this.form["nextfollowupdate"] = moment(
      //     new Date(this.form["nextfollowupdate"])
      //   ).format("DD/MM/yyyy"));
      this.form["date"] &&
        (this.form["date"] = moment(new Date(this.form["date"])).format(
          "DD/MM/yyyy"
        ));
      this.inputFields.map((item) => {
        if (item.responseValue) {
          if (item.type == "dropdown" || item.changeFieldType == "dropdown") {
            this.form[item.name] &&
              (this.form[item.name] = this.form[item.name][item.responseValue]);
          }
        }
      });
      // this.form["branch"] && (this.form["branch"] = this.form["branch"].id);
      // this.form["assignto"] &&
      //   (this.form["assignto"] = this.form["assignto"].id);
      // this.form["parent"] && (this.form["parent"] = this.form["parent"].id);
      // this.form["designation"] &&
      //   (this.form["designation"] = this.form["designation"].id);
      // this.form["gender"] && (this.form["gender"] = this.form["gender"].name);
      // this.form["followuptype"] &&
      //   (this.form["followuptype"] = this.form["followuptype"].name);
      // this.form["priority"] &&
      //   (this.form["priority"] = this.form["priority"].name);
      // this.form["status"] && (this.form["status"] = this.form["status"].name);
      // // this.form["parent"] &&
      // //   (this.form["parent"] = this.form["parent"].name);
      // this.form["channelpartner"] &&
      //   (this.form["channelpartner"] = this.form["channelpartner"].name);
      // this.form["parent"] == "" ? (this.form["parent"] = "0") : null;
      // this.form["leadsource"] &&
      //   (this.form["leadsource"] = this.form["leadsource"].id);
      // this.form["leadstage"] &&
      //   (this.form["leadstage"] = this.form["leadstage"].name);
    },
    onClickBack() {
      if (this.popUp) {
        this.handleCloseModel();
      } else {
        this.$router.push(this.options.submitRouterPath);
      }
    },
    submitForm() {
      if (this.existingEmail == true) {
        if (this.test == this.form.email) {
          delete this.form.email;
        }
      }
      // }
      // this.submitted = true
      this.clickSwitch();
      this.setDropdownsPayload();
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false && this.UnameValid == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.disabled = true;
          // eslint-disable-next-line
          this.options.cancelEvent && this.options.cancelEvent();
          this.form["nextfollowupdate"] &&
            (this.form["nextfollowupdate"] = this.nextfollowupdate);
          await axios({
            method: `${
              window.location.pathname.includes("followupinfo")
                ? this.options.method
                : this.$route.params.id || this.selectedID
                ? "put"
                : this.options.method
            }`,
            url: `${
              window.location.pathname.includes("followupinfo")
                ? this.options.url
                : this.$route.params.id
                ? `${this.options.url}/${this.$route.params.id}`
                : this.selectedID
                ? `${this.options.url}/${this.selectedID}`
                : this.options.url
            }`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.userToken}`,
            },
            data: JSON.stringify(this.form),
          })
            .then((json) => {
              this.disabled = false;

              // this.leadData(json.data.data)
              // this.options.inputFields.map((item) => {
              //   item.name = "";
              // });
              // window.location.pathname.includes("followupinfo")
              //   ? this.getFollowup()
              //   :
              this.handleCloseModel && this.handleCloseModel();
              this.getGridData && this.getGridData();
              this.getTableData && this.getTableData();
              // this.$toast({
              //   component: ToastificationContent,
              //   position: 'top-right',
              //   props: {
              //     variant: 'primary',
              //     text:
              //       this.$route.query.id || this.selectedID
              //         ? 'Data Updated Successfully'
              //         : 'Data Added Successfully'
              //   }
              // })
              if (json.data.status == 200 || json.data) {
                this.$swal({
                  title: "Submited",
                  text: json.data.message
                    ? `${json.data.message}`
                    : "Update SuccessFully",
                  icon: "success",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              if (this.popUp !== true) {
                this.$router.push(this.options.submitRouterPath);
              }
            })

            .catch((error) => {
              this.disabled = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    // handleSubmit(e) {
    //   this.submitted = true;
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //     return;
    //   } else {
    //     console.log(this.form, "Input Value");
    //   }
    //   console.log(this.options.inputFields, "Input Value");
    //   this.options.cancelEvent();
    //   // let payloadData = {};
    //   // this.options.inputFields.map((item) => {
    //   //   return (payloadData = { ...payloadData, [item.name]: item.value });
    //   // });
    //   axios({
    //     method: `${this.options.method}`,
    //     url: `${
    //       this.$route.query.id
    //         ? `${this.options.url}/${this.$route.query.id}`
    //         : this.options.url
    //     }`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.userToken}`,
    //     },
    //     data: JSON.stringify(this.form),
    //   })
    //     .then((json) => {
    //       console.log(json, "json--");
    //       this.options.inputFields.map((item) => {
    //         item.name = "";
    //       });
    //       this.options.reloadGrid();
    //       this.options.clearselection();
    //     })
    //     .catch((error) => console.log(error, "error"));
    // },
    getSubItem(item) {
      // console.log(item, "sub item array");
    },
    subItems(item, action, index) {
      if (action == "add") {
        this.subItemArray.push(this.subItemArrayBlank);
      } else if (action == "onload" && this.subItemArray.length == 0) {
        this.subItemArrayBlank = item;
        this.subItemArray.push(item);
        item.map((a) => {
          return (this.subForm = { ...this.form, [a.name]: a.value });
        });
      } else if (action == "remove") {
        this.subItemArray.splice(index, 1);
      }
      return this.subItemArray;
    },
    vfileAdded(file) {},
    template() {
      return ` <div class="dropzone-previews mt-3">
            <div class="card mt-1 mb-0 shadow-none border">
                <div class="p-2">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <img data-dz-thumbnail src="#" class="avatar-sm rounded bg-light" alt="">
                        </div>
                        <div class="col pl-0">
                            <a href="javascript:void(0);" class="text-muted font-weight-bold" data-dz-name></a>
                            <p class="mb-0" data-dz-size></p>
                        </div>
                        <div class="col-auto">
                            <!-- Button -->
                            <a href="" class="btn btn-link btn-lg text-muted" data-dz-remove>
                                <i class="fe-x"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `;
    },
  },
};
</script>
<style lang="scss" scoped>
.repeater-form {
  transition: 0.35s height;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
