var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Employee Name")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Employee Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"fullname","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"None","options":_vm.employeeOption},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var fullname = ref.fullname;
var profile_image = ref.profile_image;
var username = ref.username;
return [_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(fullname)+" ")]),_c('span',[_vm._v("("+_vm._s(username)+")")])]}}],null,true),model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Description")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"type":"text","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Enter description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Attachment")]),_c('b-form-file',{attrs:{"placeholder":"Select Document","drop-placeholder":"Drop file here...","accept":"image/*"},on:{"input":function($event){return _vm.handleChangeFile($event, 'request','attachment')}},model:{value:(_vm.attachment),callback:function ($$v) {_vm.attachment=$$v},expression:"attachment"}})],1),_c('attachment',{attrs:{"data":_vm.attachment}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"primary","type":"submit","disabled":_vm.flag},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }