import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// import "./plugins/element.js";
// import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import axios from 'axios'
import './plugins/element.js'
import VueHtmlToPaper from 'vue-html-to-paper';
import { BAvatar } from 'bootstrap-vue'
// Global Components
import './global-components'
import Vuesax from 'vuesax'
import Mixin from './Mixins.js'
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)
Vue.mixin(Mixin)
// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
// Axios Mock Adapter
import '@/@fake-db/db'
import PrimeVue from 'primevue/config';
import components from './components'
Vue.use(PrimeVue);
Vue.use(components);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueHtmlToPaper);
Vue.component('b-avatar', BAvatar)
// Composition API
Vue.use(VueCompositionAPI)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false


new Vue({
  router,
  store,
  // i18n,
  render: (h) => h(App)
}).$mount('#app')
