<template>
  <b-card v-if="data" class="card-congratulation-medal">
    <div class="d-flex justify-content-between w-100">
      <div>
        <h5>Hello {{ data.name }}!</h5>
        <b-card-text class="font-small-3 m-0">
          <!-- You can sell ₹{{ data.saleToday }} -->
          Amazing 🎉,Keep it Up {{ data.name }}
        </b-card-text>
        <h5 class="mt-2">
          Show Your Sales From Here 👇
        </h5>
        <b-button
          @click="clickSales"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2"
          size="sm"
        >
          View Sales
        </b-button>
      </div>
      <!-- <b-img :src="require('@/assets/images/illustration/badge.svg')" class="congratulation-medal" alt="Medal Pic" /> -->
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText, BLink, BButton, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton
  },
  directives: {
    Ripple
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    kFormatter,
    clickSales () {
      this.$router.push('/crm/sales')
    }
  }
}
</script>
