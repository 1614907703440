<template>
  <div>

    <b-row class="row justify-content-between">
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Sales By :</label>
        <p class="viewValue">{{ selectedSalesBy }}</p>
      </b-col>

        <b-col md="4" class="mt-2" :style="selectedSalesBy == 'ChannelPartner' ? '' : 'display: none'">
          <label class="viewLabel">{{ selectedSalesByLabel }}</label>
          <p class="viewValue">{{data.team ? data.team.fullname : ''}}</p>
        </b-col>
        <!-- <b-col md="4" class="mt-2" :style="selectedSalesBy == 'Employee' ? '' : 'display: none'">
          <label class="viewLabel">{{ selectedSalesByLabel }}</label>
          <p class="viewValue">{{data.employeelist && data.employeelist.length>0 ? data.employeelist[0].name : ''}}</p>
        </b-col>
        <b-col md="4" class="mt-2" :style="selectedSalesBy == 'Franchise' ? '' : 'display: none'">
          <label class="viewLabel">{{ selectedSalesByLabel }}</label>
          <p class="viewValue">{{data.Franchise && data.Franchise.length>0 ? data.Franchise[0].name : ''}}</p>
        </b-col> -->

      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> Booking Date :</label>
        <p class="viewValue">{{ data.bookingdate ? moment(data.bookingdate).format('DD/MM/yyyy') : ''}}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Booking Type :</label>
        <p class="viewValue">{{ data.booktype ? data.booktype : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Email :</label>
        <p class="viewValue">{{  data.team ? data.team.email : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Phone No :</label>
        <p class="viewValue">{{  data.team ? data.team.mobile : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Username :</label>
        <p class="viewValue">{{  data.team ? data.team.username : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> Photo :</label>
        <div style="width:100px;">
          <attachment v-if="data.team" :data="data.team.profile_image" />
          </div>
      </b-col>
      <b-col md="12" class="mt-2">
        <h3>Application Form</h3>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Project :</label>
        <p class="viewValue">{{ data.project?data.project.projectname:'' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Suggested Plot No :</label>
        <p class="viewValue">{{ data.plotno  ? data.plotno.plotno : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot Rate :</label>
        <p class="viewValue">{{ data.plotrate ? data.plotrate : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot Area(Sq. Yard) :</label>
        <p class="viewValue">{{ data.plotareasqfeet ? (data.plotareasqfeet /9 ): '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot Area(Sq.Feet) :</label>
        <p class="viewValue">{{ data.plotareasqfeet ? data.plotareasqfeet : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> Discount (%) :</label>
        <attachment v-if="data.discount_attachment" :data="data.discount_attachment" />

      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Remarks :</label>
        <p class="viewValue">{{ data.disremarks ? data.disremarks : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Discount Attachment :</label>
        <p class="viewValue">{{ data.disremarks ? data.disremarks : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Plot Value :</label>
        <p class="viewValue">{{ data.plotvalue ? data.plotvalue : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Payment Terms Days :</label>
        <p class="viewValue">{{ data.paymenttermday ? data.paymenttermday : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Payment Type :</label>
        <p class="viewValue">{{ data.paymenttype ? data.paymenttype : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" :style="selectedPaymentThrough == 'By UPI'? '':'Display:none'">
        <label class="viewLabel"> Payment Through :</label>
        <p class="viewValue">{{ data.paymentthrough ? data.paymentthrough : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Bank :</label>
        <p class="viewValue">{{ data.bank ? data.bank.name: '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Qr Code :</label>
        <attachment v-if="data.project&&data.project" :data="data.project.qrcode" />
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Reference Number :</label>
        <p class="viewValue">{{ data.refno ? data.refno : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Upload Receipt / Screenshort :</label>
          <attachment v-if="data.bankreceipt" :data="data.bankreceipt" />
      </b-col>
      <b-col md="12" class="mt-1">
        <h3>Payment Details</h3>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Total Payment :</label>
        <p class="viewValue">{{ data.totalpayment ? data.totalpayment : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> First Payment :</label>
        <p class="viewValue">{{ data.firstpayment ? data.firstpayment : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Remaining Payment :</label>
        <p class="viewValue">{{ data.remainingpayment ? data.remainingpayment : '' }}</p>
      </b-col>
      <b-col md="12">
        <h3>Application Name</h3>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Title :</label>
        <p class="viewValue">{{ data.title ? data.title : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Customer Name :</label>
        <p class="viewValue">{{ data.cfirstname ? data.cfirstname : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Address :</label>
        <p class="viewValue">{{ data.address ? data.address : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Pin Code :</label>
        <p class="viewValue">{{ data.pincode ? data.pincode : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> City :</label>
        <p class="viewValue">{{ data.city ? data.city : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Area :</label>
        <p class="viewValue">{{ data.area ? data.area : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> state :</label>
        <p class="viewValue">{{ data.state ? data.state : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Country :</label>
        <p class="viewValue">{{ data.country ? data.country : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> DOB :</label>
        <p class="viewValue">{{ data.dateofbirth ? moment(data.dateofbirth).format('DD/MM/YYYY') : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Mobile No :</label>
        <p class="viewValue">{{ data.mobileno ? data.mobileno : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Email ID :</label>
        <p class="viewValue">{{ data.email ? data.email : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Aadhar No :</label>
        <p class="viewValue">{{ data.adharno ? data.adharno : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Pan No :</label>
        <p class="viewValue">{{ data.panno ? data.panno : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Nominee :</label>
        <p class="viewValue">{{ data.nominee ? data.nominee : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Nominee Relationship :</label>
        <p class="viewValue">{{ data.nomineerelationship ? data.nomineerelationship : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Nominee Phone :</label>
        <p class="viewValue">{{ data.nomineephone ? data.nomineephone : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Nominee DOB :</label>
        <p class="viewValue">{{ data.nomineedob ? data.nomineedob : '' }}</p>
      </b-col>

      <b-col md="12" class="mt-2">
        <h3>Customer Information</h3>
      </b-col>

      <!-- <b-row class="row justify-content-between" v-for="(item,index) in data.bank" :key="index" > -->

        <!-- <b-col>
          <label class="viewLabel">Sr No.</label>
          <p class="viewValue"></p>
        </b-col> -->

        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Customer Name :</label>
          <p class="viewValue">{{ data.cfirstname ? data.cfirstname : '' }}</p>
        </b-col>

        <b-col md="4" class="mt-2">
          <label class="viewLabel">Birth Date :</label>
          <p class="viewValue">{{ data.dateofbirth? data.dateofbirth: '' }}</p>
        </b-col>

        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Mobile :</label>
          <p class="viewValue">{{ data.mobileno ? data.mobileno : '' }}</p>
        </b-col>

        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Email :</label>
          <p class="viewValue">{{ data.email ? data.email : '' }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Aadhaar No :</label>
          <p class="viewValue">{{ data.adharno ? data.adharno : '' }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Pan No :</label>
          <p class="viewValue">{{ data.panno ? data.panno : '' }}</p>
        </b-col>

        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Nominee Name :</label>
          <p class="viewValue">{{ data.nominee ? data.nominee : '' }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Nominee Relation :</label>
          <p class="viewValue">{{ data.nomineerelationship ? data.nomineerelationship : '' }}</p>
        </b-col>
        <b-col md="4" class="mt-2" v-if="data.nomineedob && data.nomineedob!='0000-00-00'">
          <label class="viewLabel"> Nominee Birth Date :</label>
          <p class="viewValue">{{ data.nomineedob ? data.nomineedob : '' }}</p>
        </b-col>
        <b-col md="4" class="mt-2">
          <label class="viewLabel"> Nominee Phone :</label>
          <p class="viewValue">{{ data.nomineephone ? data.nomineephone : '' }}</p>

        </b-col>
        <b-col md="12">
          <hr style="border:1px solid black; opacity:0.5">
      </b-col>
        <!-- </b-row> -->
      </b-row>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import axios from "@/components/axios";
import Attachment from "@/components/Attechment.vue";
import moment from 'moment';
export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: {
    closepopup: {
      default: () => { },
    },
    selectedID: {
      default: 0,
    },
  },
  data() {
    return {
      moment,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: {},
      loginData: JSON.parse(localStorage.getItem("userData")),
      selecttype:[
        "channelpartner","Franchise","Employee",'direct'
      ],
      selectedPaymentThrough:"",
      selectedTeam:"",
      label:"",
      selectedSalesBy: "",
      selectedSalesByLabel: "",
      userType:"",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getsalesbyid/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data)
            ? json.data.data[0]
            : json.data.data;
          this.selectedPaymentThrough = this.data.paymentthrough ? this.paymentthrough : "";
          this.selectedSalesBy = this.data.branch ? this.data.branch : "";
          this.selectedSalesByLabel = this.getLabelForSelection(this.selectedSalesBy);

          console.log(this.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getLabelForSelection(selection) {
      if (selection === "Franchise") {
        return "Franchise";
      }else if (selection === 'ChannelPartner'){
        return "ChannelPartner";
      } else if(selection === 'Employee'){
        return "Employee"
      }
      else {
        return "Sales By Selection";
      }
    },

  },
};
</script>
<style>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
