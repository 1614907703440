<template>
  <b-card class="text-center cursor-pointer boxes" @click="handleRoute">
    <b-avatar class="mb-1" :variant="`light-${color}`" size="35">
      <svg
        v-if="icon == 'rupee'"
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        fill="currentColor"
        class="bi bi-currency-rupee"
        viewBox="0 0 16 16"
      >
        <path
          d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z"
        />
      </svg>
      <feather-icon v-else size="17" :icon="icon" />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder" style="font-size: 16px">
        {{ statistic }}
      </h2>
      <span>{{ statisticTitle }}</span>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      // required: true,
      default: "",
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    route: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleRoute() {
      if (this.route.includes("/")) {
        this.$router.push(`${this.route}`);
      } else {
        this.$router.push({ name: this.route });
      }
    },
  },
};
</script>
<style>
.boxes {
  min-height: 130px;
  min-width: 160px;
}
</style>
