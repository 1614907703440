<template>
  <b-card no-body :style="compStyle">
    <b-card-body class="d-flex justify-content-start align-items-top">
      <b-avatar :variant="color" :size="roundSize">
        <feather-icon :size="iconSize" :icon="icon" />
      </b-avatar>
      <div class="truncate ml-1">
        <h5 class="mb-25 font-weight-bolder">
          {{ statistic }}
        </h5>
        <span>{{ statisticTitle }}</span>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    statistic: {
      type: [Number, String],
      required: true
    },
    statisticTitle: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    iconSize: {
      type: String,
      default: '15'
    },
    roundSize:{
      type: String,
      default: '35'
    },
    compStyle:{
      default:''
    }
  }
}
</script>
