<template>
    <div class="d-flex align-items-center pt-1 pl-1">
      <b-avatar :variant="`light-${color}`" size="30"  >
        <span>{{ statisticTitle }}</span>
      </b-avatar>
      <div class="truncate ml-1 d-flex align-items-center">
        <h6 class="font-weight-bolder m-0">
          {{ statistic }}
        </h6>
      </div>
    </div>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar
  },
  props: {
    statistic: {
      type: [Number, String],
      required: true
    },
    statisticTitle: {
      type: [Number, String],
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
