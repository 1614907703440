// const params = new URLSearchParams(window.location.search).has("id");
let userRole
if (localStorage.getItem('accessToken') !== null) {
  userRole = JSON.parse(localStorage.getItem('userData')).role
} else {
  userRole = false
}

export default [
  {
    path: '/accounting/payment',
    name: 'accounting-payment',
    component: () => import('@/views/accounting/payment/Payment.vue'),
    meta: {
      pageTitle: 'Payment',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Payment',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/addpayment',
    alias: '/accounting/editpayment',
    name: 'add-payment',
    component: () => import('@/views/accounting/payment/AddPayment.vue'),
    meta: {
      pageTitle: 'Add Payment',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Payment',
          to: '/accounting/payment'
        },
        {
          text: 'Add Payment',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/editpayment/:id',
    name: 'edit-payment',
    component: () => import('@/views/accounting/payment/AddPayment.vue'),
    meta: {
      pageTitle: 'Edit Payment',
      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Payment',
          to: '/accounting/payment'
        },
        {
          text: 'Edit Payment',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/receipt',
    name: 'accounting-receipt',
    component: () => import('@/views/accounting/receipt/Receipt.vue'),
    meta: {
      pageTitle: 'Receipt',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Receipt',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/addreceipt',
    alias: '/accounting/editreceipt',
    name: 'add-receipt',
    component: () => import('@/views/accounting/receipt/AddReceipt.vue'),
    meta: {
      pageTitle: 'Add Receipt',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Receipt',
          to: '/accounting/receipt'
        },
        {
          text: 'Add Receipt',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/editreceipt/:id',
    name: 'edit-receipt',
    component: () => import('@/views/accounting/receipt/AddReceipt.vue'),
    meta: {
      pageTitle: 'Edit Receipt',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Receipt',
          to: '/accounting/receipt'
        },
        {
          text: 'Edit Receipt',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/contra',
    name: 'accounting-contra',
    component: () => import('@/views/accounting/contra/Contra.vue'),
    meta: {
      pageTitle: 'Contra',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Contra',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/addcontra',
    alias: '/accounting/editcontra',
    name: 'add-contra',
    component: () => import('@/views/accounting/contra/AddContra.vue'),
    meta: {
      pageTitle: 'Add Contra',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Contra',
          to: '/accounting/contra'
        },
        {
          text: 'Add Contra',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/editcontra/:id',
    name: 'edit-contra',
    component: () => import('@/views/accounting/contra/AddContra.vue'),
    meta: {
      pageTitle: 'Edit Contra',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Contra',
          to: '/accounting/contra'
        },
        {
          text: 'Edit Contra',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/accounting/journal",
  //   name: "accounting-journal",
  //   component: () => import("@/views/accounting/journal/Journal.vue"),
  //   meta: {
  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: "ACCOUNTING",
  //         to: '/accounting',
  //       } : {
  //         text: "ACCOUNTING",
  //         active: true,
  //       },
  //       {
  //         text: "Journal",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/journal",
  //   name: "add-journal",
  //   component: () => import("@/views/accounting/journal/AddJournal.vue"),
  //   meta: {
  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: "ACCOUNTING",
  //         to: '/accounting',
  //       } : {
  //         text: "ACCOUNTING",
  //         active: true,
  //       },
  //       {
  //         text: "Journal",
  //         to: "/accounting/journal",
  //       },
  //       {
  //         text: `${params ? "Edit Journal" : "Add Journal"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/accounting/sales',
  //   name: 'accounting-sales',
  //   component: () => import('@/views/accounting/sales/Sales.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Accounting',
  //         active: true
  //       },
  //       {
  //         text: 'Sales',
  //         active: true
  //       },
  //     ]
  //   }
  // },
  // {
  //   path: '/sales',
  //   name: 'add-sales',
  //   component: () => import('@/views/accounting/sales/AddSales.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Accounting',
  //         active: true
  //       },
  //       {
  //         text: 'Sales',
  //         to:'/accounting/sales'
  //       },
  //       {
  //         text: `${params ? 'Edit Sales' : 'Add Sales'}`,
  //         active:true
  //       },
  //     ]
  //   }
  // },
  {
    path: '/accounting/chequereturn',
    name: 'accounting-chequereturn',
    component: () => import('@/views/accounting/chequereturn/ChequeReturn.vue'),
    meta: {
      pageTitle: 'Cheque Return',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'ChequeReturn',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/addchequereturn',
    alias: '/accounting/editchequereturn',
    name: 'add-chequereturn',
    component: () => import('@/views/accounting/chequereturn/AddChequeReturn.vue'),
    meta: {
      pageTitle: 'Add Cheque Return',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'ChequeReturn',
          to: '/accounting/chequereturn'
        },
        {
          text: 'Add ChequeReturn',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/editchequereturn/:id',
    name: 'edit-chequereturn',
    component: () => import('@/views/accounting/chequereturn/AddChequeReturn.vue'),
    meta: {
      pageTitle: 'Edit Cheque Return',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'ChequeReturn',
          to: '/accounting/chequereturn'
        },
        {
          text: 'Edit ChequeReturn',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/voucher',
    name: 'accounting-voucher',
    component: () => import('@/views/accounting/voucher/Voucher.vue'),
    meta: {
      pageTitle: 'Voucher',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Voucher',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/addvoucher',
    alias: '/accounting/editvoucher',
    name: 'add-voucher',
    component: () => import('@/views/accounting/voucher/AddVoucher.vue'),
    meta: {
      pageTitle: 'Add Voucher',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Voucher',
          to: '/accounting/voucher'
        },
        {
          text: 'Add Voucher',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/editvoucher/:id',
    name: 'edit-voucher',
    component: () => import('@/views/accounting/voucher/AddVoucher.vue'),
    meta: {
      pageTitle: 'Edit Voucher',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Voucher',
          to: '/accounting/voucher'
        },
        {
          text: 'Edit Voucher',
          active: true
        }
      ]
    }
  },

  {
    path: '/accounting/landpurchase',
    name: 'accounting-purchase',
    component: () => import('@/views/accounting/landpurchase/LandPurchase.vue'),
    meta: {
      pageTitle: 'Land Purchase',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Land Purchase',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/addlandpurchase',
    alias: '/accounting/editlandpurchase',
    name: 'add-landpurchase',
    component: () => import('@/views/accounting/landpurchase/AddLandPurchase.vue'),
    meta: {
      pageTitle: 'Add Land Purchase',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Land Purchase',
          to: '/accounting/landpurchase'
        },
        {
          text: 'Add Land Purchase',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/editlandpurchase/:id',
    name: 'edit-landpurchase',
    component: () => import('@/views/accounting/landpurchase/AddLandPurchase.vue'),
    meta: {
      pageTitle: 'Edit Land Purchase',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'Land Purchase',
          to: '/accounting/landpurchase'
        },
        {
          text: 'Edit Land Purchase',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/otherpurchase',
    name: 'accounting-otherpurchase',
    component: () => import('@/views/accounting/otherpurchase/OtherPurchase.vue'),
    meta: {
      pageTitle: 'Other Purchase',


      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'OtherPurchase',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/addotherpurchase',
    alias: '/accounting/editotherpurchase',
    name: 'add-other-purchase',
    component: () => import('@/views/accounting/otherpurchase/AddOtherPurchase.vue'),
    meta: {
      pageTitle: 'Add Other Purchase',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'OtherPurchase',
          to: '/accounting/otherpurchase'
        },
        {
          text: 'Add OtherPurchase',
          active: true
        }
      ]
    }
  },
  {
    path: '/accounting/editotherpurchase/:id',
    name: 'edit-other-purchase',
    component: () => import('@/views/accounting/otherpurchase/AddOtherPurchase.vue'),
    meta: {
      pageTitle: 'Edit Other Purchase',

      breadcrumb: [
        userRole != 'admin' ? {
          text: 'ACCOUNTING',
          to: '/accounting'
        } : {
          text: 'ACCOUNTING',
          active: true
        },
        {
          text: 'OtherPurchase',
          to: '/accounting/otherpurchase'
        },
        {
          text: 'Edit OtherPurchase',
          active: true
        }
      ]
    }
  }
  // {
  //   path: "/accounting/purchase",
  //   name: "accounting-purchase",
  //   component: () => import("@/views/accounting/purchase/Purchase.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Accounting",
  //         active: true,
  //       },
  //       {
  //         text: "Purchase",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/accounting/addpurchase",
  //   alias: "/accounting/editpurchase",
  //   name: "add-chequereturn",
  //   component: () =>
  //     import("@/views/accounting/purchase/AddPurchase.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Accounting",
  //         active: true,
  //       },
  //       {
  //         text: "Purchase",
  //         to: "/accounting/purchase",
  //       },
  //       {
  //         text: `${params ? "Edit Purchase" : "Add Purchase"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/accounting/ledger",
  //   name: "accounting-ledger",
  //   component: () => import("@/views/accounting/ledger/Ledger.vue"),
  //   meta: {
  //     breadcrumb:   [
  //       {
  //         text: "Accounting",
  //         active: true,
  //       },
  //       {
  //         text: "Ledger",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/ledger",
  //   name: "add-ledger",
  //   component: () => import("@/views/accounting/ledger/AddLedger.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Accounting",
  //         active: true,
  //       },
  //       {
  //         text: "Ledger",
  //         to: "/accounting/ledger",
  //       },
  //       {
  //         text: `${params ? "Edit Ledger" : "Add Ledger"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/accounting/pdclist",
  //   name: "accounting-pdclist",
  //   component: () => import("@/views/accounting/pdclist/PDCList.vue"),
  //   meta: {
  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: "ACCOUNTING",
  //         to: '/accounting',
  //       } : {
  //         text: "ACCOUNTING",
  //         active: true,
  //       },
  //       {
  //         text: "PDCList",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/pdclist",
  //   name: "add-pdclist",
  //   component: () => import("@/views/accounting/pdclist/AddPDCList.vue"),
  //   meta: {
  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: "ACCOUNTING",
  //         to: '/accounting',
  //       } : {
  //         text: "ACCOUNTING",
  //         active: true,
  //       },
  //       {
  //         text: "PDCList",
  //         to: "/accounting/pdclist",
  //       },
  //       {
  //         text: `${params ? "Edit PDCList" : "Add PDCList"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/accounting/landpurchase",
  //   name: "accounting-landpurchase",
  //   component: () => import("@/views/accounting/landpurchase/LandPurchase.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Accounting",
  //         active: true,
  //       },
  //       {
  //         text: "LandPurchase",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/landpurchase",
  //   name: "add-landpurchase",
  //   component: () =>
  //     import("@/views/accounting/landpurchase/AddLandPurchase.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Accounting",
  //         active: true,
  //       },
  //       {
  //         text: "LandPurchase",
  //         to: "/accounting/landpurchase",
  //       },
  //       {
  //         text: `${params ? "Edit LandPurchase" : "Add LandPurchase"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/accounting/landsales",
  //   name: "accounting-landsales",
  //   component: () => import("@/views/accounting/landsales/LandSales.vue"),
  //   meta: {
  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: "ACCOUNTING",
  //         to: '/accounting',
  //       } : {
  //         text: "ACCOUNTING",
  //         active: true,
  //       },
  //       {
  //         text: "LandSales",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/landsales",
  //   name: "add-landsales",
  //   component: () => import("@/views/accounting/landsales/AddLandSales.vue"),
  //   meta: {
  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: "ACCOUNTING",
  //         to: '/accounting',
  //       } : {
  //         text: "ACCOUNTING",
  //         active: true,
  //       },
  //       {
  //         text: "LandSales",
  //         to: "/accounting/landsales",
  //       },
  //       {
  //         text: `${params ? "Edit LandSales" : "Add LandSales"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/accounting/creditnote",
  //   name: "accounting-creditnote",
  //   component: () => import("@/views/accounting/creditnote/CreditNote.vue"),
  //   meta: {
  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: "ACCOUNTING",
  //         to: '/accounting',
  //       } : {
  //         text: "ACCOUNTING",
  //         active: true,
  //       },
  //       {
  //         text: "CreditNote",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/creditnote",
  //   name: "add-creditnote",
  //   component: () => import("@/views/accounting/creditnote/AddCreditNote.vue"),
  //   meta: {
  //     breadcrumb: [
  //       userRole != 'admin' ? {
  //         text: "ACCOUNTING",
  //         to: '/accounting',
  //       } : {
  //         text: "ACCOUNTING",
  //         active: true,
  //       },
  //       {
  //         text: "CreditNote",
  //         to: "/accounting/creditnote",
  //       },
  //       {
  //         text: `${params ? "Edit CreditNote" : "Add CreditNote"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
