<template>
  <div>

    <b-row class="row justify-content-between">
      <b-col md="4" class="mt-2" :style="type == 'admin' || type == 'sitemanager'  ? '' : 'display:none'">
        <label class="viewLabel"> Source Type :</label>
        <p class="viewValue">{{ data.sourcetype }}</p>
      </b-col>
      <b-col md="4" class="mt-2"  :style="sourcetype !== 'Company' ? '' : 'display:none'">
        <label class="viewLabel"> Source :</label>
        <p class="viewValue">{{ data.source ? (data.source.name + ' '+ data.source.surname) : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="type == 'admin' || type == 'sitemanager'">
        <label class="viewLabel"> Site Visit Manager :</label>
        <p class="viewValue">{{ data.manager ? (data.manager.name + ' ' + data.manager.surname) : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" :style="
      type == 'admin' || type == 'sitemanager' || type == 'siteassistant' ? '' : 'display:none'
    ">
        <label class="viewLabel"> Assistant :</label>
        <p class="viewValue">{{ data.assistant ? data.assistant.name : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Client Name :</label>
        <p class="viewValue">{{ data.clientname ? (data.clientname.name + ' '+ data.clientname.surname)  : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> No. Of Person :</label>
        <p class="viewValue">{{ data.noofperson ? data.noofperson : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Arrival Date :</label>
        <p class="viewValue">{{ data.arrivaldate ? data.arrivaldate : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Arrival Time :</label>
        <p class="viewValue">{{ data.arrivaltime ? data.arrivaltime : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Travel By :</label>
        <p class="viewValue">{{ data.travelby ? data.travelby : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Pickup Point :</label>
        <p class="viewValue">{{ data.pickuppoint ? data.pickuppoint : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Vehicle Required :</label>
        <p class="viewValue">{{ data.vehiclereq ? data.vehiclereq : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" :style="
      (type == 'admin' || type == 'sitemanager' || type == 'driver' )  && data.vehiclereq == 'Yes'
        ? ''
        : 'display:none'
    ">
        <label class="viewLabel"> Vehicle No. :</label>
        <p class="viewValue">{{ data.vehicleno ? data.vehicleno : '' }}</p>
      </b-col>

      <b-col md="4" class="mt-2" :style="
      data.vehicleno && data.vehiclereq == 'Yes' && data.vehicleno == 'Other'
        ? ''
        : 'display:none'
    "
  >
        <label class="viewLabel"> Remarks :</label>
        <p class="viewValue">{{ data.remarks ? data.remarks.vehicleRemarks : '' }}</p>
      </b-col>

      <b-col md="4" class="mt-2" :style="
      (type == 'admin' ||type == 'sitemanager'||  type == 'driver' )&& vehiclereq == 'Yes'
        ? ''
        : 'display:none'
    ">
        <label class="viewLabel"> Driver :</label>
        <p class="viewValue">{{ data.driver ? (data.driver.name + ' ' +  data.driver.surname) : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" :style="
      data.driver && data.vehiclereq == 'Yes' && data.driver.name == 'Other'
        ? ''
        : 'display:none'
    ">
        <label class="viewLabel"> Remarks :</label>
        <p class="viewValue">{{ data.remarks ? data.remarks.driverRemarks : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Site Visit Date :</label>
        <p class="viewValue">{{ data.visitdate ? data.visitdate : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Site Visit Time :</label>
        <p class="viewValue">{{ data.visittime ? data.visittime : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Stay :</label>
        <p class="viewValue">{{ data.isstay?'Yes':'No' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Departure Date :</label>
        <p class="viewValue">{{ data.departurdate ? data.departurdate : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Drop Time :</label>
        <p class="viewValue">{{ data.droptime ? data.droptime : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Drop Location :</label>
        <p class="viewValue">{{ data.droplocation ? data.droplocation : ' ' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" :style="data.isstay? data.isstay == 'Yes':'display:none'">
        <label class="viewLabel"> Accommodation Start Date :</label>
        <p class="viewValue">{{ data.accommodationstart ? data.accommodationstart : '' }}</p>
      </b-col>

      <b-col md="4" class="mt-2" :style="data.isstay? data.isstay == 'Yes':'display:none'">
        <label class="viewLabel"> Accommodation End Date:</label>
        <p class="viewValue">{{ data.accommodationend ? data.accommodationend : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" :style="data.isstay? data.isstay == 'Yes':'display:none'">
        <label class="viewLabel"> Stay Location:</label>
        <p class="viewValue">{{ data.stay ? data.stay : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" :style="data.isstay? data.isstay == 'Yes':'display:none'">
        <label class="viewLabel">{{selectedSalesByLabel}}:</label>
        <p class="viewValue">{{ data.guestmanager ? (data.guestmanager.name + ' ' + data.guestmanager.surname) : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Site Visit Project:</label>
        <p class="viewValue">{{ data.project ? data.project : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Remarks :</label>
        <p class="viewValue">{{ data.remarks ? data.remarks.remarks : '' }}</p>
      </b-col>
    </b-row>

    <b-row class="md-12">
      <b-col>
        <h3>Special Remarks</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-2">
        <div style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 25cm">
            <thead>
              <tr>
                <th style="font-size:11px !important;" class="col-md-1">SR. NO.</th>
                <th style="font-size:11px !important;" class="col-md-3">SPECIAL REMARKS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data.emergencyremarks" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ item.remarks }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <b-row class="md-12" style="margin-top:30px">
      <b-col>
        <h3>Person Details</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-2">
        <div style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 25cm">
            <thead>
              <tr>
                <th style="font-size:11px !important">SR. NO.</th>
                <th style="font-size:11px !important" class="col-md-4">NAME</th>
                <th style="font-size:11px !important" class="col-md-4">MOBILE NO. </th>
                <th style="font-size:11px !important" class="col-md-4">AGE</th>
                <th style="font-size:11px !important" class="col-md-4">GENDER</th>
                <th style="font-size:11px !important" class="col-md-4">CITY</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ data.person && data.person.length > 0 ? data.person[0].name : '' }}</td>
                <td>{{ data.person && data.person.length > 0 ? data.person[0].mobile : '' }}</td>
                <td>{{ data.person && data.person.length > 0 ? data.person[0].age : '' }}</td>
                <td>{{ data.person && data.person.length > 0 ? data.person[0].gender : '' }}</td>
                <td>{{ data.person && data.person.length > 0 ? data.person[0].city : '' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <b-row class="md-12" style="margin-top:30px">
      <b-col>
        <h3>Attachments</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="mt-2">
        <div style="width: 100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 25cm">
            <thead>
              <tr>
                <th style="font-size:11px !important">SR. NO.</th>
                <th style="font-size:11px !important" class="col-md-4">ATTACHMENT NAME </th>
                <th style="font-size:11px !important" class="col-md-4">ATTACHMENT </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data.attachment ? JSON.parse(data.attachment) : []" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>
                  <img :src="item.attachment" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import axios from "@/components/axios";
import Attachment from "@/components/Attechment.vue";
export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: {
    selectedID: {
      default: 0,
    },


  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: {},
      loginData: JSON.parse(localStorage.getItem("userData")),
      selectedSalesBy: "",
      selectedSalesByLabel: "",
      isstay:'',
      type:'',
      role:'',
      user:'',
      clientname:'',
      sourcetype: "",
      source: "",
    };
  },
  mounted() {
    this.getData();
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.role = this.user.role;
    this.type = this.user.type;
    if (this.role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Site Visit") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/sitevisit");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/sitevisit");
          }
        }
      });
    }
    if (this.role == "channelpartner") {
      this.sourcetype = "Channel Partner";

    }
    else if (this.role == 'franchise'){
      this.sourcetype = "Franchise";
    }


  },
  methods: {
    async getData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getsitevisitById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data)
            ? json.data.data[0]
            : json.data.data;
            this.selectedSalesByLabel = this.getLabelForSelection(this.data.stay);
            this.isstay = this.data.isstay ? this.data.isstay :'';
            this.clientname =this.data.clientname? this.data.clientname:'';
            this.sourcetype= this.data.sourcetype?this.data.sourcetype:'';
            this.sourcetype= this.data.sourcetype?this.data.sourcetype:'';
            this.source=this.data.source?this.data.source:'';
        })
        .catch((error) => console.log(error, "error"));

    },
    async getUsers(id){
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getusersbyid/${id}`,
      };

      await axios(requestOptionsUsers).then((response) => {
        this.source = response.data.data[0];
        if (this.source.role == "channelpartner") {
          this.sourcetype = "Channel Partner";
        } else if (this.source.role == "franchise") {
          this.sourcetype = "Franchise";
        } else if (this.source.role == "employee") {
          this.sourcetype = "Employee";
        } else if (this.source.role == "admin") {
          this.sourcetype = "Company";
        }
      });
    },
    getLabelForSelection(selection) {
      if (selection == "Hotel") {
        return "Hotel Name"
      } else if (selection === "Bunglow") {
        return "Bunglow Manager"
      } else if (selection === "Greentech") {
        return "Greentech Manager"
      } else if (selection === "Farm House") {
        return "Bunglow Manager"
      }
    },

  },
};
</script>
<style>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
