var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-transaction",staticStyle:{"cursor":"default"},attrs:{"no-body":""}},[_c('b-card-header',{staticStyle:{"position":"sticky !important","top":"0px","background":"white"}},[_c('b-card-title',[_vm._v("Site Visit")]),_c('b-form-input',{staticClass:"mt-1",attrs:{"placeholder":"Search"},on:{"input":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(JSON.stringify(_vm.search ? _vm.finalData : _vm.visitData) !== '[]')?_c('b-card-body',_vm._l((_vm.search ? _vm.finalData : _vm.visitData),function(item){return _c('div',{key:item.id,staticClass:"d-flex flex-column mt-1",class:_vm.clickedItem
          ? _vm.clickedItem == item.id
            ? 'bg-primary bg-lighten-1 text-white rounded'
            : ''
          : '',staticStyle:{"padding":"10px","border-radius":"10px","box-shadow":"0 4px 24px 0 rgba(34, 41, 47, 0.2)"},on:{"click":function($event){return _vm.handelClickItem(item)}}},[_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-link',[_c('b-img',{staticClass:"rounded-circle",attrs:{"src":item.source &&
                item.source.profile_image &&
                item.source.profile_image !== null
                  ? item.source.profile_image
                  : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg',"height":"40","width":"40"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title",class:_vm.clickedItem ? (_vm.clickedItem == item.id ? 'text-white' : '') : ''},[_vm._v(" Source : "),_c('span',{class:!(_vm.clickedItem == item.id)? 'text-primary' : 'font-weight-bold'},[_vm._v(" "+_vm._s(item.source ? item.source.name : "")+" "+_vm._s(item.source ? item.source.surname : "")+" ")])])])],1),_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-link',[_c('b-img',{staticClass:"rounded-circle",attrs:{"src":item.clientname &&
                item.clientname.profile_image &&
                item.clientname.profile_image !== null
                  ? item.clientname.profile_image
                  : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg',"height":"40","width":"40"}})],1)],1),_c('b-media-body',[(item.clientname)?_c('h6',{staticClass:"transaction-title",class:_vm.clickedItem ? (_vm.clickedItem == item.id ? 'text-white' : '') : ''},[_vm._v(" Client : "),_c('span',{class:!(_vm.clickedItem == item.id) ? 'text-success' : 'font-weight-bold'},[_vm._v(" "+_vm._s(item.clientname ? item.clientname.name : "")+" "+_vm._s(item.clientname ? item.clientname.surname : ""))])]):_vm._e()]),_c('small',[_vm._v(" "+_vm._s(item.arrivaldate))])],1),_c('div',{staticClass:"demo-inline-spacing"})],1)}),0):_c('b-card-body',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('h4',[_vm._v("NO DATA FOUND")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }