<template>
  <!-- <div style="background-color:rgb(189, 0, 0);" class="main d-flex justify-content-start">
            <div style="background-color:rgb(255, 254, 254); width:66.2%;margin-top: 4px;display: flex;justify-content: center;height:555px"> -->
  <div
    class="main d-flex justify-content-start flex-wrap position-relative"
    v-if="receiptData && receiptData.id"
  >
    <div class="innerMain">
      <span class="cancel-receipt" v-if="cancel"> CANCEL </span>
      <div class="receipt">
        <div class="headerClass">
          <img
            class="w-100 h-100"
            v-if="
              receiptData && receiptData.project && receiptData.project.receipt_header
            "
            :src="receiptData.project.receipt_header"
            onerror='this.src="../../../receiptImages/default_header.png"'
          />
          <img v-else src="../../../receiptImages/default_header.png" />
        </div>
        <div class="receiptTable position-relative">
          <table
            class="mainTable"
            style="font-weight: bolder; font-size: 10px"
            width="100%"
          >
            <tr class="tr">
              <td class="td">Receipt No.</td>
              <td class="td" style="font-weight: bolder; color: black" colspan="6">
                {{ receiptData ? receiptData.receiptNo : "" }}
              </td>
              <td class="td">Date</td>
              <td class="td" style="font-weight: bolder; color: black">
                {{
                  receiptData
                    ? moment(
                        receiptData.approved1datetime
                          ? receiptData.approved1datetime
                          : new Date()
                      ).format("DD/MM/yyyy")
                    : ""
                }}
              </td>
            </tr>
            <tr class="tr">
              <td class="td">Mr. /Mrs./Ms.</td>
              <td class="td" style="font-weight: bolder; color: black" colspan="8">
                {{
                  receiptData && receiptData.clientname
                    ? receiptData.clientname.fullname
                    : ""
                }}
                <span
                  v-for="(item, index) in receiptData
                    ? receiptData.extra_customer_data
                    : []"
                  :key="index"
                >
                  {{
                    index + 1 == receiptData.extra_customer_data.length ? " and " : ", "
                  }}
                  {{ item.fullname }}
                </span>
              </td>
            </tr>
            <tr class="tr">
              <td class="td">Received Amt.</td>
              <td class="td" style="font-weight: bolder; color: black" colspan="8">
                {{ receiptData ? receiptData.amountinwords : "" }}
              </td>
            </tr>
            <tr class="tr">
              <td class="td">Project Name</td>
              <td class="td" colspan="8" style="font-weight: bolder; color: black">
                {{
                  receiptData && receiptData.project
                    ? receiptData.project.projectname
                    : ""
                }}
              </td>
            </tr>

            <tr class="tr" v-if="receiptData && receiptData.receipt_items.length == 1">
              <td
                class="td"
                :rowspan="receiptData ? receiptData.receipt_items.length + 1 : 1"
                style="align-item: center"
              >
                Suggested Plot & Payment Details
              </td>
              <td class="td" colspan="4">Suggested Plot No</td>
              <td class="td" colspan="6">Amount (Rs.)</td>
            </tr>
            <tr
              class="tr"
              v-if="receiptData && receiptData.receipt_items.length == 1"
              v-for="(item, index) in receiptData ? receiptData.receipt_items : []"
              :key="index"
            >
              <td class="td" colspan="4" style="font-weight: bolder; color: black">
                {{ item.plot_data && item.plot_data ? item.plot_data.plotno : "" }}
              </td>
              <td class="td" style="font-weight: bolder; color: black" colspan="6">
                {{ item.amount ? parseFloat(item.amount).toLocaleString("en-In") : 0 }}
              </td>
            </tr>

            <tr class="tr" v-if="receiptData && receiptData.receipt_items.length > 1">
              <td class="td" style="align-item: center">
                Suggested Plot & Payment Details
              </td>
              <td class="td" colspan="8" style="font-weight: bolder; color: black">
                <div class="d-flex flex-wrap" v-if="receiptData.paymenttype == 'Token'">
                  <div
                    style="margin-right: 10px"
                    v-for="(item, index) in receiptData.receipt_items"
                    :key="index"
                  >
                    {{ item.plot_data.plotno }}
                    <span v-if="receiptData.receipt_items.length !== index + 1">, </span>
                  </div>
                </div>
                <div v-else class="d-flex flex-wrap">
                  <div
                    style="margin-right: 10px"
                    v-for="(item, index) in receiptData.receipt_items"
                    :key="index"
                  >
                    {{ item.plot_data.plotno }} ( {{ item.amount }} ₹ )
                    <span v-if="receiptData.receipt_items.length !== index + 1">, </span>
                  </div>
                </div>
              </td>
            </tr>

            <tr class="tr">
              <td class="td">Booking Date</td>
              <td class="td" style="font-weight: bolder; color: black" colspan="6">
                {{
                  receiptData
                    ? receiptData.receipt_items[0].sales_data.bookingdate
                        .split("-")
                        .reverse()
                        .join("/")
                    : ""
                }}
              </td>
              <td class="td">Payment Type</td>
              <td class="td" style="font-weight: bolder; color: black">
                {{ receiptData ? receiptData.paymenttype : "" }}
              </td>
            </tr>
            <tr class="tr">
              <td class="td">Address</td>
              <td class="td" style="font-weight: bolder; color: black" colspan="8">
                {{
                  receiptData && receiptData.clientname && receiptData.clientname.curarea
                    ? receiptData.clientname.curarea
                    : ""
                }}
                {{
                  receiptData && receiptData.clientname && receiptData.clientname.curcity
                    ? "," + receiptData.clientname.curcity
                    : ""
                }}
                {{
                  receiptData && receiptData.clientname && receiptData.clientname.curarea
                    ? "," + receiptData.clientname.curarea
                    : ""
                }}
                {{
                  receiptData &&
                  receiptData.clientname &&
                  receiptData.clientname.curpincode
                    ? "," + receiptData.clientname.curpincode
                    : ""
                }}
                {{
                  receiptData && receiptData.clientname && receiptData.clientname.curstate
                    ? "," + receiptData.clientname.curstate
                    : ""
                }}
              </td>
            </tr>

            <tr class="tr">
              <td class="td">Payment By</td>
              <td class="td" colspan="4">Payment Date</td>
              <td class="td">Bank</td>
              <td class="td">Transaction No.</td>
              <td class="td">Branch</td>
              <td class="td">Amount</td>
            </tr>
            <tr class="tr">
              <td class="td" style="font-weight: bolder; color: black">
                {{ receiptData ? receiptData.transactiontype : "" }} - ({{
                  receiptData ? receiptData.ledgername.name : ""
                }})
              </td>
              <td class="td" style="font-weight: bolder; color: black" colspan="4">
                {{ receiptData ? receiptData.transaction_date : "" }}
              </td>
              <td class="td" style="font-weight: bolder; color: black">
                {{ receiptData ? receiptData.bankname : "" }}
              </td>
              <td class="td" style="font-weight: bolder; color: black">
                {{
                  receiptData
                    ? receiptData.transactiontype == "By Cheque"
                      ? receiptData.chequeno
                      : receiptData.utrno
                      ? receiptData.utrno
                      : receiptData.refno
                    : ""
                }}
              </td>
              <td class="td" style="font-weight: bolder; color: black">
                {{ receiptData ? receiptData.branchname : "" }}
              </td>
              <td class="td" style="font-weight: bolder; color: black">
                {{
                  receiptData ? parseFloat(receiptData.amount).toLocaleString("en-In") : 0
                }}
                /-
              </td>
            </tr>
          </table>

          <div class="background-logo">
            <img
              class="w-100 h-100"
              v-if="
                receiptData &&
                receiptData.project &&
                receiptData.project.receipt_background_logo
              "
              :src="receiptData.project.receipt_background_logo"
              onerror='this.src="../../../receiptImages/default_logo.png"'
            />
            <img
              class="w-100 h-100"
              v-else-if="
                receiptData &&
                receiptData.project &&
                !receiptData.project.receipt_footer &&
                !receiptData.project.receipt_header
              "
              src="../../../receiptImages/default_logo.png"
            />
          </div>
        </div>
        <div class="foo">
          <img
            class="w-100 h-100"
            v-if="
              receiptData && receiptData.project && receiptData.project.receipt_footer
            "
            :src="receiptData.project.receipt_footer"
            onerror='this.src="../../../receiptImages/default_footer.png"'
          />
          <img v-else src="../../../receiptImages/default_footer.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { BRow, BCol } from "bootstrap-vue";
export default {
  props: ["receiptData"],
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      moment,
      array: [],
      cancel: false,
    };
  },
};
</script>

<style>
.mainfoo {
  width: 100%;
}

.childfoo {
  /* width:100%; */
  height: 35px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: space-between;
  font-size: 15px;
  border-radius: 30px 0px 0px 0px;
  background-image: radial-gradient(circle, #2aa450, #009075, #00798a, #005f88, #074470);
}

.main {
  width: 100%;
  /* margin: 15px 0; */
  display: flex;
  justify-content: center;
}

.header {
  width: 100%;
  display: flex;
  padding: 10px 5px;
}

.receipt {
  width: 100%;
  position: relative;
  /* height: 700px; */
  /* margin:  15px auto; */
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

.address {
  font-size: 12px;
  font-weight: 600;
}

.headerlogo {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.receiptBody {
  width: 80%;
  height: 300px;
  border: 1px solid gray;
  margin: 10px auto;
}

.receiptTable {
  border: 1px solid gray;
  margin: 3px 15px;
  justify-content: center;
}
.tr {
  height: 25px;
}

.td {
  padding-left: 13px;
}

.mainTable {
  border-collapse: collapse;
}

.RulesDiv {
  font-size: 10px;
  font-weight: bolder;
  margin-left: 10px;
  width: 70%;
  color: black;
}

.signBox {
  border-radius: 7px;
  border: 1px solid #6d6357;
  width: 150px;
  height: 70px;
}

.foo {
  justify-content: space-between;
  display: flex;
  width: 800px;
  flex-wrap: wrap;
}

.rule {
  padding: 0;
  margin: 0;
  height: 15px;
}

.mainTable th,
.mainTable td {
  border: 1px solid rgb(92, 92, 92);
}

.payment {
  width: 21%;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  background-image: radial-gradient(circle, #2aa450, #009075, #00798a, #005f88, #074470);
  border-radius: 4px;
  padding: 4px 5px;
  position: relative;
}
.borderImg {
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
}

p.MsoTitle1,
li.MsoTitle1,
div.MsoTitle1 {
  font-size: 11pt;
  font-family: "Arial", sans-serif;
  font-weight: bold;
}

.headerClass {
  /* padding-left: 44px; */
  padding-bottom: 2px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.innerMain {
  padding-top: 2px;
  width: 800px;
  display: flex;
  justify-content: center;
  height: 14.7cm;
  position: relative;
}

.receipt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tollFree {
  font-size: 15px;
  color: rgb(4, 4, 145);
}
.companyName {
  color: #0a4570;
  font-size: 34px;
}
.cancel-receipt {
  font-size: 177px;
  position: absolute;
  z-index: 100000;
  top: 137px;
  right: 38px;
  opacity: 0.5;
  color: red;
  font-weight: bold;
  transform: rotate(-27deg);
}

.background-logo {
  position: absolute;
  width: 25%;
  top: 50%;
  z-index: -1;
  opacity: 0.3;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
