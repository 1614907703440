// const params = new URLSearchParams(window.location.search).has('id')
export default [
  {
    path: '/setup/users',
    name: 'setup-users',
    component: () => import('@/views/setup/users/Users.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/addissuelog',
    name: 'setup-addissuelog',
    component: () => import('@/views/setup/issuelog/AddIssuelog.vue'),
    meta: {
      pageTitle: 'Add Issue Log',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Issue Log',
          to: '/setup/issuelog'
        },
        {
          text: 'Add Issue Log',
          active: true
        },
      ]
    }
  },
  {
    path: '/setup/issuelog',
    name: 'setup-issuelog',
    component: () => import('@/views/setup/issuelog/Issuelog.vue'),
    meta: {
      pageTitle: 'Issue Log',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Issue Log',
          active: true
        },
      ]
    }
  },
  {
    path: '/setup/addusers',
    name: 'add-users',
    component: () => import('@/views/setup/users/AddUsers.vue'),
    meta: {
      pageTitle: 'Add Users',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Users',
          to: '/setup/users'
        },
        {
          text: 'Add Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/editusers/:id',
    name: 'edit-users',
    component: () => import('@/views/setup/users/AddUsers.vue'),
    meta: {
      pageTitle: 'Edit Users',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Users',
          to: '/setup/users'
        },
        {
          text: 'Edit Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/position',
    name: 'setup-position',
    component: () => import('@/views/setup/position/Position.vue'),
    meta: {
      pageTitle: 'Position',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Position',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addposition',
  // //   alias: '/setup/editposition',
  // //   name: 'add-position',
  // //   component: () => import('@/views/setup/position/AddPosition.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Position',
  // //         to: '/setup/position'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Position' : 'Add Position'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/department',
    name: 'setup-department',
    component: () => import('@/views/setup/department/Department.vue'),
    meta: {
      pageTitle: 'Department',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Department',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/adddepartment',
  // //   alias: '/setup/editdepartment',
  // //   name: 'add-department',
  // //   component: () => import('@/views/setup/department/AddDepartment.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Department',
  // //         to: '/setup/department'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Department' : 'Add Department'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup',
    name: 'setup',
    component: () => import('@/views/setup/Setup.vue'),
    meta: {
      pageTitle: 'Setup',
      breadcrumb: [
        {
          text: 'Setup',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/softwares',
    name: 'setup-softwares',
    component: () => import('@/views/setup/softwares/Softwares.vue'),
    meta: {
      pageTitle: 'Softwares',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Softwares',
          active: true
        }
      ]
    }
  },
  // {
  //   path: '/setup/addsoftwares',
  //   alias: '/setup/editsoftwares',
  //   name: 'add-softwares',
  //   component: () => import('@/views/setup/softwares/AddSoftwares.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Setup',
  //         to: '/setup'
  //       },
  //       {
  //         text: 'Softwares',
  //         to: '/setup/softwares'
  //       },
  //       {
  //         text: `${params ? 'Edit Softwares' : 'Add Softwares'}`,
  //         active: true
  //       }
  //     ]
  //   }
  // },
  {
    path: '/setup/modules',
    name: 'setup-modules',
    component: () => import('@/views/setup/modules/Modules.vue'),
    meta: {
      pageTitle: 'Modules',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Modules',
          active: true
        }
      ]
    }
  },
  // {
  //   path: '/setup/addmodules',
  //   alias: '/setup/editmodules',
  //   name: 'add-modules',
  //   component: () => import('@/views/setup/modules/AddModules.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Setup',
  //         to: '/setup'
  //       },
  //       {
  //         text: 'Modules',
  //         to: '/setup/modules'
  //       },
  //       {
  //         text: `${params ? 'Edit Modules' : 'Add Modules'}`,
  //         active: true
  //       }
  //     ]
  //   }
  // },
  {
    path: '/setup/language',
    name: 'setup-language',
    component: () => import('@/views/setup/language/Language.vue'),
    meta: {
      pageTitle: 'Languages',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Language',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addlanguage',
  // //   alias: '/setup/editlanguage',
  // //   name: 'add-language',
  // //   component: () => import('@/views/setup/language/AddLanguage.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Language',
  // //         to: '/setup/language'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Language' : 'Add Language'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/widgets',
    name: 'setup-widgets',
    component: () => import('@/views/setup/widgets/Widgets.vue'),
    meta: {
      pageTitle: 'Widgets',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Widgets',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addwidgets',
  // //   alias: '/setup/editwidgets',
  // //   name: 'add-widgets',
  // //   component: () => import('@/views/setup/widgets/AddWidgets.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Widgets',
  // //         to: '/setup/widgets'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Widgets' : 'Add Widgets'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/settings',
    name: 'setup-settings',
    component: () => import('@/views/setup/settings/Settings.vue'),
    meta: {
      pageTitle: 'Settings',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Settings',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addsettings',
  // //   alias: '/setup/editsettings',
  // //   name: 'add-settings',
  // //   component: () => import('@/views/setup/settings/AddSettings.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Settings',
  // //         to: '/setup/settings'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Settings' : 'Add Settings'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/approval',
    name: 'setup-approval',
    component: () => import('@/views/setup/approval/Approval.vue'),
    meta: {
      pageTitle: 'Approval',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Approval',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addapproval',
  // //   alias: '/setup/editapproval',
  // //   name: 'add-approval',
  // //   component: () => import('@/views/setup/approval/AddApproval.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Approval',
  // //         to: '/setup/approval'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Approval' : 'Add Approval'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/reminder',
    name: 'setup-reminder',
    component: () => import('@/views/setup/reminder/Reminder.vue'),
    meta: {
      pageTitle: 'Reminder',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Reminder',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addreminder',
  // //   alias: '/setup/editreminder',
  // //   name: 'add-reminder',
  // //   component: () => import('@/views/setup/reminder/AddReminder.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Reminder',
  // //         to: '/setup/reminder'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Reminder' : 'Add Reminder'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/assign-rights',
    name: 'setup-assign-rights',
    component: () => import('@/views/setup/AssignRights.vue'),
    meta: {
      pageTitle: 'Assign Rights',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Assign Rights',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/fixed-ledger',
    name: 'setup-fixedledger',
    component: () => import('@/views/setup/fixedledger/FixedLedger.vue'),
    meta: {
      pageTitle: 'Fixed Ledger',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'FixedLedger',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addfixedledger',
  // //   alias: '/setup/editfixedledger',
  // //   name: 'add-fixedledger',
  // //   component: () => import('@/views/setup/fixedledger/AddFixedLedger.vue'),
  // //   meta: {
  // //     pageTitle: `${params ? 'Edit FixedLedger' : 'Add FixedLedger'}`,

  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'FixedLedger',
  // //         to: '/setup/fixed-ledger'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit FixedLedger' : 'Add FixedLedger'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/company',
    name: 'setup-company',
    component: () => import('@/views/setup/company/Company.vue'),
    meta: {
      pageTitle: 'Company',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Company',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/addcompany',
    alias: '/setup/editcompany',
    name: 'add-company',
    component: () => import('@/views/setup/company/AddCompany.vue'),
    meta: {
      pageTitle: 'Add Company',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Company',
          to: '/setup/company'
        },
        {
          text: 'Add Company',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/editcompany/:id',
    name: 'edit-company',
    component: () => import('@/views/setup/company/AddCompany.vue'),
    meta: {
      pageTitle: 'Edit Company',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Company',
          to: '/setup/company'
        },
        {
          text: 'Edit Company',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/branch',
    name: 'setup-branch',
    component: () => import('@/views/setup/branch/Branch.vue'),
    meta: {
      pageTitle: 'Branch',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Branch',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addbranch',
  // //   alias: '/setup/editbranch',
  // //   name: 'add-branch',
  // //   component: () => import('@/views/setup/branch/AddBranch.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Branch',
  // //         to: '/setup/branch'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Branch' : 'Add Branch'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/cron',
    name: 'setup-cron',
    component: () => import('@/views/setup/cron/Cron.vue'),
    meta: {
      pageTitle: 'Cron',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Cron',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/validations',
    name: 'setup-validations',
    component: () => import('@/views/setup/validations/Validations.vue'),
    meta: {
      pageTitle: 'Validation',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Validations',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/import-export',
  // //   name: 'setup-import-export',
  // //   component: () => import('@/views/setup/import-export/ImportExport.vue'),
  // //   meta: {
  // //     pageTitle: 'Import-Export',
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Import-Export',
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/backup-restore',
    name: 'setup-backup-restore',
    component: () => import('@/views/setup/backup-restore/BackupRestore.vue'),
    meta: {
      pageTitle: 'Backup/Restore',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Backup-Restore',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/template',
    name: 'setup-template',
    component: () => import('@/views/setup/template/Template.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Template',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addtemplate',
  // //   name: 'setup-addtemplate',
  // //   component: () => import('@/views/setup/template/AddTemplate.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Add Template',
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/ledger-voucher',
    name: 'setup-ledgervoucher',
    component: () => import('@/views/setup/ledgervoucher/LedgerVoucher.vue'),
    meta: {
      pageTitle: 'Ledger Voucher',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Ledger Voucher',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/addledgervoucher',
    alias: '/setup/editledgervoucher',
    name: 'edit-ledgervoucher',
    component: () => import('@/views/setup/ledgervoucher/AddLedgerVoucher.vue'),
    meta: {
      pageTitle: 'Add Ledger Voucher',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Ledger Voucher',
          to: '/setup/ledger-voucher'
        },
        {
          text: 'Add Ledger Voucher',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/editledgervoucher',
    name: 'add-ledgervoucher',
    component: () => import('@/views/setup/ledgervoucher/AddLedgerVoucher.vue'),
    meta: {
      pageTitle: 'Edit Ledger Voucher',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Ledger Voucher',
          to: '/setup/ledger-voucher'
        },
        {
          text: 'Edit Ledger Voucher',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/stock',
  // //   name: 'setup-stock',
  // //   component: () => import('@/views/setup/stock/Stock.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Stock',
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  // // {
  // //   path: '/setup/addstock',
  // //   alias: '/setup/editstock',
  // //   name: 'add-stock',
  // //   component: () => import('@/views/setup/stock/AddStock.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Stock',
  // //         to: '/setup/stock'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Stock' : 'Add Stock'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/serial-no',
    name: 'setup-serialno',
    component: () => import('@/views/setup/serialno/SerialNo.vue'),
    meta: {
      pageTitle: 'Serial No',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Serial No',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addserialno',
  // //   alias: '/setup/editserialno',
  // //   name: 'add-serialno',
  // //   component: () => import('@/views/setup/serialno/AddSerialNo.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Serial No',
  // //         to: '/setup/serial-no'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Serial No' : 'Add Serial No'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/ledger-mapping',
    name: 'setup-ledgermapping',
    component: () => import('@/views/setup/ledgermapping/LedgerMapping.vue'),
    meta: {
      pageTitle: 'Ledger Mapping',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Ledger Mapping',
          active: true
        }
      ]
    }
  },
  // // {
  // //   path: '/setup/addledgermapping',
  // //   alias: '/setup/editledgermapping',
  // //   name: 'add-ledgermapping',
  // //   component: () => import('@/views/setup/ledgermapping/AddLedgerMapping.vue'),
  // //   meta: {
  // //     breadcrumb: [
  // //       {
  // //         text: 'Setup',
  // //         to: '/setup'
  // //       },
  // //       {
  // //         text: 'Ledger Mapping',
  // //         to: '/setup/ledger-mapping'
  // //       },
  // //       {
  // //         text: `${params ? 'Edit Ledger Mapping' : 'Add Ledger Mapping'}`,
  // //         active: true
  // //       }
  // //     ]
  // //   }
  // // },
  {
    path: '/setup/banner',
    name: 'banner',

    component: () => import('@/views/setup/banner/Banner.vue'),
    meta: {
      pageTitle: 'Banner',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Banner',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/addbanner',
    alias: '/setup/editbanner',
    name: 'add-banner',
    component: () => import('@/views/setup/banner/AddBanner.vue'),
    meta: {
      pageTitle: 'Add Banner',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Banner',
          active: true
        },
        {
          text: 'Add Banner',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/editbanner/:id',
    name: 'edit-banner',
    component: () => import('@/views/setup/banner/AddBanner.vue'),
    meta: {
      pageTitle: 'Edit Banner',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Banner',
          active: true
        },
        {
          text: 'Edit Banner',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/offer',
    name: 'offer',

    component: () => import('@/views/setup/offer/Offer.vue'),
    meta: {
      pageTitle: 'Offer',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Offer',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/addoffer',
    alias: '/setup/editoffer',
    name: 'add-offer',
    component: () => import('@/views/setup/offer/AddOffer.vue'),
    meta: {
      pageTitle: 'Add Offer',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Offer',
          to: '/setup/offer'
        },
        {
          text: 'Add Offer',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/editoffer/:id',
    name: 'edit-offer',
    component: () => import('@/views/setup/offer/AddOffer.vue'),
    meta: {
      pageTitle: 'Edit Offer',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Offer',
          to: '/setup/offer'
        },
        {
          text: 'Edit Offer',
          active: true
        }
      ]
    }
  },
  {
    path: '/setup/kyc',
    name: 'add-kyc',
    component: () => import('@/views/setup/kyc/Kyc.vue'),
    meta: {
      pageTitle: 'KYC',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'KYC',
          active: true
        },
      ]
    }
  },
  {
    path: '/setup/trash',
    name: 'trash',
    component: () => import('@/views/setup/Trash.vue'),
    meta: {
      pageTitle: 'Trash',

      breadcrumb: [
        {
          text: 'Setup',
          to: '/setup'
        },
        {
          text: 'Trash',
          active: true
        },
      ]
    }
  }
]
