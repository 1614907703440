export default [
  {
    path: '/followup/followupinfo',
    name: 'followup-followupinfo',
    component: () => import('@/views/followup/FollowupInfo.vue'),
    meta: {
      pageTitle: 'Followup',
      breadcrumb: [
        {
          text: 'DASHBOARD',
          to: '/',
        },
        {
          text: 'Followup',
          active: true
        }
      ]
    }
  }
]
