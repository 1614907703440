const params = new URLSearchParams(window.location.search).has('id')
export default [
  {
    path: '/inventory/purchasebill',
    name: 'inventory-purchasebill',
    component: () => import('@/views/inventory/purchasebill/PurchaseBill.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'inventory',
          active:true
        },
        {
          text: 'Purchase Bill',
          active: true
        }
      ]
    }
  },
  {
    path: '/inventory/addpurchasebill',
    alias: '/inventory/editpurchasebill',
    name: 'add-purchasebill',
    component: () => import('@/views/inventory/purchasebill/AddPurchaseBill.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'inventory',
          active:true
        },
        {
          text: 'Purchase Bill',
          to:'/inventory/purchasebill'
        },
        {
          text: `${params ? 'Edit Purchase Bill' : 'Add Purchase Bill'}`,
          active:true
        }
      ]
    }
  },
  {
    path: '/inventory/purchaseorder',
    name: 'inventory-purchaseorder',
    component: () => import('@/views/inventory/purchaseorder/PurchaseOrder.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'inventory',
          active:true
        },
        {
          text: 'Purchase Order',
          active: true
        }
      ]
    }
  },
  {
    path: '/inventory/addpurchaseorder',
    alias: '/inventory/editpurchaseorder',
    name: 'add-purchaseorder',
    component: () => import('@/views/inventory/purchaseorder/AddPurchaseOrder.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'inventory',
          active:true
        },
        {
          text: 'Purchase Order',
          to:'/inventory/purchaseorder'
        },
        {
          text: `${params ? 'Edit Purchase Order' : 'Add Purchase Order'}`,
          active:true
        }
      ]
    }
  },
  {
    path: '/inventory/materialinward',
    name: 'inventory-materialinward',
    component: () => import('@/views/inventory/materialinward/MaterialInward.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'inventory',
          active:true
        },
        {
          text: 'MaterialInward',
          active: true
        }
      ]
    }
  },
  {
    path: '/inventory/addmaterialinward',
    alias: '/inventory/editmaterialinward',
    name: 'add-materialinward',
    component: () => import('@/views/inventory/materialinward/AddMaterialInward.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'inventory',
          active:true
        },
        {
          text: 'MaterialInward',
          to:'/inventory/materialinward'
        },
        {
          text: `${params ? 'Edit MaterialInward' : 'Add MaterialInward'}`,
          active:true
        }
      ]
    }
  }
]
