<template>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label>Employee Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Employee Name"
              >
                <v-select
                  v-model="employee"
                  label="fullname"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="employeeOption"
                >
                <template #option="{ fullname, profile_image, username }">
                  <b-avatar :src="getprofileImage(profile_image)"
                    />
                  <span class="font-weight-bolder"> {{fullname }} </span>
                  <span>({{username
                       }})</span>
                </template>
              </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Description</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Description"
              >
                <b-form-textarea
                v-model="description"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter description"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Attachment</label>
              <b-form-file
                v-model="attachment"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'request','attachment')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="attachment" />
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              class="mr-4"
              :disabled="flag"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import attachment from '@/components/Attechment.vue'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormFile,
  BAvatar
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';
import moment from "moment";

export default {
  props: ["closepopup", "getGridData", "selectedID"],

  components: {
    attachment,
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BFormFile,
  BAvatar

  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      employee: "",
      employeeOption: [],
      description: "",
      ifEdit: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      id: "",
      flag: false,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      attachment:''
    }
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Request") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.closepopup()
            }
          } else if (item.add !== 1) {
              this.closepopup()

          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.selectedID;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== '') {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image
        return test
      }
      return image
    },
    async handleChangeFile(e, type, name) {
      this.flag = true;
      const vue=this
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions).then((response) => {
        vue[name] =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        this.flag = false;
      });
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/requestById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.employee = item.employee;
        this.description = item.description;
        this.attachment = item.attachment;
      });
    },
    init() {
      this.getUser();
    },
    async getUser() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.employeeOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.closepopup()
    },
    submitForm(e) {
      const data = {
        employee: this.employee.id,
        description: this.description,
        attachment: this.attachment,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all the fields",
            icon: "warning",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/request/${this.selectedID}`
              : `${baseApi}/request`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.flag = false;
              this.closepopup()
              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
