<template>
  <b-card :class="'text-center cursor-pointer leaddetails border-'+color" :style="`width:120px;height:120px;border-radius: 50%;border-width:4px !important`">
    <!-- <b-avatar class="mb-1" :variant="color" size="35">
      <feather-icon size="17" :icon="icon" />
    </b-avatar> -->
    <div class="truncate d-flex align-items-center justify-content-around flex-column">
      <h1 :class="'mb-25 font-weight-bolder text-'+color" >
        {{ statistic }}
      </h1>
      <h5>{{ statisticTitle }}</h5>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      // required: true,
      default: "",
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    route: {
      type: String,
      default: "",
    },
  },
  data(){
    return{
      dynamicColor:''
    }
  },
  mounted(){
    if(this.color == 'success'){
      this.dynamicColor='#28c76f'
    }
    if(this.color == 'danger'){
      this.dynamicColor='#ea5455'
    }
    if(this.color == 'primary'){
      this.dynamicColor='#7367f0'
    }
    if(this.color == 'secondary'){
      this.dynamicColor='#B4B9BF'
    }
    if(this.color == 'warning'){
      this.dynamicColor='#ff9f43'
    }
    if(this.color == 'info'){
      this.dynamicColor='#00cfe8'
    }
  }
};
</script>
<style scop>
.leaddetails .card-body{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
