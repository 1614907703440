<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent="submitForm">
      <b-row>
        <b-col md="6" v-if="displayType">
          <b-form-group>
            <label>From Type</label>
            <v-select
              v-model="fromtype"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['Unassign','Channelpartner', 'Employee','All']"
              placeholder="None"
              label="name"
              @input="handletype($event, 'from')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="fromtype!='Unassign'">
          <b-form-group>
            <label>From Transfer</label>
            <v-select
              v-model="oldemployee"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="allEmployeeOption"
              placeholder="None"
              label="fullname"
              @input="handleLeadCategory"
            >
              <template #option="{ profile_image, username, fullname }">
                <b-avatar
                  :src="getprofileImage(profile_image)"

                />
                <span class="font-weight-bolder"> {{ fullname}}</span
                ><span>({{ username }})</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="assigntoType">
          <b-form-group>
            <label>To Type</label>
            <v-select
              v-model="totype"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['Channelpartner', 'Employee','All']"
              placeholder="None"
              label="name"
              @input="handletype($event, 'to')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label>To Transfer</label>
            <v-select
              v-model="newemployee"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="employeeOption"
              placeholder="None"
              label="fullname"
            >
              <template #option="{ fullname, profile_image, username}">
                <b-avatar
                  :src="getprofileImage(profile_image)"

                />
                <span class="font-weight-bolder"> {{ fullname}}</span
                ><span>({{ username }})</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
          <b-col md="6">
          <b-form-group>
            <label>Lead Category</label>
            <v-select
              multiple
              :class="all ? 'overflow-auto' : ''"
              :style="all ? 'max-height:500px' : ''"
              v-model="category"
              :options="categoryOption"
              placeholder="None"
              label="name"
              :close-on-select="false"
              @input="handleLeadCategory"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label>Lead Name</label>
            <v-select
              multiple
              :class="all ? 'overflow-auto' : ''"
              :style="all ? 'max-height:500px' : ''"
              v-model="leads"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="leadOptions"
              placeholder="None"
              label="fullname"
              :close-on-select="false"
              @input="LeadsAll"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label>Transfer Remarks</label>
            <b-form-input
              v-model="transferremarks"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Enter Remarks"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            variant="primary"
            type="submit"
            @click.prevent="submitForm"
            class="mr-4"
            :disabled="submitDisable"
          >
            Submit
          </b-button>
          <b-button variant="primary" @click="onClickBack">Back </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import { CloudLightningIcon } from 'vue-feather-icons';

export default {
  props: ["closepopup"],
  components: {
    PinchScrollZoom,
    flatPickr,
    Datepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BAvatar,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      employeeOption: [],
      allEmployeeOption: [],
      leadOptions: [],
      oldemployee: "",
      newemployee: "",
      leads: [],
      lead_id: [],
      submitDisable: false,
      transferremarks: "",
      fromtype: "",
      totype: "",
      displayType: false,
      all: false,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      assignedUser:[],
      assigntoType:false,
      category:[],
      categoryOption:[],
    };
  },
  mounted() {
    this.userData = this.$store.state.app.user_data
    if (this.userData && this.userData.type == "leadmanager" || this.userData.type == "admin") {
      if (this.userData.leadaccess == "Channelpartner") {
        this.getcp();
      }
      if (this.userData.leadaccess == "Employee") {
        this.getteam();
      }
      if (this.userData.leadaccess == "Both" || this.userData.type == "admin") {
        this.displayType = true;
      }
    } else {
      this.setAssignto();
      if(this.userData.role == "employee" &&this.userData.type != "leadmanager" ){
        this.allEmployeeOption = this.$store.state.app.all_users.filter(item => item.assignto == this.$store.state.app.user_data.id)
        this.allEmployeeOption.push(this.$store.state.app.user_data)
      }
      else{
        this.getEmployee();
      }
    }

    if(this.userData.type != 'franchise'){
      this.assigntoType = true
    }
    this.getAllCategory()
  },
  watch:{
    '$store.state.app.user_data':{
      immediate:true,
      handler:'setAssignto',
      deep:true
      }
  },
  methods: {
    setAssignto(){
    this.userData = this.$store.state.app.user_data
        if(this.userData.type=='channelpartner'){
          this.assignedUser = this.userData.assignto
        }
    },
    async getAllCategory(){
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/all-leadcategory`,
      }).then((res) => {
        this.categoryOption = res.data.data
        this.categoryOption.unshift({name:'All',id:0})
      });
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath)
          ? image
          : this.imagePath + image;
        return test;
      }
      return image;
    },
    handletype(e, type) {
      if (e) {
        if (e == "Channelpartner") {
          this.getcp(type);
        } else if (e == "Employee") {
          this.getteam(type);
        } else if(e == 'All'){
          this.allUsers(type);
        } else{
          this.getUnassignlead();
        }
      }
    },
    LeadsAll(e) {
      if(e){
        let all = e.findIndex(item => item.fullname == 'All')
        this.leads = all!=-1 ? this.leadOptions.slice(1) :this.leads
        this.$forceUpdate();
      }
      else{
        this.leads=[]
      }
    },
    async getUnassignlead(){
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/get-unassignleads`,
      }).then((res) => {
        this.leadOptions = res.data.data
      });
    },
    async allUsers(type) {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUsers`,
      }).then((res) => {
        if (!type || type == "from") {
          this.allEmployeeOption = res.data.data;
        }
        if (!type || type == "to") {
          this.employeeOption = res.data.data;
        }
      });
    },
    async getteam(type) {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      }).then((res) => {
        if (!type || type == "from") {
          this.allEmployeeOption = res.data.data;
        }
        if (!type || type == "to") {
          this.employeeOption = res.data.data;
        }
      });
    },
    async getcp(type) {

      if(this.$store.state.app.user_data && this.$store.state.app.user_data.role == 'employee' && this.$store.state.app.user_data.type != 'leadmanager'){
          this.employeeOption = this.$store.state.app.all_users.filter(item=> item.assignto == this.$store.state.app.user_data.id)
          return
      }
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getallcp`,
      }).then((res) => {
        if (!type || type == "from") {
          this.allEmployeeOption = res.data.data;
        }
        if (!type || type == "to") {
          this.employeeOption = res.data.data;
        }
      });
    },
    onClickBack() {
      this.closepopup();
    },
    async getEmployee() {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/cpparentchild`,
      })
        .then((response) => {
          this.allEmployeeOption.push(response.data.data);
          response.data.data.children.map((item) => {
            this.allEmployeeOption.push(item);
          });
          this.employeeOption =[...this.assignedUser,...this.allEmployeeOption];
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleLeadCategory() {
      if(this.category.length> 0){
        let all = this.category.findIndex(item=>item.name == 'All')
        this.category = all != -1 ? this.categoryOption.slice(1) : this.category 
      }
      if(!this.oldemployee || this.category.length == 0){
        this.leadOptions=[]
        return
      }
      const data = {
        user_id: this.oldemployee.id,
        categories:this.category ?this.category.map(item=>item.id):[]
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/enterbylead`,
        data: JSON.stringify(data),
      })
        .then((response) => {
          this.leadOptions = [];
          if (response.data.data.length > 0) {
            this.leadOptions = [{ fullname: "All" }, ...response.data.data];
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    submitForm(e) {
      this.lead_id =this.leads.map((item) => {
        return item.id
      });
      const data = {
        fromtype:this.fromtype,
        oldemployee: this.oldemployee?this.oldemployee.id:'',
        newemployee: this.newemployee.id,
        lead_id: this.lead_id,
        transferremarks: this.transferremarks,
      };

      // var ifEdit = this.$route.query.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;

          await axios({
            method: "post",
            url: `${this.baseApi}/transferlead`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.submitDisable = false;

              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.closepopup();
            })
            .catch((error) => {
              this.submitDisable = false;

              console.log(error);
            });
        }
      });
    },
  },
};
</script>
