import crm from "./crm";
import setup from "./setup";
import accounting from "./accounting";
import inventory from "./inventory";
import folloup from "./folloup";
import pages from "./pages";
import chartsMaps from "./charts-maps";
import hr from "./hr";
import reports from "./reports";
import master from "./master";
import print from "./print";
const routeData = [
  ...crm,
  ...setup,
  ...master,
  ...reports,
  ...accounting,
  ...hr,
  ...inventory,
  ...folloup,
  ...pages,
  ...chartsMaps,
  ...print,
];
routeData.push(
  {
    path: "/",
    name: "dashboard-analytics",
    component: () => import("@/views/dashboard/analytics/Analytics.vue"),
  },
  {
    path: "/master",
    name: "dashboard-master",
    component: () => import("@/views/dashboard/analytics/dashboardMaster.vue"),
  },
  {
    path: "/bulkland",
    name: "dashboard-bulkland",
    component: () =>
      import("@/views/dashboard/analytics/BulkLand.vue"),
  },
  {
    path: "/reports/accountsummary",
    name: "dashboard-reports-accountsummary",
    component: () => import("@/views/dashboard/analytics/reports/dashboardReportsAccountsummary.vue"),
  },
  {
    path: "/reports/accounts",
    name: "dashboard-reports-accounts",
    component: () => import("@/views/dashboard/analytics/reports/dashboardReportsAccounts.vue"),
  },
  {
    path: "/channelpartner",
    name: "dashboard-channelpartner",
    component: () =>
      import("@/views/dashboard/analytics/ChannelPartnerDashboard.vue"),
  },
  {
    path: "/reports/hr",
    name: "dashboard-reports-hr",
    component: () => import("@/views/dashboard/analytics/reports/dashboardReportsHr.vue"),
  },
  {
    path: "/hr",
    name: "dashboard-hr",
    component: () => import("@/views/dashboard/analytics/dashboardHr.vue"),
  },
  {
    path: "/crm",
    name: "dashboard-CRM",
    component: () => import("@/views/dashboard/analytics/dashboardCrm.vue"),
  },
  {
    path: "/legal",
    name: "dashboard-Legal",
    component: () => import("@/views/dashboard/analytics/dashboardLegal.vue"),
  },
  {
    path: "/crm/followup",
    name: "dashboard-CRM-followup",
    component: () =>
      import("@/views/dashboard/analytics/dashboardFollowup.vue"),
  },
  {
    path: "/accounting",
    name: "dashboard-accounts",
    component: () => import("@/views/dashboard/analytics/dashboardAccount.vue"),
  },
  // {
  //   path: "/master",
  //   name: "dashboard-master",
  //   component: () => import("@/views/dashboard/analytics/dashboardMaster.vue"),
  // },
  {
    path: "/reports",
    name: "dashboard-reports",
    component: () => import("@/views/dashboard/analytics/dashboardReports.vue"),
  },
  // {
  //   path: "/reports/accounts",
  //   name: "dashboard-reports-accounts",
  //   component: () => import("@/views/dashboard/analytics/reports/dashboardReportsAccounts.vue"),
  // },
  // {
  //   path: "/reports/hr",
  //   name: "dashboard-reports-hr",
  //   component: () => import("@/views/dashboard/analytics/reports/dashboardReportsHr.vue"),
  // },
  {
    path: "/reports/crm",
    name: "dashboard-reports-crm",
    component: () =>
      import("@/views/dashboard/analytics/reports/dashboardReportsCrm.vue"),
  },
  // {
  //   path: "/reports/accountsummary",
  //   name: "dashboard-reports-accountsummary",
  //   component: () => import("@/views/dashboard/analytics/reports/dashboardReportsAccountsummary.vue"),
  // },
  {
    path: "/project",
    name: "dashboard-project",
    component: () =>
      import("@/views/dashboard/analytics/CustomerDashboard.vue"),
  },
  {
    path: "/team",
    name: "dashboard-team",
    component: () => import("@/views/dashboard/analytics/dashboardTeam.vue"),
  },
  {
    path: "/dashboard/ecommerce",
    name: "dashboard-ecommerce",
    component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue"),
  },
  {
    path: "*",
    redirect: "error-404",
    name: "error",
  },
  {
    path: "/sign",
    name: "sign",
    component: () => import("@/components/sign.vue"),
  },
);
export default routeData;
