<template>
  <b-card no-body>
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>Followup</b-card-title>

    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>
        <b-col md="2">
          <div class="d-flex justify-content-around align-items-center h-100 flex-wrap" style="font-size: 15px">
            <b-badge variant="success h-10" style="height:40px; width:140px"
              class="d-flex align-items-center justify-content-center m-0">
              Fresh Leads : &nbsp;{{
                this.followUpCount.data.freshLeads
              }}
            </b-badge>
            <b-badge variant="info" class="d-flex align-items-center justify-content-center m-0"
              style="height:40px; width:140px">
              Total Leads : &nbsp;{{
                isNaN(allLeads)?0
                :
                allLeads
              }}
            </b-badge>
          </div>
        </b-col>
        <b-col md="10">
          <div class="demo-inline-spacing d-flex justify-content-between">
            <div id="tooltip1" class="title" @click="handleClickInquiryFollowUp('Today')">
              <b-badge class="followup" v-if="overviewkey == 'Today'" variant="primary">
                <span class="feather-icon position-relative">
                  <svg xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px" fill="currentColor"
                    viewBox="0 0 16 16">
                    <path
                      d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                  </svg>
                  <span class="badge badge-up badge-pill bg-white text-primary badge-glow border border-primary">
                    {{ followUpCount.data.Today ? followUpCount.data.Today.Today : "0" }}
                  </span>
                </span>
              </b-badge>
              <span v-else class="feather-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px" fill="currentColor"
                  class="text-primary" viewBox="0 0 16 16">
                  <path
                    d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                </svg>
                <span class="badge badge-up badge-pill badge-primary badge-glow">
                  {{ followUpCount.data.Today ? followUpCount.data.Today.Today : "0" }}
                </span>
              </span>
              <p class="m-0">Today</p>
            </div>
            <div id="tooltip2" class="title" @click="handleClickInquiryFollowUp('Upcoming')">
              <b-badge class="followup" v-if="overviewkey == 'Upcoming'" variant="info">
                <span class="feather-icon position-relative">
                  <svg xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px" fill="currentColor"
                    viewBox="0 0 16 16">
                    <path
                      d="M2 14.5a.5.5 0 0 0 .5.5h11a.5.5 0 1 0 0-1h-1v-1a4.5 4.5 0 0 0-2.557-4.06c-.29-.139-.443-.377-.443-.59v-.7c0-.213.154-.451.443-.59A4.5 4.5 0 0 0 12.5 3V2h1a.5.5 0 0 0 0-1h-11a.5.5 0 0 0 0 1h1v1a4.5 4.5 0 0 0 2.557 4.06c.29.139.443.377.443.59v.7c0 .213-.154.451-.443.59A4.5 4.5 0 0 0 3.5 13v1h-1a.5.5 0 0 0-.5.5zm2.5-.5v-1a3.5 3.5 0 0 1 1.989-3.158c.533-.256 1.011-.79 1.011-1.491v-.702s.18.101.5.101.5-.1.5-.1v.7c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13v1h-7z" />
                  </svg>
                  <span class="badge badge-up badge-pill bg-white text-info badge-glow border border-info">
                    {{
                      followUpCount.data.Upcoming
                      ? followUpCount.data.Upcoming.Upcoming
                      : "0"
                    }}
                  </span>
                </span>
              </b-badge>
              <span v-else class="feather-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px" fill="currentColor"
                  class="text-info" viewBox="0 0 16 16">
                  <path
                    d="M2 14.5a.5.5 0 0 0 .5.5h11a.5.5 0 1 0 0-1h-1v-1a4.5 4.5 0 0 0-2.557-4.06c-.29-.139-.443-.377-.443-.59v-.7c0-.213.154-.451.443-.59A4.5 4.5 0 0 0 12.5 3V2h1a.5.5 0 0 0 0-1h-11a.5.5 0 0 0 0 1h1v1a4.5 4.5 0 0 0 2.557 4.06c.29.139.443.377.443.59v.7c0 .213-.154.451-.443.59A4.5 4.5 0 0 0 3.5 13v1h-1a.5.5 0 0 0-.5.5zm2.5-.5v-1a3.5 3.5 0 0 1 1.989-3.158c.533-.256 1.011-.79 1.011-1.491v-.702s.18.101.5.101.5-.1.5-.1v.7c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13v1h-7z" />
                </svg>
                <span class="badge badge-up badge-pill badge-info badge-glow">
                  {{
                    followUpCount.data.Upcoming ? followUpCount.data.Upcoming.Upcoming : "0"
                  }}
                </span>
              </span>
              <p class="m-0">Upcoming</p>
            </div>
            <div id="tooltip3" class="title" @click="handleClickInquiryFollowUp('Overdue')">
              <b-badge class="followup" v-if="overviewkey == 'Overdue'" variant="danger">
                <span class="feather-icon position-relative">
                  <svg xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px" fill="currentColor"
                    viewBox="0 0 16 16">
                    <path
                      d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                  </svg>
                  <span class="badge badge-up badge-pill bg-white text-danger badge-glow border border-danger">
                    {{
                      followUpCount.data.Overdue ? followUpCount.data.Overdue.Overdue : "0"
                    }}
                  </span>
                </span>
              </b-badge>
              <span v-else class="feather-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px" fill="currentColor"
                  class="text-danger" viewBox="0 0 16 16">
                  <path
                    d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                </svg>
                <span class="badge badge-up badge-pill badge-danger badge-glow">
                  {{
                    followUpCount.data.Overdue ? followUpCount.data.Overdue.Overdue : "0"
                  }}
                </span>
              </span>
              <p class="m-0">Overdue</p>
            </div>
            <div id="tooltip4" class="title" @click="handleClickInquiryFollowUp('Converted')">
              <b-badge class="followup" v-if="overviewkey == 'Converted'" variant="success">
                <span class="feather-icon position-relative">
                  <svg xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px" fill="currentColor"
                    viewBox="0 0 16 16">
                    <path
                      d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                  </svg>
                  <span class="badge badge-up badge-pill bg-white text-success badge-glow border border-success">
                    {{
                      followUpCount.data.Converted
                      ? followUpCount.data.Converted.Converted
                      : "0"
                    }}
                  </span>
                </span>
              </b-badge>
              <span v-else class="feather-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px" fill="currentColor"
                  class="text-success" viewBox="0 0 16 16">
                  <path
                    d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                </svg>
                <span class="badge badge-up badge-pill badge-success badge-glow">
                  {{
                    followUpCount.data.Converted
                    ? followUpCount.data.Converted.Converted
                    : "0"
                  }}
                </span>
              </span>
              <p class="m-0">Converted</p>
            </div>
            <div id="tooltip4" class="title" @click="handleClickInquiryFollowUp('Closelead')">
              <b-badge class="followup" v-if="overviewkey == 'Closelead'" variant="secondary">
                <span class="feather-icon position-relative">
                  <svg xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px" fill="currentColor"
                    viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                  <span class="badge badge-up badge-pill bg-white text-secondary badge-glow border border-secondary">
                    {{
                      followUpCount.data.Closelead
                      ? followUpCount.data.Closelead.Closelead
                      : "0"
                    }}
                  </span>
                </span>
              </b-badge>
              <span v-else class="feather-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" style="width: 25px; height: 25px" fill="currentColor"
                  class="text-secondary" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                <span class="badge badge-up badge-pill badge-secondary badge-glow">
                  {{
                    followUpCount.data.Closelead
                    ? followUpCount.data.Closelead.Closelead
                    : "0"
                  }}
                </span>
              </span>
              <p class="m-0" align="center">Lost / Not Interested</p>
            </div>
          </div>
        </b-col>

      </b-row>


      <template>
        <div v-if="InnerInquiryFollowUp" class="mt-2">
          <!-- <p class="pt-2 font-weight-500">Leads Employee wise</p> -->
          <b-row class="d-flex justify-content-end">
            <!-- <b-col md="4">
              <b-form-input
              v-model="search"
              placeholder="Search"
              @input="debounceInput()"
              class="mt-1 mb-1"
              />
            </b-col> -->
          </b-row>
          <div>
            <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col cols="12">
                    <b-card-title>
                      Lead categories
                    </b-card-title></b-col>
                  <div style="width:90px" class="d-flex flex-column justify-content-center align-items-center">
                    <div style="width:70px" class="d-flex flex-column">
                      <b-badge variant="light-danger" class="mt-1">
                        HOT
                      </b-badge>
                      <b-badge variant="light-warning" class="mt-1">
                        Warm
                      </b-badge>
                      <b-badge variant="light-info" class="mt-1">
                        COLD
                      </b-badge>
                    </div>
                  </div>
                  <b-col cols="8" md="4" class="p-0 pl-2">
                    <vue-apex-charts height="120" type="donut" :options="earningsChart.chartOptions" :series="series" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex justify-content-end mb-1 mt-1" style="width:100%">
            <b-button variant="primary" size="sm" @click="handleDownloadUserwise()">
              <feather-icon icon="DownloadIcon" />
              Download
            </b-button>
          </div>
          <div class="mb-1 d-flex justify-content-center align-items-center flex-wrap">
            <div><h2><span :class="'text-'+keyColor +' font-weight-bolder'">{{overviewkey}}</span></h2></div>
          </div>
          <div v-if="EmployeeLoading" class="d-flex justify-content-center">
            <b-spinner variant="primary" label="Text Centered" />
          </div>
          <vs-table v-else-if="employeeData && employeeData.length>1" :data="employeeData" @selected="handleInnerInquirySelected(selected, overviewkey)"
            v-model="selected" style="max-height: 400px; overflow: auto">
            <template slot="thead">
              <vs-th style="width: 10%">Sr.No</vs-th>
              <vs-th>Assign To</vs-th>
              <vs-th>Leads</vs-th>
              <!-- <vs-th>Category</vs-th> -->
            </template>
            <template>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in  employeeData">
                <vs-td>
                  {{ indextr + 1 }}
                </vs-td>
                <vs-td>
                  {{ tr.assignto && tr.assignto.length > 0 ? `${tr.assignto[0].name + ' ' +tr.assignto[0].surname}`  : '' }}
                </vs-td>
                <vs-td>
                  {{ tr.count }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div v-if="InnerUsersLead">

          <div class="d-flex justify-content-end mb-1 mt-1" style="width:100%">
            <b-button variant="primary" size="sm" @click="handleDownload()">
              <feather-icon icon="DownloadIcon" />
              Download
            </b-button>
            <b-button variant="primary" class="ml-1" size="sm" @click="handleClear()">
              Clear Filter
            </b-button>
          </div>
          <div class="mb-1 d-flex justify-content-start align-items-center flex-wrap">
            <div class="d-flex w-50"><h5 v-if="selectedAssignto">Assign To: <span :class="'text-'+keyColor +' font-weight-bolder'">{{selectedAssignto.assignto[0].name}} {{selectedAssignto.assignto[0].surname}}</span></h5></div>
            <div><h2 v-if="selectedAssignto"><span :class="'text-'+keyColor +' font-weight-bolder'">{{overviewkey}}</span></h2></div>
          </div>
          <div v-if="EmployeeLoading" class="d-flex justify-content-center">
            <b-spinner variant="primary" label="Text Centered" />
          </div>
          <vs-table v-else-if="userData && userData.length>0" :data="userData" @selected="handleInnerUserLeadSelected(selected, leadID)" v-model="selected"
            style="max-height: 400px; overflow: auto">
            <template slot="thead">
              <vs-th style="width: fit-content">No</vs-th>
              <vs-th style="width:20%">
                <div>
                  <div>Client Name</div>
                  <b-form-input v-model="name" placeholder="Search" @input="debounceInput()" class="mt-1 mb-1" />
                </div>
              </vs-th>
              <vs-th style="width:20%">
                <div>

                  <div>Mobile No.</div>
                  <b-form-input v-model="mobile" placeholder="Search" @input="debounceInput()" class="mt-1 mb-1" />
                </div>
              </vs-th>
              <vs-th style="width:20%">
                <div>
                  <div>City</div>
                  <b-form-input v-model="city" placeholder="Search" @input="debounceInput()" class="mt-1 mb-1" />
                </div>

              </vs-th>
              <vs-th style="width:20%">
                <div>
                  <div>Next Date</div>
                  <flat-pickr v-model="nextfollowupdate" class="form-control" :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                  }" style="background-color: transparent" @input="debounceInput()" />
                  <!-- <b-form-input v-model="nextfollowupdate" placeholder="Search"  class="mt-1 mb-1" /> -->
                </div>

              </vs-th>
              <vs-th style="width:20%">
                <div>
                  <div>Last Date</div>
                  <flat-pickr v-model="modifieddatetime" class="form-control" :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                  }" style="background-color: transparent" @input="debounceInput()" />
                  <!-- <b-form-input v-model="nextfollowupdate" placeholder="Search"  class="mt-1 mb-1" /> -->
                </div>

              </vs-th>

            </template>

            <template>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in userData" >
                <vs-td :data="tr.id" :class="tr.followupdata.length ==0 ? 'bg-success bg-lighten-5':''">
                  {{ indextr + 1 }}
                </vs-td>
                <vs-td :data="tr.name" :class="tr.followupdata.length ==0 ? 'bg-success bg-lighten-5':''">
                  {{ tr.name }}
                </vs-td>
                <vs-td :data="tr.mobile" :class="tr.followupdata.length ==0 ? 'bg-success bg-lighten-5':''">
                  {{ tr.mobile }}
                </vs-td>
                <vs-td :data="tr.city" :class="tr.followupdata.length ==0 ? 'bg-success bg-lighten-5':''">
                  {{ tr.city }}
                </vs-td>
                <vs-td :data="tr.nextfollowupdate" :class="tr.followupdata.length ==0 ? 'bg-success bg-lighten-5':''">
                  {{ tr.nextfollowupdate.split('-').reverse().join('/') }}
                </vs-td>
                <vs-td :data="tr.modifieddatetime" :class="tr.followupdata.length ==0 ? 'bg-success bg-lighten-5':''">
                  {{ tr.modifieddatetime.split('T')[0].split('-').reverse().join('/') }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="handleInnerInquirySelected(selectedAssignto, overviewkey)" />
              <span class="text-nowrap">{{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span>
            </div>
            <div>
              <b-pagination v-model="paginationValue" :total-rows="paginationTotal" :per-page="pageLength" first-number
                last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="handleInnerInquirySelected(selectedAssignto, overviewkey)">
                <!-- @input="(value) => props.pageChanged({ currentPage: value })" -->
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </div>
      </template>
      <div id="container" style="visibility:hidden;height:0px"></div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  VBTooltip,
  BCol,
  BCardText,
  BBadge,
  BSpinner,
  BFormInput,
  BButton,
  BPagination,
  BFormSelect,



} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { BTable } from "bootstrap-vue";
import axios from '@/components/axios';
import flatPickr from "vue-flatpickr-component";

const $earningsStrokeColor1 = '#ea5455'
const $earningsStrokeColor2 = '#ff9f43'
const $earningsStrokeColor3 = '#00cfe8'

import moment from 'moment';
import _ from 'lodash'
export default {
  components: {
    BFormInput,
    BPagination,

    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BBadge,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BButton,
    BFormSelect,
    flatPickr

  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  async mounted() {
    this.getOverview();
    let lastFollowupData = JSON.parse(localStorage.getItem('lastFollowupData'))
    if(lastFollowupData){
      this.selectedAssignto =lastFollowupData.assignto
      this.overviewkey= lastFollowupData.overviewkey
      this.pageLength=lastFollowupData.pageLength,
      this.paginationValue=lastFollowupData.paginationValue,
      this.params=lastFollowupData.params,
      this.name=lastFollowupData.name,
      this.mobile=lastFollowupData.mobile,
      this.city=lastFollowupData.city,
      this.keyColor=lastFollowupData.keyColor,

      this.nextfollowupdate=lastFollowupData.nextfollowupdate,
      this.modifieddatetime=lastFollowupData.modifieddatetime,
      this.handleInnerInquirySelected(this.selectedAssignto,this.overviewkey)
    }
    // this.$store.state.app.lastFollowupData=null
    // const accessToken = localStorage.getItem("accessToken"); // JSON.parse()
    // const baseApi = process.env.VUE_APP_APIENDPOINT;
    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    //   data: JSON.stringify({ data: "Today" }),
    //   url: `${baseApi}/getLeadsByBranches`,
    // };

    // await axios(requestOptions)
    //   .then((response) => {
    //     this.leadSummaryData = response.data;
    //   })
    //   .catch((error) => console.log(error, "error"));
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      keyColor:'primary',
      selectedAssignto: {},
      checkpointReward: {},
      subscribersGained: {},
      ordersRecevied: {},
      salesBarSession: {},
      supportTracker: {},
      productsOrder: {},
      salesRadar: {},
      InquiryFollowUp: false,
      InnerInquiryFollowUp: false,
      MemberRenewalFollowUp: false,
      InnerMemberRenewalFollowUp: false,
      followUp: null,
      followUpCount: {
        data: {
          Overdue: 0,
          Today: 0,
          Upcoming: 0,
          Closelead: 0,
          Converted: 0,

        },
      },
      modifieddatetime:null,
      EmployeeLoading: false,
      leadSummaryData: null,
      overviewkey: null,
      branchID: null,
      selected: [],
      branchData: [],
      employeeData: null,
      userData: null,
      leadID: null,
      InnerUsersLead: false,
      leadKey: null,
      name: null,
      mobile: null,
      city: null,
      nextfollowupdate: null,
      users: [
        {
          id: 1,
          location: "Ahmedabad",
          followupcount: 2,
        },
        {
          id: 2,
          location: "Gota",
          followupcount: 2,
        },
        {
          id: 3,
          location: "Nadiad",
          followupcount: 2,
        },
        {
          id: 4,
          location: "Ahmedabad",
          followupcount: 2,
        },
      ],

      timelineData: [
        {
          color: "primary",
          icon: "PlusIcon",
          title: "Client Meeting",
          desc: "Bonbon macaroon jelly beans gummi bears jelly lollipop apple",
          time: "25 mins Ago",
        },
        {
          color: "warning",
          icon: "MailIcon",
          title: "Email Newsletter",
          desc: "Cupcake gummi bears soufflé caramels candy",
          time: "15 Days Ago",
        },
        {
          color: "danger",
          icon: "UsersIcon",
          title: "Plan Webinar",
          desc: "Candy ice cream cake. Halvah gummi bears",
          time: "20 days ago",
        },
        {
          color: "success",
          icon: "LayoutIcon",
          title: "Launch Website",
          desc: "Candy ice cream cake. Halvah gummi bears Cupcake gummi bears soufflé caramels candy.",
          time: "25 days ago",
        },
        {
          color: "primary",
          icon: "TvIcon",
          title: "Marketing",
          desc: "Candy ice cream cake. Halvah gummi bears Cupcake gummi bears.",
          time: "28 days ago",
        },
      ],

      analyticsData: null,
      dispatchedOrders: [],
      leadSummaryData: null,
      selected: [],
      items: [
        {
          age: 40,
          first_name: "Dickerson",
          last_name: "Macdonald",
          Occupation: "Job",
        },
        {
          age: 21,
          first_name: "Larsen",
          last_name: "Shaw",
          Occupation: "Job",
        },
        {
          age: 89,
          first_name: "Geneva",
          last_name: "Wilson",
          Occupation: "Bussiness",
        },
        {
          age: 38,
          first_name: "Jami",
          last_name: "Carney",
          Occupation: "Bussiness",
        },
        {
          age: 40,
          first_name: "James",
          last_name: "Thomson",
          Occupation: "Job",
        },
      ],
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%",
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: "#5e5873",
                  fontSize: "1rem",
                },
                value: {
                  offsetY: 15,
                  color: "#5e5873",
                  fontSize: "1.714rem",
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ["Completed Tickets"],
        },
      },
      series:[],
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: { show: false },
          comparedResult: [2, -3],
          labels: ['HOT', 'WARM', 'COLD'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor1, $earningsStrokeColor2, $earningsStrokeColor3],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20
            }
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    }
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Total',
                  }
                }
              }
            }
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 120
                }
              }
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120
                }
              }
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100
                }
              }
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 100
                }
              }
            }
          ]
        }
      },
      search: "",
      finalData: [],
      pageLength: 10,
      paginationStart: 0,
      paginationEnd: 0,
      paginationTotal: 0,
      paginationValue: 1,
      paginationTotal: 0,
      params: '',
      prioritycount:{
          Today:{
            hot:0,
            cold:0,
            warm:0
          },
          Upcoming:{
            hot:0,
            cold:0,
            warm:0
          },
          Overdue:{
            hot:0,
            cold:0,
            warm:0
          },
          Converted:{
            hot:0,
            cold:0,
            warm:0
          },
          Closelead:{
            hot:0,
            cold:0,
            warm:0
          }
      },
      allLeads:0
    };
  },
  methods: {
    handleClear() {
      this.name = '';
      this.mobile = '';
      this.city = '';
      this.nextfollowupdate = '';
      this.modifieddatetime = '';

      this.handleSearch()
    },
    handleDownloadUserwise() {
      var aoa = [
        ['Date :', moment(new Date()).format('DD/MM/yyyy')],
        ['Type :', this.overviewkey],
        [],
        [],
        [],
        ['Sr. No', 'Assign To', 'Leads'],
        [],

      ];
      if (this.employeeData && this.employeeData.length > 0) {
        this.employeeData.map((item, index) => {
          aoa.push([index + 1, item.assignto.length > 0 ? item.assignto[0].name : '', item.count])
        })
      }

      var ws = XLSX.utils.aoa_to_sheet(aoa);
      var html_string = XLSX.utils.sheet_to_html(ws, { id: "data-table", editable: true });
      document.getElementById("container").innerHTML = html_string;
      function doit(type, fn) {
        var elt = document.getElementById('data-table');
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
      }
      doit('xlsx', this.overviewkey + 'leads.xlsx');
    },
    handleDownload() {
      var aoa = [
        ['Date :', moment(new Date()).format('DD/MM/yyyy')],
        ['Type :', this.overviewkey],
        [],
        [],
        [],
        ['Sr. No', 'Client Name', 'Assign To', 'Mobile', 'City', 'Next Date'],
        [],

      ];
      if (this.userData && this.userData.length > 0) {
        this.userData.map((item, index) => {
          aoa.push([index + 1, item.name, item.assignto.length > 0 ? item.assignto[0].name : '', item.mobile, item.city, item.nextfollowupdate])
        })
      }

      var ws = XLSX.utils.aoa_to_sheet(aoa);
      var html_string = XLSX.utils.sheet_to_html(ws, { id: "data-table", editable: true });
      document.getElementById("container").innerHTML = html_string;
      function doit(type, fn) {
        var elt = document.getElementById('data-table');
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
      }
      doit('xlsx', this.overviewkey + 'leads User Wise.xlsx');
    },
    debounceInput: _.debounce(function () {
      this.handleSearch()
    }, 1000),
    handleSearch() {
      this.params = ''
      if (this.city) {
        this.params += `&city=${this.city}`
      }
      if (this.name) {
        this.params += `&name=${this.name}`
      }
      if (this.nextfollowupdate) {
        this.params += `&nextfollowupdate=${this.nextfollowupdate.split('/').reverse().join('-')}`
      }
      if (this.modifieddatetime) {
        this.params += `&modifieddatetime=${this.modifieddatetime.split('/').reverse().join('-')}`
      }
      if (this.mobile) {
        this.params += `&mobile=${this.mobile}`
      }
      this.handleInnerInquirySelected(this.selectedAssignto, this.overviewkey)

    },
    async getOverview() {
      const accessToken = localStorage.getItem("accessToken"); // JSON.parse()
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      // const user = JSON.parse(localStorage.getItem("userData"));

      fetch(`${baseApi}/getoverview`, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          this.$store.state.app.priorityData ={
            hot:json.data.Today.hot + json.data.Upcoming.hot + json.data.Overdue.hot,
            cold:json.data.Today.cold + json.data.Upcoming.cold + json.data.Overdue.cold,
            warm:json.data.Today.warm + json.data.Upcoming.warm + json.data.Overdue.warm
          }

          Object.keys(json.data).map((item)=>{
            if(item !=='freshLeads'){
              this.prioritycount[item].hot=json.data[item].hot
              this.prioritycount[item].cold=json.data[item].cold
              this.prioritycount[item].warm=json.data[item].warm
            }

          })

              this.followUpCount = json;
               this.allLeads=this.followUpCount.data.Today.Today +
                this.followUpCount.data.Upcoming.Upcoming +
                this.followUpCount.data.Overdue.Overdue +
                this.followUpCount.data.Closelead.Closelead +
                this.followUpCount.data.Converted.Converted
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleClickInquiryFollowUp(key) {
      this.series=[this.prioritycount[key].hot,this.prioritycount[key].warm,this.prioritycount[key].cold ]

      if (key == "Today") {
        this.keyColor = 'primary'
        this.$root.$emit("bv::hide::tooltip", "tooltip2");
        this.$root.$emit("bv::hide::tooltip", "tooltip3");
        this.$root.$emit("bv::hide::tooltip", "tooltip4");
      }
      if (key == "Upcoming") {
        this.keyColor = 'info'

        this.$root.$emit("bv::hide::tooltip", "tooltip1");
        this.$root.$emit("bv::hide::tooltip", "tooltip3");
        this.$root.$emit("bv::hide::tooltip", "tooltip4");
      }
      if (key == "Overdue") {
        this.keyColor = 'danger'

        this.$root.$emit("bv::hide::tooltip", "tooltip2");
        this.$root.$emit("bv::hide::tooltip", "tooltip1");
        this.$root.$emit("bv::hide::tooltip", "tooltip4");
      }
      if (key == "Converted") {
        this.keyColor = 'success'

        this.$root.$emit("bv::hide::tooltip", "tooltip2");
        this.$root.$emit("bv::hide::tooltip", "tooltip1");
        this.$root.$emit("bv::hide::tooltip", "tooltip4");
      }
      if (key == "Closelead") {
        this.keyColor = 'secondary'

        this.$root.$emit("bv::hide::tooltip", "tooltip2");
        this.$root.$emit("bv::hide::tooltip", "tooltip3");
        this.$root.$emit("bv::hide::tooltip", "tooltip1");
      }
      this.InquiryFollowUp = true;
      this.InnerInquiryFollowUp = true;
      this.InnerUsersLead = false;
      this.EmployeeLoading = true;
      this.overviewkey = key;
      const accessToken = localStorage.getItem("accessToken"); // JSON.parse()
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptionsEmployeeSummary = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getTotalLead`,
        data: JSON.stringify({ data: `${key}` }),
      };
      await axios(requestOptionsEmployeeSummary)
        .then((response) => {
          this.employeeData = response.data;

          // let total =this.prioritycount[this.overviewkey].hot+this.prioritycount[this.overviewkey].warm+this.prioritycount[this.overviewkey].cold
          // if(total==0){
          //   series=[1,0,0]
          // }
          if(this.employeeData.length==1 && this.employeeData[0].assignto.length>0 ){
            this.handleInnerInquirySelected(response.data[0], this.overviewkey)
          }
          this.employeeData = this.employeeData.filter((item) => {
            if (item.assignto.length > 0) {
                return true
            }
              return false
          })
          this.search = null;
        })
        .catch((error) => console.log(error, "error"))
        .finally(()=>{
          this.EmployeeLoading=false
        })
    },
    handleClickMemberRenewalFollowUp(val) {
      this.MemberRenewalFollowUp = true;
    },
    async handleInnerInquirySelected(selected, key) {

      this.selectedAssignto = selected
      this.InnerUsersLead = true;
      this.InnerInquiryFollowUp = false;
      this.InquiryFollowUp = false;
      this.EmployeeLoading=true
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptionsLeadsSummary = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getTotalLeadData?pagenum=${this.paginationValue}&pagesize=${this.pageLength}` + this.params,
        data: JSON.stringify({
          id: selected.assignto[0].id,
          data: key,
        }),
      };
      await axios(requestOptionsLeadsSummary)
        .then((response) => {

          this.userData = response.data.data;
          this.paginationTotal = response.data.total
          this.paginationStart = response.data.from
          this.paginationEnd = response.data.to
        })
        .catch((error) => console.log(error, "error"))
        .finally(()=>{
          this.EmployeeLoading=false
           localStorage.setItem('lastFollowupData',JSON.stringify({
              assignto:selected,
              overviewkey:key,
              pageLength:this.pageLength,
              paginationValue:this.paginationValue,
              params:this.params,
              name:this.name,
              mobile:this.mobile,
              city:this.city,
              nextfollowupdate:this.nextfollowupdate,
              modifieddatetime:this.modifieddatetime,
              keyColor:this.keyColor
          }))
        })
    },
    async handleInnerUserLeadSelected(select) {
      if (this.overviewkey == 'Converted') {
        return
      }
      this.$router.push(`/followup/followupinfo?id=${select.id}`);
    },
    handleMemberRenewalSelected(tr) {
      // console.log(tr, "trrr")
    },
    doubleSelection(tr) {
      // console.log(tr, "double tr");
    },
  },
};
</script>
<style>
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
}

#tooltip1 ,#tooltip2 ,#tooltip3 ,#tooltip4,#tooltip5  {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
  border-radius: 10px;
  height: 110px;
  width: 130px;
  padding-top: 10px;
  margin-right:1rem !important;
}
</style>
