import { $themeBreakpoints } from '@themeConfig'
import axios from '@/components/axios'
import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    priorityData: {},
    shallShowOverlay: false,
    timer: null,
    user_data: {},
    all_users: [],
    user_approval: [],
    user_widgets: [],
    showHideData: {},
    customerDetailsModal: false,
    customerDetails: {
      data: {},
      loading: false,
      success: false,
      error: null,
    },
    accessToken: localStorage.getItem("accessToken"),
    baseApi: process.env.VUE_APP_APIENDPOINT,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    approvalAccess: (state) => (title) => {
      let arr = state.user_approval.filter((item) => item.module?.name == title)
      return arr.length > 0;
    },
    widgetAccess: (state) => (name) => {
      let arr = state.user_widgets.filter((item) => {
        return item.name == name && item.view
      })
      return arr.length > 0;
    },
  },
  mutations: {
    saveState(state) {
      if (state) {
        state.customerDetailsModal = false
        state.customerDetails = {
          data: {},
          loading: false,
          success: false,
          error: null,
        },
          localStorage.setItem('vuex-state', JSON.stringify(state));
      }
    },
    loadState(state) {
      const localState = localStorage.getItem('vuex-state');
      if (localState) {
        Object.assign(state, JSON.parse(localState));
        localStorage.removeItem('vuex-state');
      }
    },
    SET_USERS(state, val) {
      state.all_users = val
    },

    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_USER_DATA(state, val) {
      state.user_data = val
    },
    SET_APPROVAL_DATA(state, val) {
      state.user_approval = val
    },
    SET_WIDGET_DATA(state, val) {
      state.user_widgets = val
    },
    SET_CUSTOMER_MODAL(state) {
      state.customerDetailsModal = !state.customerDetailsModal
    },
    SET_CUSTOMER_DETAILS(state, val) {
      state.customerDetails.data = val
    },
    SET_CUSTOMER_LOADING(state, val) {
      state.customerDetails.loading = val
    },
    SET_CUSTOMER_SUCCESS(state, val) {
      state.customerDetails.success = val
    },
    SET_CUSTOMER_ERROR(state, val) {
      state.customerDetails.error = val
    },
    UPDATE_SHOW_HIDE_DATA(state, payload) {
      Vue.set(state.showHideData, payload.key, payload.value);
    }
  },
  actions: {
    updateShowHideData({ commit }, payload) {

      commit('UPDATE_SHOW_HIDE_DATA', payload);
    },
    async getUserData(context) {

      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      let id = 0
      if (localStorage.getItem('userData')) {
        id = JSON.parse(localStorage.getItem('userData')).id
      }
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getusersbyid/${id}`,
      };
      await axios(reportauthority)
        .then((response) => {
          context.commit('SET_USER_DATA', response.data.data[0])
        })
        .catch((error) => console.log(error, "error"));
    },
    async getApprovals(context) {

      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;

      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getApprovalsByUser`,
      };
      await axios(reportauthority)
        .then((response) => {
          context.commit('SET_APPROVAL_DATA', response.data.data)
        })
        .catch((error) => console.log(error, "error"));
    },
    async getWidgetRights(context) {

      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const id = JSON.parse(localStorage.getItem('userData')).id

      const data = {
        type: 'dynamic',
        id
      }
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getwidgetrights`,
        data: data
      };
      await axios(reportauthority)
        .then((response) => {
          context.commit('SET_WIDGET_DATA', response.data.data ? JSON.parse(response.data.data) : [])
        })
        .catch((error) => console.log(error, "error"));
    },
    async getCustomerDetails(context, payload) {

      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      if (payload) {
        context.commit('SET_CUSTOMER_LOADING', true);
        context.commit('SET_CUSTOMER_SUCCESS', false);
        context.commit('SET_CUSTOMER_ERROR', null);
        context.commit('SET_CUSTOMER_DETAILS', {});
        context.state.customerDetailsModal = !context.state.customerDetailsModal

        if (context.state.customerDetailsModal) {
          const reportauthority = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            url: `${baseApi}/customer-details/${payload.id}`,
          };
          await axios(reportauthority)
            .then((response) => {
              context.commit('SET_CUSTOMER_DETAILS', response.data.data)
              context.commit('SET_CUSTOMER_SUCCESS', true);

            })
            .catch((error) => {
              context.commit('SET_CUSTOMER_ERROR', error.response.data.message || 'Failed to fetch data');
            })
            .finally(() => {
              context.commit('SET_CUSTOMER_LOADING', false);
            });

        }

      }

    }
  }
}
