import CustomerModal from '@/components/CustomerModal.vue'
const components = {
  CustomerModal
};
function install(Vue) {
  if (install.installed) return;

  for (const item in components) {

    if (components[item].name) {
      Vue.component(components[item].name, components[item]);
    }
  }
}

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export default {
  install,
  ...components,
};
