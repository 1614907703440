<template>
  <div>
  <b-row class="py-1 justify-content-start">
      <b-col md='4'>
        <v-select
          v-model="field"
          placeholder="None"
          :options="fieldOptions"
          label="name"
        />
      </b-col>
      <b-col md='4' v-if="field && field.type == 'dropdown'">
        <v-select
          v-model="search"
          placeholder="None"
          :options="field.options"
          @input="getTasks"
          label="name"
        />
      </b-col>
      <b-col md='4' v-else-if="field && field.type=='date'">
        <flat-pickr
            v-model="search"
            placeholder="Select Date"
            class="form-control"
            :config="{
              mode:'range',
              enableTime: false,
              dateFormat: 'd/m/Y',
            }"
            @input="handleSearch"

            style="background: transparent"
          />
      </b-col>
      <b-col md='4' v-else>
        <b-form-input
          v-model="search"
          placeholder="Search"
          @input="handleSearch"
        />
      </b-col>
    </b-row>
    <div class=" overflow-auto" style="min-height: 5cm;max-height:10cm">
      <table class="table" style="min-width:300px !important">
        <thead class="text thead-sticky">
          <tr>
            <th class="tableTh">
              No.
            </th>
             <th class="tableTh">
              Subject
            </th>
            <th class="tableTh">
              Assign By
            </th>
            <th class="tableTh">
              Assign To
            </th>
            <th class="tableTh">
              Priority
            </th>

            <th class="tableTh">
              Deadline
            </th>
            <th class="tableTh">
              Status
            </th>
            <th class="tableTh">
              Completed
            </th>
            <th class="tableTh">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, id) in tasks" :key="id" style="height:80px" @dblclick="handleClick(item)">
            <td class="tableTd">{{ id + 1 }}</td>
            <td class="tableTd">{{ item.task }}</td>
            <td class="tableTd">{{ item.enterby_data[0].fullname }}</td>
            <td class="tableTd">{{ item.assignto ? item.assignto.fullname : '' }}</td>
            <td class="tableTd">
              <b-badge :variant="statusVariant(item.priority)">
                {{ item.priority }}
              </b-badge>
            </td>
            <td class="tableTd">{{ item.deadline &&item.deadline!='0000-00-00' ? moment(item.deadline).format('DD/MM/YYYY'):'' }}</td>
            <td class="font-weight-bolder"
              :class="item.status == 'Complete' ? 'text-success' : item.status == 'Open' ? 'text-info' : 'text-danger'">
              {{ item.status }}
            </td>
            <td class="tableTd">
              {{ item.complition ? item.complition : 0 }}%</td>
            <td style="position: relative;"> <b-button variant="primary" size="sm" @click="handleClick(item)">
                Action</b-button>
              <div class="d-flex justify-content-between mt-1"><b-button
                  v-if="item.complition == 100 && item.status !== 'Complete' && (userData.id == item.enterby_data[0].id || userData.role == 'admin')"
                  variant="success" size="sm" @click="handleComplition(item, 'Approve')">
                  Approve</b-button></div>
              <div class="pendingBox" v-if="display[item.id]">
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
       <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="getTasks" />
              <span class="text-nowrap">{{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span>
            </div>
            <div>
              <b-pagination v-model="paginationValue" :total-rows="paginationTotal" :per-page="pageLength" first-number
                last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="getTasks">
                <!-- @input="(value) => props.pageChanged({ currentPage: value })" -->
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>

    <b-modal id="modal-form" :title="taskItem.task"  v-model="popUpVisible" size="xl" cancel-variant="outline-secondary" ok-title="Submit"
      cancel-title="Close" hide-footer>
      <div>
       <span class="font-weight-bolder"> Description : </span> {{ taskItem.description }}
      </div>
      <b-row>
        <b-col sm="8">
          <div class="commentForm">

            <b-form-textarea v-model="remarks" placeholder="Enter Remarks" rows="5" />
            <b-form-group>
              <b-form-file v-model="attachment" class="mt-1" placeholder="Select Document"
                drop-placeholder="Drop file here..." @input="handleFileChange($event, 'Task')" />
            </b-form-group>
            <div class="d-flex justify-content-between">
              <h2>Comments</h2>
              <div>
                <b-button variant="primary" size="sm" @click="handleClickSubmit" :disabled="flag">
                  Submit</b-button>
                </div>
              </div>
          </div>
          <div class="commentDetails">
            <div class="comment " v-if="taskItem.attachment1 || taskItem.attachment2">
              <h5>Attachments</h5>
              <div class="d-flex">
                <div style="width:50%">
                  <attachment class="mt-1" v-if="taskItem.attachment1" :data="taskItem.attachment1" />
                </div><div style="width:50%">
                  <attachment class="mt-1" v-if="taskItem.attachment2" :data="taskItem.attachment2" />
                </div>
              </div>
            </div>
            <div v-for="(item, index) in comments" :key="index" class="comment">
              <div class="d-flex justify-content-end" style="width:100%;">
                <b-badge variant="light-primary">
                  {{ item.datetime }}
                </b-badge>
              </div>
              <div class="d-flex align-items-center">
                <div style="width:10%">
                  <b-avatar variant="none" :src="getprofileImage(userData.profile_image)" />
                </div>
                <div class="ml-1 font-weight-bolder" style="width:60%">
                  {{ item.enterby }}

                </div>
              </div>
              <p class="mt-1">{{ item.remarks }}</p>
              <div style="width:40%" v-if="item.attachment">
                <attachment class="mt-1" :data='item.attachment' />
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="4" class="taskInfo">
          <div class="infoHeader">
            Details
          </div>
          <div class="d-flex flex-wrap taskInfoDetails">
            <div class="w-50 font-weight-bolder">
              Assign By :
            </div>
            <div class="w-50">
              {{ taskItem.id && taskItem.enterby_data.length > 0 ? taskItem.enterby_data[0].name : '' }}
            </div>
            <div class="w-50 font-weight-bolder">
              Assign To :
            </div>
            <div class="w-50">
              {{ taskItem.id && taskItem.assignto.length > 0 ? taskItem.assignto[0].name : '' }}
            </div>
            <div class="w-50 font-weight-bolder">
              Priority :
            </div>
            <div class="w-50 font-weight-bolder" :class="taskItem.priority == 'High' ? 'text-danger' : taskItem.priority == 'Low' ? 'text-info' : 'text-warning'">
              {{ taskItem.priority }}
            </div>
            <div class="w-50 font-weight-bolder">
              Description :
            </div>
            <div class="w-50">
              {{ taskItem.description }}
            </div>
            <div class="w-50 font-weight-bolder">
              Deadline :
            </div>
            <div class="w-50">
              {{ taskItem.deadline }}
            </div>
            <div class="w-50 font-weight-bolder">
              status :
            </div>

            <div class=" w-50 font-weight-bolder"
            :class="taskItem.status == 'Complete' ? 'text-success' : taskItem.status == 'Open' ? 'text-info' : 'text-danger'">
              {{ taskItem.status }}
            </div>
            <div class="pt-1" style="width:100%;border-top:1px solid #d8d6de">
              <app-timeline>
                <app-timeline-item variant="info"
                  :fillBorder="taskItem.complition && parseInt(taskItem.complition) >= 25">
                  <div class="pendingItem" :class="!taskItem.complition ? 'borderClass' : ''">0% complete</div>
                </app-timeline-item>
                <app-timeline-item variant="danger"
                  :fillBorder="taskItem.status == 'Complete' || (taskItem.complition && parseInt(taskItem.complition) >= 50)">
                  <div class="pendingItem" :class="taskItem.complition == 25 ? 'borderClass' : ''"
                    @click="handleComplition(taskItem, 25)">25% complete</div>
                </app-timeline-item>
                <app-timeline-item variant="warning"
                  :fillBorder="taskItem.status == 'Complete' || (taskItem.complition && parseInt(taskItem.complition) >= 75)">
                  <div class="pendingItem" :class="taskItem.complition == 50 ? 'borderClass' : ''"
                    @click="handleComplition(taskItem, 50)">50% complete</div>
                </app-timeline-item>
                <app-timeline-item variant="primary"
                  :fillBorder="taskItem.status == 'Complete' || (taskItem.complition && parseInt(taskItem.complition) >= 100)">
                  <div class="pendingItem" :class="taskItem.complition == 75 ? 'borderClass' : ''"
                    @click="handleComplition(taskItem, 75)">75% complete</div>
                </app-timeline-item>
                <app-timeline-item variant="success" :fillBorder="taskItem.status == 'Complete'">
                  <div class="pendingItem" :class="taskItem.complition == 100 ? 'borderClass' : ''"
                    @click="handleComplition(taskItem, 100)">100% complete</div>
                  <div v-if="taskItem.complition == 100 && taskItem.status !== 'Complete'" class="text-danger"> waiting
                    for
                    approval</div>
                  <div v-if="taskItem.complition == 100 && taskItem.status == 'Complete'"
                    class="text-success font-weight-bolder">
                    Complete</div>
                </app-timeline-item>
              </app-timeline>
            </div>
          </div>

        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>

import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
  BBadge,
  BLink,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormTextarea,
  BFormSelect,
  BPagination,

  BButton,
  BFormFile,

} from "bootstrap-vue";
import axios from '@/components/axios';
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { quillEditor } from "vue-quill-editor";
import moment from 'moment';
import Attachment from "../../../components/Attechment.vue";
import vSelect from "vue-select";
import _ from 'lodash'
import flatPickr from "vue-flatpickr-component";


export default {
  components: {

    Attachment,
    quillEditor,
    BButton,
    AppTimeline,
    AppTimelineItem,
    BLink,
    BCard,
    BImg,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormTextarea,
    BFormGroup,
    BPagination,
    BFormSelect,
    BBadge,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BFormInput,
    BRow,
    BCol,
    BFormFile,
    vSelect,
    flatPickr
  },
  data() {
    return {
      moment,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      clickedItem: 0,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      userData: {},
      searchEmp: "",
      searchCp: "",
      tasks: [],
      user: {},
      display: {},
      popUpVisible: false,
      comments: [],
      remarks: '',
      flag: false,
      attachment: '',
      taskItem: {},
      pageLength:10,
      paginationStart:0,
      paginationEnd:0,
      paginationTotal:0,
      paginationValue:0,
      fieldOptions: [
        {name:'Subject',value:'task',type:'text'},
        {name:'Assign By',value:'enterby',type:'text'},
        {name:'Assing To',value:'assignto',type:'text'},
        {name:'Priority',value:'',type:'dropdown',options:['High', 'Medium', 'Low']},
        {name:'Deadline',value:'deadline',type:'date'},
        {name:'Status',value:'status',type:'dropdown',options:['Open', 'Pending', 'Complete']},
        {
          name: 'Completed', value: 'complition', type: 'dropdown',
          options: ['0', 25, 50, 75, 100]
        },
      ],
      field:{name:'Subject',value:'task',type:'text'},
      search:'',
    };
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.getTasks()
  },
  computed: {
    statusVariant() {
      const statusColor = {
        High: "danger",
        Low: "info",
        Medium: "warning",

      };

      return (status) => statusColor[status];
    },
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== '') {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image
        return test
      }
      return image
    },
    async handleClickSubmit() {
      if (!this.attachment && !this.remarks) {
        return
      }
      let data = {
        comments: JSON.stringify([ { remarks: this.remarks, attachment: this.attachment, enterby: this.userData.name, photo: this.userData.profile_img, datetime: moment(new Date).format('MMMM Do YYYY, h:mm a') },...this.comments])
      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/task/${this.taskItem.id}`,
        data: data
      };
      await axios(requestOptions)
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Succcess",
            text:'comment add successfully',
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.remarks=''
          this.attachment=[]
          this.getTasks()
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Error",
            text: 'Some error occurred',
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          console.log(error, "error")
        });
    },
    async handleComplition(item, complition) {
      if (parseInt(item.complition) < complition || item.complition == null || complition == 'Approve') {

        let data = {}
        if (complition == 'Approve') {
          data = {
            status: 'Complete'
          }
        }
        else {
          data = {
            complition: complition
          }
        }
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Submit",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then(async (result) => {
            if (result.value) {
              const requestOptions = {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.accessToken}`,
                },
                url: `${this.baseApi}/task/${item.id}`,
                data: data
              };
              await axios(requestOptions)
                .then((response) => {
                  this.$swal({
                    icon: "success",
                    title: "Succcess",
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                  });
                  this.getTasks()
                })
                .catch((error) => {
                  this.$swal({
                    icon: "error",
                    title: "Error",
                    text: 'Some error occurred',
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                  });
                  console.log(error, "error")
                });
            }
          })
      }

    },
    async handleFileChange(e, type, index) {
      this.flag = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.attachment = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.flag = false;
        })
    },
    handleClick(item) {
      this.popUpVisible = true
      this.comments = item.comments ? JSON.parse(item.comments) : []
      console.log(this.comments)
      this.taskItem = item
    },
     handleSearch: _.debounce(function () {
          this.getTasks()

        }, 1000),
    handleSearchEmplooyee(e) {
      let arr = []
      if (e) {

        this.allEmp.map((item) => {
          if (
            item?.name.toUpperCase().includes(e.toUpperCase()) ||
            item?.surname.toUpperCase().includes(e.toUpperCase()) ||
            item?.username.toUpperCase().includes(e.toUpperCase()) ||
            item?.type.toUpperCase().includes(e.toUpperCase())
          ) {
            arr.push(item)
          }
        })
        this.employees = arr
      }
      else {
        this.employees = this.allEmp
      }
    },

    async getTasks() {
      let params = {}
      params.pagenum = this.paginationValue;
      params.pagesize = this.pageLength;
      params.search = this.search
      params.field = (this.field ?this.field.value:'')
      params.fieldType = (this.field ?this.field.type:'')
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/gettask`,
        params:params
      };
      await axios(requestOptions)
        .then((response) => {
          this.tasks = response.data.data.data;
          this.paginationTotal = response.data.data.total
          this.paginationStart = response.data.data.from
          this.paginationEnd = response.data.data.to
          if (this.taskItem.id) {
            this.tasks.map((item) => {
              if (item.id == this.taskItem.id) {
                this.taskItem = item;
                this.comments = JSON.parse(item.comments ? item.comments : [])
              }
            })
          }
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>
<style>
.pendingBox {
  position: absolute;
  height: 250px;
  width: 170px;
  background: white;
  top: 20px;
  left: -150px;
  box-shadow: 0 0px 2px 0 black;
  padding-top: 15px;
  border-radius: 10px;
}

.timeline-item {
  padding: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

}

.pendingItem {
  padding: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  position: relative;
  top: -11px;
  text-align: center;

}

.pendingItem:hover {
  border-radius: 10px;

  border: 1px solid #7367f0;
}

.comment {
  margin-top: 10px;
  padding: 10px;
  border-bottom: 1px solid #d8d6de;

}
.infoHeader{
  padding: 10px;
  background: #e5e5e5;
  width: 100%;
  font-weight: bolder;
}
.taskInfo{
  padding: 0px !important;
  border: 1px solid #d8d6de;
  min-height: 600px;
}
.taskInfoDetails{
  padding: 10px;
  height: 95%;
}
.commentForm{
  padding: 10px;
  border-bottom: 1px solid #d8d6de;
}
.commentDetails{
  height: 400px;
  overflow: auto;
}
.tableTh,.tableTd{
  max-width: 80px;
  padding: 10px 10px 10px 5px !important;
  text-align: center;
}
.tableTh{
  font-size: 11px !important;
}
.thead-sticky{
  position: sticky;
  top:0px;
  z-index: 10;
}
</style>
