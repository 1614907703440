<template lang="en">
    <div>
        <div class="d-flex justify-content-between">
            <h4>{{ options.label }}</h4>
                <button type="button" class="btn btn-primary" @click="subItems(options.sub_item,'add','')">Add a row</button>
        </div>  
        <div id="table">
            <table class="table">
                <thead>
                    <th scope="col" v-for="header in options.headers">{{ header.name }}</th>
                </thead>
                <tbody>
                    <tr v-for="(subItem,index) in subItems(options.sub_item,'onload','')">
                        <td v-for="row in subItem">
                            <div v-if="row.type == 'text'">
                                <input v-bind:id="row.name" v-model="form[row.name]" :name="row.name" @input="onInputChange(form)" class="form-control" :class="{ 'is-invalid': submitted && $v.form[row.name] && $v.form[row.name].$error }" :style="row.inputStyle" :type="row.type" :placeholder="row.placeholder" />
                                <div v-if="submitted && $v.form[row.name] && !$v.form[row.name].required" class="invalid-feedback">{{ row.label }} is required</div>
                            </div>   
                            <div v-if="row.type == 'number'">
                                <input v-bind:id="row.name" v-model="form[row.name]" :name="row.name" @input="onInputChange(form)" class="form-control" :class="{ 'is-invalid': submitted && $v.form[row.name] && $v.form[row.name].$error }" :style="row.inputStyle" :type="row.type" :placeholder="row.placeholder" />
                                <div v-if="submitted && $v.form[row.name] && !$v.form[row.name].required" class="invalid-feedback">{{ row.label }} is required</div>
                            </div>
                            <div v-if="row.type == 'email'">
                                <input v-bind:id="row.name" v-model="form[row.name]" :name="row.name" @input="onInputChange(form)" class="form-control" :class="{ 'is-invalid': submitted && $v.form[row.name] && $v.form[row.name].$error }" :style="row.inputStyle" :type="row.type" :placeholder="row.placeholder" />
                                <div v-if="submitted && $v.form[row.name]" class="invalid-feedback">
                                    <span v-if="!$v.form[row.name].required">{{ row.label }} is required</span>
                                    <span v-if="!$v.form[row.name].email">Please enter a valid email.</span>
                                </div>
                            </div>
                            <div v-else-if="row.type == 'dropdown'">                        
                                <multiselect v-model="form[row.name]" deselect-label="Can't remove this value" track-by="name" label="name" :class="{ 'is-invalid': submitted && $v.form[row.name] && $v.form[row.name].$error }" :placeholder="row.placeholder" :options="row.options" :allow-empty="false">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                </multiselect>
                                <div v-if="submitted && $v.form[row.name] && !$v.form[row.name].required" class="invalid-feedback">{{ row.label }} is required</div>
                            </div>                                          
                        </td>
                        <td><button v-if="subItem" type="button" class="btn btn-success" @click="subItems('','remove',index)">Remove</button></td>
                    </tr>
                    <tr v-for="rowTotal in options.total">
                      <td>
                        <div v-if="rowTotal.type == 'text'">
                            <input v-bind:id="rowTotal.name" v-model="form[rowTotal.name]" :name="rowTotal.name" @input="onInputChange(form)" class="form-control" :class="{ 'is-invalid': submitted && $v.form[rowTotal.name] && $v.form[rowTotal.name].$error }" :style="rowTotal.inputStyle" :type="rowTotal.type" :placeholder="rowTotal.placeholder" />
                            <div v-if="submitted && $v.form[rowTotal.name] && !$v.form[rowTotal.name].required" class="invalid-feedback">{{ rowTotal.label }} is required</div>
                        </div>  
                      </td>
                    </tr>
                </tbody>
            </table>
        </div>  
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
/**
 * Form Advanced component
 */
export default {
  props: ["options", "getSubItem"],
  components: {
    DatePicker,
    Multiselect,
  },
  data() {
    return {
      inputFields: [],
      submitted: false,
      selectedValue: null,
      datePicker: "",
      multiValue: [],
      form: {},
      subItemArray: [],
      subItemArrayBlank: [],
      userToken: JSON.parse(localStorage.getItem("user")).token,
    };
  },
  validations() {
    return {
      form: this.options.validations,
    };
  },
  mounted() {
    this.inputFields = this.options.inputFields;
    this.options.sub_item.map((item) => {
      return (this.form = { ...this.form, [item.name]: item.value });
    });
  },
  methods: {
    subItems(item, action, index) {
      if (action == "add") {
        this.subItemArray.push(this.subItemArrayBlank);
      } else if (action == "onload" && this.subItemArray.length == 0) {
        this.subItemArrayBlank = item;
        this.subItemArray.push(item);
        // item.map((a) => {
        //   return (this.subForm = { ...this.form, [a.name]: a.value });
        // });
      } else if (action == "remove") {
        this.onInputChange(this.form);
        this.subItemArray.splice(index, 1);
      }
      return this.subItemArray;
    },
    onInputChange(item) {
      this.getSubItem(item);
    },
  },
};
</script>
<style scoped>
.multiselect__placeholder {
  white-space: nowrap;
}
</style>