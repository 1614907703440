<template>
  <b-row>
    <b-col lg="12">
      <b-card
        class="card-transaction d-fex flex-column justify-content-between"
        no-body
        style="cursor: default"
        v-if="
          $store.getters['app/widgetAccess']('Employee') ||
          this.$store.state.app.user_data.role == 'admin'
        "
      >
        <b-card-header>
          <div class="d-flex justify-content-between align-items-center w-100">
            <h3>Employees</h3>
            <div
              style="width: 100%; padding: 10px"
              class="d-flex justify-content-end mt-1 mb-1"
            >
              <b-row>
                <b-form-input
                  class="Search"
                  v-model="searchEmp"
                  placeholder="Search"
                  @input="debounceInputEmp($event)"
                />
              </b-row>
            </div>
          </div>

          <div class="overflow-auto" style="width: fit-content">
            <div class="d-flex">
              <div
                v-for="employee in employees"
                :key="employee.id"
                class="mainBoxEmployee cursor-pointer"
                @click="handleUser(employee)"
              >
                <div
                  style="
                    border-radius: 50%;
                    width: 90px;
                    border: 5px solid #7367f0;
                    height: 90px;
                  "
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-link>
                    <b-img
                      ref="previewEl"
                      style="border-radius: 50%"
                      :src="
                        employee.profile_image
                          ? employee.profile_image
                          : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                      "
                      height="80"
                      width="80"
                    />
                  </b-link>
                </div>
                <div
                  class="d-flex flex-column justify-content-between mt-1"
                  style="height: 50%"
                >
                  <div class="text-center">
                    <b> {{ employee.name }}&nbsp;{{ employee.surname }}</b>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div class="d-flex flex-column justify-content-center text-promary">
                      <span><b>Leads</b> : {{ employee.totalLead }}</span>
                      <span style="font-size: 11px"
                        ><b>Username</b> : {{ employee.username }}</span
                      >
                      <span style="font-size: 11px"
                        ><b>Mobile</b> : {{ employee.mobile }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-header>
      </b-card>
      <b-card
        class="card-transaction d-fex flex-column justify-content-between"
        no-body
        style="cursor: default"
        v-if="
          $store.getters['app/widgetAccess']('ChannelPartners') ||
          this.$store.state.app.user_data.role == 'admin'
        "
      >
        <b-card-header style="position: sticky !important; top: 0px; background: white">
          <div class="d-flex justify-content-between align-items-center w-100">
            <h3>ChannelPartners</h3>
            <div
              style="width: 100%; padding: 10px"
              class="d-flex justify-content-end mt-1 mb-1"
            >
              <b-row>
                <b-form-input
                  class="Search"
                  v-model="searchCp"
                  placeholder="Search"
                  @input="debounceInputCp($event)"
                />
              </b-row>
            </div>
          </div>

          <div class="overflow-auto" style="width: fit-content">
            <div class="d-flex">
              <div
                v-for="cp in channelpartners"
                :key="cp.id"
                class="mainBoxEmployee cursor-pointer"
                @click="handleUser(cp)"
              >
                <div
                  style="
                    border-radius: 50%;
                    width: 90px;
                    border: 5px solid #7367f0;
                    height: 90px;
                  "
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-link>
                    <b-img
                      ref="previewEl"
                      style="border-radius: 50%"
                      :src="
                        cp.profile_image
                          ? cp.profile_image
                          : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                      "
                      height="80"
                      width="80"
                    />
                  </b-link>
                </div>
                <div
                  class="d-flex flex-column justify-content-between mt-1"
                  style="height: 50%"
                >
                  <div class="text-center">
                    <b> {{ cp.name }}&nbsp;{{ cp.surname }}</b>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div class="d-flex flex-column justify-content-center">
                      <span><b>Leads</b> : {{ cp.totalLead }}</span>
                      <span style="font-size: 11px"
                        ><b>Username</b> : {{ cp.username }}</span
                      >
                      <span style="font-size: 11px"><b>Mobile</b> : {{ cp.mobile }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-header>
      </b-card>
    </b-col>
    <b-modal
      id="modal-form"
      v-model="popUpVisible"
      size="lg"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      title="Lead Details"
      hide-footer
    >
      <b-row>
        <b-col sm="4">
          <div
            class="d-flex justify-content-center align-items-center mb-3"
            style="width: 100%; height: 100%; margin: 0px; max-width: 100%"
          >
            <b-link>
              <b-img
                ref="previewEl"
                style="border-radius: 15px"
                :src="
                  user.profile_image
                    ? user.profile_image
                    : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                "
                height="230"
              />
            </b-link>
          </div>
        </b-col>
        <b-col sm="8" class="popUpInfo">
          <div class="Details d-flex flex-column">
            <p>
              <b-row>
                <b-col md="5">
                  <span class="font-weight-bold h5" style="color: black"> Name:</span>
                </b-col>
                <b-col md="7">
                  <span style="font-size: larger">
                    {{ user.name }} {{ user.surname }}</span
                  >
                </b-col>
              </b-row>
            </p>
            <p>
              <b-row>
                <b-col md="5">
                  <span class="font-weight-bold h5" style="color: black">
                    Designation:</span
                  >
                </b-col>
                <b-col md="7">
                  <span style="font-size: larger"> {{ user.type }}</span>
                </b-col>
              </b-row>
            </p>
            <p>
              <b-row>
                <b-col md="5">
                  <span class="font-weight-bold h5" style="color: black"> ID:</span>
                </b-col>
                <b-col md="7">
                  <span style="font-size: larger"> {{ user.username }}</span>
                </b-col>
              </b-row>
            </p>
            <p>
              <b-row>
                <b-col md="5">
                  <span class="font-weight-bold h5" style="color: black">
                    Mobile No:</span
                  >
                </b-col>
                <b-col md="7">
                  <span style="font-size: larger"> {{ user.mobile }}</span>
                </b-col>
              </b-row>
            </p>
            <p>
              <b-row>
                <b-col md="5">
                  <span class="font-weight-bold h5" style="color: black"> Email:</span>
                </b-col>
                <b-col md="7">
                  <span style="font-size: larger"> {{ user.email }}</span>
                </b-col>
              </b-row>
            </p>
          </div>
        </b-col>
        <b-col sm="12" class="mt-2">
          <b-row>
            <b-col sm="6" md="4" @click="getTotalLeads('total')" class="cursor-pointer">
              <small-info
                iconSize="17"
                roundSize="40"
                icon="UsersIcon"
                statistic="Total Leads"
                :statistic-title="`${user.totalLead}`"
                color="danger"
              />
            </b-col>
            <b-col
              sm="6"
              md="4"
              @click="getDatewiseLeads('Today')"
              class="cursor-pointer"
            >
              <small-info
                iconSize="17"
                roundSize="40"
                icon="UsersIcon"
                statistic="Today's Leads"
                :statistic-title="`${user.todayLead}`"
                color="warning"
              />
            </b-col>
            <b-col
              sm="6"
              md="4"
              @click="getDatewiseLeads('Overdue')"
              class="cursor-pointer"
            >
              <small-info
                iconSize="17"
                roundSize="40"
                icon="UsersIcon"
                statistic="Overdue Leads"
                :statistic-title="`${user.overDue}`"
                color="success"
              />
            </b-col>
            <b-col
              sm="6"
              md="4"
              @click="getDatewiseLeads('Closelead')"
              class="cursor-pointer"
            >
              <small-info
                iconSize="17"
                roundSize="40"
                icon="UsersIcon"
                statistic="Closed Leads"
                :statistic-title="`${user.close}`"
                color="primary"
              />
            </b-col>
            <b-col
              sm="6"
              md="4"
              @click="getDatewiseLeads('Converted')"
              class="cursor-pointer"
            >
              <small-info
                iconSize="17"
                roundSize="40"
                icon="UsersIcon"
                statistic="Converted Leads"
                :statistic-title="`${user.convert}`"
                color="secondary"
              />
            </b-col>
            <b-col sm="6" md="4" @click="getTotalLeads('total')" class="cursor-pointer">
              <small-info
                iconSize="17"
                roundSize="40"
                icon="UsersIcon"
                statistic="Assign Leads"
                :statistic-title="`${user.totalLead}`"
                color="danger"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="12"> <b-card-title> Lead categories </b-card-title></b-col>
            <b-col cols="4" md="2" class="d-flex flex-column justify-content-center">
              <div class="d-flex flex-column">
                <b-badge variant="light-danger"> HOT </b-badge>
                <b-badge variant="light-warning"> Warm </b-badge>
                <b-badge variant="light-info"> COLD </b-badge>
              </div>
            </b-col>
            <b-col cols="8" md="4" class="p-0 pl-2">
              <vue-apex-charts
                height="120"
                type="donut"
                :options="earningsChart.chartOptions"
                :series="series"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" class="mt-2">
          <b-card-title> Lead Details </b-card-title>
          <div class="overflow-auto position-relative" style="max-height: 10cm">
            <table class="table" style="min-width: 300px !important">
              <thead class="text position-sticky" style="top: -1px">
                <tr>
                  <th class="tableElement">Lead Source</th>
                  <th class="tableElement">Lead Stage</th>
                  <th class="tableElement">Lead Name</th>
                  <th class="tableElement">Lead Category</th>
                  <th class="tableElement">Assign To</th>
                  <th class="tableElement">Priority</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, id) in leads"
                  :key="id"
                  style="height: 80px"
                  @click="handleClick(item)"
                >
                  <td class="tableElement">
                    <p>
                      {{
                        item.leadsource && item.leadsource.length > 0
                          ? item.leadsource[0].name
                          : ""
                      }}
                    </p>
                    <p>
                      {{
                        item.subsource && item.subsource.length > 0
                          ? item.subsource[0].name
                          : ""
                      }}
                    </p>
                  </td>
                  <td class="tableElement">
                    <b-badge :variant="statusVariant(item.status)">
                      {{ item.status }}
                    </b-badge>
                  </td>
                  <td class="tableElement">
                    {{ item.name }}
                  </td>
                  <td class="tableElement">
                    {{
                      item.leadcategory && item.leadcategory.length > 0
                        ? item.leadcategory[0].name
                        : ""
                    }}
                  </td>
                  <td class="tableElement">
                    {{
                      item.assignto && item.assignto.length > 0
                        ? item.assignto[0].name
                        : ""
                    }}
                  </td>
                  <td class="tableElement">
                    <b-badge v-if="item.priority" :variant="statusVariant(item.priority)">
                      {{ item.priority }}
                    </b-badge>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
      <div class="mb-2" style="z-index: 1">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '20', '50', '100', '500']"
              class="mx-1"
              @input="key == 'total' ? getTotalLeads('total') : getDatewiseLeads(key)"
            />
            <span class="text-nowrap"
              >{{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal }}</span
            >
          </div>
          <div>
            <b-pagination
              @input="key == 'total' ? getTotalLeads('total') : getDatewiseLeads(key)"
              v-model="paginationValue"
              :total-rows="paginationTotal"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <!-- @input="(value) => props.pageChanged({ currentPage: value })" -->
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </b-modal>
  </b-row>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
  BBadge,
  BLink,
  BFormInput,
  BRow,
  BCol,
  BPagination,
  BFormSelect,
} from "bootstrap-vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import smallInfo from "@core/components/statistics-cards/smallInfo.vue";
import VueApexCharts from "vue-apexcharts";

const $earningsStrokeColor1 = "#ea5455";
const $earningsStrokeColor2 = "#ff9f43";
const $earningsStrokeColor3 = "#00cfe8";

export default {
  components: {
    VueApexCharts,
    smallInfo,
    BCardCode,
    BLink,
    BCard,
    BImg,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BFormInput,
    BRow,
    BCol,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      loginData: JSON.parse(localStorage.getItem("userData")),
      priority: {
        HOT: 0,
        COLD: 0,
        WARM: 0,
      },
      leads: [],
      earningsChart: {
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3],
          labels: ["HOT", "WARM", "COLD"],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor1, $earningsStrokeColor2, $earningsStrokeColor3],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: "Total",
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      clickedItem: 0,
      popUpVisible: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      searchEmp: "",
      searchCp: "",
      finalData: this.visitData,
      employees: [],
      allEmp: [],
      channelpartners: [],
      allCp: [],
      user: {},
      series: [0, 0, 0],
      total: 0,
      key: "",
      paginationValue: 1,
      paginationTotal: 0,
      pageLength: 10,
      paginationStart: 0,
      paginationEnd: 0,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        "In Progress": "primary",
        "Not Interested": "danger",
        "Site Visit": "warning",
        "Schedule Zoom Demo": "info",
        Presentation: "success",
        Lost: "warning",
        Sales: "danger",
        COLD: "info",
        WARM: "warning",
        HOT: "danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  mounted() {
    if (
      this.$store.getters["app/widgetAccess"]("Employee") ||
      this.$store.state.app.user_data.role == "admin"
    ) {
      this.getEmployee();
    }
    if (
      this.$store.getters["app/widgetAccess"]("ChannelPartners") ||
      this.$store.state.app.user_data.role == "admin"
    ) {
      this.getChannelpartner();
    }
  },
  methods: {
    async getDatewiseLeads(key) {
      this.key = key;
      const requestOptionsLeadsSummary = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getTotalLeadData?pagenum=${this.paginationValue}&pagesize=${this.pageLength}`,
        data: JSON.stringify({
          id: this.user.id,
          data: key,
        }),
      };
      await axios(requestOptionsLeadsSummary)
        .then((response) => {
          this.paginationTotal = response.data.total;
          this.paginationStart = response.data.from;
          this.paginationEnd = response.data.to;
          this.leads = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handleClick(item) {
      this.$router.push(`crm/editlead/${item.id}`);
    },
    async handleUser(user) {
      const requestOptions = {
        method: "POST",
        data: {
          id: user.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/employee-with-lead-details-by-id`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.user = response.data.data;
          this.user = { ...this.user, ...user };

          this.total = this.user.hot + this.user.cold + this.user.warm;
          this.popUpVisible = true;
          this.series = [this.user.hot, this.user.warm, this.user.cold];
          this.leads = [];
          this.total = 0;
          this.key = "";
          this.paginationValue = 1;
          this.paginationTotal = 0;
          this.pageLength = 10;
          this.paginationStart = 0;
          this.paginationEnd = 0;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getTotalLeads(key) {
      this.key = key;

      let data = {
        data: "assignto",
        id: this.user.id,
      };
      await axios({
        method: "POST",
        url: `${this.baseApi}/getleadbycategory?pagenum=${this.paginationValue}&pagesize=${this.pageLength}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data,
      }).then((response) => {
        this.leads = response.data.data.data;
        this.paginationTotal = response.data.data.total;
        this.paginationStart = response.data.data.from;
        this.paginationEnd = response.data.data.to;
      });
    },
    debounceInputEmp: _.debounce(function (str) {
      this.handleSearchEmplooyee(str);
    }, 1000),
    debounceInputCp: _.debounce(function (str) {
      this.handleSearchCP(str);
    }, 1000),
    handleSearchEmplooyee(e) {
      let arr = [];
      if (e) {
        this.allEmp.map((item) => {
          if (
            item?.name.toUpperCase().includes(e.toUpperCase()) ||
            item?.surname.toUpperCase().includes(e.toUpperCase()) ||
            item?.username.toUpperCase().includes(e.toUpperCase()) ||
            item?.type.toUpperCase().includes(e.toUpperCase())
          ) {
            arr.push(item);
          }
        });
        this.employees = arr;
      } else {
        this.employees = this.allEmp;
      }
    },
    handleSearchCP(e) {
      let arr = [];
      if (e) {
        this.allCp.map((item) => {
          if (
            item?.name.toUpperCase().includes(e.toUpperCase()) ||
            item?.surname.toUpperCase().includes(e.toUpperCase()) ||
            item?.username.toUpperCase().includes(e.toUpperCase())
          ) {
            arr.push(item);
          }
        });
        this.channelpartners = arr;
      } else {
        this.channelpartners = this.allCp;
      }
    },
    async getEmployee() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/employee-with-lead-details`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.employees = response.data.data;
          this.allEmp = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getChannelpartner() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/channelPartnerWithLeadDetails`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.channelpartners = response.data.data;
          this.allCp = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>
<style>
.empDetails {
  padding-left: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  border-left: 3px solid #ea5455;
  color: black;
}

.mainBoxEmployee {
  min-width: 170px;
  border-radius: 10px;
  padding: 5px;
  margin: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Details {
  padding-left: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  color: black;
  width: 100%;
}

.popUpInfo {
  display: flex;
  border-left: 1px solid #7367f0;
  align-items: center;
}

.tableElement {
  color: black;
  max-width: 80px;
  padding: 10px 10px 10px 5px !important;
  text-align: center;
  font-size: 12px !important;
}
</style>
