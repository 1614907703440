<template>
  <div>

    <b-row class="row justify-content-between">
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Land Use For :</label>
        <p class="viewValue">{{ data.landusefor ? data.landusefor : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel">In SIR?</label>
        <p class="viewValue">{{ sir ?'Yes':'No' }}</p>
      </b-col>

      <b-col md="4" class="mt-2" v-if="sir == 'Yes'">
        <label class="viewLabel">  Zone :</label>
        <p class="viewValue">{{ data.zone ? data.zone : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="sir == 'Yes'">
        <label class="viewLabel"> T.P :</label>
        <p class="viewValue">{{ data.tpno ? data.tpno : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="sir == 'Yes'">
        <label class="viewLabel"> Sub T.P. :</label>
        <p class="viewValue">{{ data.subtpno ? data.subtpno : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Village Name :</label>
        <p class="viewValue">{{ data.villagename ? data.villagename : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Survey No. Old :</label>
        <p class="viewValue">{{ data.surveynoold ? data.surveynoold : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Survey No. New :</label>
        <p class="viewValue">{{ data.surveynonew ? data.surveynonew : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Khata No.:</label>
        <p class="viewValue">{{ data.khatano ? data.khatano : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Original Plot No. (OP) :</label>
        <p class="viewValue">{{ data.orignalplotno ? data.orignalplotno : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Taluka/Tehsil:</label>
        <p class="viewValue">{{ data.taluka ? data.taluka : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Patwari Hulka No. :</label>
        <p class="viewValue">{{ data.patwarihulkano ? data.patwarihulkano : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> State :</label>
        <p class="viewValue">{{ data.state ? data.state : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> City:</label>
        <p class="viewValue">{{ data.dist ? data.dist : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Total Area(In Sq.Yard) :</label>
        <p class="viewValue">{{areainyd}}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Total Area(In Sq.MTR) :</label>
        <p class="viewValue">{{ data.totalarea ? data.totalarea : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Sq. Yard in 1 Bigha:</label>
        <p class="viewValue">{{ data.yardinbigha ? data.yardinbigha : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Total Area(In Bigha) :</label>
        <p class="viewValue">{{ data.totalarea ? data.totalarea : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> Front Road Size(In Sq.MTR):</label>
        <p class="viewValue">{{ data.frontrodesize ? data.frontrodesize : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Corner Plot:</label>
        <p class="viewValue">{{ data.cornerplot ? data.cornerplot : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Side Road Size(In Sq.MTR):</label>
        <p class="viewValue">{{ data.siderodesize1 ? data.siderodesize1 : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Side Road Size(In Sq.MTR) :</label>
        <p class="viewValue">{{ data.siderodesize2 ? data.siderodesize2 : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Other Side Road Size(In Sq.MTR) :</label>
        <p class="viewValue">{{ data.othersideroadsize ? data.othersideroadsize : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> Is FP?:</label>
        <p class="viewValue">{{ data.isfp }}</p>
      </b-col>
      <b-col md="4" class="mt-2" :style="data.isfp == 'Yes' ? '' : 'display:none'">
        <label class="viewLabel"> FP No.:</label>
        <p class="viewValue">{{ data.fpno ? data.fpno : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" :style="data.isfp == 'Yes' ? '' : 'display:none'">
        <label class="viewLabel"> FP Area(In Sq.Yard) :</label>
        <p class="viewValue">{{ fparea }}</p>
      </b-col>
      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> With NA? :</label>
        <p class="viewValue">{{ data.withna ? data.withna : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" :style="data.withna == 'No' ? '' : 'display:none'">
        <label class="viewLabel"> NA Charges <small>(Per Bigha)</small> :</label>
        <p class="viewValue">{{ data.withna ? data.withna : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> Owner's Rate <small>(Per Sq. Yard)</small>:</label>
        <p class="viewValue">{{ ownerrate }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="landusefor == 'Sales'">
        <label class="viewLabel"> CP Final Rate <small>(Per Sq.Yard)</small>:</label>
        <p class="viewValue">{{ data.cprate ? data.cprate : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="landusefor == 'Sales'" >
        <label class="viewLabel"> Market Rate <small>(Per Sq.Yard)</small> :</label>
        <p class="viewValue">{{ data.marketrate ? data.marketrate : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> Total Owner's Amount:</label>
        <p class="viewValue">{{ totalowneramount }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="landusefor == 'Sales'" >
        <label class="viewLabel"> Total CP Final Amount:</label>
        <p class="viewValue">{{ totalcpamount }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="landusefor == 'Sales'" >
        <label class="viewLabel"> Total Market Amount:</label>
        <p class="viewValue">{{ totalmarketamount }}</p>
      </b-col>
      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> Land Payment Time :</label>
        <p class="viewValue">{{ data.paymenttime ? data.paymenttime : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> Token Amount:</label>
        <p class="viewValue">{{ data.tokenamount ? data.tokenamount : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" >
        <label class="viewLabel"> Jantri Rate :</label>
        <p class="viewValue">{{ data.Juntrirate ? data.Juntrirate : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel">Min. Booking Amount:</label>
        <p class="viewValue">{{ data.bookingamount ? data.bookingamount : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="landusefor == 'Yes' ">
        <label class="viewLabel"> Assign To:</label>
        <p class="viewValue">{{ data.assignto && data.assignto.length > 0 ? data.assignto[0].role : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2" v-if="landusefor == 'Sales'">
        <label class="viewLabel">CP/Franchise:</label>
        <p class="viewValue" >{{data.assignto && data.assignto.length>0 ? data.assignto[0].name:''}}</p>

      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Land Proposal Attach Pdf file :</label>
        <div>
          <attachment :data="data.landproposal" />
        </div>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Map Image :</label>
          <img :src="data.mapimage" alt="" class="w-100 h-100">
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Guidline :</label>
        <div>
          <img style="width:100%; height:100%" :src="data.guidline" alt="">
        </div>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> KMZ File :</label>
        <div>
          <img style="width:100%; height:100%" :src="data.kmzfile" alt="">
        </div>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Land Video :</label>
        <iframe
        :src="landvideo"
        class="mt-2 w-100"
        style="border: 5"
        height="200"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      >
      </iframe>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Land Location :</label>
        <iframe
        :src="landloacation"
        class="mt-2 w-100"
        style="border: 5"
        height="200"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      >
      </iframe>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Other Details :</label>
        <p class="viewValue">{{ data.details ? data.details : '' }}</p>
      </b-col>
      <b-col md="4" class="mt-2">
        <label class="viewLabel"> Remarks :</label>
        <p class="viewValue">{{ data.remarks ? data.remarks : '' }}</p>
      </b-col>

      <b-col md="12">
        <h3>Attachments</h3>
      </b-col>
      <b-col md="12" class="mt-2">
        <div style="width:100%; overflow: auto">
          <table class="table table-bordered" style="min-width: 25cm">
            <thead>
              <tr>
                <th style="font-size:11px !important" class="col-md-2">SR. NO.</th>
                <th style="font-size:11px !important">NAME</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data.highlights ? JSON.parse(data.highlights) : []" :key="index">
                <td>{{ index + 1 }}</td>
                <td style="width:100px">{{ item.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import axios from "@/components/axios";
import Attachment from "@/components/Attechment.vue";
export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: {
    closepopup: {
      default: () => { },
    },
    selectedID: {
      default: 0,
    },
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: {},
      loginData: JSON.parse(localStorage.getItem("userData")),

      sir: '',
      landvideo:'',
      landloacation:'',
      landusefor:'',
      assignto:'',
      totalowneramount:'',
      isfp:'',
      fparea:'',
      areainyd:'',
      ownerrate:'',
      cprate:'',
      marketrate:'',


    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getlandmasterById/${this.selectedID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.data = Array.isArray(json.data.data)
            ? json.data.data[0]
            : json.data.data;
          this.selectedPaymentThrough = this.data.paymentthrough ? this.data.paymentthrough : "";
          this.bookingdate = this.data.bookingdate ? this.data.bookingdate : '';
          this.landusefor = this.data.landusefor ? this.data.landusefor : '';
          this.isfp = this.data.isfp ? this.data.isfp : 'No'
          this.fparea = this.data.fparea  ? this.data.fparea  : '';
          this.areainyd = this.data.areainyd ? this.data.areainyd : '';
          this.ownerrate = this.data.ownerrate ? this.data.ownerrate : '';
          this.cprate = this.data.cprate ? this.data.cprate : '';
          this.marketrate = this.data.marketrate ? this.data.marketrate : '';
          this.sir = this.data.zone ? 'Yes' : "No";

          if (this.data.landvideo && this.data.landvideo.includes("src=")) {
            this.landvideo = this.data.landvideo.split('src="')[1].split('"')[0];
        }
        if (this.data.landloacation && this.data.landloacation.includes("src=")) {
            this.landloacation = this.data.landloacation.split('src="')[1].split('"')[0];
        }
        this.handleTotal()
        })
        .catch((error) => console.log(error, "error"));
    },

    handleTotal() {
      this.totalmarketamount =
        this.isfp == "Yes"
          ? this.fparea * this.marketrate
          : this.areainyd * this.marketrate;
      this.totalcpamount =
        this.isfp == "Yes"
          ? this.fparea * this.cprate
          : this.areainyd * this.cprate;
      this.totalowneramount =
        this.isfp == "Yes"
          ? this.fparea * this.ownerrate
          : this.areainyd * this.ownerrate;

      this.totalowneramount = isNaN(this.totalowneramount)
        ? 0
        : parseFloat(this.totalowneramount);

    },
  },
};
</script>
<style>
.viewLabel {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.viewValue {
  font-size: 15px !important;
  color: black;
}
</style>
