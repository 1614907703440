import { render, staticRenderFns } from "./bookingInfo.vue?vue&type=template&id=308ba5dc&scoped=true&"
import script from "./bookingInfo.vue?vue&type=script&lang=js&"
export * from "./bookingInfo.vue?vue&type=script&lang=js&"
import style0 from "./bookingInfo.vue?vue&type=style&index=0&id=308ba5dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308ba5dc",
  null
  
)

export default component.exports