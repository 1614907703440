<template>
  <div>
    <div v-if="this.$props.bundle === 'world'">
      <vue-goodshare-facebook :page_url="page_url" has_icon has_counter title_social="Facebook" />
      <vue-goodshare-linked-in :page_url="page_url" has_icon has_counter title_social="LinkedIn" />
      <vue-goodshare-tumblr :page_url="page_url" has_icon has_counter title_social="Tumblr" />
      <vue-goodshare-pinterest :page_url="page_url" has_icon has_counter title_social="Pinterest" />
      <vue-goodshare-reddit :page_url="page_url" has_icon has_counter title_social="Reddit" />
      <vue-goodshare-twitter :page_url="page_url" has_icon title_social="Twitter" />
      <vue-goodshare-email :page_url="page_url" has_icon title_social="Email" />
      <vue-goodshare-sms :page_url="page_url" has_icon title_social="SMS" />
    </div>
    <div v-if="this.$props.bundle === 'ru'">
      <vue-goodshare-vkontakte :page_url="page_url" has_icon has_counter title_social="Вконтакте" />
      <vue-goodshare-odnoklassniki
        :page_url="page_url" has_icon
        has_counter
        title_social="Одноклассники"
      />
      <vue-goodshare-facebook :page_url="page_url" has_icon has_counter title_social="Фейсбук" />
      <vue-goodshare-moi-mir :page_url="page_url" has_icon has_counter title_social="Мой Мир" />
      <vue-goodshare-twitter :page_url="page_url" has_icon title_social="Твиттер" />
      <vue-goodshare-live-journal :page_url="page_url" has_icon title_social="Живой Журнал" />
      <vue-goodshare-email :page_url="page_url" has_icon title_social="Электронная почта" />
    </div>
    <div v-if="this.$props.bundle === 'mobile'">
      <vue-goodshare-telegram :page_url="page_url" has_icon title_social="Telegram" />
      <vue-goodshare-viber :page_url="page_url" has_icon title_social="Viber" />
      <vue-goodshare-whats-app :page_url="page_url" has_icon title_social="WhatsApp" />
      <vue-goodshare-line :page_url="page_url" has_icon title_social="LINE" />
    </div>
  </div>
</template>

<script>
// Import VueGoodshare social network components
import VueGoodshareVkontakte from "./providers/Vkontakte.vue";
import VueGoodshareOdnoklassniki from "./providers/Odnoklassniki.vue";
import VueGoodshareFacebook from "./providers/Facebook.vue";
import VueGoodshareMoiMir from "./providers/MoiMir.vue";
import VueGoodshareTwitter from "./providers/Twitter.vue";
import VueGoodshareLiveJournal from "./providers/LiveJournal.vue";
import VueGoodshareLinkedIn from "./providers/LinkedIn.vue";
import VueGoodshareTumblr from "./providers/Tumblr.vue";
import VueGoodsharePinterest from "./providers/Pinterest.vue";
import VueGoodshareReddit from "./providers/Reddit.vue";

// Import VueGoodshare mobile messenger components
import VueGoodshareTelegram from "./providers/Telegram.vue";
import { documentHref } from "./helpers/href";
import VueGoodshareViber from "./providers/Viber.vue";
import VueGoodshareWhatsApp from "./providers/WhatsApp.vue";
import VueGoodshareLine from "./providers/Line.vue";

// Import VueGoodshare misc components
import VueGoodshareEmail from "./providers/Email.vue";
import VueGoodshareSms from "./providers/SMS.vue";

export default {
  name: "VueGoodshare",
  props: {
    bundle: {
      type: String,
      default: "world",
    },
    imageUrl: {
      type: String,
      default: documentHref,
    },
    page_url: {
      type: String,
      default: documentHref,
    }
  },
  components: {
    VueGoodshareVkontakte,
    VueGoodshareOdnoklassniki,
    VueGoodshareFacebook,
    VueGoodshareMoiMir,
    VueGoodshareLiveJournal,
    VueGoodshareLinkedIn,
    VueGoodshareTumblr,
    VueGoodsharePinterest,
    VueGoodshareReddit,
    VueGoodshareTwitter,
    VueGoodshareTelegram,
    VueGoodshareViber,
    VueGoodshareWhatsApp,
    VueGoodshareLine,
    VueGoodshareEmail,
    VueGoodshareSms,
  },
};
</script>
