// const params = new URLSearchParams(window.location.search).has("id");
export default [
  {
    path: '/hr/employee',
    name: 'hr-employee',
    component: () => import('@/views/hr/employee/Employee.vue'),
    meta: {
      pageTitle: 'Employee',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Employee',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addemployee',
    alias: '/hr/editemployee',
    name: 'add-employee',
    component: () => import('@/views/hr/employee/AddEmployee.vue'),
    meta: {
      pageTitle: 'Add Employee',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Employee',
          to: '/hr/employee'
        },
        {
          text: 'Add Employee',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editemployee/:id',
    name: 'edit-employee',
    component: () => import('@/views/hr/employee/AddEmployee.vue'),
    meta: {
      pageTitle: 'Edit Employee',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Employee',
          to: '/hr/employee'
        },
        {
          text: 'Edit Employee',
          active: true
        }
      ]
    }
  },

  {
    path: '/hr/register',
    name: 'hr-register',
    component: () => import('@/views/hr/register/Register.vue'),
    meta: {
      pageTitle: 'Register',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Register',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addregister',
    alias: '/hr/editregister',
    name: 'add-register',
    component: () => import('@/views/hr/register/AddRegister.vue'),
    meta: {
      pageTitle: 'Add Register',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Register',
          to: '/hr/register'
        },
        {
          text: 'Add Register',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editregister/:id',
    name: 'edit-register',
    component: () => import('@/views/hr/register/AddRegister.vue'),
    meta: {
      pageTitle: 'Edit Register',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Register',
          to: '/hr/register'
        },
        {
          text: 'Edit Register',
          active: true
        }
      ]
    }
  },



  {
    path: '/hr/courier',
    name: 'hr-courier',
    component: () => import('@/views/hr/courier/Courier.vue'),
    meta: {
      pageTitle: 'Courier',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Courier',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addcourier',
    alias: '/hr/editcourier',
    name: 'add-courier',
    component: () => import('@/views/hr/courier/AddCourier.vue'),
    meta: {
      pageTitle: 'Add Courier',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Courier',
          to: '/hr/courier'
        },
        {
          text: 'Add Courier',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editcourier/:id',
    name: 'edit-courier',
    component: () => import('@/views/hr/courier/AddCourier.vue'),
    meta: {
      pageTitle: 'Edit Courier',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Courier',
          to: '/hr/courier'
        },
        {
          text: 'Edit Courier',
          active: true
        }
      ]
    }
  },


  {
    path: '/hr/hrrequest',
    name: 'hr-hrrequest',
    component: () => import('@/views/hr/hrrequest/Request.vue'),
    meta: {
      pageTitle: 'Request',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Request',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addhrrequest',
    alias: '/hr/edithrrequest',
    name: 'add-hrrequest',
    component: () => import('@/views/hr/hrrequest/AddRequest.vue'),
    meta: {
      pageTitle: 'Add Request',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Request',
          to: '/hr/request'
        },
        {
          text: 'Add Request',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/edithrrequest/:id',
    name: 'edit-hrrequest',
    component: () => import('@/views/hr/hrrequest/AddRequest.vue'),
    meta: {
      pageTitle: 'Edit Request',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Request',
          to: '/hr/herequest'
        },
        {
          text: 'Edit Request',
          active: true
        }
      ]
    }
  },



  {
    path: '/hr/dailyworking',
    name: 'hr-dailyworking',
    component: () => import('@/views/hr/dailyworking/DailyWorking.vue'),
    meta: {
      pageTitle: 'Daily Working',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Daily Working',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/adddailyworking',
    name: 'add-dailyworking',
    component: () => import('@/views/hr/dailyworking/AddDailyWorking.vue'),
    meta: {
      pageTitle: 'Add Daily Working',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Daily Working',
          to: '/hr/dailyworking'
        },
        {
          text: 'Add Daily Working',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editdailyworking/:id',
    name: 'edit-dailyworking',
    component: () => import('@/views/hr/dailyworking/AddDailyWorking.vue'),
    meta: {
      pageTitle: 'Edit Daily Woking',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Daily Woking',
          to: '/hr/dailyworking'
        },
        {
          text: 'Edit Daily Working',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/attendance',
    name: 'hr-attendance',
    component: () => import('@/views/hr/attendance/Attendance.vue'),
    meta: {
      pageTitle: 'Attendance',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Attendance',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/hr/addattendance",
  //   alias: "/hr/editattendance",
  //   name: "add-attendance",
  //   component: () => import("@/views/hr/attendance/AddAttendance.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "HR",
  //         active: true,
  //       },
  //       {
  //         text: "Attendance",
  //         to: "/hr/attendance",
  //       },
  //       {
  //         text: `${params ? "Edit Attendance" : "Add Attendance"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/hr/addtour',
    alias: '/hr/edittour',
    name: 'add-tour',
    component: () => import('@/views/hr/attendance/AddTour.vue'),
    meta: {
      pageTitle: 'Add Tour',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Attendance',
          to: '/hr/tour'
        },
        {
          text: 'Add Tour',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/edittour/:id',
    name: 'edit-tour',
    component: () => import('@/views/hr/attendance/AddTour.vue'),
    meta: {
      pageTitle: 'Edit Tour',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Attendance',
          to: '/hr/tour'
        },
        {
          text: 'Edit Tour',
          active: true
        }
      ]
    }
  },
  // {
  //   path: '/master/assign-rights',
  //   name: 'master-assign-rights',
  //   component: () => import('@/views/hr/assignrights/AssignRights.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'HR',
  //         active:true
  //       },
  //       {
  //         text: 'Assign Rights',
  //         active: true
  //       },
  //     ]
  //   }
  // },
  {
    path: '/hr/leaves',
    name: 'hr-leaves',
    component: () => import('@/views/hr/leaves/Leaves.vue'),
    meta: {
      pageTitle: 'Leaves',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Leaves',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/adddocument',
    alias: '/hr/editdocument',
    name: 'add-document',
    component: () => import('@/views/hr/document/AddDocument.vue'),
    meta: {
      pageTitle: 'Add Document',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Document',
          to: '/hr/document'
        },
        {
          text: 'Add Document',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editdocument/:id',
    name: 'edit-document',
    component: () => import('@/views/hr/document/AddDocument.vue'),
    meta: {
      pageTitle: 'Edit Document',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Document',
          to: '/hr/document'
        },
        {
          text: 'Edit Document',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/document',
    name: 'hr-document',
    component: () => import('@/views/hr/document/Document.vue'),
    meta: {
      pageTitle: 'Document',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Document',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addleaves',
    name: 'add-leaves',
    component: () => import('@/views/hr/leaves/AddLeaves.vue'),
    meta: {
      pageTitle: 'Add Leaves',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Leaves',
          to: '/hr/leaves'
        },
        {
          text: 'Add Leaves',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editleaves/:id',
    name: 'edit-leaves',
    component: () => import('@/views/hr/leaves/AddLeaves.vue'),
    meta: {
      pageTitle: 'Edit Leaves',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Leaves',
          to: '/hr/leaves'
        },
        {
          text: 'Edit Leaves',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/salary',
    name: 'hr-salary',
    component: () => import('@/views/hr/salary/Salary.vue'),
    meta: {
      pageTitle: 'Salary',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Salary',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addsalary',
    alias: '/hr/editsalary',
    name: 'add-salary',
    component: () => import('@/views/hr/salary/AddSalary.vue'),
    meta: {
      pageTitle: 'Add Salary',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Salary',
          to: '/hr/salary'
        },
        {
          text: 'Add Salary',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editsalary/:id',
    name: 'edit-salary',
    component: () => import('@/views/hr/salary/AddSalary.vue'),
    meta: {
      pageTitle: 'Edit Salary',
      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Salary',
          to: '/hr/salary'
        },
        {
          text: 'Edit Salary',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/memo',
    name: 'hr-memo',
    component: () => import('@/views/hr/memo/Memo.vue'),
    meta: {
      pageTitle: 'Memo',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Memo',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addmemo',
    alias: '/hr/editmemo',
    name: 'add-memo',
    component: () => import('@/views/hr/memo/AddMemo.vue'),
    meta: {
      pageTitle: 'Add Memo',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Memo',
          to: '/hr/memo'
        },
        {
          text: 'Add Memo',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editmemo/:id',
    name: 'edit-memo',
    component: () => import('@/views/hr/memo/AddMemo.vue'),
    meta: {
      pageTitle: 'Edit Memo',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Memo',
          to: '/hr/memo'
        },
        {
          text: 'Edit Memo',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/consultant',
    name: 'hr-consultant',
    component: () => import('@/views/hr/consultant/Consultant.vue'),
    meta: {
      pageTitle: 'Consultant',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'consultant',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/consultant",
  //   name: "add-consultant",
  //   component: () => import("@/views/hr/consultant/AddConsultant.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "HR",
  //         active: true,
  //       },
  //       {
  //         text: "Channel Partner",
  //         to: "/hr/consultant",
  //       },
  //       {
  //         text: `${params ? "Edit Channel Partner" : "Add Channel Partner"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/hr/resume',
    name: 'hr-resume',
    component: () => import('@/views/hr/resume/Resume.vue'),
    meta: {
      pageTitle: 'Resume',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Resume',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addresume',
    alias: '/hr/editresume',
    name: 'add-resume',
    component: () => import('@/views/hr/resume/AddResume.vue'),
    meta: {
      pageTitle: 'Add Resume',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Resume',
          to: '/hr/resume'
        },
        {
          text: 'Add Resume',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editresume/:id',
    name: 'edit-resume',
    component: () => import('@/views/hr/resume/AddResume.vue'),
    meta: {
      pageTitle: 'Edit Resume',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Resume',
          to: '/hr/resume'
        },
        {
          text: 'Edit Resume',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/assettracker',
    name: 'ht-assettracker',
    component: () => import('@/views/hr/assettracker/AssetTracker.vue'),
    meta: {
      pageTitle: 'Asset Tracker',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Asset Tracker',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addassettracker',
    alias: '/hr/editassettracker',
    name: 'add-assettracker',
    component: () => import('@/views/hr/assettracker/AddAssetTracker.vue'),
    meta: {
      pageTitle: 'Add Asset Tracker',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Asset Tracker',
          to: '/hr/assettracker'
        },
        {
          text: 'Add Asset Tracker',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editassettracker/:id',
    name: 'edit-assettracker',
    component: () => import('@/views/hr/assettracker/AddAssetTracker.vue'),
    meta: {
      pageTitle: 'Edit Asset Tracker',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Asset Tracker',
          to: '/hr/assettracker'
        },
        {
          text: 'Edit Asset Tracker',
          active: true
        }
      ]
    }
  },

  {
    path: '/hr/jobvacancy',
    name: 'ht-jobvacancy',
    component: () => import('@/views/hr/jobvacancy/JobVacancy.vue'),
    meta: {
      pageTitle: 'Job Vacancy',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Job Vacancy',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addjobvacancy',
    alias: '/hr/editjobvacancy',
    name: 'add-jobvacancy',
    component: () => import('@/views/hr/jobvacancy/AddJobVacancy.vue'),
    meta: {
      pageTitle: 'Add Job Vacancy',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Job Vacancy',
          to: '/hr/jobvacancy'
        },
        {
          text: 'Add Job Vacancy',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editjobvacancy/:id',
    name: 'edit-jobvacancy',
    component: () => import('@/views/hr/jobvacancy/AddJobVacancy.vue'),
    meta: {
      pageTitle: 'Edit Job Vacancy',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Job Vacancy',
          to: '/hr/jobvacancy'
        },
        {
          text: 'Edit Job Vacancy',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/employeeperformancemanagement',
    name: 'hr-employeeperformancemanagement',
    component: () => import(
      '@/views/hr/employeeperformancemanagement/EmployeePerformanceManagement.vue'
    ),
    meta: {
      pageTitle: 'Employee Performance Management',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Employee Performance Management',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/addemployeeperformancemanagement',
    alias: '/hr/editemployeeperformancemanagement',
    name: 'add-employeeperformancemanagement',
    component: () => import(
      '@/views/hr/employeeperformancemanagement/AddEmployeePerformanceManagement.vue'
    ),
    meta: {
      pageTitle: 'Add Employee Performance',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Employee Performance Management',
          to: '/hr/employeeperformancemanagement'
        },
        {
          text
            : 'Add Employee Performance Management',
          active: true
        }
      ]
    }
  },
  {
    path: '/hr/editemployeeperformancemanagement/:id',
    name: 'edit-employeeperformancemanagement',
    component: () => import(
      '@/views/hr/employeeperformancemanagement/AddEmployeePerformanceManagement.vue'
    ),
    meta: {
      pageTitle: 'Edit Employee Performance',

      breadcrumb: [
        {
          text: 'HR',
          active: true
        },
        {
          text: 'Employee Performance Management',
          to: '/hr/employeeperformancemanagement'
        },
        {
          text: 'Edit Employee Performance Management',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/hr/recruitment",
  //   name: "hr-recruitment",
  //   component: () => import("@/views/hr/recruitment/Recruitment.vue"),
  //   meta: {

  //     breadcrumb: [
  //       {
  //         text: "HR",
  //         active: true,
  //       },
  //       {
  //         text: "Recruitment",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/recruitment",
  //   name: "add-recruitment",
  //   component: () => import("@/views/hr/recruitment/AddRecruitment.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "HR",
  //         active: true,
  //       },
  //       {
  //         text: "Add Recruitment",
  //         to: "/hr/recruitment",
  //       },
  //       {
  //         text: `${params ? "Edit Recruitment" : "Add Recruitment"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/crm/tree',
    name: 'crm-tree',
    component: () => import('@/views/hr/tree/Tree.vue'),
    meta: {
      pageTitle: 'Tree',

      breadcrumb: [
        {
          text: 'CRM',
          active: true
        },
        {
          text: 'Tree',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/hr/zoom",
  //   name: "hr-zoom",
  //   component: () => import("@/views/hr/zoom/Zoom.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "HR",
  //         active: true,
  //       },
  //       {
  //         text: "Zoom",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/zoom",
  //   name: "add-zoom",
  //   component: () => import("@/views/hr/zoom/AddZoom.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "HR",
  //         active: true,
  //       },
  //       {
  //         text: "Add Zoom",
  //         to: "/hr/zoom",
  //       },
  //       {
  //         text: `${params ? "Edit Zoom" : "Add Zoom"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/hr/openingleavebalance",
  //   name: "hr-openingleavebalance",
  //   component: () =>
  //     import("@/views/hr/openingleavebalance/OpeningLeaveBalance.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "HR",
  //         active: true,
  //       },
  //       {
  //         text: "Opening Leave Balance",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/openingleavebalance",
  //   name: "add-openingleavebalance",
  //   component: () =>
  //     import("@/views/hr/openingleavebalance/AddOpeningLeaveBalance.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "HR",
  //         active: true,
  //       },
  //       {
  //         text: "Add Opening Leave Balance",
  //         to: "/hr/openingleavebalance",
  //       },
  //       {
  //         text: `${params ? "Edit Opening Leave Balance" : "Add Opening Leave Balance"
  //           }`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
