var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.$store.getters['app/widgetAccess']('Total Users Details') ||
      _vm.$store.state.app.user_data.type == 'admin'
    )?_c('b-row',_vm._l((_vm.first),function(item){return _c('b-col',{key:item.subtitle,staticClass:"cursor-pointer",attrs:{"lg":"3","sm":"6"},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('statistic-card-horizontal',{attrs:{"icon":item.icon,"statistic":item.title,"statistic-title":item.subtitle,"color":item.color}})],1)}),1):_vm._e(),(
      _vm.$store.getters['app/widgetAccess']('Business Details') ||
      _vm.$store.state.app.user_data.type == 'admin'
    )?_c('b-row',_vm._l((_vm.second),function(item){return _c('b-col',{key:item.subtitle,attrs:{"lg":"3","sm":"6"}},[_c('statistic-card-with-area-chart',{attrs:{"icon":item.icon,"statistic":_vm.kFormatter(item.title),"statistic-title":item.subtitle,"color":item.color,"chart-data":item.data}})],1)}),1):_vm._e(),(
      _vm.$store.getters['app/widgetAccess']('Plot-User-Sitevisit') ||
      _vm.$store.state.app.user_data.type == 'admin'
    )?_c('b-row',[_vm._l((_vm.third),function(item){return _c('b-col',{key:item.subtitle,staticClass:"cursor-pointer",attrs:{"lg":"3","sm":"6"},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('statistic-card-horizontal',{attrs:{"icon":item.icon,"statistic":item.title,"statistic-title":item.subtitle,"color":item.color}})],1)}),_c('b-col',{staticClass:"cursor-pointer",attrs:{"lg":"3","sm":"6"},on:{"click":function($event){return _vm.handleClickvisit()}}},[_c('statistic-card-horizontal',{attrs:{"icon":"UserIcon","statistic":_vm.visitCount,"statistic-title":"Site Visit Request","color":"warning"}})],1)],2):_vm._e(),(
      _vm.$store.getters['app/widgetAccess']('KYC') ||
      _vm.$store.state.app.user_data.type == 'admin'
    )?_c('b-row',[_c('b-col',{staticClass:"cursor-pointer",attrs:{"lg":"3","sm":"6"},on:{"click":function($event){return _vm.handleClickKyc('channelpartner')}}},[_c('statistic-card-horizontal',{attrs:{"icon":"UsersIcon","statistic":_vm.pending_kyc_cp,"statistic-title":"KYC Pending CP","color":"primary"}})],1),_c('b-col',{staticClass:"cursor-pointer",attrs:{"lg":"3","sm":"6"},on:{"click":function($event){return _vm.handleClickKyc('franchise')}}},[_c('statistic-card-horizontal',{attrs:{"icon":"UsersIcon","statistic":_vm.pending_kyc_fr,"statistic-title":"KYC Pending Franchise","color":"success"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }