<template>
  <b-card no-body>
    <b-card-body class="pb-0">
      <b-avatar class="mb-1" :variant="`light-${color}`" size="45">
        <svg
          v-if="icon == 'rupee'"
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          fill="currentColor"
          class="bi bi-currency-rupee"
          viewBox="0 0 16 16"
        >
          <path
            d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z"
          />
        </svg>
        <feather-icon v-else size="21" :icon="icon" />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ statistic }}
        </h2>
        <span>{{ statisticTitle }}</span>
      </div>
    </b-card-body>

    <vue-apex-charts
    id="chart"
      type="area"
      ref="tooltip"
      height="100"
      width="100%"
      :options="chartOptionsComputed"
      :series="chartData"
    />
<!-- <div id="chart">
</div> -->
  </b-card>
</template>
// <script src="https://cdn.jsdelivr.net/npm/apexcharts"></script>
<script>
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { areaChartOptions } from "./chartOptions";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
    BAvatar,
  },
  data() {
    return {
      tooltip: {
    shared: true,
    custom: ({
        series,
        seriesIndex,
        dataPointIndex,
        w,
    }) =>
        this.customSharedTooltip({
            series,
            seriesIndex,
            dataPointIndex,
            w,
        }),
},
    };
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  mounted(){
    document.getElementsByClassName('apexcharts-tooltip').innerHTML = "I have changed!";
},
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(areaChartOptions));
        options.theme.monochrome.color = $themeColors[this.color];
        return options;
      }
      return this.chartOptions;
    },
  },
};
</script>
