<template>
  <b-card
    class="d-flex justify-content-center"
    no-body
    v-if="visitDataItem && visitDataItem.action == 0"
  >
    <b-alert variant="danger" show>
      <h4 class="alert-heading">
        {{ "SiteVisit has been cancelled".toUpperCase() }}
      </h4>
      <div class="alert-body">
        <span
          >This SiteVisit cancelled By {{ visitDataItem.modifiedby.name }} ({{
            visitDataItem.source.id == visitDataItem.modifiedby.id
              ? "Source"
              : ""
          }}{{
            visitDataItem.manager &&
            visitDataItem.manager.id == visitDataItem.modifiedby.id
              ? "Manager"
              : "Admin"
          }})</span
        >
        <p>
          SiteVisit cancelled on
          {{ visitDataItem.modifieddatetime.split("T")[0] }} At
          {{ visitDataItem.modifieddatetime.split("T")[1].split(".")[0] }}
        </p>
      </div>
    </b-alert>
  </b-card>
  <b-card no-body class="card-developer-meetup" v-else-if="visitDataItem">
    <b-row>
      <b-col md="5">
        <div class="bg-light-primary rounded-top text-center">
          <b-img
            :src="require('@/assets/images/illustration/email.svg')"
            alt="Meeting Pic"
            height="170"
          />
        </div>
        <b-card-body>
          <!-- metting header -->
          <div class="meetup-header d-flex align-items-center">
            <div class="meetup-day">
              <h6 class="mb-0">
                {{
                  visitDataItem.arrivaldate
                    ? moment(
                        visitDataItem.arrivaldate.split("/").reverse().join("-")
                      )
                        .format("dddd")
                        .slice(0, 3)
                        .toUpperCase()
                    : ""
                }}
              </h6>
              <h3 class="mb-0">
                {{
                  visitDataItem.arrivaldate
                    ? visitDataItem.arrivaldate.split("/")[0]
                    : ""
                }}
              </h3>
            </div>
            <div class="my-auto">
              <b-card-title class="mb-25">
                Pickup From {{ visitDataItem.pickuppoint }}
              </b-card-title>
              <b-card-text
                v-if="
                  (visitDataItem.assistant
                    ? visitDataItem.assistant.id
                    : null) == userData.id || visitDataItem.driver
                    ? visitDataItem.driver
                    : null == userData.id ||
                      (visitDataItem.manager &&
                        visitDataItem.manager.id == userData.id)
                "
                class="mb-0"
              >
                You go now as a
                {{
                  (visitDataItem.assistant
                    ? visitDataItem.assistant.id
                    : null) == userData.id
                    ? "Assistant"
                    : ""
                }}
                {{
                  visitDataItem.driver
                    ? visitDataItem.driver.id
                    : null == userData.id
                    ? "Driver"
                    : ""
                }}
                {{
                  visitDataItem.manager &&
                  visitDataItem.manager.id == userData.id
                    ? "Manager"
                    : ""
                }}
              </b-card-text>
              <b-card-text
                v-if="visitDataItem.source.id == userData.id"
                class="mb-0"
              >
                You are Source For This Visit
              </b-card-text>
              <b-card-text class="mb-0">
                Vihicle NO:-
                <span class="text-danger font-weight-bold">{{
                  visitDataItem.vehicleno
                }}</span>
              </b-card-text>
            </div>
          </div>
          <!--/ metting header -->

          <!-- media -->
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-primary" size="34">
                <feather-icon icon="CalendarIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{
                  visitDataItem.arrivaldate
                    ? moment(
                        visitDataItem.arrivaldate.split("/").reverse().join("-")
                      )
                        .format("dddd")
                        .slice(0, 1)
                        .toUpperCase()
                    : ""
                }}{{
                  visitDataItem.arrivaldate
                    ? moment(
                        visitDataItem.arrivaldate.split("/").reverse().join("-")
                      )
                        .format("dddd")
                        .slice(1)
                        .slice(0, 2)
                    : ""
                }}
                ,{{
                  visitDataItem.arrivaldate
                    ? moment(
                        visitDataItem.arrivaldate.split("/").reverse().join("-")
                      ).format("MMM Do YY")
                    : ""
                }}
                <!-- May 25, 2020 -->
              </h6>
              <small>{{ visitDataItem.arrivaltime }}</small>
            </b-media-body>
          </b-media>

          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-primary" size="34">
                <feather-icon icon="MapPinIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">{{ visitDataItem.pickuppoint }}</h6>
              <small>{{ visitDataItem.vehicleno }}</small>
            </b-media-body>
          </b-media>

          <!-- avatar group -->
          <b-avatar-group class="mt-2 pt-50" size="33">
            <b-avatar
              v-for="(avatar, id) in avatars"
              :key="id"
              v-b-tooltip.hover.bottom="avatar.fullName"
              :src="avatar.avatar"
              class="pull-up"
            />
            <h6 class="align-self-center cursor-pointer ml-1 mb-0">
              +{{ visitDataItem.person ? visitDataItem.person.length : 0 }}
              {{
                visitDataItem.person && visitDataItem.person.length > 1
                  ? "Persons"
                  : "Person"
              }}
            </h6>
          </b-avatar-group>
        </b-card-body>
      </b-col>
      <b-col
        style="overflow-y: scroll; height: 15cm"
        class="py-2 pr-2 example"
        md="7"
      >
        <app-timeline>
          <app-timeline-item
            :fillBorder="visitDataItem.active >= 1 ? true : (visitDataItem.timeline[1].start && !visitDataItem.timeline[1].end?true:false )"
            :time="visitDataItem.arrivaldate"
            :icon="'CalendarIcon'"
            :variant="visitDataItem.active >= 1 ? 'success' : 'primary'"
          >
            <div
              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 p-1"
            >
              <h5>
                <span style="font-weight: 600 !important">
                  {{
                    visitDataItem.clientname
                      ? visitDataItem.clientname.name + ' '+visitDataItem.clientname.surname
                      : ""
                  }}
                </span>
                will coming by {{ visitDataItem.travelby }} At
                {{ visitDataItem.arrivaltime }} And
                {{
                  visitDataItem.driver ? visitDataItem.driver.name + ' '+  visitDataItem.driver.surname : "Driver"
                }}
                will Pickup from
                {{ visitDataItem.pickuppoint }}
              </h5>
              <p>
                <b-badge
                  variant="primary"
                  v-if="visitDataItem.arrivaldate"
                  class="badge-glow m-0"
                >
                  {{ visitDataItem.arrivaldate }}
                </b-badge>
              </p>
            </div>
            <p>
              This Visit will manage by
              <span style="font-weight: 600 !important">{{
                visitDataItem.manager ? (visitDataItem.manager.name  + ' ' +visitDataItem.manager.surname) : ""
              }}</span>
              As a Manager
            </p>
            <p>
              Pickup will Attend By
              <span style="font-weight: 600 !important">{{
                visitDataItem.driver ? (visitDataItem.driver.name + ' '+visitDataItem.driver.surname ) : "Driver"
              }}</span>
              As a Driver
            </p>
            <p v-if="visitDataItem.timeline && visitDataItem.timeline[1].start">
              <span class="font-weight-bolder">Pickup Datetime :</span
              >{{ visitDataItem.timeline[1].start }}
            </p>
            <div class="d-flex justify-content-between align-items-center">
              <user-profile-view
                v-if="visitDataItem.source"
                :number="visitDataItem.source.mobile"
                :name="visitDataItem.source ? (visitDataItem.source.name + ' '+visitDataItem.source.surname)  : ''"
                :type="'Source'"
                :img="visitDataItem.source.profile_image"
              />
              <b-button
                v-if="
                  (userData.id ==
                    (visitDataItem.driver ? visitDataItem.driver.id : null) ||
                    userData.id ==
                      (visitDataItem.manager
                        ? visitDataItem.manager.id
                        : null) ||
                    userData.id ==
                    (visitDataItem.assistant
                      ? visitDataItem.assistant.id
                      : null)
                        ) &&
                  visitDataItem.active == 0 &&
                  visitDataItem.timeline &&
                  !visitDataItem.timeline[1].start
                "
                size="sm"
                variant="primary"
                @click="handleTimeline(1)"
                >Start</b-button
              >
            </div>
            <user-profile-view
              v-if="visitDataItem.manager"
              :number="visitDataItem.manager.mobile"
              :name="visitDataItem.manager ? (visitDataItem.manager.name+ ' '+visitDataItem.manager.surname) : ''"
              :type="'Manager'"
              :img="visitDataItem.manager.profile_image"
            />
            <user-profile-view
              v-if="visitDataItem.driver"
              :number="visitDataItem.driver.mobile"
              :name="visitDataItem.driver ? (visitDataItem.driver.name+ ' '+visitDataItem.driver.surname) : ''"
              :type="'Driver'"
              :img="visitDataItem.driver.profile_image"
            />

            <div class="py-1">
              <h5 v-if="visitDataItem.isstay == 0">
                Drop Location:- Sitevisit
              </h5>
              <h5 v-else>Drop Location:- {{ visitDataItem.stay }}</h5>
            </div>
            <div class="w-100">
              <p v-if="visitDataItem.timeline && visitDataItem.timeline[2].end">
                <span class="font-weight-bolder">Drop Datetime :</span
                >{{ visitDataItem.timeline[2].end }}
              </p>
            </div>
            <b-alert
              variant="danger"
              show
              v-if="JSON.stringify(visitDataItem.emergencyremarks) !== '[]'"
            >
              <h4 class="alert-heading">Special NOTE:-</h4>
              <div
                v-for="(item, index) in visitDataItem.emergencyremarks"
                :key="index"
                class="alert-body"
              >
                <span>{{ item.remarks }}</span>
              </div>
            </b-alert>
            <div class="d-flex justify-content-between">
              <p class="font-weight-bold">
                NOTE:-
                <span class="text-danger">{{
                  visitDataItem.remarks && visitDataItem.remarks.remarks
                    ? visitDataItem.remarks.remarks
                    : ""
                }}</span>
                <span class="text-danger">{{
                  visitDataItem.remarks && visitDataItem.remarks.driverRemarks
                    ? visitDataItem.remarks.driverRemarks
                    : ""
                }}</span>
                <span class="text-danger">{{
                  visitDataItem.remarks && visitDataItem.remarks.vehicleRemarks
                    ? visitDataItem.remarks.vehicleRemarks
                    : ""
                }}</span>
              </p>
              <b-button
              class="mb-1"
                v-if="
                  (userData.id ==
                    (visitDataItem.driver ? visitDataItem.driver.id : null) ||
                    userData.id ==
                      (visitDataItem.manager
                        ? visitDataItem.manager.id
                        : null) ||
                      userData.id ==
                      (visitDataItem.assistant
                        ? visitDataItem.assistant.id
                        : null)
                        ) &&
                  visitDataItem.active == 0 &&
                  visitDataItem.timeline &&
                  visitDataItem.timeline[1].start
                "
                @click="completeTask(1)"
                size="sm"
                variant="primary"
                >Complete</b-button
              >
              <p
                class="text-success font-weight-bolder"
                v-if="visitDataItem.active > 0"
              >
                Completed
              </p>
            </div>
          </app-timeline-item>
          <!-- <app-timeline-item v-if="isstay == 1" variant="primary"> -->
          <app-timeline-item
            v-if="visitDataItem.isstay == 1"
            :fillBorder="visitDataItem.active >= 2 ? true : (visitDataItem.timeline[2].start && !visitDataItem.timeline[2].end ? true : false)"
            :variant="visitDataItem.active >= 2 ? 'success' : 'primary'"
          >
            <div
              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
            >
              <h5>
                <span style="font-weight: 600 !important">
                  {{ visitDataItem.clientname.name }} {{  visitDataItem.clientname.surname }}
                </span>
                will Stay in
                {{
                  visitDataItem.stay == "Hotel"
                    ? visitDataItem.hotelname + "(Hotel)"
                    : visitDataItem.stay
                }}
                From {{ visitDataItem.accommodationstart }} to
                {{ visitDataItem.accommodationend }}.
              </h5>


              <p>
                <b-badge
                  variant="primary"
                  v-if="visitDataItem.accommodationstart"
                  class="badge-glow m-0"
                >
                  {{ visitDataItem.accommodationstart }}
                </b-badge>
                <b-button
                  class="mt-1"
                  v-if="
                    (userData.id ==
                      (visitDataItem.manager ? visitDataItem.manager.id : '') ||
                      userData.id ==
                        (visitDataItem.assistant
                          ? visitDataItem.assistant.id
                          : 0) ||
                      userData.type ==
                        (visitDataItem.stay == 'Banglow'
                          ? 'bungalowmanager'
                          : visitDataItem.stay == 'Greentak'
                          ? 'guesthousemanager'
                          : visitDataItem.stay == 'Farm House'
                          ? 'farmhousemanager'
                          : 'sitemanager')) &&
                    visitDataItem.active == 1 &&
                    visitDataItem.timeline &&
                    !visitDataItem.timeline[2].start
                  "
                  variant="primary"
                  size="sm"
                  @click="handleTimeline(2)"
                  >Start</b-button
                >
              </p>

            </div>
            <p v-if="visitDataItem.timeline && visitDataItem.timeline[2].start">
              <span class="font-weight-bolder">Pickup Datetime :</span
              >{{ visitDataItem.timeline[2].start }}
            </p>
            <user-profile-view
              v-if="visitDataItem.clientname"
              :number="visitDataItem.clientname.mobile"
              :name="visitDataItem.clientname.name + ' ' + visitDataItem.clientname.surname"
              :type="'Client'"
              :img="visitDataItem.clientname.profile_image"
            />
            <div class="w-100 mt-1">
              <p v-if="visitDataItem.timeline && visitDataItem.timeline[2].end">
                <span class="font-weight-bolder">Drop Datetime :</span
                >{{ visitDataItem.timeline[2].end }}
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <b-button
                v-if="
                  (userData.id ==(visitDataItem.manager ? visitDataItem.manager.id : '') ||
                    userData.id ==(visitDataItem.assistant? visitDataItem.assistant.id: 0) ||
                    userData.type ==
                      (visitDataItem.stay == 'Banglow' ? 'bungalowmanager'
                        : visitDataItem.stay == 'Greentak'
                        ? 'guesthousemanager'
                        : visitDataItem.stay == 'Farm House'
                        ? 'farmhousemanager'
                        : 'sitemanager')) &&
                  visitDataItem.active == 1 &&
                  visitDataItem.timeline &&
                  visitDataItem.timeline[2].start && !visitDataItem.timeline[2].end "
                @click="completeTask(2)"
                size="sm"
                variant="primary"
                >Complete</b-button
              >
              <p
                class="text-success font-weight-bolder"
                v-if="visitDataItem.active > 1"
              >
                Completed
              </p>
            </div>
          </app-timeline-item>
          <app-timeline-item
            :fillBorder="visitDataItem.active >= 3 ? true : (visitDataItem.timeline[3].start && !visitDataItem.timeline[3].end ? true : false)"
            :variant="visitDataItem.active >= 3 ? 'success' : 'primary'"
          >
            <div
              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
            >
              <h4>GO for SiteVisit at {{ visitDataItem.visittime }} with</h4>
              <p>
                <b-badge
                  variant="primary"
                  v-if="visitDataItem.visitdate"
                  class="badge-glow m-0"
                >
                  {{ visitDataItem.visitdate }}
                </b-badge>
              </p>
            </div>
            <p v-if="visitDataItem.timeline && visitDataItem.timeline[3].start">
              <span class="font-weight-bolder">Pickup Datetime :</span
              >{{ visitDataItem.timeline[3].start }}
            </p>
            <div class="d-flex justify-content-between align-items-center">
              <user-profile-view
                v-if="
                  visitDataItem.withcp == 'Yes' && visitDataItem.source.mobile
                "
                :number="visitDataItem.source.mobile"
                :name="visitDataItem.source.name+ ' '+ visitDataItem.source.surname "
                :type="'Source'"
                :img="visitDataItem.source.profile_image"
              />

              <b-button
                v-if="
                  (userData.id ==
                    (visitDataItem.manager ? visitDataItem.manager.id : null) ||
                    userData.id ==
                      (visitDataItem.driver
                        ? visitDataItem.driver.id
                        : null) ||
                    userData.id ==
                    (visitDataItem.assistant
                      ? visitDataItem.assistant.id
                      : null)
                        ) &&
                  (visitDataItem.isstay == 1
                    ? visitDataItem.active == 2
                    : visitDataItem.active == 1) &&
                  visitDataItem.timeline &&
                  !visitDataItem.timeline[3].start
                "
                @click="handleTimeline(3)"
                size="sm"
                variant="primary"
                >Start</b-button
              >
            </div>

            <user-profile-view
              v-if="visitDataItem.driver"
              :number="visitDataItem.driver.mobile"
              :name="visitDataItem.driver.name +' '+visitDataItem.driver.surname"
              :type="'Driver'"
              :img="visitDataItem.driver.profile_image"
            />

            <user-profile-view
              v-if="visitDataItem.manager"
              :number="visitDataItem.manager.mobile"
              :name="visitDataItem.manager.name +' '+visitDataItem.manager.surname"
              :type="'Manager'"
              :img="visitDataItem.manager.profile_image"
            />
            <user-profile-view
              v-if="visitDataItem.clientname"
              :number="visitDataItem.clientname.mobile"
              :name="visitDataItem.clientname.name +' '+visitDataItem.clientname.surname"
              :type="'Client'"
              :img="visitDataItem.clientname.profile_image"
            />
            <user-profile-view
              v-if="visitDataItem.assistant"
              :number="visitDataItem.assistant.mobile"
              :name="visitDataItem.assistant.name +' '+visitDataItem.assistant.surname"
              :type="'Assistant'"
              :img="visitDataItem.assistant.profile_image"
            />
            <div
              class="py-1"
              v-if="JSON.stringify(visitDataItem.person) !== '[]'"
            >
              <!-- <b-media v-for="(item,index) in visitDataItem.person.slice(0,viewMore)" :key="index" no-body>
            <b-media-aside class="mr-50">
              <b-avatar
                class="bg-primary"
                size="38"
              ><span style="font-size:25px">{{index+1}}</span></b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="mb-0">
                {{item.name}} (Person{{index+1}})
              </h5>
              <h6 class="mb-0">
               MO:- {{item.mobile}}
              </h6>
            </b-media-body>
          </b-media> -->
              <div v-if="visitDataItem.person">
                <user-profile-view
                  v-for="(item, index) in visitDataItem.person.slice(
                    0,
                    viewMore
                  )"
                  :key="index"
                  :number="item.mobile"
                  :name="item.name"
                  :type="`Person${index + 1}`"
                  :ifPerson="true"
                  :personNo="`${index + 1}`"
                />
              </div>
              <div
                v-if="
                  visitDataItem.person && viewMore < visitDataItem.person.length
                "
                class="mt-1"
              >
                <a
                  class="text-primary"
                  style="font-size: 15px"
                  @click="viewMoreClicked"
                >
                  View More
                </a>
              </div>
              <div class="w-100 mt-1">
                <p v-if="visitDataItem.timeline && visitDataItem.timeline[3].end">
                  <span class="font-weight-bolder">Drop Datetime :</span>{{ visitDataItem.timeline[3].end }}
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <b-button
                v-if="
                  (userData.id ==
                    (visitDataItem.manager ? visitDataItem.manager.id : null) ||
                    userData.id ==
                      (visitDataItem.driver
                        ? visitDataItem.driver.id
                        : null) ||
                    userData.id ==
                    (visitDataItem.assistant
                      ? visitDataItem.assistant.id
                      : null)

                        ) &&
                  (visitDataItem.isstay == 1
                    ? visitDataItem.active == 2
                    : visitDataItem.active == 1) &&
                  visitDataItem.timeline &&
                  visitDataItem.timeline[3].start
                "
                @click="completeTask(3)"
                size="sm"
                variant="primary"
                >Complete</b-button
              >
              <p
                class="text-success font-weight-bolder"
                v-if="visitDataItem.active > 2"
              >
                Completed
              </p>
            </div>
          </app-timeline-item>
          <app-timeline-item
            :fillBorder="visitDataItem.active >= 4 ? true : (visitDataItem.timeline[4].start && !visitDataItem.timeline[4].end ? true : false)"
            :variant="visitDataItem.active >= 1 ? 'success' : 'primary'"
          >
            <div
              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
            >
              <h5>
                <span style="font-weight: 600 !important">
                  {{
                    visitDataItem.clientname
                      ? visitDataItem.clientname.name
                      : ""
                  }}
                </span>
                will Return, so drop them on {{ visitDataItem.droplocation }} at
                {{ visitDataItem.droptime }}
              </h5>
              <p>
                <b-badge
                  variant="primary"
                  v-if="visitDataItem.departurdate"
                  class="badge-glow m-0"
                >
                  {{ visitDataItem.departurdate }}
                </b-badge>
              </p>
            </div>
            <p v-if="visitDataItem.timeline && visitDataItem.timeline[4].start">
              <span class="font-weight-bolder">Pickup Datetime :</span
              >{{ visitDataItem.timeline[4].start }}
            </p>
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <user-profile-view
                v-if="visitDataItem.clientname"
                :number="visitDataItem.clientname.mobile"
                :name="visitDataItem.clientname.name +' ' +visitDataItem.clientname.surname "
                :type="'Client'"
                :img="visitDataItem.clientname.profile_image"
              />
              <b-button
                v-if="
                  (userData.id ==
                    (visitDataItem.driver ? visitDataItem.driver.id : null) ||
                    userData.id ==
                      (visitDataItem.manager
                        ? visitDataItem.manager.id
                        : null)||
                    userData.id ==
                    (visitDataItem.assistant
                      ? visitDataItem.assistant.id
                      : null)) &&
                  visitDataItem.active == 3 &&
                  visitDataItem.timeline &&
                  !visitDataItem.timeline[4].start
                "
                @click="handleTimeline(4)"
                size="sm"
                variant="primary"
                >Start</b-button
              >
            </div>
            <user-profile-view
              v-if="visitDataItem.driver"
              :number="visitDataItem.driver.mobile"
              :name="visitDataItem.driver.name +' ' +visitDataItem.driver.surname "
              :type="'Driver'"
              :img="visitDataItem.driver.profile_image"
            />
            <div class="w-100 mt-2">
              <p v-if="visitDataItem.timeline && visitDataItem.timeline[4].end">
                <span class="font-weight-bolder">Drop Datetime :</span>{{ visitDataItem.timeline[4].end }}
              </p>
            </div>
            <div class="d-flex justify-content-end pb-1">
              <b-button
                v-if="
                  (userData.id ==
                    (visitDataItem.driver ? visitDataItem.driver.id : null) ||
                    userData.id ==
                      (visitDataItem.manager
                        ? visitDataItem.manager.id
                        : null) ||
                    userData.id ==
                    (visitDataItem.assistant
                      ? visitDataItem.assistant.id
                      : null)
                        ) &&
                  visitDataItem.active == 3 &&
                  visitDataItem.timeline &&
                  visitDataItem.timeline[4].start
                "
                @click="completeTask(4)"
                size="sm"
                variant="primary"
                >Complete</b-button
              >
              <p
                class="text-success font-weight-bolder"
                v-if="visitDataItem.active > 3"
              >
                Completed
              </p>
            </div>
          </app-timeline-item>
        </app-timeline>
        <!--/ timeline -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import SiteVisit from "@/views/crm/sitevisit/SiteVisit.vue";
import {
  BCard,
  BImg,
  BCardBody,
  BBadge,
  BAlert,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import moment from "moment";
import axios from "@/components/axios";
import UserProfileView from "@/components/UserProfileView.vue";
/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    UserProfileView,
    BImg,
    AppTimeline,
    AppTimelineItem,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BAlert,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    visitDataItem: {
      default: false,
    },
  },
  watch: {
    visitDataItem: {
      handler: 'setData',
      immediate:true
    }
  },
  data() {
    return {
      viewMore: 1,
      moment,
      userData: JSON.parse(localStorage.getItem("userData")),
      mediaData: [
        {
          avatar: "CalendarIcon",
          title: "Sat, May 25, 2020",
          subtitle: "10:AM to 6:PM",
        },
        {
          avatar: "MapPinIcon",
          title: "Central Park",
          subtitle: "Manhattan, New york City",
        },
      ],
      avatars: [],
    };
  },
  mounted() {
    this.setData()
  },
  methods: {

    setData() {
      this.avatars=[]
      let obj ={
        1: {
          start: "",
            end: "",
            },
        2: {
          start: "",
            end: "",
            },
        3: {
          start: "",
            end: "",
            },
        4: {
          start: "",
            end: "",
            },
      }
       let data, data1, data2, data3, data4;
      if (this.visitDataItem) {
        this.visitDataItem.timeline = typeof this.visitDataItem.timeline == 'string'
          ? JSON.parse(this.visitDataItem.timeline)
          : (this.visitDataItem.timeline? this.visitDataItem.timeline:obj);

        if (this.visitDataItem.driver) {
          data = {
            avatar: this.visitDataItem.driver.profile_image
              ? this.visitDataItem.driver.profile_image
              : "https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg",
            fullName: `${this.visitDataItem.driver.name}(Driver)`,
          };
        }
        if (this.visitDataItem.manager) {
          data1 = {
            avatar: this.visitDataItem.manager.profile_image
              ? this.visitDataItem.manager.profile_image
              : "https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg",
            fullName: `${this.visitDataItem.manager.name}(Manager)`,
          };
        }
        if (this.visitDataItem.assistant) {
          data2 = {
            avatar: this.visitDataItem.assistant.profile_image
              ? this.visitDataItem.assistant.profile_image
              : "https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg",
            fullName: `${this.visitDataItem.assistant.name}(Assistant)`,
          };
          this.avatars.push(data2);
        }
        if (this.visitDataItem.source) {
          data3 = {
            avatar: this.visitDataItem.source.profile_image
              ? this.visitDataItem.source.profile_image
              : "https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg",
            fullName: `${this.visitDataItem.source.name}(Source)`,
          };
        }
        if (this.visitDataItem.clientname) {
          data4 = {
            avatar: this.visitDataItem.clientname.profile_image
              ? this.visitDataItem.clientname.profile_image
              : "https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg",
            fullName: `${this.visitDataItem.clientname.name}(Client)`,
          };
        }
      }

      data && this.avatars.push(data);
      data1 && this.avatars.push(data1);
      data3 && this.avatars.push(data3);
      data4 && this.avatars.push(data4);
    },
    async handleTimeline(key) {
      this.visitDataItem.timeline[key].start = moment(new Date()).format(
        "DD/MM/yyyy h:m"
      );
      const data = {
        timeline: JSON.stringify(this.visitDataItem.timeline),
      };
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      await axios({
        method: "post",
        url: `${baseApi}/changeTimeline/${this.visitDataItem.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      }).then((response) => {
        this.$emit("UpdateData");
        this.$swal({
          title: "Submited",
          text: response.data.message
            ? `${response.data.message}`
            : response.data.success
            ? `${response.data.success}`
            : "Update SuccessFully",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    },
    async completeTask(value) {
      this.visitDataItem.timeline[value].end = moment(new Date()).format(
        "DD/MM/yyyy h:m"
      );
      const data = {
        active: value,
        timeline: JSON.stringify(this.visitDataItem.timeline),
      };
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      await axios({
        method: "post",
        url: `${baseApi}/changeTimeline/${this.visitDataItem.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      }).then((response) => {
        this.$emit("UpdateData");

        this.$swal({
          title: "Submited",
          text: response.data.message
            ? `${response.data.message}`
            : response.data.success
            ? `${response.data.success}`
            : "Update SuccessFully",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    },
    viewMoreClicked() {
      this.viewMore = this.viewMore + 2;
    },
  },
};
</script>
