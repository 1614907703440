import mock from '@/@fake-db/mock'
const data = {
  // knowledge base
  knowledgeBase: [
    {
      id: 26,
      routerPath: '/setup/issuelog',
      img: require('@/assets/images/illustration/api.svg'),
      title: 'Issue Log',
      desc: 'every hero and coward, every creator and destroyer of civilization.'
    },
    {
      id: 25,
      routerPath: '/setup/kyc',
      img: require('@/assets/images/illustration/marketing.svg'),
      title: 'KYC',
      desc: 'every hero and coward, every creator and destroyer of civilization.'
    },
    {
      id: 1,
      routerPath: '/setup/company',
      img: require('@/assets/images/illustration/sales.svg'),
      title: 'Company',
      desc: 'There is perhaps no better demonstration of the folly of image of our tiny world.'
    },
    {
      id: 2,
      routerPath: '/setup/branch',
      img: require('@/assets/images/illustration/marketing.svg'),
      title: 'Branch',
      desc: 'Look again at that dot. That’s here. That’s home. That’s us. On it everyone you love.'
    },
    {
      id: 3,
      routerPath: '/setup/department',
      img: require('@/assets/images/illustration/api.svg'),
      title: 'Department',
      desc: 'every hero and coward, every creator and destroyer of civilization.'
    },
    {
      id: 4,
      routerPath: '/setup/position',
      img: require('@/assets/images/illustration/personalization.svg'),
      title: 'Position',
      desc: 'It has been said that astronomy is a humbling and character experience.'
    },
    // {
    //   id: 5,
    //   routerPath: '/setup/users',
    //   img: require('@/assets/images/illustration/email.svg'),
    //   title: 'Users',
    //   desc: 'There is perhaps no better demonstration of the folly of human conceits.'
    // },
    {
      id: 6,
      routerPath: '/setup/softwares',
      img: require('@/assets/images/illustration/demand.svg'),
      title: 'Softwares',
      desc: 'Competent means we will never take anything for granted.'
    },
    {
      id: 7,
      routerPath: '/setup/modules',
      img: require('@/assets/images/illustration/sales.svg'),
      title: 'Modules',
      desc: 'There is perhaps no better demonstration of the folly of image of our tiny world.'
    },
    {
      id: 8,
      routerPath: '/setup/widgets',
      img: require('@/assets/images/illustration/marketing.svg'),
      title: 'Widgets',
      desc: 'Look again at that dot. That’s here. That’s home. That’s us. On it everyone you love.'
    },
    {
      id: 9,
      routerPath: '/setup/language',
      img: require('@/assets/images/illustration/api.svg'),
      title: 'Language',
      desc: 'every hero and coward, every creator and destroyer of civilization.'
    },
    {
      id: 10,
      routerPath: '/setup/settings',
      img: require('@/assets/images/illustration/personalization.svg'),
      title: 'Settings',
      desc: 'It has been said that astronomy is a humbling and character experience.'
    },
    {
      id: 11,
      routerPath: '/setup/approval',
      img: require('@/assets/images/illustration/email.svg'),
      title: 'Approvals',
      desc: 'There is perhaps no better demonstration of the folly of human conceits.'
    },
    {
      id: 12,
      routerPath: '/setup/reminder',
      img: require('@/assets/images/illustration/demand.svg'),
      title: 'Reminders',
      desc: 'Competent means we will never take anything for granted.'
    },
    {
      id: 13,
      routerPath: '/setup/assign-rights',
      img: require('@/assets/images/illustration/sales.svg'),
      title: 'Rights',
      desc: 'There is perhaps no better demonstration of the folly of image of our tiny world.'
    },
    {
      id: 14,
      routerPath: '/setup/cron',
      img: require('@/assets/images/illustration/marketing.svg'),
      title: 'Cron',
      desc: 'Look again at that dot. That’s here. That’s home. That’s us. On it everyone you love.'
    },
    {
      id: 15,
      routerPath: '/setup/validations',
      img: require('@/assets/images/illustration/api.svg'),
      title: 'Validations',
      desc: 'every hero and coward, every creator and destroyer of civilization.'
    },
    {
      id: 16,
      routerPath: '/setup/import-export',
      img: require('@/assets/images/illustration/personalization.svg'),
      title: 'Import-Export',
      desc: 'It has been said that astronomy is a humbling and character experience.'
    },
    {
      id: 17,
      routerPath: '/setup/backup-restore',
      img: require('@/assets/images/illustration/email.svg'),
      title: 'Backup-Restore',
      desc: 'There is perhaps no better demonstration of the folly of human conceits.'
    },
    {
      id: 18,
      routerPath: '/setup/template',
      img: require('@/assets/images/illustration/demand.svg'),
      title: 'Template',
      desc: 'Competent means we will never take anything for granted.'
    },
    {
      id: 19,
      routerPath: '/setup/fixed-ledger',
      img: require('@/assets/images/illustration/sales.svg'),
      title: 'Fixed Ledger',
      desc: 'There is perhaps no better demonstration of the folly of image of our tiny world.'
    },
    {
      id: 20,
      routerPath: '/setup/ledger-voucher',
      img: require('@/assets/images/illustration/marketing.svg'),
      title: 'Ledger Voucher',
      desc: 'Look again at that dot. That’s here. That’s home. That’s us. On it everyone you love.'
    },
    {
      id: 21,
      routerPath: '/setup/serial-no',
      img: require('@/assets/images/illustration/api.svg'),
      title: 'Seriol No',
      desc: 'every hero and coward, every creator and destroyer of civilization.'
    },
    {
      id: 22,
      routerPath: '/setup/ledger-mapping',
      img: require('@/assets/images/illustration/personalization.svg'),
      title: 'Ledger Mapping',
      desc: 'It has been said that astronomy is a humbling and character experience.'
    },
    {
      id: 23,
      routerPath: '/setup/banner',
      img: require('@/assets/images/illustration/email.svg'),
      title: 'Banner',
      desc: 'There is perhaps no better demonstration of the folly of human conceits.'
    },
    {
      id: 24,
      routerPath: '/setup/offer',
      img: require('@/assets/images/illustration/email.svg'),
      title: 'Offer',
      desc: 'There is perhaps no better demonstration of the folly of human conceits.'
    },
    {
      id: 27,
      routerPath: '/setup/trash',
      img: require('@/assets/images/illustration/api.svg'),
      title: 'Trash',
      desc: 'every hero and coward, every creator and destroyer of civilization.'
    },

  ]
}
/* eslint-disable global-require */
// const data = {
//   // knowledge base
//   setupData: [
//     {
//       icon: '',
//       title: 'Company',
//       route: '/setup/company'
//     },
//     {
//       icon: '',
//       title: 'Branch',
//       route: '/setup/branch'
//     },
//     {
//       icon: 'UsersIcon',
//       title: 'Department',
//       route: '/setup/department'
//     },
//     {
//       icon: 'UsersIcon',
//       title: 'Position',
//       route: '/setup/position'
//     },
//     {
//       icon: 'UsersIcon',
//       title: 'Users',
//       route: '/setup/users'
//     },
//     {
//       icon: '',
//       title: 'Softwares',
//       route: '/setup/softwares'
//     },
//     {
//       icon: 'GiftIcon',
//       title: 'Modules',
//       route: '/setup/modules'
//     },
//     {
//       icon: '',
//       title: 'Widgets',
//       route: '/setup/widgets'
//     },
//     {
//       icon: '',
//       title: 'Language',
//       route: '/setup/language'
//     },
//     {
//       icon: '',
//       title: 'Settings',
//       route: '/setup/settings'
//     },
//     {
//       icon: 'UserIcon',
//       title: 'Approval',
//       route: '/setup/approval'
//     },
//     {
//       icon: 'BellIcon',
//       title: 'Reminders',
//       route: '/setup/reminder'
//     },
//     {
//       icon: '',
//       title: 'Rights',
//       route: '/setup/assign-rights'
//     },
//     {
//       icon: '',
//       title: 'Cron',
//       route: '/setup/cron'
//     },
//     {
//       icon: '',
//       title: 'Validations',
//       route: '/setup/validations'
//     },
//     {
//       icon: '',
//       title: 'Import-Export',
//       route: '/setup/import-export'
//     },
//     {
//       icon: '',
//       title: 'Backup/Restore',
//       route: '/setup/backup-restore'
//     },
//     {
//       icon: '',
//       title: 'Template',
//       route: '/setup/template'
//     },
//     {
//       icon: 'UserPlusIcon',
//       title: 'Fixed Ledger',
//       route: '/setup/fixed-ledger'
//     },
//     {
//       icon: 'BookIcon',
//       title: 'Ledger Voucher',
//       route: '/setup/ledger-voucher'
//     },
//     {
//       icon: '',
//       title: 'Serial No',
//       route: '/setup/serial-no'
//     },
//     {
//       icon: 'UsersIcon',
//       title: 'Ledger Mapping',
//       route: '/setup/ledger-mapping'
//     },
//     {
//       icon: 'UsersIcon',
//       title: 'Banner',
//       route: '/setup/banner'
//     },

//   ]
// }
/* eslint-disable global-require */
// mock.onGet('/data/setup').reply(() => [200, data.knowledgeBase])
export default data
