// const params = new URLSearchParams(window.location.search).has("id");
let userRole
if (localStorage.getItem('accessToken') !== null) {
  userRole = JSON.parse(localStorage.getItem('userData')).role
} else {
  userRole = false
}

export default [
  {
    path: '/master/hr/holiday',
    name: 'master-hr-holiday',
    component: () => import('@/views/master/hr/holiday/Holiday.vue'),
    meta: {
      pageTitle: 'Holiday',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Holiday',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/hr/holidayname',
    name: 'master-hr-holidayname',
    component: () => import('@/views/master/hr/holidayname/HolidayName.vue'),
    meta: {
      pageTitle: 'Holiday Name',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Holiday Name',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/hr/regionmaster',
    name: 'master-hr-regionmaster',
    component: () => import('@/views/master/hr/regionmaster/RegionMaster.vue'),
    meta: {
      pageTitle: 'Region',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Region',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/hr/applypost',
    name: 'master-hr-applypost',
    component: () => import('@/views/master/hr/applypost/ApplyPost.vue'),
    meta: {
      pageTitle: 'Apply Post',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Apply Post',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/hr/assetmaster',
    name: 'master-hr-assetmaster',
    component: () => import('@/views/master/hr/assetmaster/AssetMaster.vue'),
    meta: {
      pageTitle: 'Asset',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Assetmaster',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/hr/cast',
    name: 'master-hr-cast',
    component: () => import('@/views/master/hr/cast/Cast.vue'),
    meta: {
      pageTitle: 'Cast',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Cast',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/hr/document',
    name: 'master-hr-document',
    component: () => import('@/views/master/hr/document/Document.vue'),
    meta: {
      pageTitle: 'Document',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Document',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accounting/ledger',
    name: 'master-accounting-ledger',
    component: () => import('@/views/master/account/ledger/Ledger.vue'),
    meta: {
      pageTitle: 'Ledger',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Ledger',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accounting/broker',
    name: 'master-accounting-broker',
    component: () => import('@/views/master/account/broker/Broker.vue'),
    meta: {
      pageTitle: 'Broker',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Broker',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accounting/farmer',
    name: 'master-accounting-farmer',
    component: () => import('@/views/master/account/farmer/Farmer.vue'),
    meta: {
      pageTitle: 'Farmer',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Farmer',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accounting/purchasebuyer',
    name: 'master-accounting-purchasebuyer',
    component: () => import('@/views/master/account/purchasebuyer/Purchasebuyer.vue'),
    meta: {
      pageTitle: 'Purchase Buyer',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Purchasebuyer',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accounting/accountgroup',
    name: 'master-accounting-accountgroup',
    component: () => import('@/views/master/account/accountgroup/AccountGroup.vue'),
    meta: {
      pageTitle: 'Account Group',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Accountgroup',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accounting/vendorcategory',
    name: 'master-accounting-vendorcategory',
    component: () => import('@/views/master/account/vendorcategory/Vendorcategory.vue'),
    meta: {
      pageTitle: 'Vendor Category',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Vendorcategory',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accounting/bankaccount',
    name: 'master-accounting-bankaccount',
    component: () => import('@/views/master/account/bankaccount/BankAccountMaster.vue'),
    meta: {
      pageTitle: 'Bank Account',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Bankaccount',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accounting/bankstatement',
    name: 'master-accounting-bankstatement',
    component: () => import('@/views/master/account/bankstatement/BankStatement.vue'),
    meta: {
      pageTitle: 'Bank Statement',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Bankstatement',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accounting/vendortype',
    name: 'master-accounting-vendortype',
    component: () => import('@/views/master/account/vendortype/Vendortype.vue'),
    meta: {
      pageTitle: 'Vendor Type',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Vendor Type',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accounting/vendor',
    name: 'master-accounting-vendor',
    component: () => import('@/views/master/account/vendor/Vendor.vue'),
    meta: {
      pageTitle: 'Vendor',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Vendor',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/addchannelpartner',
    alias: '/master/crm/editchannelpartner',
    name: 'add-channelpartner',
    component: () => import('@/views/master/crm/channelpartner/AddChannelPartner.vue'),
    meta: {
      pageTitle: 'Add Channel Partner',
      breadcrumb:userRole =='channelpartner'? [
        {
          text: 'CRM',
          to: '/crm'
        },
        {
          text: 'Channel Partner',
          to: '/master/crm/channelpartner'
        },
        {
          text: 'Add Channel Partner',
          active: true
        }
      ]:[

        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Channel Partner',
          to: '/master/crm/channelpartner'
        },
        {
          text: 'Add Channel Partner',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/editchannelpartner/:id',
    name: 'edit-channelpartner',
    component: () => import('@/views/master/crm/channelpartner/AddChannelPartner.vue'),
    meta: {
      pageTitle: 'Edit Channel Partner',

      breadcrumb:userRole =='channelpartner'? [
        {
          text: 'CRM',
          to: '/crm'
        },
        {
          text: 'Channel Partner',
          to: '/master/crm/channelpartner'
        },
        {
          text: 'Edit Channel Partner',
          active: true
        }
      ]:[

        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Channel Partner',
          to: '/master/crm/channelpartner'
        },
        {
          text: 'Edit Channel Partner',
          active: true
        }
      ]
    }
  },
  // {
  //   path: '/crm/addchannelpartner',
  //   alias: '/crm/editchannelpartner',
  //   name: 'add-channelpartner',
  //   component: () => import('@/views/master/crm/channelpartner/AddChannelPartner.vue'),
  //   meta: {
  //     pageTitle: 'Channel Partner',
  //     breadcrumb: [
  //       {
  //         text: 'CHANNELPARTNER',
  //         to: '/channelpartner'
  //       },
  //       {
  //         text: 'Add Channel Partner',
  //         active: true
  //       }
  //     ]
  //   }
  // },
  {
    path: '/master/crm/channelpartner',
    name: 'master-crm-ChannelPartner',
    component: () => import('@/views/master/crm/channelpartner/ChannelPartner.vue'),
    meta: {
      pageTitle: 'Channel Partner',


      breadcrumb:userRole =='channelpartner'? [
        {
          text: 'CRM',
          to: '/crm'
        },
        {
          text: 'Channel Partner',
          active: true
        }
      ]:[

        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Channel Partner',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/leadsource',
    name: 'master-crm-leadsource',
    component: () => import('@/views/master/crm/leadsource/Leadsource.vue'),
    meta: {
      pageTitle: 'Lead Source',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Leadsource',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/leadstage',
    name: 'master-crm-leadstage',
    component: () => import('@/views/master/crm/leadstage/LeadStage.vue'),
    meta: {
      pageTitle: 'Lead Stage',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Leadstage',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/leadcategory',
    name: 'master-crm-leadcategory',
    component: () => import('@/views/master/crm/leadcategory/LeadCategory.vue'),
    meta: {
      pageTitle: 'Lead Category',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'LeadCategory',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/campaign',
    name: 'master-crm-campaign',
    component: () => import('@/views/master/crm/campaign/CampaignName.vue'),
    meta: {
      pageTitle: 'Campaign',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Campaign',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/addcampaign',
    alias: '/master/crm/editcampaign',
    name: 'add-campaign',
    component: () => import('@/views/master/crm/campaign/AddCampaignName.vue'),
    meta: {
      pageTitle: 'Add Campaign',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Campaign',
          to: '/master/crm/addcampaign'
        },
        {
          text: 'Add Campaign',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/editcampaign/:id',
    name: 'edit-campaign',
    component: () => import('@/views/master/crm/campaign/AddCampaignName.vue'),
    meta: {
      pageTitle: 'Edit Campaign',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Campaign',
          to: '/master/crm/addcampaign'
        },
        {
          text: 'Edit Campaign',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/reason',
    name: 'master-crm-reason',
    component: () => import('@/views/master/crm/reason/Reason.vue'),
    meta: {
      pageTitle: 'Reason',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Reason',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/franchise',
    name: 'master-crm-franchise',
    component: () => import('@/views/master/crm/franchise/Franchise.vue'),
    meta: {
      pageTitle: 'Franchise',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Franchise',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/zone',
    name: 'master-crm-zone',
    component: () => import('@/views/master/crm/zone/Zone.vue'),
    meta: {
      pageTitle: 'Zone',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Zone',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/project',
    name: 'master-crm-project',
    component: () => import('@/views/master/crm/project/Project.vue'),
    meta: {
      pageTitle: 'Project',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Project',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/master/crm/plot",
  //   name: "master-crm-plot",
  //   component: () => import("@/views/master/crm/plot/Plot.vue"),
  //   meta: {
  //     pageTitle: "Plot",

  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "CRM",
  //         to: "/master/crm",
  //       },
  //       {
  //         text: "Plot",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/master/crm/commission',
    name: 'master-crm-commission',
    component: () => import('@/views/master/crm/commission/Commission.vue'),
    meta: {
      pageTitle: 'Commission',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Commission',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/projectmapping',
    name: 'master-crm-projectmapping',
    component: () => import('@/views/master/crm/projectmapping/ProjectMapping.vue'),
    meta: {
      pageTitle: 'Project Mapping',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Projectmapping',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/franchise/addfranchise',
    alias: '/master/crm/franchise/editfranchise',
    name: 'add-franchise',
    component: () => import('@/views/master/crm/franchise/AddFranchise.vue'),
    meta: {
      pageTitle: 'Add Franchise',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Franchise',
          to: '/master/crm/franchise'
        },
        {
          text: 'Add Franchise',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/franchise/editfranchise/:id',
    name: 'edit-franchise',
    component: () => import('@/views/master/crm/franchise/AddFranchise.vue'),
    meta: {
      pageTitle: 'Edit Franchise',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Franchise',
          to: '/master/crm/franchise'
        },
        {
          text: 'Edit Franchise',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/master/crm/addprojectmapping",
  //   alias: "/master/crm/editprojectmapping",
  //   name: "add-projectmapping",
  //   component: () =>
  //     import("@/views/master/crm/projectmapping/AddProjectMapping.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "CRM",
  //         to: "/master/crm/leadsource",
  //       },
  //       {
  //         text: "Project",
  //         to: "/master/crm/projectmapping",
  //       },
  //       {
  //         text: `${params ? "Edit Project Mapping" : "Add Project Mapping"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/master/crm/plotmaster',
    name: 'master-plotmaster',
    component: () => import('@/views/master/crm/plot/Plot.vue'),
    meta: {
      pageTitle: 'Plot',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Plot',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/addplotmaster',
    alias: '/master/plotmaster/editplotmaster',
    name: 'add-plotmaster',
    component: () => import('@/views/master/crm/plot/AddPlot.vue'),
    meta: {
      pageTitle: 'Add Plot',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Plot',
          to: '/master/crm/plotmaster'
        },
        {
          text: 'Add Plot',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/editplotmaster/:id',
    name: 'edit-plotmaster',
    component: () => import('@/views/master/crm/plot/AddPlot.vue'),
    meta: {
      pageTitle: 'Edit Plot',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Plot',
          to: '/master/crm/plotmaster'
        },
        {
          text: 'Edit Plot',
          active: true
        }
      ]
    }
  },

  {
    path: '/master/crm/projectmaster',
    name: 'master-projectmaster',
    component: () => import('@/views/master/crm/project/Project.vue'),
    meta: {
      pageTitle: 'Project',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Project',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/addprojectmaster',
    alias: '/master/crm/editprojectmaster',
    name: 'add-projectmaster',
    component: () => import('@/views/master/crm/project/AddProject.vue'),
    meta: {
      pageTitle: 'Add Project',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Project',
          to: '/master/crm/projectmaster'
        },
        {
          text: ' Add Project ',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/editprojectmaster/:id',
    name: 'edit-projectmaster',
    component: () => import('@/views/master/crm/project/AddProject.vue'),
    meta: {
      pageTitle: 'Edit Project',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Project',
          to: '/master/crm/projectmaster'
        },
        {
          text: 'Edit Project ',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/commission',
    name: 'master-commission',
    component: () => import('@/views/master/crm/commission/Commission.vue'),
    meta: {
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Commission',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/commission/addcommission',
    alias: '/master/commission/editcommission',
    name: 'add-commission',
    component: () => import('@/views/master/crm/commission/AddCommission.vue'),
    meta: {
      pageTitle: 'Add Commission',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Commission',
          to: '/master/crm/commission'
        },
        {
          text: ' Add Commission',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/commission/editcommission/:id',
    name: 'edit-commission',
    component: () => import('@/views/master/crm/commission/AddCommission.vue'),
    meta: {
      pageTitle: 'Edit Commission',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Commission',
          to: '/master/crm/commission'
        },
        {
          text: 'Edit Add Commission',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/land',
    name: 'master-land',
    component: () => import('@/views/master/crm/land/Land.vue'),
    meta: {
      pageTitle: 'Land',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Land',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/land/addland',
    alias: '/master/crm/land/editland',
    name: 'add-land',
    component: () => import('@/views/master/crm/land/AddLand.vue'),
    meta: {
      pageTitle: 'Add Land',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'land',
          to: '/master/crm/land'
        },
        {
          text: ' Add Land',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/land/editland/:id',
    name: 'edit-land',
    component: () => import('@/views/master/crm/land/AddLand.vue'),
    meta: {
      pageTitle: 'Edit Land',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'land',
          to: '/master/crm/land'
        },
        {
          text: 'Edit Land',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/area',
    name: 'master-crm-area',
    component: () => import('@/views/master/crm/area/Area.vue'),
    meta: {
      pageTitle: 'Area',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Area',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/addarea',
    alias: '/master/crm/editarea',
    name: 'add-area',
    component: () => import('@/views/master/crm/area/AddArea.vue'),
    meta: {
      pageTitle: 'Add Area',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Area',
          to: '/master/crm/area'
        },
        {
          text: ' Add Area',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/crm/editarea/:id',
    name: 'edit-area',
    component: () => import('@/views/master/crm/area/AddArea.vue'),
    meta: {
      pageTitle: 'Edit Area',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'CRM',
          to: '/master/crm'
        },
        {
          text: 'Area',
          to: '/master/crm/area'
        },
        {
          text: ' Edit Area',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/ledger',
    name: 'master-ledger',
    component: () => import('@/views/master/account/ledger/Ledger.vue'),
    meta: {
      pageTitle: 'Ledger',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Ledger',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/ledger/addledger',
    alias: '/master/account/ledger/editledger',
    name: 'add-ledger',
    component: () => import('@/views/master/account/ledger/AddLedger.vue'),
    meta: {
      pageTitle: 'Add Ledger',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Ledger',
          to: '/master/account/ledger'
        },
        {
          text: 'Add Ledger',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/ledger/editledger/:id',
    name: 'edit-ledger',
    component: () => import('@/views/master/account/ledger/AddLedger.vue'),
    meta: {
      pageTitle: 'Edit Ledger',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Ledger',
          to: '/master/account/ledger'
        },
        {
          text: 'Edit Ledger',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/accountgroup',
    name: 'master-chartofaccount',
    component: () => import('@/views/master/account/accountgroup/AccountGroup.vue'),
    meta: {
      pageTitle: 'Account Group',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Account Group',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/accountgroup/addaccountgroup',
    alias: '/master/accountgroup/editaccountgroup',
    name: 'add-group',
    component: () => import('@/views/master/account/accountgroup/AddAccountGroup.vue'),
    meta: {
      pageTitle: 'Add Account Group',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Accoun Group',
          to: '/master/account/accountgroup'
        },
        {
          text: 'Add Account Group',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/accountgroup/editaccountgroup/:id',
    name: 'edit-group',
    component: () => import('@/views/master/account/accountgroup/AddAccountGroup.vue'),
    meta: {
      pageTitle: 'Edit Account Group',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Accoun Group',
          to: '/master/account/accountgroup'
        },
        {
          text: 'Edit Account Group',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/bankaccount',
    name: 'master-bankaccount',
    component: () => import('@/views/master/account/bankaccount/BankAccountMaster.vue'),
    meta: {
      pageTitle: 'Bank Account',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Bank Account',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/addbankaccount',
    alias: '/master/account/editbankaccount',
    name: 'add-bank',
    component: () => import('@/views/master/account/bankaccount/AddBankAccountMaster.vue'),
    meta: {
      pageTitle: 'Add Bank Account',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Bank Account',
          to: '/master/account/bankaccount'
        },
        {
          text: 'Add Bank Account',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/editbankaccount/:id',
    name: 'edit-cbank',
    component: () => import('@/views/master/account/bankaccount/AddBankAccountMaster.vue'),
    meta: {
      pageTitle: 'Edit Bank Account',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Bankaccount',
          to: '/master/account/bankaccount'
        },
        {
          text: 'Add Bank Account',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/bankstatement/bankstatement',
    name: 'master-bankstatement',
    component: () => import('@/views/master/account/bankstatement/BankStatement.vue'),
    meta: {
      pageTitle: 'Bank Statement',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Account Group',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/addbankstatement',
    alias: '/master/account/editbankstatement',
    name: 'add-bankstatement',
    component: () => import('@/views/master/account/bankstatement/AddBankStatement.vue'),
    meta: {
      pageTitle: 'Add Bank Statement',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Bankstatement',
          to: '/master/accounting/bankstatement'
        },
        {
          text: 'Add Bank Statement',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/editbankstatement/:id',
    name: 'edit-bankstatement',
    component: () => import('@/views/master/account/bankstatement/AddBankStatement.vue'),
    meta: {
      pageTitle: 'Edit Bank Statement',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Bankstatement',
          to: '/master/accounting/bankstatement'
        },
        {
          text: 'Edit Bank Statement',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/vendor',
    name: 'master-vendor',
    component: () => import('@/views/master/account/vendor/Vendor.vue'),
    meta: {
      pageTitle: 'Vendor',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Account Group',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/addvendor',
    alias: '/master/account/editvendor',
    name: 'add-vendor',
    component: () => import('@/views/master/account/vendor/AddVendor.vue'),
    meta: {
      pageTitle: 'Add Vendor',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Vendor',
          to: '/master/accounting/vendor'
        },
        {
          text: 'Add Vendor',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/editvendor/:id',
    name: 'edit-vendor',
    component: () => import('@/views/master/account/vendor/AddVendor.vue'),
    meta: {
      pageTitle: 'Edit Vendor',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Vendor',
          to: '/master/accounting/vendor'
        },
        {
          text: 'Edit Vendor',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/addbroker',
    alias: '/master/account/editbroker',
    name: 'add-broker',
    component: () => import('@/views/master/account/broker/AddBroker.vue'),
    meta: {
      pageTitle: 'Add Broker',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Broker',
          to: '/master/accounting/broker'
        },
        {
          text: 'Add broker',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/editbroker/:id',
    name: 'edit-broker',
    component: () => import('@/views/master/account/broker/AddBroker.vue'),
    meta: {
      pageTitle: 'Edit Broker',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Broker',
          to: '/master/accounting/broker'
        },
        {
          text: 'Edit broker',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/addfarmer',
    alias: '/master/account/editfarmer',
    name: 'add-farmer',
    component: () => import('@/views/master/account/farmer/AddFarmer.vue'),
    meta: {
      pageTitle: 'Add Farmer',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Farmer',
          to: '/master/accounting/farmer'
        },
        {
          text: 'Add Farmer',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/editfarmer/:id',
    name: 'edit-farmer',
    component: () => import('@/views/master/account/farmer/AddFarmer.vue'),
    meta: {
      pageTitle: 'Edit Farmer',

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Farmer',
          to: '/master/accounting/farmer'
        },
        {
          text: 'Edit Farmer',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/addpurchasebuyer',
    alias: '/master/account/editpurchasebuyer',
    name: 'add-buyer',
    component: () => import('@/views/master/account/purchasebuyer/AddPurchasebuyer.vue'),
    meta: {
      pageTitle: 'Add Purchase Buyer',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Purchase Buyer',
          to: '/master/accounting/purchasebuyer'
        },
        {
          text: 'Add Purchase Buyer',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/editpurchasebuyer/:id',
    name: 'edit-buyer',
    component: () => import('@/views/master/account/purchasebuyer/AddPurchasebuyer.vue'),
    meta: {
      pageTitle: 'Edit Purchase Buyer',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Purchase Buyer',
          to: '/master/accounting/purchasebuyer'
        },
        {
          text: 'Edit Purchase Buyer',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/vendortype',
    name: 'master-vendortype',
    component: () => import('@/views/master/account/vendortype/Vendortype.vue'),
    meta: {
      pageTitle: 'Vendor Type',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Account Group',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/vendorcategory',
    name: 'master-vendorcategory',
    component: () => import('@/views/master/account/vendorcategory/Vendorcategory.vue'),
    meta: {
      pageTitle: 'Vendor Category',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Account Group',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/addvendorcategory',
    alias: '/master/account/editvendorcategory',
    name: 'add-vendorcategory',
    component: () => import('@/views/master/account/vendorcategory/AddVendorcategory.vue'),
    meta: {
      pageTitle: 'Add Vendor Category',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Add Vendor Category',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/editvendorcategory/:id',
    name: 'edit-category',
    component: () => import('@/views/master/account/vendorcategory/AddVendorcategory.vue'),
    meta: {
      pageTitle: 'Edit Vendor Category',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Edit Vendor Category',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/chequemargin',
    name: 'master-chequemargin',
    component: () => import('@/views/master/account/chequemargin/ChequeMargin.vue'),
    meta: {
      pageTitle: 'Cheque Margin',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Cheque Margin',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/chequemargin/addchequemargin',
    alias: '/master/account/chequemargin/editchequemargin',
    name: 'add-chequemargin',
    component: () => import('@/views/master/account/chequemargin/AddChequeMargin.vue'),
    meta: {
      pageTitle: 'Add Cheque Margin',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Cheque Margin',
          to: '/master/account'
        },
        {
          text: 'Add Cheque Margin',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/chequemargin/editchequemargin/:id',
    name: 'edit-chequemargin',
    component: () => import('@/views/master/account/chequemargin/AddChequeMargin.vue'),
    meta: {
      pageTitle: 'Edit Cheque Margin',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Cheque Margin',
          to: '/master/account'
        },
        {
          text: 'Edit Cheque Margin',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/openingbalance',
    name: 'master-openingbalance',
    component: () => import('@/views/master/account/openingbalance/OpeningBalance.vue'),
    meta: {

      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Opening Balance',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/openingbalance/addopeningbalance',
    alias: '/master/account/openingbalance/editopeningbalance',
    name: 'add-openingbalance',
    component: () => import('@/views/master/account/openingbalance/AddOpeningBalance.vue'),
    meta: {
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Account',
          to: '/master/accounting'
        },
        {
          text: 'Opening Balance',
          to: '/master/account'
        },
        {
          text: 'Add Opening Balance',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/openingland',
    name: 'master-openingland',
    component: () => import('@/views/master/account/openingland/OpeningLand.vue'),
    meta: {
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Opening Land',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/openingland/addopeningland',
    alias: '/master/account/openingland/editopeningland',
    name: 'add-openingland',
    component: () => import('@/views/master/account/openingland/AddOpeningLand.vue'),
    meta: {
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Opening Land',
          to: '/master/account'
        },
        {
          text: 'Add Opening Land',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/account/schememaster',
    name: 'master-schememaster',
    component: () => import('@/views/master/account/schememaster/SchemeMaster.vue'),
    meta: {
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'Scheme Master',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/master/account/schememaster/addschememaster",
  //   alias: "/master/account/schememaster/editschememaster",
  //   name: "add-schememaster",
  //   component: () =>
  //     import("@/views/master/account/schememaster/AddSchemeMaster.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Scheme Master",
  //         to: "/master/account",
  //       },
  //       {
  //         text: `${params ? "Edit Scheme Master" : "Add Scheme Master"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/account/unitmaster",
  //   name: "master-unitmaster",
  //   component: () => import("@/views/master/account/unitmaster/UnitMaster.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Unit Master",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/account/unitmaster/addunitmaster",
  //   alias: "/master/account/unitmaster/editunitmaster",
  //   name: "add-unitmaster",
  //   component: () =>
  //     import("@/views/master/account/unitmaster/AddUnitMaster.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Unit Master",
  //         to: "/master/account",
  //       },
  //       {
  //         text: `${params ? "Edit Unit Master" : "Add Unit Master"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: "/master/hr/departmentmaster",
  //   name: "hr-departmentmaster",
  //   component: () =>
  //     import("@/views/master/hr/departmentmaster/DepartmentMaster.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Department Master",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/account/departmentmaster/adddepartmentmaster",
  //   alias: "/master/account/departmentmaster/editdepartmentmaster",
  //   name: "add-department",
  //   component: () =>
  //     import("@/views/master/hr/departmentmaster/AddDepartmentMaster.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Department Master",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: `${params ? "Edit Department Master" : "Add Department Master"
  //           }`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/document",
  //   name: "hr-document",
  //   component: () => import("@/views/master/hr/document/Document.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Master",
  //         active: true,
  //       },
  //       {
  //         text: "Document",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/adddocument",
  //   alias: "/master/hr/editdocument",
  //   name: "add-document",
  //   component: () => import("@/views/master/hr/document/AddDocument.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Master",
  //         active: true,
  //       },
  //       {
  //         text: "Document",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: `${params ? "Edit Document" : "Add Document"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/cast",
  //   name: "hr-cast",
  //   component: () => import("@/views/master/hr/cast/Cast.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Master",
  //         active: true,
  //       },
  //       {
  //         text: "Cast",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/addcast",
  //   alias: "/master/hr/editcast",
  //   name: "add-cast",
  //   component: () => import("@/views/master/hr/cast/AddCast.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Master",
  //         active: true,
  //       },
  //       {
  //         text: "Cast",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: `${params ? "Edit Cast" : "Add Cast"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/applypost",
  //   name: "hr-applypost",
  //   component: () => import("@/views/master/hr/applypost/ApplyPost.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Master",
  //         active: true,
  //       },
  //       {
  //         text: "Apply Post",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/addapplypost",
  //   alias: "/master/hr/editapplypost",
  //   name: "add-applypost",
  //   component: () => import("@/views/master/hr/applypost/AddApplyPost.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Master",
  //         active: true,
  //       },
  //       {
  //         text: "Apply Post",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: `${params ? "Edit Apply Post" : "Add Apply Post"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/holiday",
  //   name: "hr-holiday",
  //   component: () => import("@/views/master/hr/holiday/Holiday.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Master",
  //         active: true,
  //       },
  //       {
  //         text: "Holiday",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/master/hr/holiday/addholiday',
    alias: '/master/hr/holiday/editholiday',
    name: 'add-holiday',
    component: () => import('@/views/master/hr/holiday/AddHoliday.vue'),
    meta: {
      pageTitle: 'Add Holiday',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Holiday',
          to: '/master/hr/holiday'
        },
        {
          text: 'Add Holiday',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/hr/holiday/editholiday/:id',
    name: 'edit-holiday',
    component: () => import('@/views/master/hr/holiday/AddHoliday.vue'),
    meta: {
      pageTitle: 'Edit Holiday',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Holiday',
          to: '/master/hr/holiday'
        },
        {
          text: 'Edit Holiday',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/master/hr/assetmaster",
  //   name: "hr-assetmaster",
  //   component: () => import("@/views/master/hr/assetmaster/AssetMaster.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Master",
  //         active: true,
  //       },
  //       {
  //         text: "Asset Master",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/master/hr/assetmaster/addassetmaster',
    alias: '/master/hr/assetmaster/editassetmaster',
    name: 'add-assetmaster',
    component: () => import('@/views/master/hr/assetmaster/AddAssetMaster.vue'),
    meta: {
      pageTitle: 'Add Asset',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Assetmaster',
          to: '/master/hr/assetmaster'
        },
        {
          text: 'Add Asset Master',
          active: true
        }
      ]
    }
  },
  {
    path: '/master/hr/assetmaster/editassetmaster/:id',
    name: 'edit-assetmaster',
    component: () => import('@/views/master/hr/assetmaster/AddAssetMaster.vue'),
    meta: {
      pageTitle: 'Edit Asset',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Assetmaster',
          to: '/master/hr/assetmaster'
        },
        {
          text: 'Edit Asset',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/master/hr/holidayname",
  //   name: "hr-holidayname",
  //   component: () => import("@/views/master/hr/holidayname/HolidayName.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Master",
  //         active: true,
  //       },
  //       {
  //         text: "Holiday Name",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/addholidayname",
  //   alias: "/master/hr/editholidayname",
  //   name: "add-holidayname",
  //   component: () => import("@/views/master/hr/holidayname/AddHolidayName.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Master",
  //         active: true,
  //       },
  //       {
  //         text: "Holiday Name",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: `${params ? "Edit Holiday Name" : "Add Holiday Name"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/openingleavebalance",
  //   name: "hr-openingleavebalance",
  //   component: () =>
  //     import("@/views/master/hr/openingleavebalance/OpeningLeaveBalance.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Opening Leave Balance",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/openingleavebalance/addopeningleavebalance",
  //   alias: "/master/hr/openingleavebalance/editopeningleavebalance",
  //   name: "add-openingleavebalance",
  //   component: () =>
  //     import(
  //       "@/views/master/hr/openingleavebalance/AddOpeningLeaveBalance.vue"
  //     ),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Opening Leave Balance",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: `${params ? "Edit Opening Leave Balance" : "Add Opening Leave Balance"
  //           }`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/phasemaster",
  //   name: "hr-phasemaster",
  //   component: () => import("@/views/master/hr/phasemaster/PhaseMaster.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "HR",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: "Phase Master",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/phasemaster/addphasemaster",
  //   alias: "/master/hr/phasemaster/editphasemaster",
  //   name: "add-phasemaster",
  //   component: () => import("@/views/master/hr/phasemaster/AddPhaseMaster.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "HR",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: "Phase Master",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: `${params ? "Edit Phase Master" : "Add Phase Master"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/position",
  //   name: "hr-position",
  //   component: () => import("@/views/master/hr/position/Position.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Position",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/position/addposition",
  //   alias: "/master/hr/position/editposition",
  //   name: "add-position",
  //   component: () => import("@/views/master/hr/position/AddPosition.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Position",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: `${params ? "Edit Position" : "Add Position"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/projects",
  //   name: "hr-projects",
  //   component: () => import("@/views/master/hr/projects/Projects.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Projects",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/master/hr/projects/addprojects",
  //   alias: "/master/hr/projects/editprojects",
  //   name: "add-project",
  //   component: () => import("@/views/master/hr/projects/AddProjects.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Projects",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: `${params ? "Edit Projects" : "Add Projects"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/master/hr/regionmaster',
    name: 'hr-regionmaster',
    component: () => import('@/views/master/hr/regionmaster/RegionMaster.vue'),
    meta: {
      pageTitle: 'Page Title',
      breadcrumb: [
        userRole == 'admin'?
        {
          text: 'MASTER',
          active: true
        }:
        {
          text: 'MASTER',
          to: '/master'
        },
        {
          text: 'HR',
          to: '/master/hr'
        },
        {
          text: 'Region Master',
          active: true
        }
      ]
    }
  },
  // {
  //   path: "/master/hr/regionmaster/addregionmaster",
  //   alias: "/master/hr/regionmaster/editregionmaster",
  //   name: "add-regionmaster",
  //   component: () =>
  //     import("@/views/master/hr/regionmaster/AddRegionMaster.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "MASTER",
  //         active: true,
  //       },
  //       {
  //         text: "Region Master",
  //         to: "/master/hr",
  //       },
  //       {
  //         text: `${params ? "Edit Region Master" : "Add Region Master"}`,
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/master/crm/driver",
    name: "crm-driver",
    component: () => import("@/views/master/crm/driver/Driver.vue"),
    meta: {
      pageTitle: "Driver",
      breadcrumb: [
        {
          text: "MASTER",
          active: true,
        },
        {
          text: "CRM",
          to: "/master/crm",
        },
        {
          text: "Driver",
          active: true,
        },
      ],
    },
  },
  {
    path: "/master/crm/adddriver",
    alias: "/master/crm/editdriver",
    name: "add-driver",
    component: () => import("@/views/master/crm/driver/AddDriver.vue"),
    meta: {
      pageTitle: "Add Driver",
      breadcrumb: [
        {
          text: "MASTER",
          active: true,
        },
        {
          text: "CRM",
          to: "/master/crm",
        },
        {
          text: "Driver",
          to: "/master/crm/driver",
        },
        {
          text: "Add Driver",
          active: true,
        },
      ],
    },
  },
  {
    path: "/master/crm/editdriver/:id",
    name: "edit-driver",
    component: () => import("@/views/master/crm/driver/AddDriver.vue"),
    meta: {
      pageTitle: "Edit Driver",
      breadcrumb: [
        {
          text: "MASTER",
          active: true,
        },
        {
          text: "CRM",
          to: "/master/crm",
        },
        {
          text: "Driver",
          to: "/master/crm/driver",
        },
        {
          text: "Edit Driver",
          active: true,
        },
      ],
    },
  },
  {
    path: "/master/crm/vehicle",
    name: "crm-vehicle",
    component: () => import("@/views/master/crm/vehicle/Vehicle.vue"),
    meta: {
      pageTitle: "Vehicle",
      breadcrumb: [
        {
          text: "MASTER",
          active: true,
        },
        {
          text: "CRM",
          to: "/master/crm",
        },
        {
          text: "Vehicle",
          active: true,
        },
      ],
    },
  },
  {
    path: "/master/crm/addvehicle",
    alias: "/master/crm/editvehicle",
    name: "add-vehicler",
    component: () => import("@/views/master/crm/vehicle/AddVehicle.vue"),
    meta: {
      pageTitle: "Add Vehicle",
      breadcrumb: [
        {
          text: "MASTER",
          active: true,
        },
        {
          text: "CRM",
          to: "/master/crm",
        },
        {
          text: "Vehicle",
          to: "/master/crm/vehicle",
        },
        {
          text: "Add Vehicle",
          active: true,
        },
      ],
    },
  },
  {
    path: "/master/crm/editvehicle/:id",
    name: "edit-vehicle",
    component: () => import("@/views/master/crm/vehicle/AddVehicle.vue"),
    meta: {
      pageTitle: "Edit Vehicle",
      breadcrumb: [
        {
          text: "MASTER",
          active: true,
        },
        {
          text: "CRM",
          to: "/master/crm",
        },
        {
          text: "Vehicle",
          to: "/master/crm/vehicle",
        },
        {
          text: "Edit Vehicle",
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/crm',
    name: 'master-crm',
    component: () => import('@/views/master/crm/masterCrm.vue')
  },
  {
    path: '/master/accounting',
    name: 'master-accounting',
    component: () => import('@/views/master/account/masterAccount.vue')
  },
  {
    path: '/master/hr',
    name: 'master-hr',
    component: () => import('@/views/master/hr/masterHr.vue')
  }
]
