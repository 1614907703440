<template>
  <b-card class="card-transaction" no-body style="cursor: default">
    <b-card-header
      style="position: sticky !important; top: 0px; background: white"
    >
      <b-card-title>Site Visit</b-card-title>
      <b-form-input
        v-model="search"
        placeholder="Search"
        @input="handleSearch"
        class="mt-1"
      />
    </b-card-header>

    <b-card-body v-if="JSON.stringify(search ? finalData : visitData) !== '[]'">
      <div
        v-for="item in search ? finalData : visitData"
        :key="item.id"
        @click="handelClickItem(item)"
        class="d-flex flex-column mt-1"
        :class="
          clickedItem
            ? clickedItem == item.id
              ? 'bg-primary bg-lighten-1 text-white rounded'
              : ''
            : ''
        "
        style="padding: 10px;border-radius:10px;box-shadow:0 4px 24px 0 rgba(34, 41, 47, 0.2)"
      >
        <b-media no-body class="align-items-center">
          <b-media-aside>
            <b-link>
              <b-img
                class="rounded-circle"
                :src="
                  item.source &&
                  item.source.profile_image &&
                  item.source.profile_image !== null
                    ? item.source.profile_image
                    : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                "
                height="40"
                width="40"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6
              :class="
                clickedItem ? (clickedItem == item.id ? 'text-white' : '') : ''
              "
              class="transaction-title"
            >
              Source :&nbsp;<span :class="!(clickedItem == item.id)? 'text-primary' : 'font-weight-bold'"> {{ item.source ? item.source.name : "" }} {{ item.source ? item.source.surname : "" }} </span>
            </h6>
          </b-media-body>
        </b-media>

        <b-media no-body class="align-items-center">
          <b-media-aside>
            <b-link>
              <b-img
                class="rounded-circle"
                :src="
                  item.clientname &&
                  item.clientname.profile_image &&
                  item.clientname.profile_image !== null
                    ? item.clientname.profile_image
                    : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                "
                height="40"
                width="40"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <!-- <h4
              :class="
                clickedItem ? (clickedItem == item.id ? 'text-white' : '') : ''
              "
              class="transaction-title"
            >
              {{ item.manager ? item.manager.name : "" }}&nbsp;<span
                style="font-size: 14px"
                >(Manager)</span
              >
            </h4> -->
            <h6
              v-if="item.clientname"
              :class="
                clickedItem ? (clickedItem == item.id ? 'text-white' : '') : ''
              "
              class="transaction-title"
            >
              Client :&nbsp;<span :class="!(clickedItem == item.id) ? 'text-success' : 'font-weight-bold'"> {{ item.clientname ? item.clientname.name : "" }} {{ item.clientname ? item.clientname.surname : "" }}</span>
            </h6>
          </b-media-body>
          <small> {{ item.arrivaldate }}</small>
        </b-media>
        <div class="demo-inline-spacing">
          <!-- <b-badge
      :variant="clickedItem ? clickedItem == item.id ? 'success' : 'primary' : 'primary'"
      class="badge-glow m-0"
    >
     {{item.source.name}}
    </b-badge> -->
        </div>
      </div>
    </b-card-body>
    <b-card-body
      style="display: flex; justify-content: center; align-items: center"
      v-else
    >
      <h4>NO DATA FOUND</h4>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
  BBadge,
  BLink,
  BFormInput,
} from "bootstrap-vue";
import moment from "moment";

export default {
  components: {
    BLink,
    BCard,
    BImg,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BFormInput,
  },
  props: ["visitData"],
  data() {
    return {
      clickedItem: 0,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      search: "",
      finalData: this.visitData,
    };
  },
  methods: {
    handelClickItem(item) {
      this.clickedItem = item.id;
      this.$emit("visitDataItem", item);
    },
    handleSearch(e) {
      this.finalData = [];
      if (this.search == null || this.search == "") {
        this.finalData = this.visitData;
      } else {
        this.visitData.map((item) => {


          if (
            item.source.name
              .toUpperCase()
              .includes(this.search.toUpperCase()) ||
            item.clientname.name
              .toUpperCase()
              .includes(this.search.toUpperCase()) ||
            JSON.stringify(item.arrivaldate).includes(this.search)
          ) {
            this.finalData.push(item);
          }
        });
      }
    },
  },
};
</script>
