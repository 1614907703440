<template>
  <b-card class="earnings-card">
    <b-row>
      <b-col cols="12">
      <b-card-title >
        Lead categories {{ getPriorityData }}
      </b-card-title></b-col>
      <b-col cols="6">
        <b-row class="d-flex align-items-center h-100">
          <b-col cols="6">
            <h4>
              {{ total }}
            </h4>
            <div class="font-small-2">
              Total
            </div>

          </b-col>
          <b-col cols="6" class="d-flex flex-column justify-content-around" style="height: 80px;">
            <b-badge
              variant="light-info"
            >
              COLD
            </b-badge>
            <b-badge
              variant="light-danger"
            >
              HOT
            </b-badge><b-badge
              variant="light-warning"
            >
              Warm
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6" v-if="success">
        <!-- chart -->
        <vue-apex-charts
          type="donut"
          height="120"
          :options="earningsChart.chartOptions"
          :series="series"
        />

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText,
  BBadge,
 } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { kFormatter } from '@core/utils/filter'
import { $themeColors } from '@themeConfig'
import axios from '@/components/axios'

const $earningsStrokeColor1 = '#ea5455'
const $earningsStrokeColor2 = '#ff9f43'
const $earningsStrokeColor3 = '#00cfe8'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  BBadge,

  },

  data () {
    return {
      total:0,
      success: false,
      thisMonthPr: '',
      lastPr: '',
      priority:{
        HOT:0,
        WARM:0,
        COLD:0
      },
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: { show: false },
          comparedResult: [2, -3],
          labels: ['HOT', 'WARM','COLD'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor1, $earningsStrokeColor2,$earningsStrokeColor3 ],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20
            }
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15
                  },
                  value: {
                    offsetY: -15,
                    formatter (val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    }
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Total',
                  }
                }
              }
            }
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100
                }
              }
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120
                }
              }
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100
                }
              }
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120
                }
              }
            }
          ]
        }
      },
      accessToken: localStorage.getItem('accessToken'),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      earning: 0,
      lastEarning: 0,
      month: new Date().getMonth(),
      per: 0,
      msg: '',
      series: [100,0,0]
    }
  },
  computed:{
    getPriorityData(){
      this.series=[this.$store.state.app.priorityData.hot,this.$store.state.app.priorityData.cold,this.$store.state.app.priorityData.warm]
      this.total= this.series.reduce((acc, current) => acc + current, 0)
      this.success=true
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    kFormatter,
    fetchData () {

    }
  }
}
</script>
