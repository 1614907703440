var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[(_vm.displayType)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("From Type")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":['Unassign','Channelpartner', 'Employee','All'],"placeholder":"None","label":"name"},on:{"input":function($event){return _vm.handletype($event, 'from')}},model:{value:(_vm.fromtype),callback:function ($$v) {_vm.fromtype=$$v},expression:"fromtype"}})],1)],1):_vm._e(),(_vm.fromtype!='Unassign')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("From Transfer")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.allEmployeeOption,"placeholder":"None","label":"fullname"},on:{"input":_vm.handleLeadCategory},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var profile_image = ref.profile_image;
var username = ref.username;
var fullname = ref.fullname;
return [_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(fullname))]),_c('span',[_vm._v("("+_vm._s(username)+")")])]}}],null,false,1183788807),model:{value:(_vm.oldemployee),callback:function ($$v) {_vm.oldemployee=$$v},expression:"oldemployee"}})],1)],1):_vm._e(),(_vm.assigntoType)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("To Type")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":['Channelpartner', 'Employee','All'],"placeholder":"None","label":"name"},on:{"input":function($event){return _vm.handletype($event, 'to')}},model:{value:(_vm.totype),callback:function ($$v) {_vm.totype=$$v},expression:"totype"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("To Transfer")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.employeeOption,"placeholder":"None","label":"fullname"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var fullname = ref.fullname;
var profile_image = ref.profile_image;
var username = ref.username;
return [_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(fullname))]),_c('span',[_vm._v("("+_vm._s(username)+")")])]}}]),model:{value:(_vm.newemployee),callback:function ($$v) {_vm.newemployee=$$v},expression:"newemployee"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Lead Category")]),_c('v-select',{class:_vm.all ? 'overflow-auto' : '',style:(_vm.all ? 'max-height:500px' : ''),attrs:{"multiple":"","options":_vm.categoryOption,"placeholder":"None","label":"name","close-on-select":false},on:{"input":_vm.handleLeadCategory},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Lead Name")]),_c('v-select',{class:_vm.all ? 'overflow-auto' : '',style:(_vm.all ? 'max-height:500px' : ''),attrs:{"multiple":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.leadOptions,"placeholder":"None","label":"fullname","close-on-select":false},on:{"input":_vm.LeadsAll},model:{value:(_vm.leads),callback:function ($$v) {_vm.leads=$$v},expression:"leads"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Transfer Remarks")]),_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Enter Remarks"},model:{value:(_vm.transferremarks),callback:function ($$v) {_vm.transferremarks=$$v},expression:"transferremarks"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"primary","type":"submit","disabled":_vm.submitDisable},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }