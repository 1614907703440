<template>
  <b-card-code style="padding:0px !important">
    <div class="mb-1 d-flex justify-content-between">
      <span style="font-weight: 500; font-size: 1.285rem">Site Visit</span>
      <span style="width: 150px">According To :</span>
    </div>
    <div class="d-flex justify-content-between">
      <b-form-group style="margin-bottom: 10px">
        <b-form-radio-group
          id="radio-group-2"
          v-model="type"
          name="radio-sub-component-1"

        >
          <b-form-radio name="some-radios" value="normal">Normal</b-form-radio>
          <b-form-radio name="some-radios1" value="reschedule"
            >Rescheduled</b-form-radio
          >
        </b-form-radio-group>
      </b-form-group>
      <b-form-group style="margin-bottom: 10px">
        <b-form-radio-group
          id="radio-group-3"
          v-model="dateType"
          name="radio-sub-component"
        >
          <b-form-radio
            name="some-radios"
            value="visitdate"
            @input="handelReminder"
            >Sitevisit Date</b-form-radio
          >
          <b-form-radio
            name="some-radios1"
            value="arrivaldate"
            @input="handelReminder"
            >Arrival Date</b-form-radio
          >
        </b-form-radio-group>
      </b-form-group>
    </div>
    <b-tabs>
      <div
        v-for="(item1, index) in type == 'normal' ? allData : rescheduleData"
        :key="index"
      >
        <b-tab active>
          <template #title>
            <div class="d-flex flex-column justify-content-center">
              <span style="font-size: 18px; text-align: center">{{
                Object.keys(item1).length
              }}</span>
              <span style="font-size: 11px">{{
                index == 0
                  ? "Today"
                  : index == 1
                  ? "Upcoming"
                  : index == 2
                  ? "Overdue"
                  : index == 3
                  ? "Completed"
                  : index == 4
                  ? "Cancelled"
                  : ""
              }}</span>
            </div>
          </template>
          <div
            v-if="JSON.stringify(item1) !== '{}'"
            style="height: 300px; overflow-y: auto"
          >
            <div
              @click="handelClickItem(item)"
              v-for="item in item1"
              :key="item.id"
              class="transaction-item pb-1 pt-1 d-flex align-items-center cursor-pointer"
              style="width: 100%"
              :class="
                clickedItem
                  ? clickedItem == item.id
                    ? 'bg-primary bg-lighten-1 text-white rounded '
                    : ''
                  : ''
              "
            >
              <b-media no-body style="width: 100%; align-items: center">
                <div class="d-flex align-items-center">
                  <b-media-aside>
                    <b-link>
                      <b-img
                        class="rounded-circle"
                        :src="
                          item.source &&
                          item.source.profile_image &&
                          item.source.profile_image !== null
                            ? item.source.profile_image
                            : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                        "
                        height="40"
                        width="40"
                      />
                    </b-link>
                  </b-media-aside>
                </div>

                <b-media-body>
                  <b-row>
                    <b-col
                      md="4"
                      v-if="farmManager"
                      class="d-flex align-items-center"
                    >
                      <div>
                        <h4
                          :class="
                            clickedItem
                              ? clickedItem == item.id
                                ? 'text-white'
                                : ''
                              : ''
                          "
                          class="transaction-title"
                        >
                          Total Person:&nbsp;<span style="font-size: 14px">{{
                            item.noofperson + 1
                          }}</span>
                        </h4>

                        <span
                          >Male : {{ item.male }} Female : {{ item.female
                          }}{{
                            item.other !== 0 ? `Other :${item.other}` : ""
                          }}</span
                        >
                      </div>
                    </b-col>

                    <b-col md="4" v-if="farmManager">
                      <small
                        >Start Date: &nbsp;{{ item.accommodationstart }}</small
                      >
                      <p>
                        <small>
                          End Date:&nbsp;{{ item.accommodationend }}</small
                        >
                      </p>
                    </b-col>

                    <b-col md="7" v-else class="d-flex flex-column justify-content-center">
                      <h4
                        :class="
                          clickedItem
                            ? clickedItem == item.id
                              ? 'text-white'
                              : ''
                            : ''
                        "
                        class="transaction-title w-100"
                      >
                        Source:&nbsp;<span style="font-size: 14px">{{
                          item.source ? item.source.name : "None"
                        }}</span>
                      </h4>
                      <h4
                        :class="
                          clickedItem
                            ? clickedItem == item.id
                              ? 'text-white'
                              : ''
                            : ''
                        "
                        class="transaction-title"
                      >
                        Client:&nbsp;<span style="font-size: 14px">{{
                          item.clientname ? item.clientname.name : "None"
                        }}</span>
                      </h4>
                    </b-col>

                    <b-col md="4" class="d-flex align-items-center"  v-if="farmManager">
                      <h4
                        :class="
                          clickedItem
                            ? clickedItem == item.id
                              ? 'text-white'
                              : ''
                            : ''
                        "
                        class="transaction-title"
                      >
                        Client:&nbsp;<span style="font-size: 14px">{{
                          item.clientname ? item.clientname.name : "None"
                        }}</span>
                      </h4>
                    </b-col>

                    <b-col md="5" v-if="!farmManager" style="padding:0px !important">
                      <small>Arrival Date: &nbsp;{{ item.arrivaldate?item.arrivaldate:'N/A' }}</small>
                      <p>
                        <small> Visit Date:&nbsp;{{ item.visitdate?item.visitdate:'N/A' }}</small>
                      </p>
                    </b-col>
                  </b-row>
                </b-media-body>
              </b-media>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-center align-items-center demo-inline-spacing font-weigth-bold h5"
          >
            NO DATA
          </div>
        </b-tab>
      </div>
    </b-tabs>
  </b-card-code>
</template>

<script>
import {
  BTabs,
  BTab,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCol,
  BRow,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import moment from "moment";

export default {
  props: ["visitData"],
  components: {
    BMediaBody,

    BMedia,
    BMediaAside,
    BLink,
    BImg,
    BCardCode,
    BCardText,
    BTabs,
    BTab,
    BCol,
    BRow,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
  },
  data() {
    return {
      clickedItem: 0,
      allData: {
        Today: [],
        Upcoming: [],
        Overdue: [],
        Completed: [],
        Cancelled: [],
      },
      rescheduleData: {
        Today: [],
        Upcoming: [],
        Overdue: [],
        Completed: [],
        Cancelled: [],
      },
      userData: "",
      userType: "",
      farmManager: false,
      type: "normal",
      dateType: "arrivaldate",
    };
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.userType = this.userData.type;
    if (
      this.userType == "farmhousemanager" ||
      this.userType == "guesthousemanager" ||
      this.userType == "bungalowmanager"
    ) {
      this.farmManager = true;
    }
    this.allData.Completed = [];
    this.rescheduleData.Completed = [];
    this.handelReminder();
  },
  methods: {
    handelClickItem(item) {
      if(JSON.parse(localStorage.getItem("userData")).role !== 'admin'){

        var e = document.getElementById("Customeview");
        e.scrollIntoView();
        this.clickedItem = item.id;
        this.$emit("visitDataItem", item);
      }
    },
    handelReminder() {
      this.allData = {
        Today: [],
        Upcoming: [],
        Overdue: [],
        Completed: [],
        Cancelled: [],
      }
        this.rescheduleData = {
          Today: [],
          Upcoming: [],
          Overdue: [],
          Completed: [],
          Cancelled: [],
        }
        this.visitData.map((item) => {
          item.male = 0;
          item.female = 0;
          item.other = 0;
          if (item.clientname && item.clientname.gender == "Male") {
            item.male += 1;
          } else if (item.clientname && item.clientname.gender == "Female") {
            item.female += 1;
          } else if (item.clientname && item.clientname.gender == "Other") {
            item.other += 1;
          }
          item.person &&
            item.person.map((person) => {
              if (person.gender == "Male") {
                item.male += 1;
              } else if (person.gender == "Female") {
                item.female += 1;
              } else if (person.gender == "Other") {
                item.other += 1;
              }
            });
          if (item.reschedule && item.reschedule.length > 1) {
            if (item.action == 0) {
              this.rescheduleData.Cancelled.push(item);
            } else if (item.active == 4) {
              this.rescheduleData.Completed.push(item);
            } else {
              const data = moment(this.farmManager? item.accommodationstart:item[this.dateType], 'DD/MM/YYYY');
              const data1 = moment(new Date())
              const data3 = data.diff(data1, "days");
              if (data3 == 0) {
                this.rescheduleData.Today.push(item);
              } else if (data3 < 0) {
                this.rescheduleData.Overdue.push(item);
              } else {
                this.rescheduleData.Upcoming.push(item);
              }
            }
          } else {
            if (item.action == 0) {
              this.allData.Cancelled.push(item);
            } else if (item.active == 4) {
              this.allData.Completed.push(item);
            } else {
              const data = moment(this.farmManager? item.accommodationstart: item[this.dateType],'DD/MM/YYYY');
              const data1 = moment(new Date())
              const data3 = data.diff(data1, "days");
              if (data3 == 0) {
                this.allData.Today.push(item);
              } else if (data3 < 0) {
                this.allData.Overdue.push(item);
              } else {
                this.allData.Upcoming.push(item);
              }
            }
          }
        });
      let arr = [];
      Object.keys(this.allData).forEach((key) => {
        arr = [...arr, { ...this.allData[key] }];
      });
      this.allData = arr;

      let arr1 = [];
      Object.keys(this.rescheduleData).forEach((key) => {
        arr1 = [...arr1, { ...this.rescheduleData[key] }];
      });
      this.rescheduleData = arr1;
      this.$forceUpdate()
    },
  },
};
</script>
<style>
</style>
